import React, { createRef } from 'react';
import {
  Circle,
  FeatureGroup,
  GeoJSON,
  LayersControl,
  Map,
  Marker,
  Polygon,
  Polyline,
  Popup,
  Tooltip
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import FullscreenControl from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/dist/styles.css';
import { GoogleLayer } from 'react-leaflet-google';
import { Link } from 'react-router-dom';

import L from 'leaflet';
import moment from 'moment';

import { DATE_FORMAT } from '../constants/common';

import SiteFeature from './SiteFeature';

const { BaseLayer } = LayersControl;

const IconMarkerDark = new L.Icon({
  iconUrl: require('../assets/images/marker-pin-dark-small.png'),
  iconRetinaUrl: require('../assets/images/marker-pin-dark-small.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5]
});
const IconMarkerAlert = new L.Icon({
  iconUrl: require('../assets/images/marker-pin-alert-16px.png'),
  iconRetinaUrl: require('../assets/images/marker-pin-alert-16px.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5]
});
const IconMarkerStart = new L.Icon({
  iconUrl: require('../assets/images/start-pin.png'),
  iconRetinaUrl: require('../assets/images/start-pin.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5],
  className: 'rg-icon-position'
});
const IconMarkerEnd = new L.Icon({
  iconUrl: require('../assets/images/end-pin.png'),
  iconRetinaUrl: require('../assets/images/end-pin.png'),
  iconAnchor: L.Browser.retina ? [10, 10] : [5, 5],
  className: 'rg-icon-position'
});
const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const satellite = 'SATELLITE';

function Feature(props) {
  let type = props.type;

  let LINE;
  if (type === 'Polygon') {
    LINE = <Polygon positions={props.geometry}></Polygon>;
  }
  if (type === 'LineString') {
    LINE = <Polyline color="#ff0000" positions={props.geometry}></Polyline>;
  }
  if (type === 'Point') {
    if (props.properties.animal_id) {
      LINE = (
        <Circle
          onClick={() => props.setAnimal(props.properties.animal_id)}
          center={props.geometry}
          color="#ffffff"
          weight={0.5}
          fillOpacity="0"
          fillColor="#000000"
          opacity="0"
          radius={1}>
          <Marker
            onClick={() => props.setAnimal(props.properties.animal_id)}
            icon={props.properties.hasAlerts ? IconMarkerAlert : IconMarkerDark}
            draggable={false}
            position={props.geometry}>
            <Tooltip>
              <span>
                <b>Livestock ID </b>: {props.properties.identifier}
              </span>
              <br />
              <span>
                <b>Mgmt Tag ID </b>: {props.properties.eartag_management_id}
              </span>
              <br />
              {props.properties.time ? (
                <span>
                  <b>Last Reading </b>: {moment(props.properties.time).format(DATE_FORMAT.DATETIME)}
                </span>
              ) : null}
            </Tooltip>
          </Marker>
        </Circle>
      );
    } else {
      LINE = (
        <Circle
          center={props.geometry}
          color="#ffffff"
          weight={0.5}
          fillOpacity="0"
          fillColor="#000000"
          opacity="0"
          radius={1}>
          <Marker
            icon={props.properties.hasAlerts ? IconMarkerAlert : IconMarkerDark}
            draggable={false}
            position={props.geometry}></Marker>
        </Circle>
      );

      // SHOW START & END ICONS //
      if (props.properties.customIcon) {
        LINE = (
          <div>
            {' '}
            + LINE LINE +={' '}
            <Circle
              center={props.geometry}
              color="#ffffff"
              weight={0.5}
              fillOpacity="1"
              fillColor="#000000"
              opacity="1"
              radius={1}>
              <Marker
                icon={props.properties.customIcon === 'end' ? IconMarkerStart : IconMarkerEnd}
                zIndexOffset={props.properties.customIcon === 'end' ? 1 : 2}
                draggable={false}
                position={props.geometry}
                opacity={'0.4'}></Marker>
              <Marker
                icon={props.properties.customIcon == 'end' ? IconMarkerStart : IconMarkerEnd}
                zIndexOffset={props.properties.customIcon === 'end' ? 1 : 2}
                draggable={true}
                position={props.geometry}></Marker>
            </Circle>
            ; LINE +={' '}
          </div>
        );
      }

      if (props.properties.last_reading) {
        LINE = (
          <Circle
            center={props.geometry}
            color="#ffffff"
            weight={0.5}
            fillOpacity="0"
            fillColor="#000000"
            opacity="0"
            radius={1}>
            <Marker
              icon={props.properties.hasAlerts ? IconMarkerAlert : IconMarkerDark}
              draggable={false}
              position={props.geometry}>
              <Tooltip>
                <span>
                  <b>Livestock ID </b>: {props.properties.identifier}
                </span>
                <br />
                <span>
                  <b>Mgmt Tag ID </b>: {props.properties.eartag_management_id}
                </span>
                <br />
                {props.properties.time ? (
                  <span>
                    <b>Last Reading </b>: {moment(props.properties.time).format(DATE_FORMAT.DATETIME)}
                  </span>
                ) : null}
              </Tooltip>
            </Marker>
          </Circle>
        );
      }
    }
  }
  return LINE;
}

class MapTrack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: this.props.isFullScreen || false,
      animal: this.props.animal,
      tracking: this.props.tracking,
      position: [-29.132312, 26.057016],
      farm: this.props.farm,
      features_sites: {
        features: []
      },
      bounds: [
        [-31.0, 13.0],
        [-31.0, 33.0]
      ],
      single: this.props.single || false,
      icon: ''
    };
    this.tick = this.tick.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);

    this.drawControlRef = createRef();
    this.featuregroupRef = createRef();
    this.mapRef = createRef();
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  componentDidMount() {
    this.tick(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.tick(nextProps);

    if (nextProps.isFullScreen !== this.props.isFullScreen) {
      this.mapRef.current.leafletElement._onResize();
    }
  }

  componentDidUpdate() {}
  async tick(props) {
    let features = {
      type: 'FeatureGroup',
      features: []
    };
    let geo = {
      type: 'FeatureCollection',
      features: []
    };
    let sts = {
      type: 'Feature',
      features: []
    };
    if (props.farm) {
      let fences = JSON.parse(JSON.stringify(props.farm.geofences));
      let sites = JSON.parse(JSON.stringify(props.farm.sites));
      for (let gf of fences) {
        if (gf.is_master) {
          geo.features.push({ ...gf.geo_json, color: gf.color });
        } else {
          features.features.push({ ...gf.geo_json, color: gf.color });
        }
      }
      for (let st of sites) {
        if (!st.geo_json.type) {
          continue;
        }
        sts.features.push({ ...st.geo_json, color: st.color });
      }
    }

    if (props.farms && props.farms.length > 0) {
      for (let farms of props.farms) {
        for (let gf of farms.geofences) {
          if (gf.is_master) {
            geo.features.push({ ...gf.geo_json, color: gf.color });
          } else {
            features.features.push({ ...gf.geo_json, color: gf.color });
          }
        }
      }
    }

    let tracking = props.tracking;
    tracking.features = tracking.features?.filter(t => t.geometry.coordinates.every(c => c !== null)) || [];

    // ADDED FLAGS FOR FIRST AND END LOCATIONS //
    if (tracking.features && tracking.features.length === 1) {
      let tmpVar = tracking.features.filter(x => x.geometry.type === 'Point');
      tmpVar[0].properties.customIcon = 'start';
      const group = new L.geoJson(tracking);
      let bounds = group.getBounds();
      // ADDING A DUMMY POINT FOR ZOOMING EFFECT //
      bounds._northEast.lng += 0.0001;
      bounds._northEast.lat += 0.0001;

      const corner2 = L.latLng(parseFloat(bounds._southWest?.lng), parseFloat(bounds._southWest?.lat));
      const corner1 = L.latLng(parseFloat(bounds._northEast?.lng), parseFloat(bounds._northEast?.lat));
      bounds = L.latLngBounds(corner1, corner2);
      this.setState({
        bounds
      });
    }
    // ADDED FLAGS FOR FIRST AND END LOCATIONS //
    if (tracking.features && tracking.features.length > 1) {
      let tmpVar = tracking.features.filter(x => x.geometry.type === 'Point');
      tmpVar[0].properties.customIcon = 'start';
      tmpVar[tmpVar.length - 1].properties.customIcon = 'end';

      const group = new L.geoJson(tracking);
      let bounds = group.getBounds();

      // if all points have the same coordinates
      if (bounds._northEast?.lat === bounds._southWest?.lat && bounds._northEast?.lng === bounds._southWest?.lng) {
        // ADDING A DUMMY POINT FOR ZOOMING EFFECT //
        bounds._northEast.lng += 0.0001;
        bounds._northEast.lat += 0.0001;
      }
      const corner2 = L.latLng(parseFloat(bounds._southWest?.lng), parseFloat(bounds._southWest?.lat));

      const corner1 = L.latLng(parseFloat(bounds._northEast?.lng), parseFloat(bounds._northEast?.lat));

      bounds = L.latLngBounds(corner1, corner2);
      this.setState({
        bounds: bounds
      });
    }
    // IF NO TRACKING HISTORY FOUND - SHOW FARM //
    if (tracking.features && tracking.features.length === 0 && geo.features.length) {
      const features = [];
      for (let feat of geo.features) {
        const feature = JSON.parse(JSON.stringify(feat));
        const coords = JSON.parse(JSON.stringify(feature.geometry.coordinates[0]));

        const coordsReverted = [];
        for (let c in coords) {
          let a = L.GeoJSON.coordsToLatLng(coords[c]);
          coordsReverted[c] = [a?.lat, a?.lng];
        }
        feature.geometry.coordinates[0] = coordsReverted;
        features.push(feature);
      }
      const group = new L.geoJson(features);
      let bounds = group.getBounds();

      // ADDING A DUMMY POINT FOR ZOOMING EFFECT //
      bounds._northEast.lng += 0.0001;
      bounds._northEast.lat += 0.0001;

      const corner2 = L.latLng(parseFloat(bounds._southWest?.lng), parseFloat(bounds._southWest?.lat));
      const corner1 = L.latLng(parseFloat(bounds._northEast?.lng), parseFloat(bounds._northEast?.lat));
      bounds = L.latLngBounds(corner1, corner2);
      this.setState({
        bounds: bounds
      });
    }

    this.setState({
      tracking: tracking,
      features_master: geo,
      features: features,
      features_sites: sts
    });
  }

  render() {
    return (
      <Map
        key={JSON.stringify(this.state.tracking)}
        ref={this.mapRef}
        scrollWheelZoom={false}
        bounds={this.state.bounds}
        className="mapobject">
        <FeatureGroup ref={this.featuregroupRef}>
          <EditControl
            ref={this.drawControl}
            position="topright"
            draw={{
              polygon: false,
              polyline: false,
              point: false,
              marker: false,
              circle: false,
              circlemarker: false,
              rectangle: false
            }}
            edit={{
              edit: false,
              delete: false
            }}
          />
          {this.state.tracking &&
            this.state.tracking.features &&
            this.state.tracking.features.map(
              function (i, index) {
                if (
                  (i.properties == undefined || i.properties.isVisible) &&
                  i.geometry.coordinates.every(e => e !== null)
                ) {
                  return (
                    <Feature
                      type={i.geometry.type}
                      geometry={i.geometry.coordinates}
                      setAnimal={this.props.onAnimalClick}
                      properties={i.properties}
                      key={index}></Feature>
                  );
                } else {
                  return;
                }
              }.bind(this)
            )}
        </FeatureGroup>
        <FeatureGroup
          edit={{
            edit: false,
            delete: false
          }}>
          {this.state.features_sites.features.length != 0 &&
            !this.state.new &&
            this.state.features_sites.features.map(i => {
              return (
                <SiteFeature
                  key={JSON.stringify(i)}
                  color={i.color}
                  type={i.geometry.type}
                  geometry={i.geometry.coordinates}
                  properties={i.properties}></SiteFeature>
              );
            })}
        </FeatureGroup>
        <LayersControl position="bottomleft">
          <BaseLayer checked name="Google Maps Satellite">
            <GoogleLayer googlekey={key} maptype={satellite} />
          </BaseLayer>
          <BaseLayer name="Google Maps Hybrid">
            <GoogleLayer googlekey={key} maptype={'HYBRID'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Terrain">
            <GoogleLayer googlekey={key} maptype={'TERRAIN'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Roadmap">
            <GoogleLayer googlekey={key} maptype={'ROADMAP'} />
          </BaseLayer>
        </LayersControl>
        {!L.Browser.mobile && !this.state.animal && <FullscreenControl position="topleft" />}
        <GoogleLayer googlekey={key} maptype={satellite}></GoogleLayer>
        {this.state?.features_master?.features?.map(item => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 3,
                fillOpacity: 0.2,
                opacity: 0.8
              }}>
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
        {this.state?.features?.features?.map(item => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 2,
                fillOpacity: 0.2,
                opacity: 0.8
              }}>
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
      </Map>
    );
  }
}

export default MapTrack;
