import { REDUCERS } from '../../constants/reducers';

import { GatewayService } from '../../services';

export const setGateway = value => ({
  type: REDUCERS.GATEWAY.SET_GATEWAY,
  value
});

export const setAllGateways = value => ({
  type: REDUCERS.GATEWAY.SET_ALL_GATEWAYS,
  value
});

export const fetchGatewaysRequest = () => ({
  type: REDUCERS.GATEWAY.FETCH_GATEWAYS_REQUEST
});

export const fetchGatewaysSuccess = data => ({
  type: REDUCERS.GATEWAY.FETCH_GATEWAYS_SUCCESS,
  payload: data
});

export const fetchGatewaysFailure = error => ({
  type: REDUCERS.GATEWAY.FETCH_GATEWAYS_FAILURE,
  payload: error
});

export const fetchGateways = payload => {
  return async (dispatch, getState) => {
    // const { gateways } = getState().gateway;
    //
    // if (gateways?.length) return;

    dispatch(fetchGatewaysRequest());

    try {
      const { data } = await GatewayService.getGateways(payload);

      dispatch(fetchGatewaysSuccess(data));
    } catch (error) {
      dispatch(fetchGatewaysFailure(error.message));
    }
  };
};

export const getAllGateways = () => {
  return async dispatch => {
    try {
      const { data } = await GatewayService.getAllGateways();
      dispatch(setAllGateways(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const searchGateways = payload => {
  return async dispatch => {
    try {
      const { data } = await GatewayService.searchGateways(payload);
      dispatch(setAllGateways(data));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewGateway = payload => {
  return async dispatch => {
    try {
      const { data } = await GatewayService.createNewGateway(payload);
      dispatch(getAllGateways());
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
