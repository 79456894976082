import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PLANS_STATUS } from '../../constants/subscription';

import LoadingBar from '../../components/LoadingBar';

import Error403 from '../Error403';

const ComponentProvider = ({ children, features }) => {
  const subscriptionState = useSelector(state => state.subscription);
  const userState = useSelector(state => state.user);

  const isAccessible = useMemo(() => {
    return (
      userState.isAdmin ||
      features.includes('default') ||
      (features.some(f => subscriptionState.myMappedFeatureOptions?.[f]) &&
        subscriptionState.myPlan.status === PLANS_STATUS.ACTIVE)
    );
  }, [features, subscriptionState, userState]);

  if (subscriptionState.loading || !userState.user?.id) {
    return <LoadingBar></LoadingBar>;
  }

  return isAccessible ? <div className="component-provider">{children}</div> : <Error403 />;
};

export default ComponentProvider;
