import { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import classnames from 'classnames';
import moment from 'moment';

import axios from '../../axios';

import { DATE_FORMAT } from '../../constants/common';

import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';
import RuleSnoozeIcon from '../../components/RuleSnoozeIcon';

import AppDropdown from '../ui-components/dropdown';

function Priority(props) {
  let priority = props.priority;
  const colors = {
    priority: 'red',
    high: 'red',
    normal: '#FFBF00',
    medium: '#FFBF00',
    low: '#CCCCCC',
    record: '#CCCCCC'
  };

  if (priority) {
    priority = priority.toLowerCase();

    return (
      <div className="priority-tag" style={{ background: colors[priority] }}>
        {props.priority.charAt(0).toUpperCase() + props.priority.slice(1)}
      </div>
    );
  } else {
    return null;
  }
}

export default class Rule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      alert_modal: false,
      delete_modal: false,
      dropdownOpen: false,
      modal_message: '',
      modal_title: '',
      modal_type: '',
      rule: {}
    };

    this.archiveRule = this.archiveRule.bind(this);
    this.editRule = this.editRule.bind(this);
    this.generateDefinition = this.generateDefinition.bind(this);
    this.getRule = this.getRule.bind(this);
    this.initActions = this.initActions.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    await this.getRule();
    this.initActions();
  }

  initActions() {
    const { rule } = this.state;

    this.setState({
      actions: [
        {
          label: 'Snooze rule',
          isVisible: () => !rule.is_snoozed,
          handler: () => this.toggleModal('delete_modal', 'Snooze')
        },
        {
          label: 'Unsnooze rule',
          isVisible: () => rule.is_snoozed,
          handler: () => this.toggleModal('delete_modal', 'Unsnooze')
        },
        {
          label: 'Edit rule',
          isVisible: () => true,
          handler: () => this.editRule()
        },
        {
          label: 'Archive rule',
          isVisible: () => true,
          handler: () => this.archiveRule()
        }
      ]
    });
  }

  async getRule() {
    return new Promise(async resolve => {
      const response = await axios.get('rules/' + this.props.match.params.id, {
        query: this.state.query
      });

      if (response.status === 200) {
        response.data.isDefault = response.data.default_rule === 1;
        this.setState({ rule: response.data }, () => this.generateDefinition());

        return resolve();
      }
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  editRule() {
    if (this.state.rule.isDefault) {
      const modal = 'alert_modal';
      const type = modal;
      const title = 'Alert';
      const message = 'This is a default system Rule which can not be edited or archived.';

      this.setState(state => ({
        ...state,
        [modal]: !state[modal],
        modal_type: type,
        modal_title: title,
        modal_message: message
      }));
    } else {
      this.props.history.push('/rules/builder/' + this.props.match.params.id);
    }
  }

  toggleModal(modal, type) {
    let title = '';
    let message = '';

    if (type === 'Archive') {
      title = 'Archive rule';
      message = 'Are you sure you want to archive this rule? This action cannot be undone.';
    } else if (type === 'Snooze') {
      title = 'Snooze rule';
      message = 'Are you sure you want to snooze this rule?';
    } else if (type === 'Alert') {
      title = 'Alert';
      message = 'This is a default system Rule which can not be edited or archived.';
    } else {
      title = 'Unsnooze rule';
      message = 'Are you sure you want to unsnooze this rule?';
    }

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  generateDefinition() {
    const def = [];
    const data = this.state.rule.data;
    const type = this.state.rule.type.value || this.state.rule.type;
    const default_rule = this.state.rule.default_rule;
    const not_defined = <span>{'? '}</span>;

    if (type === 'intermediate_health') {
      def.push(<span>Any animal moves less than </span>);

      if (data.variables) {
        if (data.variables.percentage) {
          def.push(
            <span>
              <span className="bolded">{data.variables.percentage}%</span>
              {' of '}
            </span>
          );
        }

        if (data.variables.calculated_value) {
          def.push(
            <span>
              <span className="bolded">{data.variables.calculated_value.value}</span>{' '}
            </span>
          );
        }
      }

      if (data.action) {
        def.push(
          <span>
            {'. If rule breached '}
            {data.action.label}{' '}
          </span>
        );
      } else {
        def.push(not_defined);
      }

      this.setState({ rule: { ...this.state.rule, definition: def } });

      return;
    }

    if ((type === 'gateway_disconnected' || type === 'tag_disconnected') && default_rule === 1) {
      const selector = data.selector ? data.selector.label : '';
      const condition = data.condition ? data.condition.label : '';
      const action = data.action ? '. If rule breached ' + data.action.label : '';

      this.setState({ rule: { ...this.state.rule, definition: selector + ' ' + condition + action } });

      return;
    }

    if (data.selector) {
      def.push(
        <span>
          <span className="bolded">{data.selector.label}</span>
          {' livestock labeled '}
        </span>
      );
    } else {
      def.push(not_defined);
    }

    if (data.anchor) {
      def.push(
        <span>
          <span className="bolded">{data.anchor.label}</span>{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }

    if (data.condition) {
      def.push(<span>{data.condition.label} </span>);
    } else {
      def.push(not_defined);
    }

    if (type === 'site_proximity' || type === 'livestock_proximity' || type === 'basic_health') {
      if (data.variables) {
        if (data.variables.distance) {
          def.push(
            <span>
              <span className="bolded">{data.variables.distance} </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }

        if (data.variables.distance_unit) {
          def.push(
            <span>
              <span className="bolded">
                {data.variables.distance_unit.label} {type !== 'basic_health' && ' of '}
              </span>
            </span>
          );
        } else {
          def.push(not_defined);
        }

        if (type === 'basic_health') {
          if (data.variables.timeframe) {
            def.push(
              <span>
                <span className="bolded">{data.variables.timeframe.label}</span>
              </span>
            );
          } else {
            def.push(not_defined);
          }
        }
      }
    }

    if (data.target) {
      def.push(
        <span>
          <span className="bolded">{data.target.label} </span>
        </span>
      );
    }

    if (type === 'site_frequency') {
      if (data.variables) {
        if (data.variables.frequency) {
          def.push(
            <span>
              <span className="bolded">{data.variables.frequency} </span> {' times '}{' '}
            </span>
          );
        } else {
          def.push(not_defined);
        }

        if (data.variables.timeframe) {
          def.push(
            <span>
              <span className="bolded">{data.variables.timeframe.label}</span>
            </span>
          );
        } else {
          def.push(not_defined);
        }
      }
    }

    if (data.action) {
      def.push(
        <span>
          {'. If rule breached '}
          {data.action.label}{' '}
        </span>
      );
    } else {
      def.push(not_defined);
    }

    this.setState({ rule: { ...this.state.rule, definition: def } });
  }

  archiveRule() {
    if (this.state.rule.isDefault) {
      this.toggleModal('alert_modal', 'Alert');
    } else {
      this.toggleModal('delete_modal', 'Archive');
    }
  }

  async deleteRule() {
    let response = {};

    if (this.state.modal_type === 'Archive') {
      response = await axios.delete('rules/' + this.props.match.params.id, {
        data: { force: true }
      });
    } else if (this.state.modal_type === 'Snooze') {
      response = await axios.put(`rules/${this.props.match.params.id}/snooze`, {
        seconds: 86400
      });
    } else {
      response = await axios.put(`rules/${this.props.match.params.id}/snooze`, {
        seconds: -86400
      });
    }

    if (response.status && response.status === 200) {
      this.props.history.push('/rules');
    }
  }

  render() {
    if (this.state.rule.id === null) return null;

    const rulePriority = {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      priority: 'High',
      normal: 'Medium',
      record: 'Low'
    };

    return (
      <>
        <Row className="ruls-row">
          <Col xs="8" md="8" lg="8" className="rcol8">
            <h4 className="">Rule {this.state.rule.name}</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/rules')}>
                  List of Rules
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>Rule {this.state.rule.name}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="4" md="4" lg="4" className="rcol4">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.rule.next_id}
              prevId={this.state.rule.prev_id}
              path="rule"
            />

            <AppDropdown
              className="d-flex justify-content-end"
              label="Actions"
              items={this.state.actions.filter(item => item.isVisible())}
              handleClick={action => action.handler()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-15 global-heading-tag">
                <Col xs="12" md="12" lg="6">
                  <h4>Rule {this.state.rule.name || this.state.rule.identifier} details</h4>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Rule ID</b>
                        </td>
                        <td>
                          {this.state.rule.identifier} {this.state.rule.is_snoozed && <RuleSnoozeIcon></RuleSnoozeIcon>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Name</b>
                        </td>
                        <td>{this.state.rule.name}</td>
                      </tr>
                      {this.state.rule.is_snoozed && (
                        <tr>
                          <td>
                            <b>Rule Snoozed Until</b>
                          </td>
                          <td>{moment(this.state.rule.unsnooze_at).format(DATE_FORMAT.DATETIME)}</td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <b>Rule Type</b>
                        </td>
                        <td>{this.state.rule.type_display_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Priority</b>
                        </td>
                        <td>
                          {this.state.rule.data && this.state.rule.data.action && (
                            <>
                              <Priority priority={rulePriority[this.state.rule.data.action.value]} />
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Status</b>
                        </td>
                        <td>{this.state.rule.is_snoozed ? 'Snoozed' : 'Active'}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Created</b>
                        </td>
                        <td>{moment(this.state.rule.created_at).format(DATE_FORMAT.DATETIME)}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Definition</b>
                        </td>
                        <td>{this.state.rule.definition}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Action</b>
                        </td>
                        <td className="capitalize">
                          {this.state.rule.data && this.state.rule.data.action && (
                            <>{this.state.rule.data.action.label}</>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Objects</b>
                        </td>
                        <td>
                          {this.state.rule.objects_count} (Livestock: {this.state.rule.animals_count}, Sites:{' '}
                          {this.state.rule.sites_count}, Geofences: {this.state.rule.geofences_count}, Gateways:{' '}
                          {this.state.rule.gateway_count}, Tags: {this.state.rule.tag_count})
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Triggers</b>
                        </td>
                        <td>{this.state.rule.triggers_count}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>User Actions</b>
                        </td>
                        <td>
                          <>
                            Snoozed on{' '}
                            {this.state.rule.snooze_history &&
                              this.state.rule.snooze_history
                                .map(x => moment(x.created_at).format(DATE_FORMAT.DATETIME))
                                .join(', ')}
                            <br></br>
                          </>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Rule Description</b>
                        </td>
                        <td>{this.state.rule.description}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Linked groups</strong>
                        </td>
                        <td>
                          {this.state.rule?.labels?.map((item, index) => {
                            let link = '';
                            if (index > 0) {
                              link = (
                                <>
                                  ,{' '}
                                  <Link key={item.identifier} to={`/group/${item.id}`}>
                                    {item.identifier}
                                  </Link>
                                </>
                              );
                            } else
                              link = (
                                <Link key={item.identifier} to={`/group/${item.id}`}>
                                  {item.identifier}
                                </Link>
                              );
                            return link;
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Card>
          <Row className="pad-10 pb-0">
            <Col sm="12" className="mb-0">
              <h5>History</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs className="fancy-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => this.toggle('1')}>
                    Notifications
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12" lg="12">
              <TabContent className="no-bg" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Alerts type="full" query={{ rule_ids: [this.props.match.params.id] }}></Alerts>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Card>

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRule()}>
              {this.state.modal_type}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.alert_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('alert_modal')}>
              Close
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
