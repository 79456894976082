import React, { memo, useState } from 'react';

import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Input } from 'antd';

function MultiSelectDropdown({ columns, onChange }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const options = columns.map(({ key, title }) => ({
    value: key,
    label: title
  }));
  const [selectedValues, setSelectedValues] = useState(options.map(value => value.value));
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = e => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchValue));
  const menuItems = (
    <div style={{ padding: '5px' }}>
      <Input
        placeholder="Search column"
        prefix={<SearchOutlined />}
        value={searchValue}
        onChange={handleSearchChange}
        style={{ marginBottom: '8px', color: 'rgba(0,0,0,.25)' }}
      />
      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        {filteredOptions.map(option => (
          <Checkbox
            key={option.value}
            style={{ width: '100%' }}
            checked={selectedValues.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}>
            {option.label}
          </Checkbox>
        ))}
      </div>
    </div>
  );

  const handleCheckboxChange = checkedValue => {
    const updatedValues = selectedValues.includes(checkedValue)
      ? selectedValues.filter(val => val !== checkedValue)
      : [...selectedValues, checkedValue];

    setSelectedValues(updatedValues);
    onChange?.(updatedValues);
  };

  return (
    <Dropdown
      open={isMenuOpen}
      overlay={menuItems}
      trigger={['click']}
      placement="bottomRight"
      overlayStyle={{
        backgroundColor: 'white',
        maxHeight: 500,
        overflow: 'auto',
        width: '200px',
        boxShadow: '0 5px 10px rgba(0, 0, 0, .5)'
      }}>
      <Button variant="outlined" onClick={() => setIsMenuOpen(prevState => !prevState)}>
        {selectedValues.length > 0 ? `Visible columns (${selectedValues.length})` : 'Select Options'}
        <DownOutlined style={{ marginLeft: '8px' }} />
      </Button>
    </Dropdown>
  );
}

export default memo(MultiSelectDropdown);
