import { ANIMAL_ERRORS } from '../constants/livestock';

export const formatAnimalList = list => {
  return list.map(animal => ({
    ...animal,
    breedName: animal.breed?.display_name ?? '',
    tagIdentifier: animal?.tag?.diagri_id,
    farmName: animal.farm?.name,
    livestockType: animal?.stock_type,
    officialTagId: animal?.eartag_official_id,
    earMark: animal?.ear_mark,
    specieName: animal?.specie?.display_name,
    polledHorned: animal?.polled_horned,
    dob: animal?.dob_at,
    description: animal?.name,
    purchaseDate: animal?.purchase_date,
    purchasedFrom: animal?.purchased_from,
    purchasedWhere: animal?.purchased_where,
    purchaseNotes: animal?.purchase_notes,
    lossDate: animal?.loss_date,
    lossReason: animal?.loss_reason,
    soldTo: animal?.sold_to,
    dateSold: animal?.date_sold,
    sireId: animal?.sire_id,
    damId: animal?.dam_id
  }));
};

export const formatAnimalsPayload = (animals, additionalData) => {
  return animals.map(animal => {
    const farmId = animal.farm?.id;
    const farm = additionalData?.farms?.find(f => f.id === farmId);
    const masterGeofenceId = farm.master_geofence.id;
    const customGeofence = animal?.geofences.find(g => !g.is_master && g.geofence_id === masterGeofenceId)?.id;
    const sex = animal?.sex?.value ? animal?.sex?.value : animal?.sex;

    return {
      id: animal.id,
      eartag_management_id: animal.eartag_management_id,
      colour: animal.colour,
      breedName: (animal.breedName?.label ? animal.breedName.label : animal.breedName) || null,
      sex: sex,
      farm_id: animal.farm?.id,
      kraal_tag_id: animal.tagIdentifier,
      brand: animal.brand,
      geofence_ids: customGeofence ? [masterGeofenceId, customGeofence] : [masterGeofenceId],
      stock_type: animal.livestockType?.label ? animal.livestockType.label : animal.livestockType,
      eartag_official_id: animal.officialTagId,
      ear_mark: animal.earMark,
      specie: (animal.specieName?.label ? animal.specieName.label : animal.specieName) || null,
      dob_at: animal.dob,
      polled_horned: animal.polledHorned?.label ? animal.polledHorned.label : animal.polledHorned,
      name: animal.name,
      sire_id: animal.sireId,
      dam_id: animal.damId,
      purchase_date: animal.purchaseDate,
      purchased_from: animal.purchasedFrom,
      purchased_where: animal.purchasedWhere,
      purchase_notes: animal.purchaseNotes,
      date_sold: animal.date_sold,
      sold_to: animal.soldTo,
      loss_date: animal.loss_date,
      loss_reason: animal.lossReason,
      comments: animal.comments
    };
  });
};

export const isGeofenceMatchedWithPhysicalGroup = animalData => {
  const physicalGroup = animalData?.labels?.find(i => i.main_type === 'physical');

  if (!physicalGroup) return true;
  const physicalGroupGeofenceId = physicalGroup?.geofences?.[0]?.id;
  if (!physicalGroupGeofenceId) return null;
  const animalGeofencesIds = animalData?.geofence_ids;

  const isGeofencesMatched = animalGeofencesIds.find(id => id === physicalGroupGeofenceId);
  return !!isGeofencesMatched;
};

export const getConfirmationModalDescription = flag => {
  if (flag === null) return ANIMAL_ERRORS.NOT_ASSIGNED_TO_GEOGENCE;
  if (!flag) return ANIMAL_ERRORS.ASSIGNED_TO_DIFFERENT_GEOGENCE;
  return '';
};
