import { memo } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider/lib/Slider';

import { DETAIL_SLIDER } from './constants';

function TimeSliders({ sliderHandler }) {
  const timeFrameSlots = {};
  const timeIntervalSlots = {};

  for (const i in DETAIL_SLIDER.timeFrameSlots) {
    const index = DETAIL_SLIDER.timeFrameSlots[i].index;
    timeFrameSlots[index] = DETAIL_SLIDER.timeFrameSlots[i].label;
  }

  for (const i in DETAIL_SLIDER.timeIntervalSlots) {
    const index = DETAIL_SLIDER.timeIntervalSlots[i].index;
    timeIntervalSlots[index] = DETAIL_SLIDER.timeIntervalSlots[i].label;
  }

  return (
    <Card className="animal-single-detail-page mb-1 range-sliders">
      <CardBody>
        <Row>
          <Col lg="6" className="r-slider-left">
            <Col className="text-left">
              <b>Time Frame</b>
            </Col>
            <Slider
              step={null}
              marks={timeFrameSlots}
              onAfterChange={e => sliderHandler(e, 'time_frame')}
              defaultValue={66}
            />
          </Col>

          <Col lg="6" className="r-slider-right">
            <Col className="text-left">
              <b>Time Interval</b>
            </Col>
            <Slider
              step={null}
              marks={timeIntervalSlots}
              onAfterChange={e => sliderHandler(e, 'time_interval')}
              defaultValue={0}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default memo(TimeSliders);
