import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import { admin } from './reducers/admin';
import { animal } from './reducers/animal';
import { breed } from './reducers/breed';
import { calendar } from './reducers/calendar';
import { event } from './reducers/event';
import { farm } from './reducers/farm';
import { gateway } from './reducers/gateway';
import { location } from './reducers/location';
import { notification } from './reducers/notification';
import { subscription } from './reducers/subscription';
import { tag } from './reducers/tag';
import { task } from './reducers/task';
import { user } from './reducers/user';

const rootReducer = {
  admin,
  animal,
  breed,
  calendar,
  event,
  farm,
  gateway,
  location,
  notification,
  subscription,
  tag,
  task,
  user
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk)
});

export default store;
