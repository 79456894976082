import React from 'react';
import ReactTable from 'react-table';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from 'reactstrap';

import axios from '../../axios';

import Search from '../../components/Search/Search';
import Filters from '../../components/filters/index.jsx';
import StatusIndicator from '../../components/statusIndicator';

import { SocketContext } from '../../context/socket';

class Gateways extends React.Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      gateways: [],
      query: '',
      filters: {
        farms: []
      },
      filters_open: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.getGateways = this.getGateways.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
  }

  onChange = (value, field) => {
    this.setState(state => ({
      ...state,
      [field]: value
    }));
  };
  handleKeyPress = charCode => {
    if (charCode === 13) {
      this.getGateways();
    }
  };
  onCheckboxChange = (value, field) => {
    this.setState(state => ({
      ...state,
      [field]: !state[field]
    }));
  };
  onTagChange(value, field) {
    let nt = this.state.edit_tag;
    nt[field] = value;
    this.setState(state => ({
      ...state,
      edit_tag: nt
    }));
  }

  filtersUpdated(filters) {
    console.log('filters', filters);
    this.setState(
      {
        filters: filters
      },
      () => this.getGateways()
    );
  }

  componentDidMount() {
    this.getGateways();
  }
  componentDidUpdate() {
    const { socket } = this.context;
    if (socket && !this.state.socket) {
      this.setState({
        socket
      });

      socket.on('gatewayStatus', data => {
        this.setState({
          gateways: this.state.gateways.map(item => {
            let status = item.status;
            if (data.ids.includes(item.id)) {
              status = data.status;
            }
            return {
              ...item,
              status
            };
          })
        });
      });
    }
  }
  async getGateways() {
    let response = await axios.get('gateways', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map(x => x.value)
      }
    });
    if (response.status === 200) {
      this.setState(state => ({
        ...state,
        gateways: response.data
      }));
    }
  }
  toggleModal(modal) {
    this.setState(state => ({
      ...state,
      [modal]: !state[modal]
    }));
  }

  filtersClose() {
    this.setState(state => ({
      ...state,
      filters_open: false
    }));
  }

  //
  render() {
    const columns = [
      {
        Header: 'Gateway ID',
        id: 'id',
        accessor: d => {
          return { id: d.id, appeui: d.identifier, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        Cell: props => (
          <a href="#" onClick={() => this.props.history.push('gateway/' + props.value.id)}>
            {props.value.appeui}
          </a>
        )
      },
      {
        Header: 'Gateway Name',
        accessor: d => {
          return { id: d.id, name: d.name, identifier: d.name ? d.name : '' };
        },
        headerClassName: 'wordwrap',
        id: 'name',
        Cell: props => (
          <a href="#" onClick={() => this.props.history.push('gateway/' + props.value.id)}>
            {props.value.name}
          </a>
        )
      },
      {
        Header: 'Connection Status',
        id: 'status',
        accessor: d => {
          return { id: d.id, status: d.status, identifier: d.status };
        },
        headerClassName: 'wordwrap',
        Cell: props => (
          <div>
            <StatusIndicator key={props.value.id} status={props.value.status} />
            {props.value.status?.charAt(0).toUpperCase() + props.value.status?.slice(1)}
          </div>
        )
      },
      {
        Header: 'Connected Kraal Tags',
        accessor: 'connected_tags_count',
        headerClassName: 'wordwrap'
      }
    ];

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (row[id]) {
        if (row[id] && typeof row[id] == 'object') {
          if (typeof row[id]['identifier'] == 'number') {
            return row[id]['identifier'] !== undefined ? String(row[id]['identifier']).includes(filter.value) : true;
          } else {
            return row[id] !== undefined
              ? String(row[id]['identifier'].toLowerCase()).includes(filter.value.toLowerCase())
              : true;
          }
        } else if (typeof row[id] == 'number') {
          return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
        } else {
          return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
        }
      }
    }

    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm']}></Filters>
        <Row>
          <Col xs="12" md="12" lg="12"></Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Kraal Gateways</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Kraal Gateways</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
                  <Search
                    className="mr-2"
                    id="search-kraal-gateways"
                    placeholder="Search Kraal Gateways"
                    defaultValue={this.state.query}
                    handleChange={e => this.onChange(e.target.value, e.target.name)}
                    handleKeyPress={() => this.handleKeyPress(13)}
                  />

                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() => this.setState({ ...this.state, filters_open: !this.state.filters_open })}>
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={this.state.gateways.length > 0}
                    minRows={0}
                    data={this.state.gateways}
                    columns={columns}
                    resizable={true}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Gateways;
