import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import { Select } from 'antd';
import moment from 'moment';

import axios from '../../axios';

import { capitalize, downloadCSV } from '../../helpers/common';
import { formatDateDifference } from '../../helpers/date';
import { sortDates, sortNumbers, sortStrings } from '../../helpers/filter';
import { formatAnimalList, formatAnimalsPayload } from '../../helpers/livestock';
import { getTableColumns } from '../../helpers/subscription';

import { CONNECTION_STATUSES, DATE_FORMAT, EDIT_COMPONENT_TYPE, SELECT_OPTIONS } from '../../constants/common';
import { ANIMAL_ERRORS } from '../../constants/livestock';
import messages from '../../constants/messages';
import { FEATURE_LIST } from '../../constants/subscription';

import { fetchBreedingStatuses, fetchBreeds } from '../../redux/actions/breed';
import { getFarms } from '../../redux/actions/farm';
import { fetchLocations } from '../../redux/actions/location';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { errorToastHandler, infoToastHandler, successToastHandler } from '../../components/action_notifier';
import AnimalPopup from '../../components/animal_popup';
import Livestock from '../../components/animals/Livestock';
import StatusIndicator from '../../components/statusIndicator';

import { AnimalService } from '../../services';

export default function Livestocks(props) {
  const dispatch = useDispatch();
  const subscription = useSelector(state => state.subscription);
  const isAdmin = useSelector(state => state.user.isAdmin);
  const { filterOptions, selectOptions } = useSelector(state => state.location);
  const { breedsFilterOptions, breedsSelectOptions, statusesFilterOptions } = useSelector(state => state.breed);
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [state, setState] = useState({
    activeTab: '1',
    add_modal: false,
    animalType: 'linked',
    animals: [],
    breed_opts: [],
    columnFilterOptions: {
      farm: []
    },
    csv_farm: undefined,
    csv_modal: false,
    delete_animals: [],
    delete_modal: false,
    editableIds: [],
    farm_opts: [],
    farms: [],
    filters: { farms: props?.location?.filter?.farms || [], geofences: [], labels: [] },
    isGeofenceChanged: false,
    isOpenConfirmationModal: false,
    isOpenSelectionPopover: false,
    label_modal: false,
    link_modal: false,
    new_animal: {},
    query: '',
    query_tag: '',
    selectAll: false,
    selectAllUnLinked: false,
    selected_animals: [],
    selected_animals_linked: [],
    selected_animals_unlinked: [],
    selected_farm: null,
    selected_geofences: [],
    selected_labels: [],
    selected_sex: null,
    selected_specie: null,
    selected_tags: [],
    selected_type: {},
    showAnimalPopup: false,
    showPopup: false,
    specie_opts: [
      { value: 'bovine', label: 'Bovine' },
      { value: 'ovine', label: 'Ovine' },
      { value: 'caprine', label: 'Caprine' }
    ],
    sucess_modal: false,
    tableData: [],
    tags: [],
    tempModalValue: null,
    type_opts: [],
    uploadMessage: ''
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const [tags, livestocks, farms, labels, types] = await Promise.all([
          axios.get('tags', {
            params: {
              only_linked: false,
              query: state.query_tag
            }
          }),
          axios.get('animals', {
            params: {
              query: state.query,
              farm_ids: state.filters.farms.map(x => x.value),
              geofence_ids: state.filters.geofences.map(x => x.value),
              label_ids: state.filters.labels.map(x => x.value)
            }
          }),
          dispatch(getFarms()),
          getLabels(),
          getTypes()
        ]);

        const animalsList = formatAnimalList(livestocks?.data);

        setState(prevState => ({
          ...prevState,
          actualData: [...animalsList],
          animals: [...animalsList],
          maleLivestocks: animalsList.filter(s => s.sex === 'male'),
          femaleLivestocks: animalsList.filter(s => s.sex === 'female'),
          farms,
          farm_opts: farms?.map(farm => ({
            value: farm.id,
            label: farm.name,
            id: farm.id,
            meta: {
              features: farm.geofences
            }
          })),
          columnFilterOptions: {
            ...state.columnFilterOptions,
            farm: farms?.map(farm => ({ value: farm.id, text: farm.name }))
          },
          label_opts: labels?.data.map(x => ({ value: x.id, label: x.name, color: x.colour })),
          tableData: [...animalsList],
          tags: tags?.data,
          type_opts: types?.data
        }));
      } catch (error) {
        errorToastHandler('Failed to fetch data');
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
    initActions();
  }, []);

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchBreedingStatuses());
    dispatch(fetchBreeds());
  }, [dispatch]);

  useEffect(() => {
    initActions();
  }, [state.selected_animals]);

  function initActions() {
    setActions([
      {
        label: 'Add livestock',
        isVisible: true,
        handler: () => props?.history.push('/animal_new')
      },
      {
        label: 'Upload livestock',
        isVisible: true,
        handler: () => toggleModal('csv_modal')
      },
      {
        label: 'Download livestock list',
        isVisible: true,
        handler: () => downloadList()
      },
      {
        label: 'Archive livestock',
        isVisible: state.selected_animals.length,
        handler: () => setDeleteTags()
      }
    ]);
  }

  async function downloadList() {
    let livestockIds =
      state.selected_animals.length > 0
        ? state.selected_animals
            .map(x => {
              return x;
            })
            .join(',')
        : '';
    const url = livestockIds ? `/animals/export?livestock_ids=${livestockIds}` : '/animals/export';
    const response = await axios.get(url, { responseType: 'blob' });

    downloadCSV(response.data, `Livestock_${moment().format(DATE_FORMAT.DATE)}.xlsx`);
  }

  function setDeleteTags() {
    setState(prevState => ({ ...prevState, delete_animals: state.selected_animals }));
    toggleModal('delete_modal');
  }

  async function handleTableValueChange(key, value, record) {
    const data = [...state.tableData];
    const rowIndex = data.findIndex(item => item.id === record.id);

    if (rowIndex !== -1) {
      data[rowIndex] = { ...data[rowIndex], [key]: value };

      await onClickSaveEdit([data[rowIndex]]);

      setState(prevState => ({ ...prevState, tableData: data }));
    }
  }

  function handleCSVChange(event) {
    setState(prevState => ({ ...prevState, csvfile: event.target.files[0], uploading: false }));
  }

  function onChange(value, field) {
    setState(prevState => ({ ...prevState, [field]: value }));
  }

  async function handleKeyPress(charCode) {
    if (charCode === 13) await getLivestocks();
  }

  async function uploadCSV() {
    let csv = state.csvfile;
    let formData = new FormData();
    formData.append('file', csv);
    formData.append('farm_id', state.csv_farm?.value);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    const response = await axios.post('animals/csv', formData, config);
    await getLivestocks();
    toggleModal('csv_modal');
    setState(prevState => ({ ...prevState, uploadMessage: response.data.message }));
    toggleModal('sucess_modal');
  }

  function getLabels() {
    return axios.get('labels?type=custom');
  }

  function selectRecords(selected) {
    setState(prevState => ({
      ...prevState,
      selected_animals: selected,
      selectAll: prevState.animals.length === selected.length
    }));
  }

  async function getTags() {
    const response = await axios.get('tags', {
      params: {
        only_linked: false,
        query: state.query_tag
      }
    });

    if (response.status === 200) setState(prevState => ({ ...prevState, tags: response.data }));
  }

  async function linkTag() {
    const animalId = state.selected_animals?.at(0);
    const animal = state.animals?.find(a => a.id === animalId);
    let response;

    if (state.re_linking) {
      response = await axios.put('links', {
        tag_id: state.selected_tags?.at(0),
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: state.selected_tags?.at(0),
        animal_id: animal.id
      });
    }

    if (response.status === 200) {
      await getTags();
      toggleModal('link_modal');
      setState(prevState => ({ ...prevState, re_linking: false, linking: false }));

      window.location.reload();
    }
  }

  function handleCSVFarmChange(selectedOption) {
    setState(prevState => ({ ...prevState, csv_farm: selectedOption }));
  }

  function toggleModal(modal) {
    setState(prevState => ({ ...prevState, [modal]: !state[modal] }));
  }

  async function getLivestocks() {
    const response = await axios.get('animals', {
      params: {
        query: state.query,
        farm_ids: state.filters.farms.map(x => x.value),
        geofence_ids: state.filters.geofences.map(x => x.value),
        label_ids: state.filters.labels.map(x => x.value)
      }
    });

    if (response.status === 200) {
      const animalsList = formatAnimalList(response.data);
      const maleLivestocks = animalsList.filter(s => s.sex === 'male');
      const femaleLivestocks = animalsList.filter(s => s.sex === 'female');

      setState(prevState => ({
        ...prevState,
        tableData: [...animalsList],
        animals: [...animalsList],
        actualData: animalsList.map(i => ({ ...i })),
        maleLivestocks: maleLivestocks,
        femaleLivestocks: femaleLivestocks
      }));
    }
  }

  async function deleteTag() {
    let isAnimalWithTag = false;

    for (let index = 0; index < state.selected_animals.length; index++) {
      const animalId = state?.selected_animals?.[index];
      const animal = state.animals.find(a => a.id === animalId);
      if (animal?.tag?.id) {
        isAnimalWithTag = true;
        break;
      }
    }

    if (isAnimalWithTag) {
      infoToastHandler(messages.ANIMAL_UNLINK_TAG_REQUIRED);
    } else {
      const response = await axios.delete('animals/bulk', {
        data: {
          ids: state.selected_animals
        }
      });

      if (response.status === 200) {
        await getTags();
        await getLivestocks();

        toggleModal('delete_modal');
        successToastHandler('Archived successfully!');
      }
    }
  }

  function getLabel(id) {
    const tags = state.tags?.filter(t => t.id === id);

    return tags?.length > 0 ? tags.at(0).diagri_id : '';
  }

  function getBreeds() {
    return axios.get('breeds');
  }

  function getTypes() {
    return axios.get('/animals/stock-types');
  }

  function handleLabelAddChange(selectedOption) {
    setState(prevState => ({ ...prevState, selected_labels_add: selectedOption }));
  }

  async function linkLabels() {
    const response = await axios.post('/labels/bulk/animals', {
      animal_ids: state.selected_animals_unlinked.concat(state.selected_animals_linked),
      label_ids: state.selected_labels_add.map(x => x.value)
    });

    if (response.status === 200) toggleModal('label_modal');
  }

  async function onClickSaveEdit(data) {
    let updatedData;
    if (!data) {
      const ids = state.editableIds;
      updatedData = state.tableData.filter(item => ids.includes(item.id));
    } else {
      updatedData = Array.isArray(data) ? data : [data];
    }

    const additionalData = { farms: state.farms };
    const payload = {
      animals: formatAnimalsPayload(updatedData, additionalData)
    };

    try {
      await AnimalService.updateAnimals(payload);
      await getLivestocks();

      setState(prevState => ({
        ...prevState,
        editableIds: [],
        selected_animals: [],
        selected_animals_linked: [],
        selected_animals_unlinked: []
      }));

      successToastHandler('Updated successfully!');
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  }

  function showAnimalPopup(key, liveStock) {
    setState(prevState => ({
      ...prevState,
      showPopup: true,
      animal_popup_field: key || null,
      filtered_popup_animals: liveStock || []
    }));
  }

  function onAnimalSelect(field, value) {
    if (field === 'sire_id') field = 'sireId';
    if (field === 'dam_id') field = 'damId';

    handleTableValueChange(field, value, state.tempModalValue);
    closeAnimalPopup();
  }

  function closeAnimalPopup() {
    setState(prevState => ({
      ...prevState,
      showPopup: false,
      animal_popup_field: null,
      filtered_popup_animals: [],
      tempModalValue: null
    }));
  }

  const columns = [
    {
      title: 'Livestock ID',
      dataIndex: 'identifier',
      ellipsis: true,
      fixed: 'left',
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: id => <Link to={`/animal/${id}`}>{id}</Link>,
      sorter: (a, b) => a.identifier.localeCompare(b.identifier)
    },
    {
      title: 'Mgmt Tag ID',
      dataIndex: 'eartag_management_id',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.XL,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      },
      rules: [{ required: true, message: 'Mgmt Tag ID is required.' }]
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: SELECT_OPTIONS.GENDER
      },
      ellipsis: true,
      width: COLUMN_SIZE.SM,
      sortDirections: ['ascend', 'descend'],
      filters: SELECT_OPTIONS.GENDER.map(option => ({ text: option.label, value: option.value })),
      onFilter: (value, record) => record.sex.startsWith(value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.sex, b.sex)
    },
    {
      title: 'Colour',
      dataIndex: 'colour',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.SM,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.colour, b.colour),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Breed',
      dataIndex: 'breedName',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: breedsFilterOptions,
      onFilter: (value, record) => record?.breedName.startsWith(value),
      sorter: (a, b) => sortStrings(a.breedName, b.breedName),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: breedsSelectOptions
      }
    },
    {
      title: 'Kraal Tag ID',
      dataIndex: 'tagIdentifier',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const searchField = record.tagIdentifier || 'Unlinked';

        return searchField.toString().toLowerCase().includes(value.toLowerCase());
      },
      render: (value, record) => (record?.tag?.id ? <Link to={`/tag/${record?.tag?.id}`}>{value}</Link> : 'Unlinked'),
      sorter: (a, b) => sortStrings(a.tagIdentifier, b.tagIdentifier),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      editable: true,
      ellipsis: true,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.brand, b.brand),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Farm',
      dataIndex: 'farm',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.XL,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: state.columnFilterOptions.farm,
      onFilter: (value, record) => record?.farm_id === value,
      sorter: (a, b) => sortStrings(a.farmName, b.farmName),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: state.farm_opts,
        valueKey: 'id'
      }
    },
    {
      title: 'Assigned Geofence',
      dataIndex: 'assignedGeofence',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: filterOptions,
      onFilter: (value, record) => record?.geofences?.map(g => g.id).includes(value),
      render: (value, record) => {
        const farm = state.farms?.find(i => i.id === record.farm_id);
        const currentGeofence = record.geofences?.find(g => !g?.is_master);
        const farmGeofences = farm?.geofences.filter(g => !g?.is_master);
        let geofenceValue;

        if (farmGeofences?.find(g => g?.id === currentGeofence?.id)) {
          geofenceValue = {
            ...currentGeofence,
            value: currentGeofence.value,
            label: currentGeofence.name
          };
        }

        const handleOnChange = () => {
          const data = [...state.tableData];
          setState(
            prevState => ({ ...prevState, editableIds: [record.id], tableData: data, isGeofenceChanged: true }),
            async () => {
              if (state.isGeofenceChanged) {
                setState(prevState => ({ ...prevState, isOpenConfirmationModal: true }));
              }
            }
          );
        };

        return (
          <Select
            variant="borderless"
            options={selectOptions}
            style={{ width: '100%' }}
            value={geofenceValue ?? {}}
            onChange={handleOnChange}
          />
        );
      },
      sorter: (a, b) => sortStrings(a.assigned_geofences.at(0), b.assigned_geofences.at(0))
    },
    {
      title: 'Livestock Type',
      dataIndex: 'livestockType',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: state.type_opts.map(type => ({ value: type.value, text: type.label })),
      onFilter: (value, record) => record?.livestockType?.startsWith(value),
      onCell: d => (d.livestockType?.label ? d.livestockType.label : d.livestockType) ?? '',
      render: value => capitalize(value),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: state.type_opts
      },
      sorter: (a, b) => sortStrings(a.livestockType, b.livestockType)
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: COLUMN_SIZE.MD,
      searchable: true,
      ellipsis: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const age = formatDateDifference(record.dob_at);

        return age.includes(value.toLowerCase());
      },
      render: (value, record) => formatDateDifference(record.dob_at),
      sorter: (a, b) => sortDates(a.dob_at, b.dob_at)
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      ellipsis: true,
      width: COLUMN_SIZE.SM,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.weight, b.weight)
    },
    {
      title: 'Breeding Status',
      dataIndex: 'breeding_status',
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: statusesFilterOptions,
      onFilter: (value, record) => record?.breeding_status === value,
      sorter: (a, b) => sortStrings(a.breeding_status, b.breeding_status)
    },
    {
      title: 'Calf ID',
      dataIndex: 'calf_livestock_identifier',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) =>
        record ? <Link to={`/animal/${record.calf_livestock_id}`}>{record.calf_livestock_identifier}</Link> : null,
      sorter: (a, b) => sortStrings(a.calf_livestock_identifier, b.calf_livestock_identifier)
    },
    {
      title: 'Calf Mgmt Tag ID',
      dataIndex: 'calf_mgmt_tag_id',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => record.calf_mgmt_tag_id,
      sorter: (a, b) => sortStrings(a.calf_livestock_identifier, b.calf_livestock_identifier)
    },
    {
      title: 'Calf Age',
      dataIndex: 'calf_age',
      ellipsis: true,
      width: COLUMN_SIZE.SM,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const age = formatDateDifference(record.calf_age);

        return age.includes(value.toLowerCase());
      },
      render: (value, record) => formatDateDifference(record.calf_age),
      sorter: (a, b) => sortDates(a.calf_age, b.calf_age)
    },
    {
      title: 'Connection Status',
      dataIndex: 'connectionStatus',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      sortDirections: ['ascend', 'descend'],
      filters: CONNECTION_STATUSES,
      onFilter: (value, record) => record?.tag?.status.startsWith(value),
      sorter: (a, b) => sortStrings(a?.tag?.status, b?.tag?.status),
      render: (value, record) => {
        const status = record?.tag?.status;
        const id = record?.id;

        return (
          record && (
            <div className="d-flex align-items-center">
              <StatusIndicator key={id} status={status} />
              {capitalize(status)}
            </div>
          )
        );
      }
    },
    {
      title: 'Last Reading',
      dataIndex: 'lastReading',
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => record?.tag?.timestamp_at?.includes(value.toLowerCase()),
      sorter: (a, b) => sortDates(a.tag?.timestamp_at, b.tag?.timestamp_at),
      render: (value, record) => {
        const lastReadingTime = record?.tag?.timestamp_at;

        return lastReadingTime ? moment(lastReadingTime).format(DATE_FORMAT.DATETIME) : null;
      }
    },
    {
      title: 'Official Tag ID',
      dataIndex: 'officialTagId',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.officialTagId, b.officialTagId),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Ear Mark',
      dataIndex: 'earMark',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.SM,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.earMark, b.earMark),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Specie',
      dataIndex: 'specieName',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (record.specieName?.label ? record.specieName.label : record.specieName) ?? '',
      sorter: (a, b) => sortStrings(a.specieName, b.specieName),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: SELECT_OPTIONS.SPECIE
      }
    },
    {
      title: 'DoB',
      dataIndex: 'dob',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (record.dob ? moment(record.dob).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.dob, b.dob),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.DATE_PICKER
      }
    },
    {
      title: 'Polled/Horned',
      dataIndex: 'polledHorned',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      sortDirections: ['ascend', 'descend'],
      filters: SELECT_OPTIONS.POLLED_HORNED.map(option => ({ text: option.label, value: option.value })),
      onFilter: (value, record) => record?.polledHorned?.startsWith(value),
      render: (value, record) => (record.polledHorned?.label ? record.polledHorned.label : record.polledHorned) ?? '',
      sorter: (a, b) => sortStrings(a.polledHorned, b.polledHorned),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: SELECT_OPTIONS.POLLED_HORNED
      }
    },
    {
      title: 'Description',
      dataIndex: 'name',
      editable: true,
      width: COLUMN_SIZE.XL,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.name, b.name),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Sire ID',
      dataIndex: 'sireId',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const animal = state?.maleLivestocks?.find(s => s.id === record.sireId);

        if (animal?.identifier) {
          return animal.identifier.toString().toLowerCase().includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        const animalA = state?.maleLivestocks?.find(s => s.id === a.sireId);
        const animalB = state?.maleLivestocks?.find(s => s.id === b.sireId);
        const strA = animalA ? animalA.identifier : 'Select';
        const strB = animalB ? animalB.identifier : 'Select';

        return sortStrings(strA, strB);
      },
      render: (value, record) => {
        const animal = state?.maleLivestocks?.find(s => s.id === record.sireId);
        const animalIdentifier = animal ? animal.identifier : 'Select';

        return (
          <>
            <a
              href="#"
              onClick={() => {
                if (animalIdentifier === 'Select') {
                  setState(prevState => ({ ...prevState, tempModalValue: record }));
                  showAnimalPopup('sire_id', state.maleLivestocks);
                } else {
                  props?.history.push(`/animal/${record.sireId}`);
                }
              }}>
              {animalIdentifier}
            </a>
            {animalIdentifier !== 'Select' && (
              <span className="float-right rg-pointer" onClick={() => handleTableValueChange('sireId', '', record)}>
                clear
              </span>
            )}
          </>
        );
      }
    },
    {
      title: 'Dam ID',
      dataIndex: 'damId',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const animal = state?.femaleLivestocks?.find(s => s.id === record.damId);

        if (animal?.identifier) {
          return animal.identifier.toString().toLowerCase().includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        const animalA = state?.femaleLivestocks?.find(s => s.id === a.damId);
        const animalB = state?.femaleLivestocks?.find(s => s.id === b.damId);
        const strA = animalA ? animalA.identifier : 'Select';
        const strB = animalB ? animalB.identifier : 'Select';

        return sortStrings(strA, strB);
      },
      render: (value, record) => {
        const animal = state?.femaleLivestocks?.find(s => s.id === record.damId);
        const animalIdentifier = animal ? animal.identifier : 'Select';

        return (
          <>
            <a
              href="#"
              onClick={() => {
                if (animalIdentifier === 'Select') {
                  setState(prevState => ({ ...prevState, tempModalValue: record }));
                  showAnimalPopup('dam_id', state.femaleLivestocks);
                } else {
                  props?.history.push(`/animal/${record.damId}`);
                }
              }}>
              {animalIdentifier}
            </a>
            {animalIdentifier !== 'Select' && (
              <span className="float-right rg-pointer" onClick={() => handleTableValueChange('damId', '', record)}>
                clear
              </span>
            )}
          </>
        );
      }
    },
    {
      title: 'Number of calves',
      dataIndex: 'numbers_calves',
      ellipsis: true,
      width: COLUMN_SIZE.XS,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortNumbers(a.numbers_calves, b.numbers_calves)
    },
    {
      title: 'Purchase Date',
      dataIndex: 'purchaseDate',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const date = moment(record.purchaseDate).format(DATE_FORMAT.DATE);

        if (date) {
          return date.toString().includes(value.toLowerCase());
        }
      },
      render: (value, record) => (record.purchaseDate ? moment(record.purchaseDate).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.purchaseDate, b.purchaseDate),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.DATE_PICKER
      }
    },
    {
      title: 'Purchase From',
      dataIndex: 'purchasedFrom',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.purchasedFrom, b.purchasedFrom),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Purchase Where',
      dataIndex: 'purchasedWhere',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.purchasedWhere, b.purchasedWhere),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Purchase Notes',
      dataIndex: 'purchaseNotes',
      editable: true,
      width: COLUMN_SIZE.XL,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.purchaseNotes, b.purchaseNotes),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Date Sold',
      dataIndex: 'date_sold',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const date = moment(record.dateSold).format(DATE_FORMAT.DATE);

        if (date) {
          return date.toString().includes(value.toLowerCase());
        }
      },
      render: (value, record) => (record.dateSold ? moment(record.dateSold).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.date_sold, b.date_sold),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.DATE_PICKER
      }
    },
    {
      title: 'Sold To',
      dataIndex: 'soldTo',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.soldTo, b.soldTo),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Loss Date',
      dataIndex: 'loss_date',
      editable: true,
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const date = moment(record.lossDate).format(DATE_FORMAT.DATE);

        if (date) {
          return date.toString().includes(value.toLowerCase());
        }
      },
      render: (value, record) => (record.lossDate ? moment(record.lossDate).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.loss_date, b.loss_date),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.DATE_PICKER
      }
    },
    {
      title: 'Loss Reason',
      dataIndex: 'lossReason',
      editable: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.lossReason, b.lossReason),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      editable: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.comments, b.comments),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      }
    }
  ];
  const tableColumns = isAdmin
    ? columns
    : getTableColumns(columns, subscription?.myPlan?.type, FEATURE_LIST.LIVESTOCK_LIST);

  return (
    <>
      <div className="p-2 card mb-0">
        <AppTable
          breadcrumb="List of Livestock"
          title="Livestock"
          searchPlaceholder="Search Livestock"
          actions={actions}
          baseColumns={tableColumns}
          dataSource={state.tableData}
          loading={isLoading}
          searchDefaultValue={state.query}
          handleOnSelectRecords={selectRecords}
          handleSearchChange={e => onChange(e.target.value, 'query')}
          handleSearchKeyPress={() => handleKeyPress(13)}
          updateTableData={onClickSaveEdit}
        />
      </div>

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonText="Archive"
        title="Archive livestocks"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={deleteTag}>
        <div className="py-4">Are you sure you want to archive selected livestocks? This action cannot be undone.</div>
      </AppModal>

      <AppModal
        isOpen={state.link_modal}
        confirmButtonText="Link"
        title="Linking confirmation"
        handleCancel={() => toggleModal('link_modal')}
        handleConfirm={linkTag}>
        <p className="text-center">
          Tag <b>{getLabel(state.selected_tags?.at(0))}</b> will be linked to
        </p>
        <Livestock animals={state.animals} animal={state.selected_animals_unlinked}></Livestock>
        <Button
          className="float-right"
          color="secondary"
          onClick={() => props?.history.push('/animal/' + state.selected_animals_unlinked)}>
          View Livestock
        </Button>
        <Button className="float-right" color="primary" onClick={() => props?.history.push('/tracking')}>
          Go to Tracking
        </Button>
      </AppModal>

      <AppModal
        isOpen={state.csv_modal}
        confirmButtonText="Upload"
        title="XLSX Upload"
        handleCancel={() => toggleModal('csv_modal')}
        handleConfirm={uploadCSV}>
        <div className="py-4">
          <div>You can download the livestock list to input new livestocks.</div>
          <div>Download and save the file and then choose file to upload new animals.</div>
        </div>
        <FormGroup>
          <Label className="mr-2">Select farm</Label>
          <Select
            options={state.farm_opts}
            style={{ minWidth: '300px' }}
            value={state.csv_farm}
            onChange={handleCSVFarmChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>XLSX file</Label>
          <Input type="file" onChange={handleCSVChange}></Input>
        </FormGroup>
      </AppModal>

      <AppModal
        isOpen={state.label_modal}
        confirmButtonText="Link Groups"
        title="Link Groups"
        handleCancel={() => toggleModal('label_modal')}
        handleConfirm={linkLabels}>
        <FormGroup>
          <Label className="mr-2">Livestock Group</Label>
          <Select
            isMulti={true}
            isSearchable={true}
            options={state.label_opts}
            style={{ minWidth: '300px' }}
            value={state.selected_labels_add}
            onChange={handleLabelAddChange}
          />
        </FormGroup>
      </AppModal>

      <AppModal
        isOpen={state.sucess_modal}
        confirmButtonText="OK"
        title={state.uploadMessage}
        handleConfirm={() => toggleModal('sucess_modal')}
      />

      {state.showPopup && (
        <AnimalPopup
          key="animal-popup"
          animals={state.filtered_popup_animals}
          fieldKey={state.animal_popup_field}
          onClose={closeAnimalPopup}
          onSelect={onAnimalSelect}
        />
      )}

      <ConfirmationModal
        submitButtonType="primary"
        submitText="Yes"
        description={ANIMAL_ERRORS.ASSIGNED_TO_DIFFERENT_GEOGENCE}
        isOpen={state.isOpenConfirmationModal}
        onCancel={() => setState(prevState => ({ ...prevState, isOpenConfirmationModal: false }))}
        onSubmit={() => {
          onClickSaveEdit();
          setState(prevState => ({ ...prevState, isOpenConfirmationModal: false }));
        }}
      />
    </>
  );
}
