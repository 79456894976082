import { Component } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap';

import moment from 'moment';

import axios from '../../../axios';

import { DATE_FORMAT } from '../../../constants/common';
import messages from '../../../constants/messages';

import { errorToastHandler } from '../../../components/action_notifier';

import AppDropdown from '../../ui-components/dropdown';

class EditGateway extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      delete_modal: false,
      editData: {},
      editing: false,
      gateway: {},
      selected_geofences: []
    };

    this.enableEditing = this.enableEditing.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveGateway = this.saveGateway.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    this.getGateway();
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit Kraal Gateway',
          isVisible: () => true,
          handler: () => this.enableEditing()
        },
        {
          label: 'Archive Kraal tag',
          isVisible: () => true,
          handler: () => this.setDeleteGateways()
        }
      ]
    });
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  async getGateway() {
    const id = this.props.match.params.id;
    const response = await axios.get('gateways/admin/' + id);

    if (response.status === 200) {
      const gateway = response.data;
      this.setState(state => ({ ...state, gateway: gateway }));
    }
  }

  onCancelEdit = (value, field) => {
    this.setState(state => ({ ...state, editData: this.state.gateway, [field]: value }));
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  enableEditing() {
    this.setState(state => ({
      ...state,
      editing: true,
      editData: { ...this.state.gateway }
    }));
  }

  onChange = (value, field) => {
    this.setState(state => ({
      ...state,
      editData: {
        ...state.editData,
        [field]: value
      }
    }));
  };

  setDeleteGateways() {
    this.toggleModal('delete_modal');
  }

  async deleteGateways() {
    const response = await axios.delete('gateways/archive', {
      data: {
        ids: [this.state.gateway.id]
      }
    });

    if (response.status === 200) {
      this.getGateway();
      this.toggleModal('delete_modal');
    }
  }

  async saveGateway() {
    const id = this.props.match.params.id;
    const editData = this.state.editData;

    try {
      const response = await axios.put('gateways/' + id, { ...editData });

      if (response.status === 200) {
        this.getGateway();
        this.setState({ ...this.state, editing: false });
      }
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  render() {
    if (!this.state.gateway.id) return null;

    return (
      <>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4>
              {this.state.editing && 'Edit '}Gateway {this.state.gateway.name || this.state.gateway.appeui}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/administration/gateways')}>
                  List of Kraal Gateways
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Gateway {this.state.gateway.name || this.state.gateway.appeui}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            {this.state.editing && (
              <>
                <Button color="primary" className="float-right" onClick={() => this.saveGateway()}>
                  Save
                </Button>
                <Button className="outline float-right" onClick={() => this.onCancelEdit(false, 'editing')}>
                  Cancel
                </Button>
              </>
            )}
            {!this.state.editing && !this.state.gateway.deleted_at && (
              <AppDropdown
                className="d-flex justify-content-end"
                label="Actions"
                items={this.state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="p-30  global-heading-gateway">
                    <h5 className="details-title">Gateway {this.state.gateway.name || this.state.gateway.appeui}</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway ID</b>
                        </td>
                        <td>{this.state.gateway.id || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm Id</b>
                        </td>
                        <td>
                          <span>{this.state.gateway.farm_id || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Identifier</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'identifier'}
                              defaultValue={this.state.editData.identifier}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.identifier || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Name</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'name'}
                              defaultValue={this.state.editData.name}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.name || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Appeui</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'appeui'}
                              defaultValue={this.state.editData.appeui}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.appeui || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gweui</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'gweui'}
                              defaultValue={this.state.editData.gweui}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.gweui || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>ARN</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'arn'}
                              defaultValue={this.state.editData.arn}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.arn || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Version</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'version'}
                              defaultValue={this.state.editData.version}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.version || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Carrier Type</b>
                        </td>
                        <td>
                          {this.state.editing ? (
                            <Input
                              name={'carrier_type'}
                              defaultValue={this.state.editData.carrier_type}
                              onChange={e => {
                                this.onChange(e.target.value, e.target.name);
                              }}
                            />
                          ) : (
                            <span>{this.state.gateway.carrier_type || '/'}</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Connection Speed</b>
                        </td>
                        <td>
                          <span>{this.state.gateway.connection_speed || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Lora Signal Strength</b>
                        </td>
                        <td>
                          <span>{this.state.gateway.lora_signal_strength || '/'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Lat</b>
                        </td>
                        <td>{this.state.gateway?.lat || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Lng</b>
                        </td>
                        <td>{this.state.gateway.lng || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Created at</b>
                        </td>
                        <td>
                          {(this.state.gateway.created_at &&
                            moment(this.state.gateway.created_at).format(DATE_FORMAT.DATETIME)) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Updated at</b>
                        </td>
                        <td>
                          {(this.state.gateway.updated_at &&
                            moment(this.state.gateway.updated_at).format(DATE_FORMAT.DATETIME)) ||
                            '/'}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Deleted at</b>
                        </td>
                        <td>
                          {(this.state.gateway.deleted_at &&
                            moment(this.state.gateway.deleted_at).format(DATE_FORMAT.DATETIME)) ||
                            '/'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.delete_modal} className={'delete-modal'}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive gateways</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.gateway.connected_tags_count
              ? 'This gateway has active tag connections. Archiving this gateway will disconnect all tags connected to this gateway. Are you sure you want to archive this gateway?'
              : 'Are you sure you want to archive selected gateways? This action cannot be undone.'}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteGateways()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default EditGateway;
