import moment from 'moment';

import { DATE_FORMAT } from '../constants/common';

export const getOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const formatDate = date => {
  return date ? moment(date).format(DATE_FORMAT.DATETIME) : null;
};

export const formatDateDifference = startDate => {
  if (!startDate) return '';

  const start = moment(startDate);
  const end = moment(); // Use the current date and time

  const years = end.diff(start, 'years'); // Calculate the difference in years
  start.add(years, 'years'); // Move the start date forward by the number of years

  const months = end.diff(start, 'months'); // Calculate the difference in months
  start.add(months, 'months'); // Move the start date forward by the number of months

  const days = end.diff(start, 'days'); // Calculate the difference in days

  return `${years}y ${months}m ${days}d`;
};
