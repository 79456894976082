import React, { Component } from 'react';

import axios from 'axios';
import moment from 'moment';

import { mpsToKmph } from '../../helpers/common';
import { getOffset } from '../../helpers/date';

import './rgwf-style.css';

const tzlookup = require('tz-lookup-oss');

function DayForecast(props) {
  if (!props.forecastData) {
    return null;
  }
  let html = [];
  props.forecastData.map((x, indx) => {
    // IGNORE FIRST INDEX, AS ITS ALREADY DISPLAYED IN MAIN SECTION //
    if (indx !== 0) {
      html.push(
        <div className="day" key={indx}>
          <div className="name">{x.displayDate}</div>
          <img src={`//openweathermap.org/img/wn/${x.weather[0].icon}.png`} />
          <div className="temperature">{parseInt(x.main.temp, 10)}°C</div>
        </div>
      );
    }
  });

  return html;
}

/*
 * ##### DEVELOPER INFO #####
 * Author: Rommy Garg
 * Company: Signity Solutions
 * Support Link: https://www.linkedin.com/in/rommygarg
 *
 * ##### PARAMETERS INFO #####
 * headingTxt (String) - Optional parameter to display the Heading Text. eg "Weather Forecast"
 * latLng: (Object {lat:'', lng:''}) - Mandatory, Search Priority 1, based on latitude & longitude. eg "{lat:'-0.394049', lng:'51.257215'}"
 * location: (String) - Mandatory, Search Priority 2, based on location. eg "City,Country"
 *
 */
class WeatherForecast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apikey: this.props.apikey || null,
      apiBase: '//api.openweathermap.org/data/2.5/forecast',
      headingTxt: this.props.headingTxt || null,
      location: this.props.location || null,
      latLng: this.props.latLng || null,
      apiData: [],
      forecastData: [],
      displayMessage: 'Loading...',
      timeZone: ''
    };

    this.getForecastData = this.getForecastData.bind(this);
    this.prepareData = this.prepareData.bind(this);
  }

  componentDidMount() {
    if (this.state.latLng || this.state.location) {
      this.getForecastData();
    } else {
      this.setState(state => ({
        ...state,
        displayMessage: 'Invalid Parameters'
      }));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      latLng: nextProps.latLng,
      location: nextProps.location,
      headingTxt: nextProps.headingTxt
    });
    this.getForecastData();
  }

  async getForecastData() {
    let getParams = {
      appid: this.state.apikey,
      units: 'metric'
    };

    // SEARCH PRIORITY 1 //
    if (this.state.latLng) {
      getParams['lon'] = this.state.latLng.lat || '';
      getParams['lat'] = this.state.latLng.lng || '';
      const timeZone = tzlookup(this.state.latLng.lng, this.state.latLng.lat);
      this.setState({ timeZone: timeZone });
    }
    // SEARCH PRIORITY 2 //
    else if (this.state.location) {
      getParams['q'] = this.state.location || '';
    }
    // ERROR HANDLER //
    else {
      this.setState(state => ({
        ...state,
        displayMessage: 'Invalid Parameters'
      }));
    }

    let client = axios.create();
    client
      .get(this.state.apiBase, {
        params: getParams,
        crossDomain: true
      })
      .then(res => {
        this.setState(
          {
            apiData: res.data.list
          },
          () => this.prepareData()
        );
      })
      .catch(() => {
        this.setState(state => ({
          ...state,
          displayMessage: 'API not responding!'
        }));
      });
  }

  prepareData() {
    if (this.state.apiData.length) {
      let dateData = {};
      let days = [];
      let firstDay = '';

      const timeZone = this.state.timeZone;
      const currentTime = new Date().toLocaleString('en-US', {
        timeZone: timeZone
      });
      const formattedCurrentTime = moment(currentTime).format('YYYY-MM-DD');
      const tzOffsetInSeconds = (getOffset(timeZone) - 180) * 60;
      const timeRange = ['11:00:00', '14:00:00'];
      const firstApiItem = this.state.apiData?.[0];
      const firstApiItemTime = moment(moment(firstApiItem.dt_txt).format('YYYY-MM-DD HH:mm:ss'));

      // ----- may be useful in future
      // const address = "Newcastle, South Africa";
      // const url =
      //   "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" +
      //   address;
      // fetch(url)
      //   .then((response) => response.json())
      //   .then((data) => {})
      //   .catch((err) => console.error(err));

      const utcMoment = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
      const diff = firstApiItemTime.diff(utcMoment, 'seconds');

      const timeFormat = 'HH:mm:ss';
      // GET MID-DAY FORECAST DATA //
      this.state.apiData.map((x, index) => {
        const date = moment(moment.unix(x.dt + tzOffsetInSeconds - diff).utc()).format('YYYY-MM-DD');
        const time = moment(moment.unix(x.dt + tzOffsetInSeconds - diff).utc()).format(timeFormat);
        if (index === 0) {
          x.displayDate = moment(formattedCurrentTime).format('ddd DD MMM');
          firstDay = formattedCurrentTime;
          dateData[formattedCurrentTime] = x;
          days.push(formattedCurrentTime);
        }

        if (
          moment(time, timeFormat).isBetween(moment(timeRange[0], timeFormat), moment(timeRange[1], timeFormat)) &&
          !moment(firstDay).isSame(date, 'day')
        ) {
          x.displayDate = moment(date).format('ddd DD MMM');
          dateData[date] = x;
          days.push(date);
        }
      });
      // GET MOST RECENT DATA FOR TODAY DATE //
      if (days.length) {
        const tmp_dateData = { ...dateData };
        dateData = {};
        for (let i = 0; i < 5; i++) {
          dateData[days[i]] = tmp_dateData[days[i]];
        }
      }
      this.setState(state => ({
        ...state,
        forecastData: Object.values(dateData)
      }));
    } else {
      this.setState(state => ({
        ...state,
        displayMessage: 'No data found!'
      }));
    }
  }

  render() {
    let html;
    if (this.state.forecastData.length == 0) {
      html = <div>{this.state.displayMessage}</div>;
    } else if (this.state.forecastData.length != 0) {
      html = (
        <div className="rgwf">
          <div className="weather_animated">
            {this.state.headingTxt && (
              <div className="location">
                <span className="name">{this.state.forecastData[0].displayDate}</span>
                <span>{this.state.headingTxt}</span>
                <span></span>
              </div>
            )}
            <div className="main_content">
              <div className="main_left">
                <img src={`//openweathermap.org/img/wn/${this.state.forecastData[0].weather[0].icon}@2x.png`} />
                <div className="current_weather">{this.state.forecastData[0].weather[0].main}</div>
              </div>
              <div className="main_right">
                <div className="main_info">
                  <span className="temperature">{parseInt(this.state.forecastData[0].main.temp, 10)}°C</span>
                  <span className="wind">
                    Wind: {mpsToKmph(this.state.forecastData[0].wind.speed)?.toFixed(1)} km/hr
                  </span>
                  <span className="precip">Humidity: {this.state.forecastData[0].main.humidity} %</span>
                  <span className="pressure">Pressure: {this.state.forecastData[0].main.pressure} mb</span>
                </div>
              </div>
              {/* <div className="clear-fix"></div> */}
            </div>
            <div className="week">
              <DayForecast key="dayForecast" forecastData={this.state.forecastData}></DayForecast>
            </div>
          </div>
        </div>
      );
    }

    return html;
  }
}

export default WeatherForecast;
