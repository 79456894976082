import { formatArrayToFilterOptions, formatArrayToOptions } from '../../helpers/common';

import { REDUCERS } from '../../constants/reducers';

import { LocationService } from '../../services';

export const fetchDataRequest = () => ({
  type: REDUCERS.LOCATION.FETCH_DATA_REQUEST
});

export const fetchDataSuccess = data => ({
  type: REDUCERS.LOCATION.FETCH_DATA_SUCCESS,
  payload: data
});

export const fetchDataFailure = error => ({
  type: REDUCERS.LOCATION.FETCH_DATA_FAILURE,
  payload: error
});

export const fetchLocations = () => {
  return async (dispatch, getState) => {
    const { data } = getState().location;

    if (data) return;

    dispatch(fetchDataRequest());

    try {
      const { data } = await LocationService.getLocations();
      const payload = {
        data,
        filterOptions: formatArrayToFilterOptions(data, 'name', 'id'),
        selectOptions: formatArrayToOptions(data, 'name', 'id')
      };

      dispatch(fetchDataSuccess(payload));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};
