import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import 'react-phone-input-2/lib/style.css';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ConfigProvider } from 'antd';
import { io } from 'socket.io-client';

import { getMyPlan } from './redux/actions/subscription';
import { setBaseURL } from './redux/actions/user';
import store from './redux/store';

import './assets/scss/style.css';
import { SocketContext } from './context/socket';
import indexRoutes from './routes/index.jsx';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [socket, setSocket] = useState(null);
  const logoutTimer = useRef();

  const logout = socket => {
    window.localStorage.removeItem('token');
    window.location = '/';
    if (socket) {
      socket.disconnect();
    }
  };
  const onMouseMove = useCallback(() => {
    if (logoutTimer.current) {
      clearInterval(logoutTimer.current);
      logoutTimer.current = setInterval(() => {
        logout();
      }, 960000);
    }
  }, [logoutTimer]);

  useEffect(() => {
    logoutTimer.current = setInterval(() => {
      logout();
    }, 960000);
    return () => {
      clearInterval(logoutTimer.current);
    };
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    // eslint-disable-next-line no-undef
    const [protocol, url] = process.env.REACT_APP_BASE_URL.split('://');
    const wsProtocol = protocol === 'http' ? 'ws' : 'wss';
    if (token) {
      setSocket(
        io(`${wsProtocol}://${url}`, {
          transports: ['websocket'],
          auth: {
            token
          }
        })
      );
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      dispatch(getMyPlan());
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        const token = localStorage.getItem('token');

        if (!token && props.location.pathname) {
          let baseURL = props.location.pathname;
          if (props.location.search) baseURL += '?' + props.location.search;
          if (props.location.hash) baseURL += '#' + props.location.hash;
          dispatch(setBaseURL(baseURL));
        }

        return token ? (
          <SocketContext.Provider
            value={{
              socket
            }}>
            <Component {...props} />
          </SocketContext.Provider>
        ) : (
          <Redirect to="/auth/login" />
        );
      }}
    />
  );
};

const App = () => (
  <ConfigProvider
    theme={{
      token: {
        borderRadius: 0,
        colorPrimary: '#51ae32',
        colorInfo: '#51ae32',
        fontFamily: '"Diagri", "Nunito Sans", sans-serif',
        fontSize: 13
      },
      components: {
        Table: {
          headerBg: '#f6f6f6',
          headerColor: '#9d9d9d',
          cellPaddingInlineSM: 5,
          cellPaddingBlockSM: 3,
          cellFontSize: 17,
          cellFontSizeMD: 15,
          cellFontSizeSM: 13,
          headerBorderRadius: 0
        }
      }
    }}>
    <div id="cover-spin"></div>
    <ToastContainer />
    <BrowserRouter>
      <Provider store={store}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.protected) {
              return <PrivateRoute {...prop} path={prop.path} key={key} name={prop.name} component={prop.component} />;
            } else {
              return <Route path={prop.path} key={key} component={prop.component} />;
            }
          })}
        </Switch>
      </Provider>
    </BrowserRouter>
  </ConfigProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App tab="home" />);
