import { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ReactTable from 'react-table';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import classnames from 'classnames';
import moment from 'moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { filterCaseInsensitive } from '../../helpers/filter';

import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';
import StatusIndicator from '../../components/statusIndicator';

import AppDropdown from '../ui-components/dropdown';

import default_animal from '../../assets/images/default-animal.jpg';

function dateformat(dob_at) {
  if (dob_at && dob_at !== '' && dob_at !== '0000-00-00 00:00:00' && dob_at !== '1970-01-18T00:53:16Z') {
    let b = moment(dob_at);
    let a = moment();
    let years = a.diff(b, 'year');
    b.add(years, 'years');
    let months = a.diff(b, 'months');
    b.add(months, 'months');
    let days = a.diff(b, 'days');

    return years + 'y ' + months + 'm ' + days + 'd';
  } else {
    return '';
  }
}

function Animal(props) {
  const animalId = props?.animal[0];
  const animal = props?.animals.filter(a => a.id === animalId)?.[0] ?? {};
  const b = moment(animal?.age);
  const a = moment();
  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');

  return (
    <div className="animal-box">
      <Row>
        <Col sm="12" md="12" lg="3">
          <img className="animal-profile-pic" src={animal?.photo || default_animal} alt="" />
        </Col>
        <Col sm="12" md="12" lg="9">
          <Table responsive className="no-border">
            <tbody>
              <tr>
                <td>Livestock ID</td>
                <td>
                  <a href="#" onClick={() => props.history.push('/animal/' + animal.id)}>
                    {animal?.identifier}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Management Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_management_id}</span>
                </td>
              </tr>
              <tr>
                <td>Official Tag ID</td>
                <td>
                  <span className="text-mute">{animal?.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Breed</td>
                <td>
                  <span className="text-mute">{animal?.breed?.display_name}</span>
                </td>
              </tr>
              <tr>
                <td>Sex</td>
                <td>
                  <span className="text-mute">{capitalize(animal?.sex)}</span>
                </td>
              </tr>
              <tr>
                <td>Age</td>
                <td>
                  <span className="text-mute">{dateformat(animal?.dob_at)}</span>
                </td>
              </tr>
              <tr>
                <td>Description</td>
                <td>
                  <span className="text-mute">{animal?.description}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default class Tag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      animals: [],
      delete_modal: false,
      edit_modal: false,
      farm_opts: [],
      farms: [],
      link_modal: false,
      linking: false,
      query: '',
      re_linking: false,
      selectAll: false,
      selected_animals: [],
      selected_farm: [],
      tag: {},
      tag_edit: {},
      unlink_modal: false
    };

    this.deleteTag = this.deleteTag.bind(this);
    this.getAnimals = this.getAnimals.bind(this);
    this.getFarms = this.getFarms.bind(this);
    this.getTag = this.getTag.bind(this);
    this.initActions = this.initActions.bind(this);
    this.linkTag = this.linkTag.bind(this);
    this.onAnimalSelect = this.onAnimalSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveTag = this.saveTag.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.getTag();
    this.getAnimals();
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit',
          isVisible: () => false,
          handler: () => this.toggleModal('edit_modal')
        },
        {
          label: 'Unlink tag',
          isVisible: () => this.state.tag.animal != null && !this.state.re_linking,
          handler: () => this.toggleModal('unlink_modal')
        },
        {
          label: 'Link tag',
          isVisible: () => this.state.tag.animal === null && !this.state.linking,
          handler: () => this.setState(state => ({ ...state, linking: !state.linking }))
        },
        {
          label: 'Cancel linking',
          isVisible: () => this.state.linking || this.state.re_linking,
          handler: () => this.setState(state => ({ ...state, re_linking: false, linking: false }))
        },
        {
          label: 'Archive tag',
          isVisible: () => true,
          handler: () => this.toggleModal('delete_modal')
        }
      ]
    });
  }

  onChange = (value, field) => {
    this.setState({ query: value }, this.getAnimals());
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  onTagChange(value, field) {
    this.setState(state => ({ ...state, tag_edit: { ...state.tag_edit, [field]: value } }));
  }

  handleFarmChange = selectedOption => {
    this.setState({
      selected_farm: selectedOption,
      tag_edit: { ...this.state.tag_edit, farm_id: selectedOption.value }
    });
  };

  onAnimalSelect(animal) {
    this.setState({ selected_animals: [] });

    const animals = this.state.selected_animals;

    if (animals.includes(animal)) {
      const index = animals.indexOf(animal);

      if (index > -1) animals.splice(index, 1);
    } else {
      animals.push(animal);
    }

    const allAnimals = this.state.animals.filter(a => a.tag === null);

    this.setState({ selectAll: allAnimals.length === this.state.selected_animals.length });
    this.setState({ selected_animals: animals });
  }

  async getTag() {
    const tagId = this.props.match.params.id;
    const response = await axios.get('tags/' + tagId);

    if (response.status === 200) {
      this.setState({ tag: response.data, tag_edit: response.data }, () => this.getFarms());
    }
  }

  async getAnimals() {
    const response = await axios.get('animals', { params: { indentifier: this.state.query } });

    if (response.status === 200) this.setState({ animals: response.data });
  }

  async deleteTag() {
    const response = await axios.delete('tags/' + this.state.tag.id);

    if (response.status === 200) this.props.history.push('tags');
  }

  async unlinkTag() {
    const response = await axios.delete('links', { data: { tag_id: this.state.tag.id } });

    if (response.status === 200) {
      this.setState({ selected_animals: [] });
      this.getTag();
      this.toggleModal('unlink_modal');
    }
  }

  async linkTag() {
    const animalId = this.state.selected_animals[0];
    const animal = this.state.animals.filter(a => a.id === animalId)[0];
    let response;

    if (this.state.re_linking) {
      response = await axios.put('links', {
        tag_id: this.state.tag.id,
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: this.state.tag.id,
        animal_id: animal.id
      });
    }

    if (response.status === 200) {
      this.getTag();
      this.toggleModal('link_modal');
      this.setState({ re_linking: false, linking: false });
    }
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  async saveTag() {
    const response = await axios.put('tags/' + this.props.match.params.id, {
      ...this.state.tag_edit
    });

    if (response.status === 200) {
      this.getTag();
      this.toggleModal('edit_modal');
    }
  }

  async getFarms() {
    const response = await axios.get('farms/', { query: this.state.query || null });

    if (response.status === 200) {
      const mapped_farms = [];

      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name
        });
      }

      this.setState({
        farms: response.data,
        farm_opts: mapped_farms,
        selected_farm: mapped_farms.filter(x => x.value === this.state.tag.farm_id)[0]
      });
    }
  }

  render() {
    let columns_animal = [
      {
        Header: 'Select',
        accessor: 'id',
        id: 'select',
        filterable: false,
        Cell: props => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_animals.includes(props.value)}
              value={props.value}
              onChange={e => this.onAnimalSelect(parseInt(e.target.value))}
              key={props.value}
            />{' '}
          </FormGroup>
        ),
        sortable: false,
        maxWidth: 85
      }
    ];
    const columns = [
      {
        Header: cellinfo => (
          <div className="position-relative form-check">
            <input
              type="checkbox"
              defaultChecked={this.state.selectAll}
              onChange={e => {
                this.setState({ selectAll: e.target.checked });
                let checkedCopy = [];
                if (e.target.checked) {
                  cellinfo.data.map(val => checkedCopy.push(val._original.id));
                }

                this.setState(state => ({ ...state, selected_animals: checkedCopy }));
              }}
            />
          </div>
        ),
        id: 'select',
        accessor: d => ({ id: d.id }),
        Cell: props => (
          <FormGroup check>
            <Input
              type="checkbox"
              value={props.value.id}
              defaultChecked={this.state.selected_animals.includes(props.value.id)}
              onChange={e => this.onAnimalSelect(parseInt(e.target.value))}
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 85
      },
      {
        Header: 'Livestock ID',
        id: 'glivestckid',
        accessor: d => ({ id: d.id, identifier: d.identifier }),
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
        Cell: props => (
          <a href="#" onClick={() => props.history.push('/animal/' + props.value.id)}>
            {props.value.identifier}
          </a>
        )
      },
      {
        Header: 'Mgmt Tag ID',
        id: 'eartag_management_id',
        accessor: d => (d.eartag_management_id.indexOf('mgmt~tmp~') > -1 ? '' : d.eartag_management_id),
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a.localeCompare(b),
        minWidth: 130
      },
      {
        Header: 'Sex',
        accessor: 'sex',
        headerClassName: 'wordwrap',
        Cell: props => capitalize(props.value)
      },
      {
        Header: 'Colour',
        accessor: 'colour',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Breed',
        accessor: 'breed.display_name',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Kraal Tag ID',
        id: 'tag.diagri_id',
        accessor: d => ({ id: d.id, identifier: d?.tag?.diagri_id }),
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a?.identifier?.localeCompare(b?.identifier),
        Cell: prop => (
          <a href="#" onClick={() => this.props.history.push('/tag/' + prop.value.id)}>
            {prop.value.identifier}
          </a>
        ),
        minWidth: 120
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Assigned Geofence',
        id: 'Assignedg',
        accessor: d => {
          return {
            identifier:
              d.geofences.length > 0
                ? d.geofences.filter(a => a.is_master !== true).length > 0
                  ? d.geofences
                      .filter(a => a.is_master !== true)
                      .map(x => {
                        return x.name;
                      })
                      .join(',')
                  : ''
                : '',
            name:
              d.geofences.length > 0
                ? d.geofences.filter(a => a.is_master !== true).length > 0
                  ? d.geofences
                      .filter(a => a.is_master !== true)
                      .map((x, index) => {
                        return (
                          <a key={index} href="#" onClick={() => this.props.history.push('geofence/' + x.id)}>
                            {x.name}
                          </a>
                        );
                      })
                      .reduce((prev, curr) => [prev, ', ', curr])
                  : ''
                : ''
          };
        },
        headerClassName: 'wordwrap',
        Cell: props => props.value.name,
        sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
        minWidth: 150
      },
      {
        Header: 'Livestock Type',
        id: 'LivestockType',
        accessor: d => (d.stock_type ? capitalize(d.stock_type) : ''),
        headerClassName: 'wordwrap',
        minWidth: 150
      },
      {
        Header: 'Age',
        id: 'age',
        accessor: d => {
          if (d.dob_at && d.dob_at !== '' && d.dob_at !== '0000-00-00 00:00:00' && !d.dob_at.includes('d.dob_at')) {
            let b = moment(d.dob_at);
            let a = moment();
            let years = a.diff(b, 'year');
            b.add(years, 'years');
            let months = a.diff(b, 'months');
            b.add(months, 'months');
            let days = a.diff(b, 'days');

            return years + 'y ' + months + 'm ' + days + 'd';
          } else {
            return '';
          }
          //return <div>{value} </div>
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a.localeCompare(b),
        minWidth: 150
        //filterable:false
      },
      {
        Header: 'Breeding Status',
        accessor: 'breeding_status',
        headerClassName: 'wordwrap',
        minWidth: 160
      }
    ];

    if (!this.state.tag.id) return null;

    const ANIMAL_ROWS = [];

    for (let animal of this.state.animals) {
      if (animal.tag != null) continue;

      ANIMAL_ROWS.push(
        <tr>
          <td>
            <FormGroup check>
              <Input
                type="checkbox"
                defaultChecked={this.state.selected_animals.includes(animal.id)}
                value={animal.id}
                onChange={e => this.onAnimalSelect(e.target.value, 'selected')}
              />{' '}
            </FormGroup>
          </td>
          <td>{animal.id}</td>
        </tr>
      );
    }

    const LINK_ANIMALS_TABLE = (
      <Card>
        <Row className="pad-10">
          <Col xs="12" md="12" lg="6" className="listing-heading">
            <h4 className="">Unlinked Livestock</h4>
          </Col>
          <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
            <Button
              color="primary"
              disabled={this.state.selected_animals.length === 0}
              className="float-right"
              onClick={() => this.toggleModal('link_modal')}>
              Link livestock
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <ReactTable
              showPagination={this.state.animals.filter(a => a.tag === null).length > 0}
              minRows={0}
              data={this.state.animals.filter(a => a.tag === null)}
              columns={columns}
              resizable={false}
              defaultPageSize={25}
              filterable={true}
              defaultFilterMethod={filterCaseInsensitive}
            />
          </Col>
        </Row>
      </Card>
    );

    return (
      <div>
        <Row className="">
          <Col xs="12" md="12" lg="6">
            <h4 className="">Tag {this.state.tag.diagri_id}</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/devices">List of Kraal Tags</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Tag {this.state.tag.diagri_id}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="12" md="12" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.tag.next_id}
              prevId={this.state.tag.prev_id}
              path="tag"
            />

            <AppDropdown
              className="d-flex justify-content-end"
              label="Actions"
              items={this.state.actions.filter(item => item.isVisible())}
              handleClick={action => action.handler()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card className="kraal-tag-global">
              <Row className="pad-30">
                <Col xs="12" md="12" lg="12">
                  <Row>
                    <Col xs="12" md="12" lg="6">
                      <h5 className="details-title">Tag {this.state.tag.diagri_id} details</h5>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="12" lg="6"></Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table responsive className="tag-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Kraal Tag ID</strong>
                        </td>
                        <td>{this.state.tag.diagri_id || '/'}</td>
                      </tr>
                      {this.state.tag.animal != null && (
                        <tr>
                          <td>
                            <strong>Livestock ID</strong>
                          </td>
                          <td>
                            {this.state.tag.animal.id ? (
                              <a
                                className="underline"
                                onClick={() => this.props.history.push('/animal/' + this.state.tag.animal.id)}
                                href="#">
                                {this.state.tag.animal.identifier}
                              </a>
                            ) : (
                              'Unlinked'
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <strong>Battery Status</strong>
                        </td>
                        <td>
                          <StatusIndicator status={this.state.tag.battery_status} />
                          {this.state.tag.battery_status_label}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Connection Status</strong>
                        </td>
                        <td>
                          <StatusIndicator status={this.state.tag.status} />
                          {capitalize(this.state.tag.status) || '/'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Connected to Gateway</strong>
                        </td>
                        <td>
                          {this?.state?.tag?.gateway?.is_owner ? (
                            <Link to={`/gateway/${this.state?.tag?.gateway?.id}`}>
                              {this?.state?.tag?.gateway?.name}
                            </Link>
                          ) : (
                            <span>{this?.state?.tag?.gateway?.name}</span>
                          )}
                        </td>
                      </tr>
                      {/*/ }
									<tr>
										<td>
											<strong>Tag Version</strong>
										</td>
										<td>
											{this.state.tag.version || "/"}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Tag Firmware Version</strong>
										</td>
										<td>
											{this.state.tag.firmware_version || "/"}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Tag Appeui</strong>
										</td>
										<td>
											{this.state.tag.appeui || "/"}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Tag Deveui</strong>
										</td>
										<td>
											{this.state.tag.deveui || "/"}
										</td>
									</tr>
									<tr>
										<td>
											<strong>Tag Devaddr</strong>
										</td>
										<td>
											{this.state.tag.devaddr || "/"}
										</td>
									</tr>
									{this.state.tag.animal && <tr>
										<td>
											<strong>Tag Eartag ID</strong>
										</td>
										<td>
											{this.state.tag.animal.eartag_official_id}
										</td>
									</tr>}
									{ //*/}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row className="pad-10 pb-0">
                <Col sm="12" className="mb-0">
                  <h5>History</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Nav tabs className="fancy-tabs">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => this.toggle('1')}>
                        Notifications
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <TabContent className="no-bg" activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Alerts type="full" query={{ tag_ids: [this.props.match.params.id] }}></Alerts>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {(this.state.linking || this.state.re_linking) && LINK_ANIMALS_TABLE}

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive Kraal Tag</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this tag? Livestock must be un-linked before the tag can be archived and
            this action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteTag()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.edit_modal} className={this.props.className}>
          <ModalHeader>
            <h6>
              <b>Add tag</b>
            </h6>
          </ModalHeader>
          <ModalBody>
            <br />
            <br />
            <Form>
              <FormGroup row>
                <Label sm="3">Kraal Tag ID</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="Kraal Tag ID"
                    defaultValue={this.state.tag_edit.diagri_id}
                    onChange={e => this.onTagChange(e.target.value, 'diagri_id')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Tag Version</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="Tag Version"
                    defaultValue={this.state.tag_edit.version}
                    onChange={e => this.onTagChange(e.target.value, 'version')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Tag Firmware Version</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    placeholder="Firmware Version"
                    defaultValue={this.state.tag_edit.firmware_version}
                    onChange={e => this.onTagChange(e.target.value, 'firmware_version')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="3">Tag farm</Label>
                <Col sm="9">
                  <Select
                    value={this.state.selected_farm}
                    onChange={this.handleFarmChange}
                    options={this.state.farm_opts}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('edit_modal')}>
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.saveTag()}>
              Update Tag
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.link_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Linking confirmation</b>
            </h5>
            <br />
            <br />
            <br />
            <p className="text-center">
              Tag <b>{this.state.tag.diagri_id}</b> will be linked to
            </p>
            <Animal animals={this.state.animals} animal={this.state.selected_animals}></Animal>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('link_modal')}>
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => this.linkTag()}>
              Link
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.tag.animal && (
          <Modal size="lg" isOpen={this.state.unlink_modal} className={this.props.className}>
            <ModalBody>
              <br />
              <h5 className="text-center">
                <b>Unlinking confirmation</b>
              </h5>
              <br />
              <br />
              <br />
              <p className="text-center">Tag will be unlinked with</p>
              <Animal
                history={this.props.history}
                animals={this.state.animals}
                animal={[this.state.tag.animal.id]}></Animal>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.toggleModal('unlink_modal')}>
                Cancel
              </Button>{' '}
              <Button color="primary" onClick={() => this.unlinkTag()}>
                Unlink
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}
