import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row
} from 'reactstrap';

import messages from '../../../constants/messages';

import { getRoles } from '../../../redux/actions/admin';
import { createNewUser } from '../../../redux/actions/user';

import { errorToastHandler } from '../../../components/action_notifier';

import './styles.scss';

const NewUser = ({ history }) => {
  const dispatch = useDispatch();
  const adminState = useSelector(state => state.admin);

  const [email, setEmail] = useState('');
  const [role, setRole] = useState({ label: 'Keeper', value: 'keeper' });
  const onChangeEmail = e => {
    setEmail(e.target.value);
  };
  const onChangeRole = value => {
    setRole(value);
  };

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const onClickAddUser = () => {
    if (!email) {
      errorToastHandler(messages.ADD_USER_EMAIL_REQUIRED);
    } else if (!role.value) {
      errorToastHandler(messages.ADD_USER_ROLE_REQUIRED);
    } else {
      const payload = {
        email: email,
        role: role.value
      };
      dispatch(createNewUser(payload))
        .then(res => {
          if (res.id) {
            history.push('/administration/users');
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
  };

  const onClickCancel = () => {
    history.push('/administration/users');
  };

  return (
    <div className="new-user-page">
      <Row className="">
        <Col xs="6" md="6" lg="6">
          <h4 className="">Add new user</h4>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/administration/users">List of User</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>User</BreadcrumbItem>
          </Breadcrumb>
        </Col>

        <Col lg="6" md="6" sm="6">
          <Button color="primary" className="float-right" onClick={e => onClickAddUser()}>
            Save
          </Button>
          <Button className="outline float-right" onClick={onClickCancel}>
            Cancel
          </Button>
        </Col>
      </Row>

      <div className="card">
        <Form className="mt-3" id="loginform" action="/dashbaord">
          <Label for="email" className="">
            Email
          </Label>
          <InputGroup className="mb-2">
            <InputGroupText>
              <i className="fas fa-at"></i>
            </InputGroupText>
            <Input type="email" id="email" name="email" value={email} onChange={onChangeEmail} placeholder="Email" />
          </InputGroup>

          <Label for="role" className="">
            Role
          </Label>
          <Select
            value={role}
            onChange={e => onChangeRole(e)}
            options={adminState.roles.map(role => {
              return { label: role.display_name, value: role.name };
            })}
          />
        </Form>
      </div>
      <Row>
        <Col lg="12" md="12" sm="12">
          <Button color="primary" className="float-right" onClick={e => onClickAddUser()}>
            Save
          </Button>
          <Button className="outline float-right" onClick={onClickCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default memo(NewUser);
