import { memo } from 'react';
import { Link } from 'react-router-dom';

import default_animal from '../../assets/images/default-animal.jpg';

function AnimalDetails({ animal }) {
  return (
    <div className="full-screen-animal-map-popup">
      <ul>
        <li>
          <b>Livestock ID: </b>
          <span className="text-muted">
            <Link to={`/animal/${animal.id}`}>{animal.identifier}</Link>
          </span>{' '}
        </li>
        <li>
          <b>Mgmt Tag ID: </b>{' '}
          <span className="text-muted">
            {animal.eartag_management_id.indexOf('mgmt~tmp~') > -1 ? '' : animal.eartag_management_id}
          </span>
        </li>
        <li>
          <img
            alt="Animal photo"
            src={animal.photo || default_animal}
            className="card-img-top ieFix"
            width="180"
            height="132"
          />
        </li>
      </ul>
    </div>
  );
}

export default memo(AnimalDetails);
