import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const PrevNextButtons = ({ prevId, nextId, path, history }) => {
  return (
    <ButtonGroup
      style={{
        marginLeft: '6px',
        float: 'right'
      }}>
      <Button
        color="primary"
        disabled={!prevId}
        onClick={() => {
          history.push(`/${path}/${prevId}`);
        }}>
        Prev
      </Button>
      <Button
        color="primary"
        disabled={!nextId}
        onClick={() => {
          history.push(`/${path}/${nextId}`);
        }}>
        Next
      </Button>
    </ButtonGroup>
  );
};
export default PrevNextButtons;
