import { Component, createRef } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from 'reactstrap';

import moment from 'moment';
import * as XLSX from 'xlsx';

import axios from '../../axios';

import { downloadCSV } from '../../helpers/common';

import { DATE_FORMAT } from '../../constants/common';
import messages from '../../constants/messages';
import { MEASURES_CSV_TEMPLATE } from '../../constants/templates';

import Measures from '../../components/Measures';
import UploadModal from '../../components/Modals/UploadModal';
import Search from '../../components/Search/Search';
import { errorToastHandler } from '../../components/action_notifier';
import Filters from '../../components/filters/index.jsx';

import AppDropdown from '../ui-components/dropdown.jsx';

class Measure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      filters_open: false,
      isTabInAction: false,
      tabType: 'measurement',
      tab_action: '',
      selectedIds: []
    };

    this.addNewTabRows = this.addNewTabRows.bind(this);
    this.clearTabOperation = this.clearTabOperation.bind(this);
    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.initActions = this.initActions.bind(this);

    this.measureTab = createRef();
  }

  componentDidMount() {
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: `Add ${this.state.tabType}`,
          isVisible: () => true,
          handler: () => this.tab_addAction()
        },
        {
          label: `Edit ${this.state.tabType}`,
          isVisible: () => true,
          handler: () => this.tab_editAction()
        },
        {
          label: `Upload ${this.state.tabType}`,
          isVisible: () => true,
          handler: () => this.tab_uploadAction()
        },
        {
          label: 'Download measures list',
          isVisible: () => this.measureTab?.current?.state?.selectedIds?.length,
          handler: () => this.downloadCSV()
        },
        {
          label: `Archive ${this.state.tabType}`,
          isVisible: () => this.measureTab?.current?.state?.selectedIds?.length,
          handler: () => this.tab_deleteAction()
        }
      ]
    });
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  tab_addAction() {
    let tabRef = this.measureTab;

    if (!Object.keys(tabRef.current.sigTblRef.current.state.editApply).length) {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    } else if (Object.keys(tabRef.current.sigTblRef.current.state.editApply).length === 1) {
      tabRef.current.addAction();
      this.setState({
        isTabInAction: !this.state.isTabInAction,
        tab_action: 'add'
      });
    } else if (Object.keys(tabRef.current.sigTblRef.current.state.editApply).length > 1) {
      tabRef.current.toggleModal('bulk_modal');
    }
  }

  tab_uploadAction() {
    this.toggleModal('csv_modal');
  }

  tab_editAction() {
    let tabRef = this.measureTab;

    if (Object.keys(tabRef.current.sigTblRef.current.state.editApply).length) {
      const data = tabRef.current.state.data;
      const editApply = tabRef.current.sigTblRef.current.state.editApply;
      const selectedRecordIds = Object.keys(editApply);
      const recordsWithMeasureId = selectedRecordIds.filter(id => data.find(record => record.id === Number(id)));
      if (!recordsWithMeasureId.length) {
        errorToastHandler(messages.MEASUREMENT_NOT_FOUND);
      } else {
        if (recordsWithMeasureId.length !== selectedRecordIds.length) {
          errorToastHandler(messages.MEASUREMENT_NOT_FOUND);
        }
        const editApplyRecords = recordsWithMeasureId.reduce((a, v) => {
          return { ...a, [Number(v)]: editApply[Number(v)] };
        }, {});
        tabRef.current.sigTblRef.current.changeEditApplyRecords(editApplyRecords);
        tabRef.current.editAction();
        this.setState({
          isTabInAction: !this.state.isTabInAction,
          tab_action: 'edit'
        });
      }
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }

  tab_deleteAction() {
    this.measureTab.current.deleteAction();
  }

  tab_saveAction() {
    this.measureTab.current.saveAction();
  }

  tab_cancelAction() {
    this.measureTab.current.cancelAction();
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: this.state.tab_action
    });
  }

  addNewTabRows() {
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: 'add'
    });
  }

  clearTabOperation() {
    this.setState({
      isTabInAction: !this.state.isTabInAction,
      tab_action: this.state.tab_action
    });
  }

  filtersUpdated(filters) {
    this.measureTab.current.filterData(filters);
  }

  filtersClose() {
    this.setState(state => ({ ...state, filters_open: false }));
  }

  handleCSVChange(event) {
    this.setState({ uploading: true });

    let reader = new FileReader();
    const isXLSX = event.target.files?.[0]?.name?.includes('.xlsx');

    if (!isXLSX) reader.readAsText(event.target.files[0]);

    const rABS = !!reader.readAsBinaryString;

    reader.onload = function (e) {
      let data = '';
      if (isXLSX) {
        let wb = XLSX.read(e.target.result, {
          type: rABS ? 'binary' : 'array'
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const csv = XLSX.utils.sheet_to_csv(ws, { strip: true });
        data = csv;
      } else {
        data = reader.result;
      }
      this.setState({
        csvfile: data,
        uploading: false
      });
    }.bind(this);
    if (isXLSX) {
      if (rABS) reader.readAsBinaryString(event.target.files[0]);
      else reader.readAsArrayBuffer(event.target.files[0]);
    }

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  async uploadCSV() {
    try {
      let csv = this.state.csvfile;
      await axios.post('measures/uploadCsv', {
        csv: csv
      });
      this.toggleModal('csv_modal');
      this.measureTab.current.getData();
    } catch (error) {
      console.error(error);
    }
  }

  async downloadCSV() {
    if (this.measureTab?.current?.state?.selectedIds?.length) {
      try {
        const query = `measure_ids=${this.measureTab?.current?.state?.selectedIds}`;
        const response = await axios.get(`/measures/export?${query}`, {
          responseType: 'blob'
        });
        downloadCSV(response.data, `Measures_${moment().format(DATE_FORMAT.DATE)}.xlsx`);
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const { actions } = this.state;
    const upload = () => this.uploadCSV();
    const handle = e => this.handleCSVChange(e);

    return (
      <div>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'geofence', 'label']}
        />

        <UploadModal
          filename="measures-template.csv"
          isOpen={this.state.csv_modal}
          onUpload={upload}
          handleCSVChange={handle}
          template={MEASURES_CSV_TEMPLATE}
          onCancel={() => this.toggleModal('csv_modal')}
          description={
            <>
              <div>You can download the measures list to input new measures.</div>
              <div>Download and save the file and then choose this file to upload new measurements.</div>
            </>
          }
        />

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6" className="listing-heading">
                  <h4 className="mb-0">Livestock measures</h4>
                  <Breadcrumb>
                    <BreadcrumbItem>List of Livestock measures</BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
                  <Search
                    className="mr-2"
                    id="search-measures"
                    placeholder="Search measures"
                    defaultValue={this.state.query}
                    handleChange={e => this.measureTab.current.keywordSearch(e.target.value, 'query')}
                    handleKeyPress={() => this.measureTab.current.handleKeyPress(13)}
                  />

                  {!this.state.isTabInAction && (
                    <AppDropdown
                      label="Actions"
                      items={actions.filter(item => item.isVisible())}
                      handleClick={action => action.handler()}
                    />
                  )}

                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        filters_open: !this.state.filters_open
                      })
                    }>
                    <i className="fa fa-filter"></i>Filters
                  </Button>

                  {this.state.isTabInAction && (
                    <div>
                      <Button color="primary" className="float-right" onClick={() => this.tab_saveAction()}>
                        Save
                      </Button>
                      <Button className="outline float-right" onClick={() => this.tab_cancelAction()}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Measures
                    ref={this.measureTab}
                    type="list"
                    tab_action={this.state.tab_action}
                    addNewTabRows={this.addNewTabRows}
                    clearTabOperation={this.clearTabOperation}
                    initActions={this.initActions}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Measure;
