export const COLUMN_SIZE = {
  XL: 190,
  LG: 160,
  MD: 130,
  SM: 110,
  XS: 80
};

export const TABLE_SIZE = {
  LG: 'large',
  MD: 'middle',
  SM: 'small'
};
