import { Component, createRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Card, CardBody, Col, Form, Input, Row } from 'reactstrap';

import user1 from '../../../assets/images/users/1.jpg';
import user2 from '../../../assets/images/users/2.jpg';
import Message from './message.js';

export default class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chats: [
        {
          username: 'Kevin Hsu',
          content: 'Hello World!',
          img: user1,
          id: 1
        },
        {
          username: 'Alice Chen',
          content: 'Love it! :heart:',
          img: user1,
          id: 2
        },
        {
          username: 'Kevin Hsu',
          content: 'Check out my Github at https://github.com/WigoHunter',
          img: user1,
          id: 3
        },
        {
          username: 'KevHs',
          content:
            'Lorem ipsum dolor sit amet, nibh ipsum. Cum class sem inceptos incidunt sed sed. Tempus wisi enim id, arcu sed lectus aliquam, nulla vitae est bibendum molestie elit risus.',
          img: user2,
          id: 4
        },
        {
          username: 'Kevin Hsu',
          content: 'So',
          img: user1,
          id: 5
        },
        {
          username: 'Kevin Hsu',
          content: 'Chilltime is going to be an app for you to view videos with friends',
          img: user1,
          id: 6
        },
        {
          username: 'Kevin Hsu',
          content: 'You can sign-up now to try out our private beta!',
          img: user1,
          id: 7
        },
        {
          username: 'Alice Chen',
          content: 'Definitely! Sounds great!',
          img: user1,
          id: 8
        }
      ]
    };

    this.submitMessage = this.submitMessage.bind(this);

    this.chatsRef = createRef();
    this.msgRef = createRef();
  }

  componentDidMount() {
    this.scrollToBot();
  }

  componentDidUpdate() {
    this.scrollToBot();
  }

  scrollToBot() {
    this.chatsRef.current.scrollTop = this.chatsRef.current.scrollHeight;
  }

  submitMessage(e) {
    e.preventDefault();
    this.setState(
      {
        chats: this.state.chats.concat([
          {
            username: 'Kevin Hsu',
            content: <span>{this.msgRef.current.value}</span>,
            img: user2
          }
        ])
      },
      () => {
        this.msgRef.current.value = '';
      }
    );
  }

  render() {
    const username = 'Kevin Hsu';
    const { chats } = this.state;
    return (
      /*--------------------------------------------------------------------------------*/
      /* Used In Dashboard-1 [Classic] && Dashboard-4 [General]                         */
      /*--------------------------------------------------------------------------------*/
      <Card>
        <CardBody>
          <h5 className="mb-4">Recent Chats</h5>
          <div className="chat-box" style={{ height: '475px' }}>
            <PerfectScrollbar>
              <ul className="chat-list p-4" ref={this.chatsRef}>
                {chats.map((chat, index) => (
                  <Message key={index} chat={chat} user={username} />
                ))}
              </ul>
            </PerfectScrollbar>
          </div>
          <div className="border-top pt-4">
            <Form onSubmit={e => this.submitMessage(e)}>
              <Row>
                <Col xs="9">
                  <div className="input-field mt-0 mb-0">
                    <Input
                      placeholder="Type and enter"
                      ref={this.msgRef}
                      className="form-control border-0"
                      type="text"
                    />
                  </div>
                </Col>
                <Col xs="3">
                  <Button
                    className="btn btn-circle btn-lg btn-cyan float-right text-white"
                    type="submit"
                    value="submit">
                    <i className="fas fa-paper-plane"></i>
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    );
  }
}
