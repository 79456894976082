import { Button, Input, InputGroup } from 'reactstrap';

import './styles.scss';

export default function Search({ className, defaultValue, id, placeholder, handleChange, handleKeyPress }) {
  return (
    <InputGroup size="sm" className={`head-search ${className}`}>
      <Input
        name="query"
        type="search"
        defaultValue={defaultValue}
        id={id}
        placeholder={placeholder}
        onChange={e => handleChange(e)}
        onKeyDown={e => handleKeyPress(e.code)}
      />
      <Button onClick={() => handleKeyPress(13)} style={{ zIndex: 9 }}>
        <i className="fas fa-search"></i>
      </Button>
    </InputGroup>
  );
}
