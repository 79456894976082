import moment from 'moment';

export const TIME_OPTIONS = {
  '1day': moment.utc().subtract(1, 'day').format(),
  '1week': moment.utc().subtract(1, 'week').format(),
  '2week': moment.utc().subtract(2, 'week').format(),
  '4week': moment.utc().subtract(4, 'week').format()
};

export const MIN_TIME_FRAME_DOTS_NUMBER = {
  1: 5,
  2: 10,
  6: 30,
  12: 60,
  24: 120,
  36: 180,
  48: 240
};

export const DETAIL_SLIDER = {
  timeFrameSlots: [
    {
      index: 0,
      value: 1,
      label: '1hr'
    },
    {
      index: 16,
      value: 2,
      label: '2hrs'
    },
    {
      index: 32,
      value: 6,
      label: '6hrs'
    },
    {
      index: 49,
      value: 12,
      label: '12hrs'
    },
    {
      index: 66,
      value: 24,
      label: '24hrs'
    },
    {
      index: 83,
      value: 36,
      label: '36hrs'
    },
    {
      index: 100,
      value: 48,
      label: '48hrs'
    }
    // {
    //   index: 86,
    //   value: 96,
    //   label: '4days'
    // },
    // {
    //   index: 100,
    //   value: 168,
    //   label: '7days'
    // }
  ],
  timeIntervalSlots: [
    {
      index: 0,
      value: 1,
      label: '10mins'
    },
    {
      index: 15,
      value: 2,
      label: '20mins'
    },
    {
      index: 30,
      value: 3,
      label: '30mins'
    },
    {
      index: 45,
      value: 6,
      label: '60mins'
    },
    {
      index: 58,
      value: 12,
      label: '2hrs'
    },
    {
      index: 72,
      value: 36,
      label: '6hrs'
    },
    {
      index: 86,
      value: 72,
      label: '12hrs'
    },
    {
      index: 100,
      value: 144,
      label: '24hrs'
    }
  ]
};
