import { Slide, toast } from 'react-toastify';

import { v4 as uuidv4 } from 'uuid';

import messages from '../constants/messages';

const options = {
  autoClose: 3000,
  className: 'black-background',
  closeOnClick: false,
  draggable: true,
  hideProgressBar: true,
  pauseOnHover: true,
  position: 'top-right',
  progress: undefined,
  theme: 'dark',
  toastId: uuidv4(),
  transition: Slide
};

export function networkErrorToastHandler(error) {
  const msg =
    error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : messages.DEFAULT_ERROR;

  toast.error(msg, { ...options, autoClose: 6000 });
}

export function errorToastHandler(error) {
  toast.error(error, { ...options, autoClose: 6000 });
}

export function successToastHandler(message) {
  toast.success(message, options);
}

export function infoToastHandler(message) {
  toast.info(message, options);
}
