import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

const CloseAccountModal = ({ isOpen, description, onCancel, onSubmit }) => {
  const [isCloseAcc, setCloseAcc] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      setCloseAcc(false);
    }
  }, [isOpen]);

  return (
    <div className="confirmation-modal">
      <Modal isOpen={isOpen}>
        <ModalBody>
          <br />
          <h5 className="text-center">
            <strong>Close Account</strong>
          </h5>
          <br />
          <Form>
            <FormGroup row>
              <Label sm="12">{description}</Label>
            </FormGroup>
            <FormGroup row>
              <Label sm="10">
                I understand that by closing my account, I will lose all access and data on my account.
              </Label>
              <Col
                sm="2"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}>
                <Input
                  id="close-acc"
                  type="checkbox"
                  onChange={() => {
                    setCloseAcc(!isCloseAcc);
                  }}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>{' '}
          <Button color="danger" onClick={() => onSubmit()} disabled={!isCloseAcc}>
            Close my account
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CloseAccountModal;
