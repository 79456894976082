import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';

import axios from '../../../axios';

import { decodeToken } from '../../../helpers/token';

import messages from '../../../constants/messages';

import { useSetState } from '../../../hooks/useSetState';

import { setPassword, verifyToken } from '../../../redux/actions/user';

import TermsModal from '../../../components/Modals/TermsModal';
import { errorToastHandler } from '../../../components/action_notifier';

import img2 from '../../../assets/images/diagri-background.png';
import img101 from '../../../assets/images/kraal_darklogo.png';
import validators from '../../authentication/validators';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

const SetPassword = props => {
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    password: '',
    password_confirm: '',
    error: null,
    hide_password: true,
    validated: false,
    validate_error: 'Validating your token.',
    done: false,
    email: ''
  });
  const [isOpenTermsModal, setOpenTermsModal] = useState(false);

  useEffect(() => {
    try {
      const token = props.match?.params?.token;
      const decodedToken = decodeToken(token);
      const payload = { token: token };
      dispatch(verifyToken(payload)).then(() => {
        setState({
          validated: true,
          email: decodedToken.e
        });
        setOpenTermsModal(true);
      });
    } catch (error) {
      console.error('verify reset token', error.response.data.message);
      setState({
        validate_error: error.response.data.message
      });
    }
  }, []);

  const onSubmitTermsModal = async isTermsAccepted => {
    try {
      const payload = {
        is_terms_accepted: isTermsAccepted,
        email: state.email
      };
      await axios.put('users/registration', payload);
      if (!isTermsAccepted) {
        errorToastHandler(messages.ACCEPT_TERMS_AND_CONDITIONS);
      }
      setOpenTermsModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onInputChange = event => {
    setState({
      [event.target.name]: event.target.value
    });
    formValidators([event.target.name], event.target.value);
  };

  const submitPassword = e => {
    e.preventDefault();
    const { password } = state;
    const token = props.match?.params?.token;
    const payload = {
      token: token,
      password: password
    };
    try {
      dispatch(setPassword(payload));
      setState({
        done: true
      });
    } catch (e) {
      console.error(e);
    }
  };

  const showErrors = useCallback(
    fieldName => {
      const validator = validators[fieldName];
      const result = '';

      if (!validator?.valid) {
        const errors = validator.errors.map((info, index) => {
          return (
            <span className="error" key={index}>
              * {info}
              <br />
            </span>
          );
        });
        return <div className="error mb-2">{errors}</div>;
      }
      return result;
    },
    [
      validators.password.errors?.length,
      validators.password_confirm.errors?.length,
      state.password,
      state.password_confirm
    ]
  );

  const formValidators = (fieldName, value) => {
    if (fieldName !== 'email') {
      validators[fieldName].valid = true;
      validators[fieldName].state = value;
    }

    validators[fieldName].errors = [];
    validators[fieldName].state = value;
    validators[fieldName].valid = true;
    validators[fieldName].rules.forEach(rule => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value, state.password)) {
          validators[fieldName].errors.push(rule.message);
          validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value, state.password)) {
          validators[fieldName].errors.push(rule.message);
          validators[fieldName].valid = false;
        }
      }
    });
  };

  const togglePassword = (type = 'normal') => {
    if (type === 'normal') {
      setState({ hide_password: !state.hide_password });
    } else if (type === 'confirm') {
      setState({
        hide_password_confirm: !state.hide_password_confirm
      });
    }
  };

  const validForm = () => {
    let status = true;
    Object.keys(validators).forEach(field => {
      if (field === 'password') {
        if (!validators[field].valid) {
          status = false;
        }
      }
    });

    return status && state.password?.trim() === state.password_confirm?.trim();
  };
  return (
    <div className="set-password-page">
      <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="5" className="bg-dark text-white">
                <div className="p-5">
                  <h5 className="font-login-small">We’ll get you back on the Kraal platform shortly.</h5>
                  <div className="logo-place login-form-logo">
                    <img src={img101}></img>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="5" className="bg-white">
                <div className="p-5">
                  <h3 className="font-medium mb-3">Set Password for {state.email}</h3>
                  {state.validated && (
                    <Form className="mt-3" id="loginform" action="/dashbaord">
                      <Label for="password" className="mt-3">
                        Enter Password
                      </Label>
                      <InputGroup className="mb-3" size="lg">
                        <InputGroupText>
                          <i className="fas fa-lock"></i>
                        </InputGroupText>
                        <Input
                          type={state.hide_password ? 'password' : 'text'}
                          id="password"
                          name="password"
                          value={state.password}
                          onChange={onInputChange}
                          placeholder="Password"
                        />

                        <InputGroupText
                          onClick={() => {
                            togglePassword('normal');
                          }}>
                          <i className={`fas ${state.hide_password ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                        </InputGroupText>
                      </InputGroup>
                      {showErrors('password', state.password)}
                      <Label for="password" className="mt-3">
                        Confirm Password
                      </Label>
                      <InputGroup className="mb-3" size="lg">
                        <InputGroupText>
                          <i className="fas fa-lock"></i>
                        </InputGroupText>
                        <Input
                          type={state.hide_password ? 'password' : 'text'}
                          id="password"
                          name="password_confirm"
                          value={state.password_confirm}
                          onChange={onInputChange}
                          placeholder="Password"
                        />

                        <InputGroupText
                          onClick={() => {
                            togglePassword('normal');
                          }}>
                          <i className={`fas ${state.hide_password ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                        </InputGroupText>
                      </InputGroup>
                      {state.password_confirm?.trim() !== state.password?.trim()
                        ? showErrors('password_confirm')
                        : null}

                      <Row className="mb-3">
                        <Col xs="12">
                          <Button
                            color="primary"
                            onClick={submitPassword}
                            disabled={!validForm()}
                            className={`${validForm() ? '' : 'disabled'}`}
                            size="lg"
                            type="submit"
                            block>
                            Submit password
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                  {!state.validated && (
                    <div>
                      <p className="text-red">{state.validate_error || ''}</p>
                      <a href="/auth/recover-pwd">
                        <b>Get new token</b>
                      </a>
                    </div>
                  )}
                  {state.done && (
                    <div>
                      <p>Your password has been set. You can now login with your new password</p>
                      <a href="/auth/login">
                        <b>Go to Login</b>
                      </a>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <TermsModal
        isOpen={isOpenTermsModal}
        setOpen={setOpenTermsModal}
        onSubmit={() => onSubmitTermsModal(true)}
        onCancel={() => onSubmitTermsModal(false)}
      />
    </div>
  );
};

export default withRouter(SetPassword);
