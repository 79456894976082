export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id]) {
    if (typeof row[id] == 'object') {
      if (typeof row[id]['identifier'] == 'number') {
        return row[id]['identifier'] !== undefined ? String(row[id]['identifier'])?.includes(filter.value) : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]['identifier']?.toLowerCase())?.includes(filter?.value?.toLowerCase())
          : true;
      }
    } else if (typeof row[id] === 'number') {
      return row[id] !== undefined ? String(row[id])?.includes(filter?.value) : true;
    } else {
      return row[id] !== undefined ? String(row[id]?.toLowerCase())?.includes(filter?.value?.toLowerCase()) : true;
    }
  }
};

export const sortStrings = (a, b) => {
  const strA = !a ? '' : a + '';
  const strB = !b ? '' : b + '';

  return strA.localeCompare(strB);
};

export const sortDates = (a, b) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (isNaN(dateA)) return 1;
  if (isNaN(dateB)) return -1;

  return dateA - dateB;
};

export const sortNumbers = (a, b) => {
  const numA = Number(a);
  const numB = Number(b);

  if (isNaN(numA)) return 1;
  if (isNaN(numB)) return -1;

  return numA - numB;
};
