import { memo } from 'react';
import { Col, Row } from 'reactstrap';

import moment from 'moment/moment';

import default_animal from '../../../assets/images/default-animal.jpg';

function Livestock(props) {
  const animalId = props.animal.at(0);
  const animal = props.animals.find(a => a.id === animalId);
  const b = moment(animal.age);
  const a = moment();
  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');

  const days = a.diff(b, 'days');

  return (
    <div className="animal-box">
      <Row>
        <Col sm="12" md="12" lg="3">
          <img className="animal-profile-pic" src={default_animal} alt="Animal default picture" />
        </Col>
        <Col sm="12" md="12" lg="9">
          <Table responsive className="no-border">
            <tbody>
              <tr>
                <td>Official Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Tag ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Management Tag ID</td>
                <td>
                  <span className="text-mute">{animal.eartag_official_id}</span>
                </td>
              </tr>
              <tr>
                <td>Livestock ID</td>
                <td>
                  <span className="text-mute">{animal.id}</span>
                </td>
              </tr>
              <tr>
                <td>Age</td>
                <td>
                  <span className="text-mute">{years + ' years ' + months + ' months ' + days + ' days'}</span>
                </td>
              </tr>
              <tr>
                <td>Breed</td>
                <td>
                  <span className="text-mute">{animal.breed.display_name}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default memo(Livestock);
