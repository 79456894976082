import React, { memo } from 'react';

import { Button, Modal } from 'antd';

import './styles.scss';

function AppModal({
  cancelButtonText = 'Cancel',
  children,
  confirmButtonText,
  isOpen,
  loading,
  title,
  handleCancel,
  handleConfirm
}) {
  return (
    <Modal
      open={isOpen}
      title={title}
      onOk={handleConfirm}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          size="large"
          style={{ display: handleCancel ? 'inline-block' : 'none' }}
          onClick={handleCancel}>
          {cancelButtonText}
        </Button>,
        <Button key="submit" size="large" type="primary" loading={loading} onClick={handleConfirm}>
          {confirmButtonText}
        </Button>
      ]}>
      {children}
    </Modal>
  );
}

export default memo(AppModal);
