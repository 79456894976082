import { useEffect } from 'react';
import { CompactPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import classnames from 'classnames';
import moment from 'moment';

import axios from '../../axios';

import { capitalize, getColorName } from '../../helpers/common';
import { filterCaseInsensitive, sortStrings } from '../../helpers/filter';

import { ANIMAL_ERRORS } from '../../constants/livestock';
import messages from '../../constants/messages';

import { useSetState } from '../../hooks/useSetState';

import { getFarms } from '../../redux/actions/farm';

import ChooseGeofencesModal from '../../components/Modals/ChooseGeofencesModal';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import SelectionPopover from '../../components/Popovers/SelectionPopover';
import PrevNextButtons from '../../components/PrevNextButtons';
import { errorToastHandler } from '../../components/action_notifier';

import AppDropdown from '../ui-components/dropdown';

const selectedTypeMapping = {
  virtual: {
    value: 'virtual',
    label: 'Virtual'
  },
  physical: {
    value: 'physical',
    label: 'Physical'
  },
  collection: {
    value: 'collection',
    label: 'Collection'
  }
};

const LabelPage = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useSetState({ animals: true, labels: true });
  const [state, setState] = useSetState({
    actions: [],
    actionsForExclLivestock: [],
    actionsForInclLivestock: [],
    activeTab: '1',
    add_modal: false,
    chooseGeofencesModal: false,
    colourName: '',
    delete_modal: false,
    edit: false,
    excludedLivestock: [],
    geofenceOptions: [],
    includedLivestock: [],
    isOpenConfirmationModal: false,
    isOpenSelectionPopover: { 1: false, 2: false },
    label: {
      name: '',
      id: null,
      color: '',
      animals: [],
      rules: []
    },
    linked_livestock: [],
    livestock_opts: [],
    loading: {
      animals: true,
      labels: true
    },
    new_label: {
      name: '',
      color: '',
      rules: [],
      livestock: []
    },
    query: '',
    query_rules: '',
    rules_opts: [],
    selectAll: false,
    selectedExcluded: [],
    selectedGeofence: {},
    selectedIncluded: [],
    selectedLivestock: [],
    selected_labels: [],
    selected_livestock: [],
    selected_rules: [],
    tableData: [],
    tableExcludedData: [],
    tableIncludedData: []
  });

  const farmState = useSelector(state => state.farm);

  useEffect(() => {
    getRules();
    getLabel();
    getLabels();
    getFences();
    getFarmsOptions();
    initActionsForInclLivestock();
    initActionsForExclLivestock();
  }, []);

  useEffect(() => {
    initActions();
  }, [state.label]);

  useEffect(() => {
    if (!loading.animals && !loading.labels) {
      const includedLivestock = [];
      const excludedLivestock = [];

      state.livestock_opts.forEach(item => {
        if (state.linked_livestock.includes(item.id)) {
          includedLivestock.push(item);
        } else {
          const physicalGroup = item.labels.find(group => group.main_type === 'physical');
          if (!physicalGroup) {
            excludedLivestock.push(item);
          }
        }
      });

      setState({
        includedLivestock: includedLivestock,
        tableIncludedData: includedLivestock.slice(0, 25),
        excludedLivestock: excludedLivestock,
        tableExcludedData: excludedLivestock.slice(0, 25)
      });
    }
  }, [loading]);

  function initActions() {
    setState({
      actions: [
        {
          label: 'Add group',
          isVisible: () => isCustomGroup(state.label),
          handler: () => props.history.push('/group/new')
        },
        {
          label: 'Edit group',
          isVisible: () => true,
          handler: () => setState({ edit: true })
        },
        {
          label: 'Archive group',
          isVisible: () => isCustomGroup(state.label),
          handler: () => toggleModal('delete_modal')
        },
        {
          label: 'Edit group geofence',
          isVisible: () => isCustomGroup(state.label) && !isVirtualGroup(state.label),
          handler: () => toggleModal('chooseGeofencesModal')
        }
      ]
    });
  }

  function initActionsForInclLivestock() {
    setState({
      actionsForInclLivestock: [
        {
          label: 'Remove from group',
          isVisible: () => true,
          handler: () => removeFromTheGroup()
        }
      ]
    });
  }

  function initActionsForExclLivestock() {
    setState({
      actionsForExclLivestock: [
        {
          label: 'Add to the group',
          isVisible: () => true,
          handler: () => hasAnimalDifferentGeofence()
        }
      ]
    });
  }

  const onClickOnThisPage = () => {
    const newCheckboxValue = !state.selectAll;
    const selected = state.activeTab === '1' ? 'tableIncludedData' : 'tableExcludedData';
    const checkedCopy = [];

    if (newCheckboxValue) {
      state[selected].map(val => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }

    setState(() => ({ selectedLivestock: checkedCopy, linked_livestock: checkedCopy }));
    setState({ selectAll: newCheckboxValue });
    onToggleSelectionPopover();
  };

  const onClickAllRecords = () => {
    const newCheckboxValue = !state.selectAll;
    const checkedCopy = [];
    const selected = state.activeTab === '1' ? 'includedLivestock' : 'excludedLivestock';

    if (newCheckboxValue) state[selected].map(val => checkedCopy.push(val.id));

    setState(() => ({ selectedLivestock: checkedCopy, linked_livestock: checkedCopy }));
    setState({ selectAll: newCheckboxValue });
    onToggleSelectionPopover();
  };

  const onToggleSelectionPopover = isOpen => {
    const open = isOpen !== undefined ? isOpen : !state.isOpenSelectionPopover[state.activeTab] && !state.selectAll;

    setState({ isOpenSelectionPopover: { [state.activeTab]: open } });
  };

  const getFarmsOptions = () => {
    dispatch(getFarms());
  };

  const getLabels = async () => {
    const response = await axios.get('labels', {
      params: {
        farm_ids: []
      }
    });

    const groups = response.data
      .filter(item => item.main_type !== 'collection')
      .map(item => ({ value: item.id, label: item.name }));

    setState({ groups });
  };

  const getFences = async () => {
    try {
      const response = await axios.get('geofences', { params: { only_custom: true } });

      setState({ geofenceOptions: response?.data?.map(x => ({ value: x.id, label: x.name })) });
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  };

  const getLabel = () => {
    return new Promise(async resolve => {
      const response = await axios.get('labels/' + props.match.params.id, {
        query: state.query
      });

      const label = {
        ...response.data,
        collection: (response.data.collection || []).map(item => {
          return {
            label: item.name,
            value: item.id
          };
        })
      };

      if (response.status === 200) {
        setState({
          label,
          linked_livestock: response.data.animals.map(x => x.id),
          selectedGeofence: response.data?.geofences?.[0]
            ? {
                label: response.data?.geofences?.[0]?.name,
                value: response.data?.geofences?.[0]?.id
              }
            : null
          //colourName: col.data.colors[0].name,
        });
        setLoading({ labels: false });
        getLivestock(response.data);

        return resolve();
      }
    });
  };

  const toggle = tab => {
    if (state.activeTab !== tab) setState({ activeTab: tab });
  };

  const toggleModal = modal => {
    setState(state => ({ ...state, [modal]: !state[modal] }));
  };

  const getRules = async () => {
    const response = await axios.get('rules?query=' + state.query_rules);

    setState({ rules_opts: response.data });
  };

  const getLivestock = async data => {
    const groupType = data.type;
    const mainType = data.main_type;
    const farmId = data.farm_id;
    const localQuery = groupType === 'loss_date' ? '&with_archived=true' : '';
    const params =
      mainType === 'collection'
        ? {
            label_ids: data.collection.map(item => item.id)
          }
        : {
            farm_ids: [farmId].filter(Boolean)
          };
    const response = await axios.get('animals?query=' + state.query + localQuery, {
      params
    });

    setState({ livestock_opts: response.data });
    setLoading({ animals: false });
  };

  const saveLabel = async () => {
    const payload = {
      ...state.label,
      collectionIds: (state.label.collection || []).map(item => item.value)
    };

    await axios.put(`/labels/${props.match.params.id}`, payload);
    setState({ edit: false });
    getLabel();
  };

  const onSelectLivestock = id => {
    const livestocks = state.selectedLivestock;

    if (livestocks.includes(id)) {
      const index = livestocks.indexOf(id);

      if (index > -1) livestocks.splice(index, 1);
    } else {
      livestocks.push(id);
    }

    setState({ selectedLivestock: livestocks });
  };

  const removeFromTheGroup = async () => {
    try {
      await axios.delete(`labels/${props.match.params.id}/animals`, {
        data: { animal_ids: [...state.selectedLivestock] }
      });
      getLabel();
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  };

  const addToTheGroup = async () => {
    try {
      await axios.post(`labels/${props.match.params.id}/animals`, {
        animal_ids: [...state.selectedLivestock]
      });
      getLabel();
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  };

  const hasAnimalDifferentGeofence = () => {
    const groupGeofenceId = state.label?.geofences?.[0]?.id;
    let isAnimalWithDifferentGeofenceId = false;

    for (const id of state.selectedLivestock) {
      const animal = state.excludedLivestock.find(i => i.id === id);
      const sameGeofence = animal?.geofences?.find(i => i.id === groupGeofenceId);

      if (!sameGeofence) isAnimalWithDifferentGeofenceId = true;
    }

    if (isAnimalWithDifferentGeofenceId) {
      setState({ isOpenConfirmationModal: true });
    } else {
      addToTheGroup();
    }
  };

  const deleteLabel = async () => {
    const response = await axios.delete('labels/' + props.match.params.id);

    if (response.status === 200) props.history.push('/groups');
  };

  const onLabelChange = (value, field) => {
    setState({ label: { ...state.label, [field]: value } });
  };

  const onSelectGeofence = value => {
    setState({ selectedGeofence: value });
  };

  const getAssignedAnimalsList = () => {
    const animals = state.label.animals;
    const assignedAnimals = [];

    for (const animal of animals) {
      if (animal?.labels?.every(l => l.main_type !== 'physical')) {
        assignedAnimals.push(animal);
      }
    }

    return assignedAnimals;
  };

  const onSaveGeofence = async () => {
    const groupId = props.match.params.id;
    const geofenceId = state.selectedGeofence?.value;

    if (geofenceId) {
      try {
        await axios.put('labels/updateGeofenceInGroup/' + groupId, {
          geofence_id: geofenceId
        });
        getLivestock();
      } catch (error) {
        errorToastHandler(messages.DEFAULT_ERROR);
      }
    }

    toggleModal('chooseGeofencesModal');
  };

  const onChangeTab = tabNumber => {
    toggle(tabNumber);
    setState({ selectedLivestock: [], selectAll: false });
  };

  const isCustomGroup = label => label.type === 'custom';
  const isVirtualGroup = label => label.type === 'virtual';

  const columns1 = [
    {
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={state.isOpenSelectionPopover['1']}
            onClickOnThisPage={() => onClickOnThisPage()}
            onClickAllRecords={() => onClickAllRecords()}
            setOpen={isOpen =>
              setState({
                isOpenSelectionPopover: {
                  [state.activeTab]: isOpen !== undefined ? isOpen : !state.isOpenSelectionPopover[state.activeTab]
                }
              })
            }
            content={
              <input
                type="checkbox"
                defaultChecked={state.selectAll}
                onChange={() => {
                  onToggleSelectionPopover();

                  if (state.selectAll) {
                    setState({ selectAll: !state.selectAll });
                    setState(state => ({ ...state, linked_livestock: [], selectedLivestock: [] }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      id: 'select',
      accessor: d => ({ id: d.id }),
      Cell: props => (
        <FormGroup check>
          <Input
            type="checkbox"
            value={props.value.id}
            defaultChecked={state.selectedLivestock.includes(props.value.id)}
            onChange={e => onSelectLivestock(parseInt(e.target.value))}
            key={props.value.id}
          />{' '}
        </FormGroup>
      ),
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      maxWidth: 85
    },
    {
      Header: 'Livestock ID',
      id: 'glivestckid',
      accessor: d => ({ id: d.id, identifier: d.identifier }),
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
      Cell: prop => (
        <a href="#" onClick={() => props.history.push('/animal/' + prop.value.id)}>
          {prop.value.identifier}
        </a>
      )
    },
    {
      Header: 'Mgmt Tag ID',
      id: 'eartag_management_id',
      accessor: d => (d.eartag_management_id.indexOf('mgmt~tmp~') > -1 ? '' : d.eartag_management_id),
      headerClassName: 'wordwrap',
      sortMethod: sortStrings,
      minWidth: 130
    },
    {
      Header: 'Sex',
      accessor: 'sex',
      headerClassName: 'wordwrap',
      Cell: props => capitalize(props.value)
    },
    {
      Header: 'Colour',
      accessor: 'colour',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Breed',
      accessor: 'breed.display_name',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Kraal Tag ID',
      id: 'tag.diagri_id',
      headerClassName: 'wordwrap',
      accessor: d => ({ id: d.id, identifier: d.tag?.diagri_id }),
      sortMethod: (a, b) => a?.identifier?.localeCompare(b?.identifier),
      Cell: prop => (
        <a href="#" onClick={() => props.history.push('/tag/' + prop.value.id)}>
          {prop.value.identifier}
        </a>
      ),
      minWidth: 120
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Assigned Geofence',
      id: 'Assignedg',
      accessor: d => {
        return {
          identifier:
            d.geofences.length > 0
              ? d.geofences.filter(a => a.is_master !== true).length > 0
                ? d.geofences
                    .filter(a => a.is_master !== true)
                    .map(x => {
                      return x.name;
                    })
                    .join(',')
                : ''
              : '',
          name:
            d.geofences.length > 0
              ? d.geofences.filter(a => a.is_master !== true).length > 0
                ? d.geofences
                    .filter(a => a.is_master !== true)
                    .map((x, index) => {
                      return (
                        <a key={index} href="#" onClick={() => this.props.history.push('geofence/' + x.id)}>
                          {x.name}
                        </a>
                      );
                    })
                    .reduce((prev, curr) => [prev, ', ', curr])
                : ''
              : ''
        };
      },
      headerClassName: 'wordwrap',
      Cell: props => props.value.name,
      sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
      minWidth: 150
    },
    {
      Header: 'Livestock Type',
      id: 'LivestockType',
      accessor: d => (d.stock_type ? capitalize(d.stock_type) : ''),
      headerClassName: 'wordwrap',
      minWidth: 150
    },
    {
      Header: 'Age',
      id: 'age',
      accessor: d => {
        let value = '';
        if (d.dob_at && d.dob_at !== '' && d.dob_at !== '0000-00-00 00:00:00' && !d.dob_at.includes('d.dob_at')) {
          let b = moment(d.dob_at);
          let a = moment();
          let years = a.diff(b, 'year');
          b.add(years, 'years');
          let months = a.diff(b, 'months');
          b.add(months, 'months');
          let days = a.diff(b, 'days');
          value = years + 'y ' + months + 'm ' + days + 'd';
          return value;
        } else {
          return '';
        }
        //return <div>{value} </div>
      },
      headerClassName: 'wordwrap',
      sortMethod: sortStrings,
      minWidth: 150
      //filterable:false
    },
    {
      Header: 'Breeding Status',
      accessor: 'breeding_status',
      headerClassName: 'wordwrap',
      minWidth: 160
    }
  ];

  const columns2 = [
    {
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={state.isOpenSelectionPopover['2']}
            onClickOnThisPage={() => onClickOnThisPage()}
            onClickAllRecords={() => onClickAllRecords()}
            setOpen={isOpen =>
              setState({
                isOpenSelectionPopover: {
                  [state.activeTab]: isOpen !== undefined ? isOpen : !state.isOpenSelectionPopover[state.activeTab]
                }
              })
            }
            content={
              <input
                type="checkbox"
                defaultChecked={state.selectAll}
                onChange={() => {
                  onToggleSelectionPopover();

                  if (state.selectAll) {
                    setState({ selectAll: !state.selectAll });
                    setState(state => ({ ...state, linked_livestock: [], selectedLivestock: [] }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      id: 'select',
      accessor: d => ({ id: d.id }),
      Cell: props => (
        <FormGroup check>
          <Input
            type="checkbox"
            value={props.value.id}
            defaultChecked={state.selectedLivestock.includes(props.value.id)}
            onChange={e => onSelectLivestock(parseInt(e.target.value))}
            key={props.value.id}
          />{' '}
        </FormGroup>
      ),
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      maxWidth: 85
    },
    {
      Header: 'Livestock ID',
      id: 'glivestckid',
      accessor: d => ({ id: d.id, identifier: d.identifier }),
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
      Cell: prop => (
        <a href="#" onClick={() => props.history.push('/animal/' + prop.value.id)}>
          {prop.value.identifier}
        </a>
      )
    },
    {
      Header: 'Mgmt Tag ID',
      id: 'eartag_management_id',
      accessor: d => (d.eartag_management_id.indexOf('mgmt~tmp~') > -1 ? '' : d.eartag_management_id),
      headerClassName: 'wordwrap',
      sortMethod: sortStrings,
      minWidth: 130
    },
    {
      Header: 'Sex',
      accessor: 'sex',
      headerClassName: 'wordwrap',
      Cell: props => capitalize(props.value)
    },
    {
      Header: 'Colour',
      accessor: 'colour',
      headerClassName: 'wordwrap'
    },
    {
      Header: 'Breed',
      accessor: 'breed.display_name',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Kraal Tag ID',
      id: 'tag.diagri_id',
      accessor: d => ({ id: d.id, identifier: d.tag?.diagri_id }),
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => a?.identifier?.localeCompare(b?.identifier),
      Cell: prop => (
        <a href="#" onClick={() => props.history.push('/tag/' + prop.value.id)}>
          {prop.value.identifier}
        </a>
      ),
      minWidth: 120
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      headerClassName: 'wordwrap',
      minWidth: 120
    },
    {
      Header: 'Assigned Geofence',
      id: 'Assignedg',
      accessor: d => {
        return {
          identifier:
            d.geofences.length > 0
              ? d.geofences.filter(a => a.is_master !== true).length > 0
                ? d.geofences
                    .filter(a => a.is_master !== true)
                    .map(x => {
                      return x.name;
                    })
                    .join(',')
                : ''
              : '',
          name:
            d.geofences.length > 0
              ? d.geofences.filter(a => a.is_master !== true).length > 0
                ? d.geofences
                    .filter(a => a.is_master !== true)
                    .map((x, index) => {
                      return (
                        <a key={index} href="#" onClick={() => this.props.history.push('geofence/' + x.id)}>
                          {x.name}
                        </a>
                      );
                    })
                    .reduce((prev, curr) => [prev, ', ', curr])
                : ''
              : ''
        };
      },
      headerClassName: 'wordwrap',
      Cell: props => props.value.name,
      sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
      minWidth: 150
    },
    {
      Header: 'Livestock Type',
      id: 'LivestockType',
      accessor: d => (d.stock_type ? capitalize(d.stock_type) : ''),
      headerClassName: 'wordwrap',
      minWidth: 150
    },
    {
      Header: 'Age',
      id: 'age',
      accessor: d => {
        let value = '';
        if (d.dob_at && d.dob_at !== '' && d.dob_at !== '0000-00-00 00:00:00' && !d.dob_at.includes('d.dob_at')) {
          let b = moment(d.dob_at);
          let a = moment();
          let years = a.diff(b, 'year');
          b.add(years, 'years');
          let months = a.diff(b, 'months');
          b.add(months, 'months');
          let days = a.diff(b, 'days');
          value = years + 'y ' + months + 'm ' + days + 'd';
          return value;
        } else {
          return '';
        }
        //return <div>{value} </div>
      },
      headerClassName: 'wordwrap',
      sortMethod: sortStrings,
      minWidth: 150
      //filterable:false
    },
    {
      Header: 'Breeding Status',
      accessor: 'breeding_status',
      headerClassName: 'wordwrap',
      minWidth: 160
    }
  ];

  return (
    <>
      <Row>
        <Col xs="6" md="6" lg="6">
          <h4 className="">Group {state.label.name}</h4>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="#" onClick={() => props.history.push('/groups')}>
                List of Groups
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>Group {state.label.name}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
        <Col xs="6" md="6" lg="6">
          <div className="d-flex justify-content-end">
            {!state.edit && (
              <AppDropdown
                label="Actions"
                items={state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}

            {state.edit && (
              <>
                <Button color="primary" className="float-right" onClick={() => saveLabel()}>
                  Save
                </Button>
                <Button className="float-right outline" onClick={() => setState({ edit: false })}>
                  Cancel
                </Button>
              </>
            )}

            <PrevNextButtons
              history={props.history}
              nextId={state.label.next_id}
              prevId={state.label.prev_id}
              path="group"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12" className="global-table">
          <Card>
            <Row className="pad-15 global-heading-tag">
              <Col xs="12" md="12" lg="6">
                <h5 className="details-title">Group {state.label.name} details</h5>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12" lg="12">
                {!state.edit && (
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Group ID</b>
                        </td>
                        <td>{state.label.identifier}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Group Name</b>
                        </td>
                        <td>{state.label.name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Group Type</b>
                        </td>
                        <td style={{ textTransform: 'capitalize' }}>{state.label.main_type}</td>
                      </tr>
                      {state.label.main_type === 'collection' && (
                        <tr>
                          <td>
                            <b>Collection</b>
                          </td>
                          <td style={{ textTransform: 'capitalize' }}>
                            {state.label.collection.map(({ label }) => label).join(', ')}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <b>Group Color</b>
                        </td>
                        <td>
                          <div className="label-tag no-margin" style={{ background: state.label.colour }}>
                            {getColorName(state.label.colour_name) || getColorName(state.label.colour)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Farm</b>
                        </td>
                        <td>
                          <a href="#" onClick={() => props.history.push('/farm/' + state.label.farm?.id)}>
                            {state.label.farm?.name}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>No. of Livestock in Group</b>
                        </td>
                        <td>{state.label.animals_count}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Assigned Livestock</b>
                        </td>
                        <td>
                          {getAssignedAnimalsList().map((animal, index) => (
                            <a key={animal.id} href="#" onClick={() => props.history.push('/animal/' + animal.id)}>
                              {index !== 0 && <span>, </span>}
                              {animal.identifier}{' '}
                            </a>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
                {state.edit && (
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Name</b>
                        </td>
                        <td>
                          {state.label.type === 'custom' ? (
                            <Input
                              name="name"
                              defaultValue={state.label.name}
                              onChange={e => onLabelChange(e.target.value, e.target.name)}></Input>
                          ) : (
                            state.label.name
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Type</b>
                        </td>
                        <td>
                          <Select
                            value={selectedTypeMapping[state.label.main_type]}
                            onChange={e => {
                              setState({
                                label: {
                                  ...state.label,
                                  main_type: e.value,
                                  collection: []
                                }
                              });
                            }}
                            options={[
                              {
                                value: 'virtual',
                                label: 'Virtual'
                              },
                              {
                                value: 'physical',
                                label: 'Physical'
                              },
                              {
                                value: 'collection',
                                label: 'Collection'
                              }
                            ]}
                          />
                        </td>
                      </tr>
                      {state.label.main_type === 'collection' && (
                        <tr>
                          <td className="v-align-middle">
                            <b>Groups</b>
                          </td>
                          <td>
                            <Select
                              value={state.label.collection}
                              onChange={group => {
                                onLabelChange(group, 'collection');
                              }}
                              options={state.groups}
                              isMulti={true}
                              isSearchable={true}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Color</b>
                        </td>
                        <td>
                          <CompactPicker
                            name="colour"
                            color={state.label.colour}
                            onChange={e => onLabelChange(e.hex, 'colour')}></CompactPicker>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm</b>
                        </td>
                        <td>
                          <Select
                            value={farmState?.options?.find(opt => opt.value === state.label.farm_id)}
                            onChange={e => onLabelChange(e.value, 'farm_id')}
                            options={farmState?.options}
                            isSearchable={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </Col>
            </Row>
          </Card>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
              {state.edit && (
                <div>
                  <Button color="primary" className="float-right" onClick={() => saveLabel()}>
                    Save
                  </Button>
                  <Button className="float-right outline" onClick={() => setState({ edit: false })}>
                    Cancel
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12">
          <Card>
            <Row>
              <Col>
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: state.activeTab === '1' })}
                      onClick={() => onChangeTab('1')}>
                      Included Livestock
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: state.activeTab === '2' })}
                      onClick={() => onChangeTab('2')}>
                      Excluded Livestock
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <TabContent className="no-bg" activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <Row className="pad-10">
                      <Col sm="6" className="mb-0">
                        <h5>Livestock</h5>
                      </Col>
                      {(state.selectedLivestock?.length || state.selectAll) &&
                        state.label.main_type !== 'collection' &&
                        isCustomGroup(state.label) && (
                          <Col xs="12" md="12" lg="6">
                            <AppDropdown
                              className="d-flex justify-content-end"
                              label="Actions"
                              size="sm"
                              items={state.actionsForInclLivestock.filter(item => item.isVisible())}
                              handleClick={action => action.handler()}
                            />
                          </Col>
                        )}
                    </Row>
                    <Row>
                      <Col xs="12" md="12" lg="12">
                        <ReactTable
                          minRows={0}
                          data={state.includedLivestock}
                          columns={columns1}
                          resizable={true}
                          defaultPageSize={25}
                          showPagination={state.livestock_opts.length > 0}
                          filterable={true}
                          defaultFilterMethod={filterCaseInsensitive}
                          onFetchData={props => {
                            const data = props.data.length
                              ? props.sortedData.slice(0, props.pageSize)
                              : state.includedLivestock;
                            setState({ tableIncludedData: data });
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="pad-10">
                      <Col sm="6" className="mb-0">
                        <h5>Livestock</h5>
                      </Col>
                      {state.selectedLivestock?.length && state.label.main_type !== 'collection' ? (
                        <Col xs="12" md="12" lg="6">
                          <AppDropdown
                            className="d-flex justify-content-end"
                            label="Actions"
                            size="sm"
                            items={state.actionsForExclLivestock.filter(item => item.isVisible())}
                            handleClick={action => action.handler()}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col xs="12" md="12" lg="12">
                        <ReactTable
                          minRows={0}
                          data={state.excludedLivestock}
                          columns={columns2}
                          resizable={true}
                          defaultPageSize={25}
                          showPagination={state.livestock_opts.length > 0}
                          filterable={true}
                          defaultFilterMethod={filterCaseInsensitive}
                          onFetchData={props => {
                            const data = props.data.length
                              ? props.sortedData.slice(0, props.pageSize)
                              : state.excludedLivestock;
                            setState({ tableExcludedData: data });
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={state.delete_modal} className={props.className}>
        <ModalBody>
          <br />
          <h5 className="text-center">
            <b>Archive group</b>
          </h5>
          <br />
          <br />
          <br />
          Are you sure you want to archive this group? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal('delete_modal')}>
            Cancel
          </Button>{' '}
          <Button color="danger" onClick={() => deleteLabel()}>
            Archive
          </Button>
        </ModalFooter>
      </Modal>

      <ChooseGeofencesModal
        isOpen={state.chooseGeofencesModal}
        options={state.geofenceOptions}
        value={state.selectedGeofence}
        handleGeofenceChange={onSelectGeofence}
        onCancel={() => toggleModal('chooseGeofencesModal')}
        onSave={onSaveGeofence}
      />

      <ConfirmationModal
        isOpen={state.isOpenConfirmationModal}
        description={ANIMAL_ERRORS.UPDATE_GEOFENCE_TO_GROUP}
        submitText={'Yes'}
        submitButtonType={'primary'}
        onCancel={() => setState({ isOpenConfirmationModal: false })}
        onSubmit={() => {
          addToTheGroup();
          setState({ isOpenConfirmationModal: false });
        }}
      />
    </>
  );
};

export default LabelPage;
