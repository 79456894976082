import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-gb';

import { DATE_FORMAT } from '../../constants/common';
import { EVENT_MODAL_MODE } from '../../constants/event';

import { getAllEvents, setCurrentEvent, setEventQueryParams } from '../../redux/actions/event';

import EventModal from '../Modals/EventModal';
import CustomToolbar from './CustomToolbar';
import './styles.scss';

const localizer = momentLocalizer(moment);
const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'green'
    }
  });

const CustomCalendar = ({ step = 30, queryParameters = {} }) => {
  const { components, views } = useMemo(
    () => ({
      components: {
        toolbar: CustomToolbar
      },
      views: ['month', 'week', 'day', 'agenda']
    }),
    []
  );
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState('month');
  const [isOpenEventModal, setOpenEventModal] = useState(false);
  const eventState = useSelector(state => state.event);

  const getEvents = (startDate = moment().startOf('month'), endDate = moment().endOf('month')) => {
    const params = {
      endDate: moment(endDate).format(DATE_FORMAT.DATETIME),
      ...queryParameters
    };
    dispatch(setEventQueryParams(params));
    dispatch(getAllEvents(params));
  };

  useEffect(() => {
    getEvents();
  }, []);

  const formatEvents = useCallback(events => {
    return events.map(e => {
      return {
        ...e,
        title: e.title,
        start: new Date(e.start_at),
        end: new Date(e.end_at)
      };
    });
  }, []);

  const onView = view => {
    setCurrentView(view);
  };

  const onNavigate = (date, interval) => {
    let startDate, endDate;
    if (interval === 'agenda') {
      startDate = moment(date);
      endDate = moment(date).add(1, 'month');
    } else {
      startDate = moment(date).startOf('month');
      endDate = moment(date).endOf('month');
    }

    getEvents(startDate, endDate);
  };

  const onSubmit = (event, action) => {};

  const onSelectEvent = event => {
    const formattedEvent = { ...event };
    delete formattedEvent.titleWithTime;
    delete formattedEvent.end;
    delete formattedEvent.start;
    dispatch(setCurrentEvent(formattedEvent));
    setOpenEventModal(true);
  };
  return (
    <div className="calendar-component">
      <Calendar
        components={components}
        localizer={localizer}
        events={formatEvents(eventState.events)}
        showMultiDayTimes
        step={step}
        views={views}
        defaultView={'week'}
        onView={onView}
        onNavigate={onNavigate}
        onSelectEvent={onSelectEvent}
      />

      <EventModal
        isOpen={isOpenEventModal}
        onCancel={() => setOpenEventModal(false)}
        onSubmit={onSubmit}
        mode={EVENT_MODAL_MODE.VIEW}
      />
    </div>
  );
};

export default CustomCalendar;
