'use strict';

import { Component, createRef } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

import moment from 'moment';

import axios from '../axios';

import { capitalize } from '../helpers/common';
import { sortStrings } from '../helpers/filter';

import messages from '../constants/messages';

import { errorToastHandler } from './action_notifier';
import SigTable from './sig-table-component/sig-table';

export default class Measures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      props: this.props,
      bulk_modal: false,
      data: [],
      newRows: [],
      tableData: {},
      measureTypes: {},
      selectedIds: [],
      isOpenSelectionPopover: false,
      sortedTableData: [],
      majorMinors: [],
      measureForIds: [],
      common_data: {
        major_measure_id: [],
        minor_measure_id: [],
        uom: '',
        measurement: '0',
        measurement_date: null,
        comments: ''
      },
      majorFilter: null,
      minorFilter: null,

      filters: {
        farms: [],
        geofences: [],
        labels: []
      },
      query: ''
    };

    this.addAction = this.addAction.bind(this);
    this.clearCache = this.clearCache.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.createBulkRows = this.createBulkRows.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.editAction = this.editAction.bind(this);
    this.getData = this.getData.bind(this);
    this.getMeasureTypes = this.getMeasureTypes.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onEditChange = this.onEditChange.bind(this);
    this.onSaveHandler = this.onSaveHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.prepareTableData = this.prepareTableData.bind(this);
    this.resetCommonMeasurement = this.resetCommonMeasurement.bind(this);

    this.sigTblRef = createRef();
  }

  componentDidMount() {
    this.getMeasureTypes();

    // ITS VALUE WILL COME FROM LIVESTOCK LISTING PAGE //
    let measureForIds = localStorage.getItem('measureForIds') || '[]';
    measureForIds = JSON.parse(measureForIds);

    this.setState({ measureForIds: measureForIds });

    if (measureForIds.length) this.toggleModal('bulk_modal');

    localStorage.removeItem('measureForIds');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedIds !== this.state.selectedIds) {
      this.props.initActions();
    }
  }

  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) {
      this.state.tableData.map(val => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }

    this.setState(state => ({ ...state, selected_notifications: checkedCopy }));
    this.setState({ selectAll: newCheckboxValue });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) this.state.notifications.map(val => checkedCopy.push(val.id));

    this.setState(state => ({ ...state, selected_notifications: checkedCopy }));
    this.setState({ selectAll: newCheckboxValue });
    this.onToggleSelectionPopover();
  }

  onToggleSelectionPopover(isOpen) {
    const open = isOpen !== undefined ? isOpen : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({ isOpenSelectionPopover: open });
  }

  async getMeasureTypes() {
    const response = await axios.get('measuretype');

    if (response.status === 200) {
      this.setState({ measureTypes: response.data }, () => this.getData());
    }
  }

  async getData() {
    let response;

    if (this.props.animal_id) {
      response = await axios.get('measures/' + this.props.animal_id);
    } else {
      response = await axios.get('measures', {
        params: {
          query: this.state.query,
          farm_ids: this.state.filters?.farms?.map(x => x.value),
          label_ids: this.state.filters?.labels?.map(x => x.value),
          geofence_ids: this.state.filters?.geofences?.map(x => x.value)
        }
      });
    }

    if (response.status === 200) {
      this.setState(
        {
          data: response.data.map(x => {
            x['key'] = x.id;
            x['livestock_sex'] = capitalize(x.sex);

            return x;
          })
        },
        () => this.prepareTableData()
      );
    }
  }

  filterData(filters) {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters }
      },
      () => this.getData()
    );
  }

  handleKeyPress = charCode => {
    if (charCode === 13) this.getData();
  };

  keywordSearch(value, field) {
    this.setState(state => ({ ...state, [field]: value }));
  }

  prepareTableData() {
    const columns = [
      {
        Header: 'Measure ID',
        accessor: 'measure_identifier',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: (a, b) => {
          if (!a) return -1;
          return a?.localeCompare(b);
        },
        minWidth: 120
      },
      {
        Header: 'Major Measure Type *',
        accessor: 'major_measure_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: false,
          dataType: 'options',
          isRequired: false,
          onChange: data => {
            let selectedMajor = data['major_measure_id'].filter(x => x.isSelected === true);
            data['minor_measure_id'] = [];
            data['uom'] = '';
            if (selectedMajor.length) {
              data['minor_measure_id'] = selectedMajor[0]['minors'];
            }
          }
        },
        sortMethod: (a, b) => {
          let aText = a?.filter(x => x.isSelected === true);
          aText = aText?.length ? aText[0]['label'] : '';

          let bText = b?.filter(x => x.isSelected === true);
          bText = bText?.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 180,
        filterMethod: (filter, row) => {
          if (filter.value === null) {
            return true;
          } else {
            return row._original.major_measurement_name === filter.value;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => {
              onChange(event.target.value);
              this.setState({ majorFilter: event.target.value });
              this.setState({
                minorFilter: null
              });
            }}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value={null}></option>
            {this.state?.common_data?.major_measure_id.map((major, index) => {
              return (
                <option key={`${major.label}-${index}`} value={major.label}>
                  {major.label}
                </option>
              );
            })}
          </select>
        )
      },
      {
        Header: 'Minor Measure Type *',
        accessor: 'minor_measure_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options',
          isRequired: true,
          onChange: data => {
            let selectedMinor = data['minor_measure_id'].filter(x => x.isSelected === true);
            data['uom'] = '';
            if (selectedMinor.length) {
              data['uom'] = selectedMinor[0]['uom'];
            }
          }
        },
        sortMethod: (a, b) => {
          let aText = a?.filter(x => x.isSelected === true);
          aText = aText?.length ? aText[0]['label'] : '';

          let bText = b?.filter(x => x.isSelected === true);
          bText = bText?.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 180,
        filterMethod: (filter, row) => {
          if (this.state.minorFilter === null) {
            return true;
          } else {
            return row._original.minor_measurement_name === this.state.minorFilter;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => {
              onChange(event.target.value);
              this.setState({ minorFilter: event.target.value });
            }}
            style={{ width: '100%' }}
            value={this.state.minorFilter ? this.state.minorFilter : 'all'}>
            <option value={null}></option>
            {this.state.majorFilter
              ? this.state.common_data.major_measure_id
                  ?.find(major => major.label === this.state.majorFilter)
                  ?.minors?.map((minor, index) => {
                    return (
                      <option key={`${minor.label}-${index}`} value={minor.label}>
                        {minor.label}
                      </option>
                    );
                  })
              : this.state.common_data.major_measure_id
                  ?.reduce((acc, curr) => acc.concat(curr.minors), [])
                  ?.map((minor, index) => {
                    return (
                      <option key={`${minor.label}-${index}`} value={minor.label}>
                        {minor.label}
                      </option>
                    );
                  })}
          </select>
        )
      },
      {
        Header: 'UOM',
        accessor: 'uom',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 80
      },
      {
        Header: 'Measurement *',
        accessor: 'measurement',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          placeholder: '0',
          dataType: 'number',
          isRequired: true
        },
        sortMethod: (a, b) => a - b,
        minWidth: 150
      },
      {
        Header: 'Measurement Date *',
        accessor: 'measurement_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: true
        },
        sortMethod: sortStrings,
        minWidth: 160
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 180
      }
    ];

    // LIVESTOCK COLUMNS //
    const livestockColumns = [
      {
        id: 'identifier',
        Header: 'Livestock ID',
        accessor: 'identifier',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false,
          Cell: data => {
            return <a href={'/animal/' + data['id']}>{data['identifier']}</a>;
          }
        },
        sortMethod: sortStrings,
        minWidth: 120
      },
      {
        Header: 'Mgmt Tag ID',
        accessor: 'eartag_management_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 130
      },
      {
        Header: 'Sex',
        accessor: 'sex',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings
      },
      {
        Header: 'Colour',
        accessor: 'colour',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 110
      },
      {
        Header: 'Breed',
        accessor: 'breed',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        minWidth: 140
      }
    ];
    // ADD INTO LIST VIEW //
    if (typeof this.props.animal_id === 'undefined' || !this.props.animal_id) {
      livestockColumns.reverse();
      livestockColumns.map(x => columns.unshift(x));
    }

    // PERPAIR MEASURMENTS //
    const _majorMinors = this.state.measureTypes.map(x => {
      return {
        value: x.major_measurement_id,
        label: x.major_measurement_name,
        isSelected: false,
        minors: x.measure_minors.map(y => {
          return {
            value: y.minor_measurement_id,
            label: y.minor_measurement_name,
            uom: y.uom,
            isSelected: false
          };
        })
      };
    });

    // PERPAIR ROW DATA //
    // CREATING ARRAY OF MAJOR & MINOR MEASUREMENTS, SET VALUE OF UOM //
    const rowData = this.state.data.map(row => {
      let majorMinors = JSON.parse(JSON.stringify(_majorMinors));
      row.major_measure_id = majorMinors.map(x => {
        x.isSelected = false;
        if (x.value === row.major_measure_id) {
          x.isSelected = true;
          row.minor_measure_id = x.minors.map(y => {
            y.isSelected = false;
            if (y.value === row.minor_measure_id) {
              y.isSelected = true;
              row.uom = y.uom;
            }
            return y;
          });

          // SET DEAFULT VALUES, IF NO RECORD FOR MINORS //
          if (!x.minors.length) {
            row.minor_measure_id = [];
            row.uom = '';
          }
        }
        return x;
      });
      return row;
    });

    this.setState(
      state => ({
        ...state,
        majorMinors: _majorMinors,
        tableData: {
          column: columns,
          data: rowData
        }
      }),
      () => this.resetCommonMeasurement()
    );
  }

  addAction() {
    this.addRows();
  }

  addRows() {
    // GET DYNAMIC COLOUMNS FROM TABLE VIEW //
    let newrowKeys = {};
    this.state.tableData.column.map(obj => {
      if (['options', 'multi_select'].indexOf(obj.additional.dataType) > -1) {
        newrowKeys[obj.accessor] = [];
      } else if (['bool'].indexOf(obj.additional.dataType) > -1) {
        newrowKeys[obj.accessor] = false;
      } else {
        newrowKeys[obj.accessor] = '';
      }
    });

    // ADDING NEW ROW ON TOP //
    if (Object.keys(newrowKeys).length) {
      // ASSIGN DEFAULT VALUES //
      newrowKeys['major_measure_id'] = JSON.parse(JSON.stringify(this.state.common_data['major_measure_id']));
      newrowKeys['minor_measure_id'] = JSON.parse(JSON.stringify(this.state.common_data['minor_measure_id']));
      newrowKeys['uom'] = this.state.common_data['uom'];
      newrowKeys['measurement'] = this.state.common_data['measurement'];
      newrowKeys['comments'] = this.state.common_data['comments'];
      newrowKeys['isEditable'] = true;
      newrowKeys['livestock_breed'] = '';
      newrowKeys['livestock_colour'] = '';
      newrowKeys['livestock_identifier'] = '';
      newrowKeys['livestock_management_id'] = '';
      newrowKeys['livestock_sex'] = '';
      newrowKeys['measurement_date'] = this.state.common_data['measurement_date']
        ? moment(this.state.common_data['measurement_date']).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');

      // CREATE n NUMBER OF ROWS, BASED ON SELECTION //
      let newRows = [];
      let counter = 1;

      if (Object.keys(this.sigTblRef.current.state.editApply).length) {
        counter = Object.keys(this.sigTblRef.current.state.editApply).length;
      }

      // CHECK IF CREATE NEW ROWS REQUEST COME FROM LIVESTOCK LISTING PAGE //
      if (this.state.measureForIds.length) {
        counter = this.state.measureForIds.length;
      }

      while (counter--) {
        let newrow = JSON.parse(JSON.stringify(newrowKeys));
        newrow['id'] = Math.random().toString(36).substr(2, 9);
        newrow['key'] = newrow['id'];
        // CHECK IF CREATE NEW ROWS REQUEST COME FROM LIVESTOCK LISTING PAGE //
        if (this.state.measureForIds.length) {
          let row = this.state.measureForIds[counter];
          newrow['livestock_id'] = row['livestock_id'];
          newrow['measure_id'] = row['measure_id'];
          newrow['livestock_breed'] = row['breed'];
          newrow['livestock_colour'] = row['colour'];
          newrow['livestock_identifier'] = row['identifier'];
          newrow['livestock_management_id'] = row['management_id'];
          newrow['livestock_sex'] = capitalize(row['sex']);
          // newrow["identifier"] = row["identifier"];
          // newrow["breed"] = row["breed"];
          // newrow["colour"] = row["colour"];
          // newrow["eartag_management_id"] = row["management_id"];
          // newrow["sex"] = capitalize(row["sex"]);
        }
        // DEFAULT BEHAVIOUR //
        else {
          newrow['livestock_id'] = this.props.animal_id ? parseInt(this.props.animal_id) : 0;
        }
        newRows.push(newrow);
      }

      // GET & ASSIGN LIVESTOCK ID FOR NEW ROWS //
      let tmpEditApply = JSON.parse(JSON.stringify(this.sigTblRef.current.state.editApply));
      if (Object.keys(tmpEditApply).length) {
        Object.keys(tmpEditApply).map((id, indx) => {
          let row = this.sigTblRef.current.state.field.filter(x => x.id === parseInt(id));
          if (row.length) {
            newRows[indx]['livestock_id'] = row[0]['livestock_id'];
            newRows[indx]['measure_id'] = row[0]['measure_id'];
            newRows[indx]['livestock_breed'] = row[0]['livestock_breed'];
            newRows[indx]['livestock_colour'] = row[0]['livestock_colour'];
            newRows[indx]['livestock_identifier'] = row[0]['livestock_identifier'];
            newRows[indx]['livestock_management_id'] = row[0]['livestock_management_id'];
            newRows[indx]['livestock_sex'] = capitalize(row[0]['livestock_sex']);
            newRows[indx]['breed'] = row[0]['breed'];
            newRows[indx]['colour'] = row[0]['colour'];
            newRows[indx]['eartag_management_id'] = row[0]['eartag_management_id'];
            newRows[indx]['identifier'] = row[0]['identifier'];
            newRows[indx]['sex'] = capitalize(row[0]['sex']);
          }
        });
      }
      this.setState(
        state => ({ ...state, newRows: newRows }),
        () => this.sigTblRef.current.setStatePostAdd()
      );

      // SHOW FIRST PAGE AFTER ADDING NEW ROWS //
      setTimeout(() => {
        while (!document.getElementsByClassName('-previous')[0].children[0].disabled) {
          document.getElementsByClassName('-previous')[0].children[0].click();
        }
      }, 200);
    }
  }

  editAction() {
    if (Object.keys(this.sigTblRef.current.state.editApply).length) {
      this.sigTblRef.current.editHandler();
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }

  deleteAction() {
    if (Object.keys(this.sigTblRef.current.state.editApply).length) {
      this.sigTblRef.current.deleteHandler();
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }

  saveAction() {
    this.resetCommonMeasurement();
    this.sigTblRef.current.saveHandler();
  }

  cancelAction() {
    this.clearCache();
    this.resetCommonMeasurement();
    this.sigTblRef.current.globalCancelHandler();
  }

  clearCache() {
    this.setState(state => ({ ...state, measureForIds: [] }));
  }

  resetCommonMeasurement() {
    this.setState(state => ({
      ...state,
      common_data: {
        major_measure_id: JSON.parse(JSON.stringify(this.state.majorMinors)),
        minor_measure_id: [],
        uom: '',
        measurement: '0',
        measurement_date: null,
        comments: ''
      }
    }));
  }

  // NOT IN USE; BUT REQUIRED FOR CODE FLOW //
  onSelectHandler = rows => {
    this.setState(state => ({ ...state, selectedIds: [...Object.keys(rows)] }));
  };

  onSaveHandler = (rows, i) => {
    // return row which is saved
    const postData = rows.map(row => {
      let major_id = row.major_measure_id.filter(x => x.isSelected === true);
      major_id = major_id.length ? major_id[0]['value'] : null;

      let minor_id = row.minor_measure_id.filter(x => x.isSelected === true);
      minor_id = minor_id.length ? minor_id[0]['value'] : null;

      return {
        id: isNaN(row.measure_id) ? null : row.measure_id,
        livestock_id: parseInt(row.livestock_id),
        major_measure_id: parseInt(major_id),
        minor_measure_id: parseInt(minor_id),
        measure_id: parseInt(row.measure_id),
        uom: row.uom,
        measurement_date: moment(row.measurement_date).format('YYYY-MM-DD'),
        measurement: parseFloat(row.measurement),
        comments: row.comments
      };
    });

    if (postData.length) this.saveOperation(postData);
  };

  onDeleteHandler = rows => {
    // return keys of the row which is deleted
    const deletedIds = Object.keys(rows).map(x => parseInt(x));

    if (deletedIds.length) this.deleteOperation(deletedIds);
  };

  async saveOperation(postData) {
    try {
      if (this.props.tab_action === 'edit') {
        const response = await axios.put('measures/bulk', {
          measures: JSON.stringify(postData)
        });

        if (response.status === 200) {
          this.props.clearTabOperation();
          this.sigTblRef.current.clearTable();
          this.getData();
          this.clearCache();

          if (typeof this.props.onSave !== 'undefined') this.props.onSave();
        }
      } else {
        const response = await axios.post('measures/bulk', {
          measures: JSON.stringify(postData)
        });

        if (response.status === 200) {
          this.props.clearTabOperation();
          this.sigTblRef.current.clearTable();
          this.getData();
          this.clearCache();

          if (typeof this.props.onSave !== 'undefined') this.props.onSave();
        }
      }
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  async deleteOperation(deletedIds) {
    const response = await axios.delete('measures/destroy_bulk', {
      data: {
        ids: deletedIds
      }
    });

    if (response.status === 200) {
      this.getData();
      this.clearCache();
    }
  }

  toggleModal(modal) {
    this.resetCommonMeasurement();
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  closePopup(modal) {
    this.toggleModal(modal);
    this.clearCache();
  }

  onEditChange = (value, field) => {
    this.setState(state => ({
      common_data: {
        ...state.common_data,
        [field]: value
      }
    }));
  };

  createBulkRows() {
    this.props.addNewTabRows();
    this.setState(state => ({ ...state, bulk_modal: false }));
    this.addAction();
  }

  render() {
    return (
      <div className="allow-overflow">
        <SigTable
          data={this.state.tableData} // data for the table
          newRows={this.state.newRows} //add new rows here
          ondelete={this.onDeleteHandler} //callback
          onselect={this.onSelectHandler} // callback
          onsave={this.onSaveHandler} // callback
          ref={this.sigTblRef}
        />

        <Modal isOpen={this.state.bulk_modal}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Create New Measurements</b>
            </h5>
            <br />
            <Form>
              <FormGroup row>
                <Label sm="4">Major Measurement Type</Label>
                <Col sm="8">
                  <Select
                    value={this.state.common_data.major_measure_id.filter(x => x.isSelected === true)[0]}
                    onChange={selection => {
                      this.state.common_data.major_measure_id.map(x => {
                        x.isSelected = false;
                        if (x.value === selection.value) {
                          x.isSelected = true;
                          this.state.common_data.uom = '';
                          this.state.common_data.minor_measure_id = x.minors.map(y => {
                            y.isSelected = false;
                            return y;
                          });
                        }
                        return x;
                      });
                      this.setState({ common_data: this.state.common_data });
                    }}
                    options={this.state.common_data.major_measure_id}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Minor Measurement Type</Label>
                <Col sm="8">
                  <Select
                    value={
                      this.state.common_data.minor_measure_id.length
                        ? this.state.common_data.minor_measure_id.filter(x => x.isSelected === true)[0]
                        : null
                    }
                    onChange={selection => {
                      this.state.common_data.uom = '';
                      this.state.common_data.minor_measure_id.map(x => {
                        x.isSelected = false;
                        if (x.value === selection.value) {
                          x.isSelected = true;
                          this.state.common_data.uom = selection.uom;
                        }
                        return x;
                      });
                      this.setState({ common_data: this.state.common_data });
                    }}
                    options={this.state.common_data.minor_measure_id}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">UOM</Label>
                <Col sm="8">{this.state.common_data.uom}</Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Measurement</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.measurement}
                    onChange={e => this.onEditChange(e.target.value, 'measurement')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Measurement Date</Label>
                <Col sm="8">
                  <DatePicker
                    selected={this.state.common_data.measurement_date}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    onChange={e => this.onEditChange(e, 'measurement_date')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Comments</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    defaultValue={this.state.common_data.comments}
                    onChange={e => this.onEditChange(e.target.value, 'comments')}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.closePopup('bulk_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.createBulkRows()}>
              Create Measures
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
