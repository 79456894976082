import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import classnames from 'classnames';

import { sortStrings } from '../../helpers/filter';

function AppTableFilterPopup({ dataIndex, filters, selectedKeys, title, handleOnChange, handleReset, handleConfirm }) {
  const { selectedFilters } = useSelector(state => state.animal);
  const [selectedValues, setSelectedValues] = useState(selectedFilters[dataIndex] || []);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (selectedFilters) {
      setSelectedValues(selectedFilters[dataIndex] || []);
    }
  }, [selectedFilters]);

  const handleSearchChange = e => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const handleCheckboxChange = checkedValue => {
    const updatedValues = selectedValues.includes(checkedValue)
      ? selectedValues.filter(val => val !== checkedValue)
      : [...selectedValues, checkedValue];

    setSelectedValues(updatedValues);
    handleOnChange?.(updatedValues);
  };

  const filteredOptions = filters.filter(option => option.text.toLowerCase().includes(searchValue));
  filteredOptions.sort((a, b) => sortStrings(a.text, b.text));

  const reset = () => {
    setSelectedValues([]);
    setSearchValue('');
    handleReset();
  };

  return (
    <div style={{ maxWidth: '300px' }} onKeyDown={e => e.stopPropagation()}>
      <div className="search-wrapper p-2">
        <Input
          placeholder={`Search ${title}`}
          prefix={<SearchOutlined />}
          value={searchValue}
          onChange={handleSearchChange}
        />
      </div>
      <div className="p-2" style={{ maxHeight: 400, overflowY: 'auto' }}>
        {filteredOptions.map(filter => (
          <Checkbox
            className={classnames('option-item', 'p-1', { 'selected-option': selectedValues.includes(filter.value) })}
            key={filter.value}
            style={{ width: '100%' }}
            checked={selectedValues.includes(filter.value)}
            onChange={() => handleCheckboxChange(filter.value)}>
            {filter.text}
          </Checkbox>
        ))}
      </div>
      <div className="d-flex justify-content-between p-2" style={{ borderTop: '1px solid #e9ecef ' }}>
        <Button size="small" type="link" onClick={reset} disabled={selectedKeys.length === 0}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={handleConfirm}>
          Ok
        </Button>
      </div>
    </div>
  );
}

export default memo(AppTableFilterPopup);
