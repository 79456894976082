import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import classnames from 'classnames';
import moment from 'moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { getFilterOptions, sortDates, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import messages from '../../constants/messages';

import { setSelectedFilters } from '../../redux/actions/animal';

import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import LoadingBar from '../../components/LoadingBar';
import PrevNextButtons from '../../components/PrevNextButtons';
import AnimalDetails from '../../components/TrackLivestock/AnimalDetails';
import LivestockDetails from '../../components/TrackLivestock/LivestockDetails';
import TimeSliders from '../../components/TrackLivestock/TimeSliders';
import { DETAIL_SLIDER, MIN_TIME_FRAME_DOTS_NUMBER } from '../../components/TrackLivestock/constants';
import { errorToastHandler } from '../../components/action_notifier';
import Map from '../../components/map-tracking.jsx';
import StatusIndicator from '../../components/statusIndicator';

import zoom from '../../assets/images/zoom.png';

export default function Tracking({ history, match }) {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.animal);
  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [state, setState] = useState({
    activeTab: '1',
    all_tracks: {},
    all_tracksCopy: {},
    animal: null,
    animalId: match?.params?.id,
    animal_tracking: {},
    animal_tracking_filtered: {},
    animals: [],
    columnFilterOptions: {
      breeds: [],
      connectionStatuses: [],
      farm: [],
      locations: [],
      sex: []
    },
    endDateTime: moment.utc().format(),
    farm_opts: [],
    farms: [],
    isOpenSelectionPopover: false,
    isTracksLoading: false,
    tableData: [],
    filters: {
      animal_ids: [],
      farms: [],
      labels: [],
      notifications: false,
      time: null
    },
    filter_open: false,
    isFilterApplied: false,
    isFullScreen: false,
    selectAll: false,
    selected_animals: [],
    time_frame: 66,
    time_interval: 0
  });

  useEffect(() => {
    resetFilters();

    async function fetchData() {
      try {
        const [animals, farms, labels] = await Promise.all([getAnimals(), getFarms(), getLabels()]);

        setFarmsToState(farms.data);
        setAnimalsToState(animals.data);
        setLabelsToState(labels.data);

        setState(prevState => ({
          ...prevState,
          columnFilterOptions: {
            ...prevState.columnFilterOptions,
            breeds: getFilterOptions(animals?.data, 'breed', 'display_name'),
            connectionStatuses: getFilterOptions(animals?.data, 'tag', 'status'),
            farms: getFilterOptions(animals?.data, 'farm', 'name'),
            locations: [
              ...new Set(
                animals?.data?.map(l => (l.assigned_geofences.length ? l.assigned_geofences.at(0) : BLANK_FILTER_TEXT))
              )
            ].map(geofence => ({ text: geofence, value: geofence })),
            sex: getFilterOptions(animals?.data, 'sex')
          }
        }));

        await getAllTracks();
      } catch (error) {
        errorToastHandler(messages.FAILED_ON_FETCH_DATA);
      } finally {
        setIsLoading(false);
      }
    }

    initActions();
    fetchData();
  }, []);

  useEffect(() => {
    if (match?.params?.id) {
      setState(prevState => ({ ...prevState, animalId: match.params.id }));

      getAnimal();
    }
  }, [match?.params?.id]);

  useEffect(() => {
    if (state.filters.animal_ids || state.filters.farms) {
      showSelectedAnimals();
    }
  }, [state.filters.animal_ids, state.filters.farms]);

  useEffect(() => {
    initActions();
  }, [selectedFilters]);

  function initActions() {
    setActions([
      {
        label: 'Clear all filters',
        isVisible: true,
        handler: () => clearFilters(),
        disabled: !Object.values(selectedFilters).length
      }
    ]);
  }

  const setAnimalsToState = animals => {
    setState(prevState => ({
      ...prevState,
      animals,
      animalsLoaded: true
    }));
  };

  const setFarmsToState = farms => {
    const mapped_farms = [];

    for (const farm of farms) {
      mapped_farms.push({ value: farm.id, label: farm.name });
    }

    setState(prevState => ({
      ...prevState,
      farms,
      farmsLoaded: true,
      farm_opts: mapped_farms
    }));
  };

  const setLabelsToState = labels => {
    setState(prevState => ({
      ...prevState,
      labelsLoaded: true,
      labels: labels.map(x => ({ value: x.id, label: x.name }))
    }));
  };

  function toggleFilters() {
    setState(prevState => ({ ...prevState, filter_open: !state.filter_open }));
  }

  function closeFilters(bool) {
    setState(prevState => ({ ...prevState, filter_open: false, isFilterApplied: bool }));
  }

  function toggleTabs(tab) {
    if (state.activeTab !== tab) {
      setState(prevState => ({ ...prevState, activeTab: tab }));
    }
  }

  async function getAllTracks() {
    try {
      const response = await axios.get('animals/tag-data', {
        params: {
          label_ids: state.filters.labels.map(x => x.value),
          only_notifications: state.filters.notifications,
          farm_ids: state.filters.farms.map(x => x.value)
        }
      });

      if (response.status === 200) {
        if (response.data.features) {
          response.data.features = response.data.features.map(x => {
            if (x.properties) x.properties['isVisible'] = true;

            return x;
          });
        }

        if (state.animalsLoaded && state.animals.length === 0) {
          setState(prevState => ({
            ...prevState,
            tracksLoaded: true,
            all_tracks: {},
            all_tracksCopy: {}
          }));

          return;
        }

        response.data.features.map(m => {
          state.animals.find(x => {
            if (x.id === m.properties.animal_id) {
              m.geometry.coordinates[0] = x?.tag?.lat;
              m.geometry.coordinates[1] = x?.tag?.lng;
            }
          });
        });

        const res = state.animals.filter(
          item1 => !response.data.features.some(item2 => item2.properties.animal_id === item1.id)
        );

        const arr = [];
        for (const i in res) {
          arr.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [res[i].tag?.lat, res[i].tag?.lng]
            },
            properties: {
              farm_id: res[i].farm_id,
              animal_id: res[i].id,
              customIcon: 'start',
              diagri_id: res[i].tag.diagri_id,
              eartag_management_id: res[i].eartag_management_id,
              eartag_official_id: res[i].eartag_official_id,
              hasAlerts: res[i].hasAlerts,
              identifier: res[i].identifier,
              isVisible: true,
              notifications: [],
              sex: res[i].sex,
              specie: res[i].specie.name,
              time: ''
            }
          });
        }
        response.data.features = response.data.features.concat(arr);
        setState(prevState => ({
          ...prevState,
          tracksLoaded: true,
          all_tracks: response.data,
          all_tracksCopy: response.data
        }));

        showSelectedAnimals();
      }
    } catch (e) {
      setState(prevState => ({ ...prevState, tracksLoaded: true }));
    }
  }

  async function getAnimal() {
    const animalId = state.animalId;

    if (!animalId) {
      setState(prevState => ({ ...prevState, animal: null }));
    } else {
      try {
        const animal = await axios.get(`animals/${animalId}`, {
          params: {
            only_linked: true
          }
        });

        const animalTagData = await axios.get(`animals/${animalId}/tag-data`, {
          params: {
            from_date: moment.utc().subtract(48, 'hours').format(), // LAST 2 DAYS DATA (24*2)
            only_notifications: state.filters.notifications
          }
        });

        if (animalTagData.data.features) {
          animalTagData.data.features = animalTagData.data.features.map(item => {
            return {
              ...item,
              properties: {
                ...item.properties,
                isVisible: true,
                eartag_management_id: animal.data.eartag_management_id,
                eartag_official_id: animal.data.eartag_official_id,
                hasAlerts: animal.data.hasAlerts,
                identifier: animal.data.identifier
              }
            };
          });
        }

        setState(prevState => ({
          ...prevState,
          tracksLoaded: true,
          animal: animal.data,
          animal_tracking: animalTagData.data,
          farm: state.farms?.find(x => x.id === animal.data.farm_id)
        }));
        // FILTER TRACKING DATA ACCORDING TO SLIDERS //
        filterAnimalTracking();
      } catch (e) {
        setState(prevState => ({
          ...prevState,
          tracksLoaded: true
        }));
      }
    }
  }

  async function getAnimals() {
    return axios.get('animals', {
      params: {
        only_linked: true,
        label_ids: state.filters.labels.map(x => x.value),
        only_notifications: state.filters.notifications,
        farm_ids: state.filters.farms.map(x => x.value)
      }
    });
  }

  function getFarms() {
    return axios.get('farms?with_details=true');
  }

  function setAnimal(id) {
    history.push(`/tracking/${id}`);
  }

  function getLabels() {
    return axios.get('/labels');
  }

  function resetFilters(isClose) {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        labels: [],
        notifications: false,
        animal_ids: [],
        farms: []
      }
    }));

    updateFilters(isClose);
  }

  const updateFilters = bool => {
    setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        animal_ids: []
      }
    }));
    getAnimals();
    showSelectedAnimals();
    if (bool) {
      closeFilters(true);
      return;
    }
    closeFilters(bool);
  };

  const displayFarmsOnMap = async (pagination, filters, sorter, extra) => {
    const farms = extra.currentDataSource.map(c => ({ label: c?.farm?.name, value: c?.farm?.id }));

    if (extra.action === 'filter') {
      setState(state => ({
        ...state,
        filters: {
          ...state.filters,
          animal_ids: [],
          farms
        }
      }));

      try {
        await getAnimals();
      } catch (error) {
        errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
      }
    }
  };

  function showSelectedAnimals() {
    if (state.all_tracksCopy && state.all_tracksCopy.features) {
      let filteredFeatures = [...state.all_tracksCopy.features];
      const filterFarms = state.filters.farms?.map(({ value }) => value);
      const filterAnimals = state.filters.animal_ids;

      if (filterFarms.length) {
        filteredFeatures = filteredFeatures.filter(item => filterFarms.includes(item.properties.farm_id));
      }

      if (filterAnimals.length) {
        filteredFeatures = filteredFeatures.filter(item => filterAnimals.includes(item.properties.animal_id));
      }

      setState(prevState => ({
        ...prevState,
        all_tracks: {
          ...prevState.all_tracks,
          features: filteredFeatures
        }
      }));
    }
  }

  function sliderHandler(value, field) {
    state[field] = value;

    // FILTER TRACKING DATA ACCORDING TO SLIDERS //
    filterAnimalTracking();
  }

  function filterAnimalTracking() {
    let time_frame = DETAIL_SLIDER.timeFrameSlots.filter(x => x.index === state.time_frame)[0];
    let time_interval = DETAIL_SLIDER.timeIntervalSlots.filter(x => x.index === state.time_interval)[0];

    let animal_tracking = state.animal_tracking;
    let line_coordinates = [],
      animal_tracking_filtered = [];

    const last_reading = animal_tracking.features
      .filter(x => x.geometry.type === 'Point')
      .filter(x => x.properties.last_reading);

    if (animal_tracking.type) {
      animal_tracking_filtered = animal_tracking.features
        .filter(x => x.geometry.type === 'Point')
        .filter(x => !x.properties.last_reading)
        .map(x => {
          delete x.properties.customIcon;
          return x;
        });

      // APPLY FILTERS 'TIME FRAME & TIME INTERVAL' //
      if (time_frame.index !== 100 || time_interval.index !== 0) {
        // ADDED 12 MINS MORE, SO CURRENT/START POINT CAN ALSO CONSIDER //
        let startDateTime = moment(state.endDateTime).utc().subtract({ hours: time_frame.value, minutes: 12 }).format();

        // FILTER WITH 'TIME FRAME' //
        if (time_frame.index !== 100) {
          animal_tracking_filtered = animal_tracking_filtered.filter(item => {
            return moment(item.properties.time).isBetween(startDateTime, state.endDateTime);
          });
        }

        // FILTER WITH 'TIME INTERVAL'; GET EVERY NTH ELEMENT; //
        if (time_interval.index !== 0) {
          let temp_ary = [];
          for (let i = 0; i < animal_tracking_filtered.length; i = i + time_interval.value) {
            temp_ary.push(animal_tracking_filtered[i]);
          }
          animal_tracking_filtered = temp_ary;
        }
      }

      line_coordinates = animal_tracking_filtered.map(x => {
        return x.geometry.coordinates;
      });
    }

    const isLastReading = !animal_tracking_filtered.length;

    const isPartialReading =
      animal_tracking_filtered.length &&
      animal_tracking_filtered.length <= MIN_TIME_FRAME_DOTS_NUMBER[time_frame.value];

    if (animal_tracking_filtered.length > 1) {
      // MINIMUM 2 COORDINATES REQUIRED FOR LINE //
      animal_tracking_filtered.unshift({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: line_coordinates
        }
      });
    }

    if (isLastReading) {
      animal_tracking_filtered = last_reading;
      errorToastHandler(messages.NO_LOCATION_DATA_AVAILABLE_FOR_TIME_FRAME);
    }

    if (isPartialReading) {
      errorToastHandler(messages.PARTIAL_LOCATION_DATA_AVAILABLE_FOR_PART_TIME);
    }

    setState(prevState => ({
      ...prevState,
      animal_tracking_filtered: {
        type: state.animal_tracking.type,
        features: animal_tracking_filtered
      }
    }));
  }

  function toggleFullscreen() {
    setState(state => ({
      ...state,
      isFullScreen: !state.isFullScreen
    }));
  }

  function showLoadingBar() {
    return (
      (match?.params?.id && !state.animal) ||
      ((!state.tracksLoaded || !state.animalsLoaded || !state.farmsLoaded) && !state.isFilterApplied) ||
      isLoading
    );
  }

  const selectRecords = checkedIds => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        animal_ids: checkedIds
      }
    }));
  };

  const columns = [
    {
      title: 'Livestock ID',
      dataIndex: 'identifier',
      ellipsis: true,
      fixed: 'left',
      filteredValue: filteredInfo.identifier || null,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => <Link to={`/tracking/${record.id}`}>{value}</Link>,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier)
    },
    {
      title: 'Mgmt Tag ID',
      dataIndex: 'eartag_management_id',
      ellipsis: true,
      filteredValue: filteredInfo.eartag_management_id || null,
      width: COLUMN_SIZE.XL,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      rules: [{ required: true, message: 'Mgmt Tag ID is required.' }]
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      ellipsis: true,
      filteredValue: filteredInfo.sex || null,
      width: COLUMN_SIZE.SM,
      sortDirections: ['ascend', 'descend'],
      filters: state.columnFilterOptions.sex,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.sex : record?.sex === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.sex, b.sex)
    },
    {
      title: 'Colour',
      dataIndex: 'colour',
      ellipsis: true,
      filteredValue: filteredInfo.colour || null,
      width: COLUMN_SIZE.SM,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.colour, b.colour)
    },
    {
      title: 'Breed',
      dataIndex: 'breedName',
      ellipsis: true,
      filteredValue: filteredInfo.breedName || null,
      width: COLUMN_SIZE.LG,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: state.columnFilterOptions.breeds,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.breed?.display_name : record?.breed?.display_name === value,
      render: (value, record) => record?.breed?.display_name,
      sorter: (a, b) => sortStrings(a.breed?.display_name, b.breed?.display_name)
    },
    {
      title: 'Kraal Tag ID',
      dataIndex: 'tagIdentifier',
      ellipsis: true,
      filteredValue: filteredInfo.tagIdentifier || null,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const searchField = record?.tag?.diagri_id || 'Unlinked';

        return searchField.toString().toLowerCase().includes(value.toLowerCase());
      },
      render: (value, record) =>
        record?.tag?.diagri_id ? <Link to={`/tag/${record?.tag?.id}`}>{record?.tag?.diagri_id}</Link> : 'Unlinked',
      sorter: (a, b) => sortStrings(a?.tag?.id, b?.tag?.id)
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      ellipsis: true,
      filteredValue: filteredInfo.brand || null,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.brand, b.brand)
    },
    {
      title: 'Farm',
      dataIndex: 'farm',
      ellipsis: true,
      filteredValue: filteredInfo.farm || null,
      width: COLUMN_SIZE.XL,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: state.columnFilterOptions.farms,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.farm?.name : record?.farm?.name === value),
      render: (value, record) => record?.farm?.name || '',
      sorter: (a, b) => sortStrings(a?.farm?.name, b?.farm?.name)
    },
    {
      title: 'Assigned Geofence',
      dataIndex: 'assignedGeofence',
      ellipsis: true,
      filteredValue: filteredInfo.assignedGeofence || null,
      width: COLUMN_SIZE.LG,
      sortDirections: ['ascend', 'descend'],
      filterSearch: true,
      filters: state.columnFilterOptions.locations,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.assigned_geofences.length : record?.assigned_geofences?.includes(value),
      render: (value, record) => {
        const currentGeofence = record.geofences?.find(g => !g?.is_master);

        return currentGeofence ? (
          <Link to={`geofence/${currentGeofence?.id}`}>{record.assigned_geofences?.at(0)}</Link>
        ) : (
          ''
        );
      },
      sorter: (a, b) => sortStrings(a.assigned_geofences.at(0), b.assigned_geofences.at(0))
    },
    {
      title: 'Last Reading',
      dataIndex: 'lastReading',
      ellipsis: true,
      filteredValue: filteredInfo.lastReading || null,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => record?.tag?.timestamp_at?.includes(value.toLowerCase()),
      sorter: (a, b) => sortDates(a.tag?.timestamp_at, b.tag?.timestamp_at),
      render: (value, record) => {
        const lastReadingTime = record?.tag?.timestamp_at;

        return lastReadingTime ? moment(lastReadingTime).format(DATE_FORMAT.DATETIME) : null;
      }
    },
    {
      title: 'Connection Status',
      dataIndex: 'connectionStatus',
      ellipsis: true,
      filteredValue: filteredInfo.connectionStatus || null,
      width: COLUMN_SIZE.MD,
      sortDirections: ['ascend', 'descend'],
      filters: state.columnFilterOptions.connectionStatuses,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.tag?.status?.length : record?.tag?.status?.includes(value),
      sorter: (a, b) => sortStrings(a?.tag?.status, b?.tag?.status),
      render: (value, record) => {
        const status = record?.tag?.status;
        const id = record?.id;

        return (
          record && (
            <div className="d-flex align-items-center">
              <StatusIndicator key={id} status={status} />
              {capitalize(status)}
            </div>
          )
        );
      }
    }
  ];

  const zoomImg = { backgroundImage: 'url(' + zoom + ')' };

  const filteredFarms = state.farms?.filter(
    farm => !state.filters.farms.length || state.filters.farms.find(item => item.value === farm.id)
  );

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  return (
    <>
      {showLoadingBar() ? (
        <LoadingBar />
      ) : (
        <div key={match.params.id}>
          <Row className="inner-header-top">
            <Col xs="12" md="12" lg="6">
              <h4>Track Livestock {state.animal && (state.animal.identifier || state.animal.eartag_official_id)}</h4>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/tracking">Track Livestock</Link>
                </BreadcrumbItem>
                {state.animal && (
                  <BreadcrumbItem>
                    <Link to={`/animal/${state.animal.id}`}>
                      Livestock {state.animal.identifier || state.animal.eartag_official_id}
                    </Link>
                  </BreadcrumbItem>
                )}
              </Breadcrumb>
            </Col>
            <Col xs="12" md="12" lg="6">
              {state.animal && (
                <PrevNextButtons
                  history={history}
                  nextId={state.animal?.next_id}
                  prevId={state.animal?.prev_id}
                  path="tracking"
                />
              )}
            </Col>
          </Row>
          <div className={state.isFullScreen ? 'rg-full-screen' : ''}>
            <Row>
              <Col lg="12">
                <div id="track_map">
                  <Map
                    isFullScreen={state.isFullScreen}
                    key={JSON.stringify(state.animal)}
                    className="small-map"
                    onAnimalClick={setAnimal}
                    animal={state.animal}
                    farm={state.animal ? state.farm : null}
                    farms={!state.animal ? filteredFarms : null}
                    tracking={state.animal ? state.animal_tracking_filtered : state.all_tracks}
                  />
                </div>
                {state.animal && (
                  <>
                    <div className="full-screen-icon text-center" style={zoomImg} onClick={toggleFullscreen}></div>

                    {state.isFullScreen && <AnimalDetails animal={state.animal} />}
                  </>
                )}
              </Col>
            </Row>

            {state.animal && <TimeSliders sliderHandler={sliderHandler} />}
          </div>

          {state.animal && <LivestockDetails animal={state.animal} />}

          <Card>
            <Row>
              <Col sm="6" style={{ padding: '15px 30px 0px 30px' }}>
                <h4>Track Livestock</h4>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: state.activeTab === '1' })}
                      onClick={() => toggleTabs('1')}>
                      Livestock Location
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="no-bg" activeTab={state.activeTab}>
                  <TabPane tabId="1" className="p-2">
                    <AppTable
                      actions={actions}
                      baseColumns={columns}
                      dataSource={state.animals}
                      filterable={false}
                      loading={isLoading}
                      searchable={false}
                      handleFilters={() => toggleFilters()}
                      handleOnChange={(pagination, filters, sorter, extra) => {
                        displayFarmsOnMap(pagination, filters, sorter, extra);
                        setFilters(pagination, filters, sorter, extra);
                      }}
                      handleOnSelectRecords={selectRecords}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </>
  );
}
