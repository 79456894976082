'use strict';

import { Component, createRef } from 'react';

import moment from 'moment';

import axios from '../axios';

import { capitalize } from '../helpers/common';
import { sortStrings } from '../helpers/filter';

import messages from '../constants/messages';

import BreedingBulkModal from '../components/Modals/BreedingBulkModal';
import CalvRegistrationModal from '../components/Modals/CalvRegistrationModal';

import { errorToastHandler } from './action_notifier';
import SigTable from './sig-table-component/sig-table';

export default class Breeding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animals: [],
      breedingForIds: [],
      breeds: [],
      bulk_modal: false,
      calfAry: [],
      calv_registration_animal: null,
      calv_registration_modal: false,
      common_data: {
        event_type_id: [],
        event_start_date: '',
        event_end_date: '',
        expected_calving_date: '',
        event_description: '',
        is_pregnant: 0,
        sire_id: [],
        calf_id: [],
        mating_type_id: [],
        straws: '',
        straw_ref: '',
        cost: '0',
        breeding_status_id: [],
        calving_performance: '',
        maternity_performance: '',
        comments: '',
        breeding_plan: ''
      },
      data: [],
      eventTypes: [],
      filters: {
        farms: [],
        geofences: [],
        labels: []
      },
      matingTypes: [],
      measureTypes: [],
      newRows: [],
      props: this.props,
      query: '',
      selectedIds: [],
      sireAry: [],
      statusTypes: [],
      tableData: {},
      treatmentTypes: [],
      treatmentUOM: []
    };

    this.addAction = this.addAction.bind(this);
    this.clearCache = this.clearCache.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.createBulkRows = this.createBulkRows.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.editAction = this.editAction.bind(this);
    this.getData = this.getData.bind(this);
    this.getEventStatus = this.getEventStatus.bind(this);
    this.getEventTypes = this.getEventTypes.bind(this);
    this.getLivestocks = this.getLivestocks.bind(this);
    this.getMatingTypes = this.getMatingTypes.bind(this);
    this.getMeasureTypes = this.getMeasureTypes.bind(this);
    this.getTreatmentTypes = this.getTreatmentTypes.bind(this);
    this.getTreatmentUOM = this.getTreatmentUOM.bind(this);
    this.onDeleteHandler = this.onDeleteHandler.bind(this);
    this.onEditChange = this.onEditChange.bind(this);
    this.onSaveHandler = this.onSaveHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.prepareTableData = this.prepareTableData.bind(this);
    this.resetCommonData = this.resetCommonData.bind(this);
    this.validateCommonData = this.validateCommonData.bind(this);

    this.sigTblRef = createRef();
  }

  componentDidMount() {
    this.getEventTypes();
    this.getTreatmentTypes();
    this.getTreatmentUOM();
    this.getMeasureTypes();
    this.getBreeds();
    // ITS VALUE WILL COME FROM LIVESTOCK LISTING PAGE //
    let breedingForIds = localStorage.getItem('breedingForIds') || '[]';
    breedingForIds = JSON.parse(breedingForIds);

    this.setState({ breedingForIds: breedingForIds });

    if (breedingForIds.length) this.toggleModal('bulk_modal');

    localStorage.removeItem('breedingForIds');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedIds !== this.state.selectedIds) {
      this.props.initActions();
    }
  }

  async getBreeds() {
    const response = await axios.get('breeds');

    if (response.status === 200) {
      this.setState({ breeds: response.data.map(x => ({ value: x.code, label: x.display_name })) });
    }
  }

  async getMeasureTypes() {
    const response = await axios.get('measuretype');

    this.setState({ measureTypes: response.data });
  }

  async getEventTypes() {
    const response = await axios.get('breeding_event_types');

    if (response.status === 200) {
      this.setState({ eventTypes: response.data.map(x => ({ label: x.breeding_event_name, value: x.id })) }, () =>
        this.getEventStatus()
      );
    }
  }

  async getTreatmentTypes() {
    const response = await axios.get('treatment_types');

    this.setState({ treatmentTypes: response.data.map(x => ({ label: x.treatment_type_name, value: x.id })) });
  }

  async getTreatmentUOM() {
    const response = await axios.get('treatment_uom');

    this.setState({ treatmentUOM: response.data.map(x => ({ label: x.uom_description, value: x.uom_code })) });
  }

  async getEventStatus() {
    const response = await axios.get('breeding_event_status');

    if (response.status === 200) {
      this.setState({ statusTypes: response.data.map(x => ({ label: x.breeding_status_name, value: x.id })) }, () =>
        this.getMatingTypes()
      );
    }
  }

  async getMatingTypes() {
    const response = await axios.get('mating_event_types');

    if (response.status === 200) {
      this.setState({ matingTypes: response.data.map(x => ({ label: x.mating_type_name, value: x.id })) }, () =>
        this.getLivestocks()
      );
    }
  }
  async getLivestocks() {
    const response = await axios.get('animals');

    if (response.status === 200) {
      this.setState({ animals: response.data });

      const sireAry = response.data
        .filter(s => s.sex === 'male')
        .map(x => ({
          value: x.id,
          label: x.identifier + ' (' + x.eartag_management_id + ')'
        }));
      const calfAry = response.data.map(x => ({
        value: x.id,
        label: x.identifier + ' (' + x.eartag_management_id + ')'
      }));

      this.setState({ sireAry, calfAry }, () => this.getData());
    }
  }

  async getData() {
    const params = this.props.animal_id
      ? {
          livestock_ids: this.props.animal_id,
          breeding_ids: this.props.animal_id
        }
      : {
          query: this.state.query,
          farm_ids: this.state.filters?.farms?.map(x => x.value),
          label_ids: this.state.filters?.labels?.map(x => x.value),
          geofence_ids: this.state.filters?.geofences?.map(x => x.value)
        };
    const response = await axios.get('breeding/index/', { params });

    if (response.status === 200) {
      this.setState(
        {
          data: response.data.map(x => {
            x['key'] = x.id;
            x['livestock_sex'] = capitalize(x.sex);
            return x;
          })
        },
        () => this.prepareTableData()
      );
    }
  }

  prepareTableData() {
    const columns = [
      {
        Header: 'Event ID',
        accessor: 'breeding_identifier',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'number',
          isRequired: false
        },
        sortMethod: (a, b) => {
          return a?.localeCompare(b);
        },
        minWidth: 120
      },
      {
        Header: 'Event Type *',
        accessor: 'event_type_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options',
          isRequired: true
        },
        sortMethod: (a, b) => {
          let aText = a.filter(x => x.isSelected === true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b.filter(x => x.isSelected === true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 150,
        filterMethod: (filter, row) => (filter.value ? row._original.breeding_event_name === filter.value : true),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => {
              onChange(event.target.value);
            }}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}>
            <option value={null}></option>
            {this.state?.common_data?.event_type_id.map((event, index) => {
              return (
                <option key={`${event.label}-${index}`} value={event.label}>
                  {event.label}
                </option>
              );
            })}
          </select>
        )
      },
      {
        Header: 'Start Date *',
        accessor: 'event_start_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: true,
          onChange: data => {
            data['event_end_date'] = data['event_start_date'];
          }
        },
        sortMethod: (a, b) => {
          return a?.localeCompare(b);
        },
        minWidth: 140
      },
      {
        Header: 'End Date',
        accessor: 'event_end_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: false,
          onChange: data => {
            // ASSIGN END-DATE TO START-DATE IF ITS EMPTY OR MORE THEN END-DATE //
            if (
              (data['event_start_date'] === '' && data['event_end_date'] !== '') ||
              moment(data['event_start_date']).valueOf() > moment(data['event_end_date']).valueOf()
            ) {
              data['event_start_date'] = data['event_end_date'];
            }
          }
        },
        sortMethod: sortStrings,
        minWidth: 140
      },
      {
        Header: 'Expected Calving Date',
        accessor: 'expected_calving_date',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'date',
          dateFormat: 'DD/MM/YYYY',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 140
      },
      {
        Header: 'Event Description',
        accessor: 'event_description',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 200
      },
      {
        Header: 'Is Pregnant',
        accessor: 'is_pregnant',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'bool',
          isRequired: false,
          Cell: data => {
            return data['is_pregnant'] == 1 ? 'Yes' : null;
          }
        },
        minWidth: 120,
        filterMethod: (filter, row) => {
          if (filter.value === null) {
            return true;
          } else {
            return row._original?.is_pregnant_formatted?.includes(filter.value?.toLowerCase());
          }
        }
      },
      {
        Header: 'Sire ID',
        accessor: 'sire_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options',
          isRequired: false
        },
        sortMethod: (a, b) => {
          let aText = a?.filter(x => x.isSelected === true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b?.filter(x => x.isSelected === true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 150
      },
      {
        Header: 'Calf ID',
        accessor: 'calf_id',
        headerClassName: 'wordwrap',
        additional: {
          // isAddable: true,
          // isEditable: true,
          dataType: 'options',
          isRequired: false
        },
        sortMethod: (a, b) => {
          let aText = a.filter(x => x.isSelected === true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b.filter(x => x.isSelected === true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 150
      },
      {
        Header: 'Mating Type',
        accessor: 'mating_type_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options',
          isRequired: false
        },
        sortMethod: (a, b) => {
          let aText = a.filter(x => x.isSelected === true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b.filter(x => x.isSelected === true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 150
      },
      {
        Header: 'Straws',
        accessor: 'straws',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 140
      },
      {
        Header: 'Straw Ref',
        accessor: 'straw_ref',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 120
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'number',
          isRequired: false
        },
        sortMethod: (a, b) => a - b,
        minWidth: 140
      },
      {
        Header: 'Breeding Status',
        accessor: 'breeding_status_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'options'
        },
        sortMethod: (a, b) => {
          let aText = a.filter(x => x.isSelected === true);
          aText = aText.length ? aText[0]['label'] : '';

          let bText = b.filter(x => x.isSelected === true);
          bText = bText.length ? bText[0]['label'] : '';

          return aText?.localeCompare(bText);
        },
        minWidth: 160
      },
      {
        Header: 'Calving Performance',
        accessor: 'calving_performance',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 140
      },
      {
        Header: 'Maternity Performance',
        accessor: 'maternity_performance',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 140
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 180
      },
      {
        Header: 'Breeding Plan',
        accessor: 'breeding_plan',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: true,
          isEditable: true,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 140
      }
    ];

    // LIVESTOCK COLUMNS //
    const livestockColumns = [
      {
        id: 'identifier',
        Header: 'Livestock ID',
        accessor: 'identifier',
        headerClassName: 'wordwrap',

        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false,
          Cell: data => {
            return <a href={'/animal/' + data?.['id']}>{data?.['identifier']}</a>;
          }
        },
        Cell: data => {
          return <a href={'/animal/' + data.original['id']}>{data.original['identifier']}</a>;
        },
        sortMethod: sortStrings,
        minWidth: 120
      },
      {
        Header: 'Mgmt Tag ID',
        accessor: 'eartag_management_id',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 130
      },
      {
        Header: 'Sex',
        accessor: 'sex',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings
      },
      {
        Header: 'Colour',
        accessor: 'colour',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 110
      },
      {
        Header: 'Breed',
        accessor: 'breed',
        headerClassName: 'wordwrap',
        additional: {
          isAddable: false,
          isEditable: false,
          dataType: 'text_field',
          isRequired: false
        },
        sortMethod: sortStrings,
        minWidth: 140
      }
    ];
    // ADD INTO LIST VIEW //
    if (typeof this.props.animal_id === 'undefined' || !this.props.animal_id) {
      livestockColumns.reverse();
      livestockColumns.map(x => columns.unshift(x));
    }

    // PERPAIR ROW DATA //
    // CREATING ARRAY OF MAJOR & MINOR MEASUREMENTS, SET VALUE OF UOM //
    const formattedData = [];
    const rowData = this.state.data.map(row => {
      const eventTypes = JSON.parse(JSON.stringify(this.state.eventTypes));
      row.event_type_id = eventTypes.map(x => {
        x.isSelected = x.value === row.event_type_id;
        return x;
      });
      const statusTypes = JSON.parse(JSON.stringify(this.state.statusTypes));
      row.breeding_status_id = statusTypes.map(x => {
        x.isSelected = x.value === row.breeding_status_id;
        return x;
      });
      const matingTypes = JSON.parse(JSON.stringify(this.state.matingTypes));
      row.mating_type_id = matingTypes.map(x => {
        x.isSelected = x.value === row.mating_type_id;
        return x;
      });
      const sireAry = JSON.parse(JSON.stringify(this.state.sireAry));
      row.sire_id = sireAry
        .filter(x => x.value !== row.livestock_id)
        .map(x => {
          x.isSelected = x.value === row.sire_id;
          return x;
        });
      const calfAry = JSON.parse(JSON.stringify(this.state.calfAry));
      row.calf_id = calfAry
        .filter(x => x.value !== row.livestock_id)
        .map(x => {
          x.isSelected = x.value === row.calf_id;
          return x;
        });

      if (row.breeding_identifier) formattedData.push(row);

      return row;
    });

    this.setState(
      state => ({
        ...state,
        tableData: {
          column: columns,
          data: rowData
        }
      }),
      () => this.resetCommonData()
    );
  }

  addAction() {
    this.addRows();
  }

  addRows() {
    // GET DYNAMIC COLOUMNS FROM TABLE VIEW //
    let newrowKeys = {};
    this.state.tableData.column.map(obj => {
      if (['options', 'multi_select']?.indexOf(obj?.additional?.dataType) > -1) {
        newrowKeys[obj?.accessor] = [];
      } else if (['bool']?.indexOf(obj?.additional?.dataType) > -1) {
        newrowKeys[obj?.accessor] = false;
      } else {
        newrowKeys[obj?.accessor] = '';
      }
    });

    // ADDING NEW ROW ON TOP //
    if (Object.keys(newrowKeys).length) {
      // ASSIGN DEFAULT VALUES //
      newrowKeys['isEditable'] = true;
      newrowKeys['event_type_id'] = JSON.parse(JSON.stringify(this.state.common_data['event_type_id']));
      newrowKeys['mating_type_id'] = JSON.parse(JSON.stringify(this.state.common_data['mating_type_id']));
      newrowKeys['breeding_status_id'] = JSON.parse(JSON.stringify(this.state.common_data['breeding_status_id']));
      newrowKeys['sire_id'] = JSON.parse(JSON.stringify(this.state.common_data['sire_id']));
      newrowKeys['calf_id'] = JSON.parse(JSON.stringify(this.state.common_data['calf_id']));
      newrowKeys['expected_calving_date'] = this.state.common_data['expected_calving_date'];
      newrowKeys['event_description'] = this.state.common_data['event_description'];
      newrowKeys['is_pregnant'] = this.state.common_data['is_pregnant'];
      newrowKeys['straws'] = this.state.common_data['straws'];
      newrowKeys['straw_ref'] = this.state.common_data['straw_ref'];
      newrowKeys['cost'] = this.state.common_data['cost'];
      newrowKeys['calving_performance'] = this.state.common_data['calving_performance'];
      newrowKeys['maternity_performance'] = this.state.common_data['maternity_performance'];
      newrowKeys['comments'] = this.state.common_data['comments'];
      newrowKeys['breeding_plan'] = this.state.common_data['breeding_plan'];
      newrowKeys['event_start_date'] = this.state.common_data['event_start_date']
        ? moment(this.state.common_data['event_start_date']).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      newrowKeys['event_end_date'] = this.state.common_data['event_end_date']
        ? moment(this.state.common_data['event_end_date']).format('YYYY-MM-DD')
        : newrowKeys['event_start_date'];

      // CREATE n NUMBER OF ROWS, BASED ON SELECTION //
      let newRows = [];
      let counter = 1;

      if (Object.keys(this.sigTblRef.current.state.editApply).length) {
        counter = Object.keys(this.sigTblRef.current.state.editApply).length;
      }

      // CHECK IF CREATE NEW ROWS REQUEST COME FROM LIVESTOCK LISTING PAGE //
      if (this.state.breedingForIds.length) {
        counter = this.state.breedingForIds.length;
      }

      while (counter--) {
        let newrow = JSON.parse(JSON.stringify(newrowKeys));
        newrow['id'] = Math.random().toString(36).substr(2, 9);
        newrow['key'] = newrow['id'];
        // CHECK IF CREATE NEW ROWS REQUEST COME FROM LIVESTOCK LISTING PAGE //
        if (this.state.breedingForIds.length) {
          let row = this.state.breedingForIds[counter];
          newrow['livestock_id'] = row['livestock_id'];
          newrow['breeding_id'] = row['breeding_id'];
          newrow['livestock_breed'] = row['breed'];
          newrow['livestock_colour'] = row['colour'];
          newrow['livestock_identifier'] = row['identifier'];
          newrow['livestock_management_id'] = row['management_id'];
          newrow['livestock_sex'] = row['sex'];
          newrow['identifier'] = row['livestock_identifier'];
          newrow['breed'] = row['breed'];
          newrow['colour'] = row['colour'];
          newrow['eartag_management_id'] = row['management_id'];
          newrow['sex'] = capitalize(row['sex']);
        }
        // DEFAULT BEHAVIOUR //
        else {
          newrow['livestock_id'] = this.props.animal_id ? parseInt(this.props.animal_id) : 0;
        }

        // REMOVE MY-SELF FROM SELECTION
        newrowKeys['sire_id'] = newrowKeys['sire_id'].filter(x => x.value !== newrow['livestock_id']);
        newrowKeys['calf_id'] = newrowKeys['calf_id'].filter(x => x.value !== newrow['livestock_id']);

        newRows.push(newrow);
      }

      // GET & ASSIGN LIVESTOCK ID FOR NEW ROWS //
      const tmpEditApply = JSON.parse(JSON.stringify(this.sigTblRef.current.state.editApply));
      if (Object.keys(tmpEditApply).length) {
        Object.keys(tmpEditApply).map((id, indx) => {
          let row = this.sigTblRef.current.state.field.filter(x => x.id === parseInt(id));
          if (row.length) {
            newRows[indx]['livestock_id'] = row[0]['livestock_id'];
            newRows[indx]['breeding_id'] = row[0]['breeding_id'];
            newRows[indx]['livestock_breed'] = row[0]['breed'];
            newRows[indx]['livestock_colour'] = row[0]['colour'];
            newRows[indx]['livestock_identifier'] = row[0]['identifier'];
            newRows[indx]['livestock_management_id'] = row[0]['eartag_management_id'];
            newRows[indx]['livestock_sex'] = row[0]['sex'];
            newRows[indx]['breed'] = row[0]['breed'];
            newRows[indx]['colour'] = row[0]['colour'];
            newRows[indx]['eartag_management_id'] = row[0]['eartag_management_id'];
            newRows[indx]['identifier'] = row[0]['identifier'];
            newRows[indx]['sex'] = capitalize(row[0]['sex']);
          }
        });
      }
      this.setState(
        state => ({ ...state, newRows: newRows }),
        () => this.sigTblRef.current.setStatePostAdd()
      );

      // SHOW FIRST PAGE AFTER ADDING NEW ROWS //
      setTimeout(() => {
        while (!document.getElementsByClassName('-previous')[0].children[0].disabled) {
          document.getElementsByClassName('-previous')[0].children[0].click();
        }
      }, 200);
    }
  }

  editAction() {
    if (Object.keys(this.sigTblRef.current.state.editApply).length) {
      this.sigTblRef.current.editHandler();
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }

  deleteAction() {
    if (Object.keys(this.sigTblRef.current.state.editApply).length) {
      this.sigTblRef.current.deleteHandler();
    } else {
      errorToastHandler(messages.SELECTED_ITEM_REQUIRED);
    }
  }

  saveAction() {
    this.resetCommonData();
    this.sigTblRef.current.saveHandler();
  }

  cancelAction() {
    this.clearCache();
    this.resetCommonData();
    this.sigTblRef.current.globalCancelHandler();
  }

  clearCache() {
    this.setState(state => ({ ...state, breedingForIds: [] }));
  }

  resetCommonData() {
    this.setState(state => ({
      ...state,
      common_data: {
        event_type_id: JSON.parse(JSON.stringify(this.state.eventTypes)),
        event_start_date: '',
        event_end_date: '',
        expected_calving_date: '',
        event_description: '',
        is_pregnant: 0,
        sire_id: JSON.parse(JSON.stringify(this.state.sireAry)),
        calf_id: JSON.parse(JSON.stringify(this.state.calfAry)),
        breeding_status_id: JSON.parse(JSON.stringify(this.state.statusTypes)),
        mating_type_id: JSON.parse(JSON.stringify(this.state.matingTypes)),
        straws: '',
        straw_ref: '',
        cost: '0',
        calving_performance: '',
        maternity_performance: '',
        comments: '',
        breeding_plan: ''
      }
    }));
  }

  // NOT IN USE; BUT REQUIRED FOR CODE FLOW //
  onSelectHandler = rows => {
    this.setState(state => ({ ...state, selectedIds: [...Object.keys(rows)] }));
  };

  onSaveHandler = rows => {
    // return row which is saved
    const postData = rows.map(row => {
      let event_id = row.event_type_id.filter(x => x.isSelected === true);
      event_id = event_id.length ? event_id[0]['value'] : null;
      let status_id = row.breeding_status_id.filter(x => x.isSelected === true);
      status_id = status_id.length ? status_id[0]['value'] : null;
      let mating_id = row.mating_type_id.filter(x => x.isSelected === true);
      mating_id = mating_id.length ? mating_id[0]['value'] : null;
      let sire_id = row.sire_id.filter(x => x.isSelected === true);
      sire_id = sire_id.length ? sire_id[0]['value'] : null;
      let calf_id = row.calf_id.filter(x => x.isSelected === true);
      calf_id = calf_id.length ? calf_id[0]['value'] : null;

      return {
        id: isNaN(row.breeding_id) ? null : row.breeding_id,
        livestock_id: parseInt(row.livestock_id),
        event_type_id: parseInt(event_id),
        event_start_date: row.event_start_date ? moment(row.event_start_date).format('YYYY-MM-DD') : '',
        event_end_date: row.event_end_date ? moment(row.event_end_date).format('YYYY-MM-DD') : '',
        expected_calving_date: row.expected_calving_date ? moment(row.expected_calving_date).format('YYYY-MM-DD') : '',
        event_description: row.event_description,
        is_pregnant: row.is_pregnant ? 1 : 0,
        sire_id: parseInt(sire_id),
        calf_id: parseInt(calf_id),
        mating_type_id: parseInt(mating_id),
        straws: row.straws,
        straw_ref: row.straw_ref,
        cost: parseFloat(row.cost),
        breeding_status_id: parseInt(status_id),
        calving_performance: row.calving_performance,
        maternity_performance: row.maternity_performance,
        comments: row.comments,
        breeding_plan: row.breeding_plan
      };
    });

    if (postData.length) this.saveOperation(postData);
  };

  onDeleteHandler = rows => {
    // return keys of the row which is deleted
    const deletedIds = Object.keys(rows).map(x => parseInt(x));

    if (deletedIds.length) this.deleteOperation(deletedIds);
  };

  async saveOperation(postData) {
    try {
      if (this.props.tab_action === 'edit') {
        const response = await axios.put('breeding/bulk', {
          breeding_events: JSON.stringify(postData)
        });

        if (response.status === 200) {
          this.props.clearTabOperation();
          this.sigTblRef.current.clearTable();
          this.getData();
          this.clearCache();

          if (typeof this.props.onSave !== 'undefined') this.props.onSave();
        }
      } else {
        const response = await axios.post('breeding/store/bulk', {
          breeding_events: JSON.stringify(postData)
        });
        const calvingEventType = this.state.eventTypes.find(({ label }) => {
          return label === 'Calving';
        });
        const isCreatingCalving = postData.find(({ event_type_id }) => {
          return event_type_id === calvingEventType?.value;
        });

        if (isCreatingCalving) {
          const [breeding] = response.data.breeding_events;
          const animal = this.state.animals.find(({ id }) => id === breeding.livestock_id);

          this.setState({ calv_registration_modal: true, calv_registration_animal: animal });
        }

        if (response.status === 200) {
          this.props.clearTabOperation();
          this.sigTblRef.current.clearTable();
          this.getData();
          this.clearCache();

          if (typeof this.props.onSave !== 'undefined') this.props.onSave();
        }
      }
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  async deleteOperation(deletedIds) {
    const response = await axios.delete('breeding/destroy_bulk', {
      data: {
        ids: deletedIds
      }
    });

    if (response.status === 200) {
      this.getData();
      this.clearCache();
    }
  }

  toggleModal(modal) {
    this.resetCommonData();
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  closePopup(modal) {
    this.toggleModal(modal);
    this.clearCache();
  }

  onEditChange = (value, field) => {
    if (field === 'is_pregnant') {
      value = value ? 1 : 0;
    }

    this.setState(state => ({ common_data: { ...state.common_data, [field]: value } }));
  };

  validateCommonData() {
    let isValid = true;

    if (
      this.state.common_data['event_start_date'] &&
      (this.state.common_data['event_end_date'] === '' ||
        moment(this.state.common_data['event_start_date']).valueOf() >
          moment(this.state.common_data['event_end_date']).valueOf())
    ) {
      this.state.common_data['event_end_date'] = this.state.common_data['event_start_date'];
    }

    if (
      this.state.common_data['event_end_date'] &&
      (this.state.common_data['event_start_date'] === '' ||
        moment(this.state.common_data['event_start_date']).valueOf() >
          moment(this.state.common_data['event_end_date']).valueOf())
    ) {
      this.state.common_data['event_start_date'] = this.state.common_data['event_end_date'];
    }

    return isValid;
  }

  createBulkRows() {
    if (this.validateCommonData()) {
      this.props.addNewTabRows();
      this.setState(state => ({ ...state, bulk_modal: false }));
      this.addAction();
    }
  }

  filterData(filters) {
    this.setState({ filters: { ...this.state.filters, ...filters } }, () => this.getData());
  }

  handleKeyPress = charCode => {
    if (charCode === 13) this.getData();
  };

  keywordSearch(value, field) {
    this.setState(state => ({ ...state, [field]: value }));
  }

  render() {
    return (
      <div className="allow-overflow">
        <SigTable
          data={this.state.tableData} // data for the table
          newRows={this.state.newRows} //add new rows here
          ondelete={this.onDeleteHandler} //callback
          onselect={this.onSelectHandler} // callback
          onsave={this.onSaveHandler} // callback
          ref={this.sigTblRef}
        />

        <BreedingBulkModal
          isOpen={this.state.bulk_modal}
          onCancel={() => this.closePopup('bulk_modal')}
          createBulkRows={() => this.createBulkRows()}
          commonData={this.state.common_data}
          onEditChange={(value, field) => this.onEditChange(value, field)}
        />

        <CalvRegistrationModal
          isOpen={this.state.calv_registration_modal}
          animal={this.state.calv_registration_animal}
          onCancel={() => this.closePopup('calv_registration_modal')}
          commonData={this.state.common_data}
          measureTypes={this.state.measureTypes}
          treatmentTypes={this.state.treatmentTypes}
          treatmentUOM={this.state.treatmentUOM}
          breeds={this.state.breeds}
        />
      </div>
    );
  }
}
