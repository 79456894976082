import React, { memo } from 'react';

import { Button } from 'antd';

import logo from '../../assets/images/kraal-logo.svg';
import './styles.scss';

const Error403 = () => {
  return (
    <div className="error-403">
      <img src={logo} alt="Kraal logo" height="185" width="260" />
      <h1 className="error-title">Sorry, access denied</h1>
      <p className="mb-2">You don&#39;t have permission to view this page.</p>
      <Button color="primary" variant="solid" size="large" href="/profile?tab=5">
        Update subscription plan
      </Button>
    </div>
  );
};

export default memo(Error403);
