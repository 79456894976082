import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from 'reactstrap';

import { EVENT_MODAL_MODE } from '../../../../constants/event';

import { incrementNextCountCalendar, incrementPrevCountCalendar } from '../../../../redux/actions/calendar';

import EventModal from '../../../../components/Modals/EventModal';

import AppDropdown from '../../../ui-components/dropdown';

const CalendarHeaderOptions = memo(() => {
  const dispatch = useDispatch();
  const [isOpenEventModal, setOpenEventModal] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    initActions();
  }, []);

  function initActions() {
    setActions([
      {
        label: 'Add event',
        isVisible: () => true,
        handler: () => onAddEventClick()
      }
    ]);
  }

  const onAddEventClick = () => {
    setOpenEventModal(true);
  };

  const onSubmit = () => {
    setOpenEventModal(false);
  };

  return (
    <div className="calendar__header-options">
      <ButtonGroup
        style={{
          marginLeft: '6px',
          float: 'right'
        }}>
        <Button
          color="primary"
          onClick={() => {
            dispatch(incrementPrevCountCalendar());
          }}>
          Prev
        </Button>
        <Button
          color="primary"
          onClick={() => {
            dispatch(incrementNextCountCalendar());
          }}>
          Next
        </Button>
      </ButtonGroup>

      <AppDropdown
        label="Actions"
        items={actions.filter(item => item.isVisible())}
        handleClick={action => action.handler()}
      />

      <EventModal
        onSubmit={onSubmit}
        isOpen={isOpenEventModal}
        onCancel={() => setOpenEventModal(false)}
        mode={EVENT_MODAL_MODE.CREATE}
      />
    </div>
  );
});

export default CalendarHeaderOptions;
