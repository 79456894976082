import { memo, useEffect, useState } from 'react';

import { TASK_MODAL_MODE } from '../../../../constants/task';

import TaskModal from '../../../../components/Modals/TaskModal';

import AppDropdown from '../../../ui-components/dropdown';

const TaskHeaderOptions = memo(() => {
  const [isOpenTaskModal, setOpenTaskModal] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    initActions();
  }, []);

  function initActions() {
    setActions([
      {
        label: 'Add task',
        isVisible: () => true,
        handler: () => onAddTaskClick()
      }
    ]);
  }

  const onAddTaskClick = () => {
    setOpenTaskModal(true);
  };

  const onSubmit = () => {
    setOpenTaskModal(false);
  };

  return (
    <div className="calendar__header-options">
      <AppDropdown
        label="Actions"
        items={actions.filter(item => item.isVisible())}
        handleClick={action => action.handler()}
      />

      <TaskModal
        onSubmit={onSubmit}
        isOpen={isOpenTaskModal}
        onCancel={() => setOpenTaskModal(false)}
        mode={TASK_MODAL_MODE.CREATE}
      />
    </div>
  );
});

export default TaskHeaderOptions;
