import React from 'react';
import ReactTable from 'react-table';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { capitalize } from '../helpers/common';
import { formatDateDifference } from '../helpers/date';

class AnimalPopup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const columns = [
      {
        accessor: 'id',
        headerClassName: 'wordwrap',
        sortable: false,
        Header: '',
        filterable: false,
        maxWidth: 110,
        Cell: props => {
          return (
            <button
              key={props.value}
              type="button"
              className="btn btn-light btn-sm"
              onClick={e => this.props.onSelect(this.props.fieldKey, props.value)}>
              Select
            </button>
          );
        }
      },
      {
        id: 'identifier',
        minWidth: 120,
        Header: 'Livestock ID',
        accessor: d => {
          return { id: d.id, identifier: d.identifier };
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.identifier.localeCompare(b.identifier);
        },
        Cell: props => <strong>{props.value.identifier}</strong>
      },
      {
        Header: 'Mgmt Tag ID',
        id: 'eartag_management_id',
        accessor: d => {
          return d.eartag_management_id.indexOf('mgmt~tmp~') > -1 ? '' : d.eartag_management_id;
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
        minWidth: 130
      },
      {
        Header: 'Sex',
        accessor: 'sex',
        headerClassName: 'wordwrap',
        Cell: props => {
          const value = props.value?.value ? props.value.value : props.value;
          return capitalize(value);
        }
      },
      {
        Header: 'Colour',
        accessor: 'colour',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Age',
        id: 'age',
        accessor: record => formatDateDifference(record.dob_at),
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => {
          return a.localeCompare(b);
        },
        minWidth: 150
        //filterable:false
      },
      {
        Header: 'Breeding Status',
        accessor: 'breeding_status',
        headerClassName: 'wordwrap',
        filterable: false,
        minWidth: 160
      }
    ];
    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (row[id]) {
        if (row[id] && typeof row[id] == 'object') {
          if (typeof row[id]['identifier'] == 'number') {
            return row[id]['identifier'] !== undefined ? String(row[id]['identifier']).includes(filter.value) : true;
          } else {
            return row[id] !== undefined
              ? String(row[id]['identifier'].toLowerCase()).includes(filter.value.toLowerCase())
              : true;
          }
        } else if (typeof row[id] == 'number') {
          return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
        } else {
          return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
        }
      }
    }
    return (
      <>
        <Modal isOpen={true} className="modal-dialog-lg">
          <ModalHeader toggle={e => this.props.onClose()}>Livestocks</ModalHeader>
          <ModalBody>
            <ReactTable
              columns={columns}
              data={this.props.animals}
              minRows={0}
              resizable={true}
              filterable={true}
              defaultPageSize={10}
              defaultFilterMethod={filterCaseInsensitive}
              showPagination={this.props.animals.length > 0}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default AnimalPopup;
