import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row
} from 'reactstrap';

import { useSetState } from '../../../hooks/useSetState';

import { createNewUser } from '../../../redux/actions/user';

import './styles.scss';

const NewGateway = ({ history }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [state, setState] = useSetState({});

  const getFarms = async () => {
    try {
      let response = await axios.get('farms?with_details=true', {
        query: null
      });
      let mapped_farms = [];
      for (let farm of response.data) {
        mapped_farms.push({
          value: farm.id,
          label: farm.name,
          meta: {
            features: farm.geofences
          }
        });
      }
      setState({
        farm_opts: mapped_farms
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getFarms();
  }, []);

  const onChangeField = (e, field) => {
    setGateway({
      [field]: e.target.value
    });
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onClickAdd = () => {
    const payload = {
      email: email
    };
    dispatch(createNewUser(payload))
      .then(res => {
        if (res.id) {
          history.push('/administration/gateways');
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  const onClickCancel = () => {};

  return (
    <div className="new-gateway-page">
      <Row className="">
        <Col xs="6" md="6" lg="6">
          <h4 className="">Add new gateway</h4>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/administration/gateways">List of Gateway</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>Gateway</BreadcrumbItem>
          </Breadcrumb>
        </Col>

        <Col lg="6" md="6" sm="6">
          <Button color="primary" className="float-right" onClick={e => onClickAdd()}>
            Save
          </Button>
          <Button className="outline float-right" onClick={e => onClickCancel}>
            Cancel
          </Button>
        </Col>
      </Row>

      <div className="card">
        <Form className="mt-3" id="newGatewayForm" action="/dashboard">
          <Label for="email" className="">
            Email
          </Label>
          <InputGroup className="mb-2" size="lg">
            <InputGroupText>
              <i className="fas fa-at"></i>
            </InputGroupText>
            <Input type="email" id="email" name="email" value={email} onChange={onChangeEmail} placeholder="Email" />
          </InputGroup>
          {/* {this.showErrors("email")} */}
          <Label for="farm" className="">
            Farm
          </Label>
          <Select id="farm" value={state.farm} onChange={e => onChangeField(e, 'farm')} options={state.farm_opts} />
        </Form>
      </div>
      <Row>
        <Col lg="12" md="12" sm="12">
          <Button color="primary" className="float-right" onClick={e => onClickAdd()}>
            Save
          </Button>
          <Button className="outline float-right" onClick={e => onClickCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default memo(NewGateway);
