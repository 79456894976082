import { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';

import axios from '../../axios';

import { getTableColumns } from '../../helpers/subscription';

import { FEATURE_LIST } from '../../constants/subscription';

import SelectionPopover from '../../components/Popovers/SelectionPopover';
import Search from '../../components/Search/Search';
import Filters from '../../components/filters/index.jsx';

import AppDropdown from '../ui-components/dropdown';

function SelectedFencesNames(props) {
  const geofences = props.geofences;
  const selected_fences = props.selected_fences;
  const a = geofences.filter(gf => !!selected_fences.includes(gf.id));
  const LIS = [];

  for (let fn of a) {
    LIS.push(
      <li>
        <b>{fn.name}</b>
      </li>
    );
  }

  return <ul>{LIS}</ul>;
}

class Geofences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      delete_fences: [],
      delete_modal: false,
      filters_open: false,
      filters: {
        farms: [],
        geofences: [],
        labels: []
      },
      geofences: [],
      geofences_mapped: [],
      isOpenSelectionPopover: false,
      query: '',
      selectAll: false,
      selected_fences: [],
      tableData: []
    };

    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.getFences = this.getFences.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.onFenceSelect = this.onFenceSelect.bind(this);
    this.setDeleteFence = this.setDeleteFence.bind(this);
    this.setDeleteFences = this.setDeleteFences.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.getFences();
    this.initActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_fences !== this.state.selected_fences) {
      this.initActions();
    }
  }

  initActions() {
    const { selected_fences } = this.state;

    this.setState({
      actions: [
        {
          label: 'Add geofence',
          isVisible: () => true,
          handler: () => this.props.history.push('/geofence_new')
        },
        {
          label: 'Archive geofence',
          isVisible: () => selected_fences.length,
          handler: () => this.setDeleteFences()
        }
      ]
    });
  }

  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) {
      this.state.tableData.map(val => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }

    this.setState(state => ({ ...state, selected_fences: checkedCopy }));
    this.setState({ selectAll: newCheckboxValue });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) this.state.geofences.map(val => checkedCopy.push(val.id));

    this.setState(state => ({ ...state, selected_fences: checkedCopy }));
    this.setState({ selectAll: newCheckboxValue });
    this.onToggleSelectionPopover();
  }

  onToggleSelectionPopover(isOpen) {
    const open = isOpen !== undefined ? isOpen : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({ isOpenSelectionPopover: open });
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: value }));
  };

  handleKeyPress = charCode => {
    if (charCode === 13) this.getFences();
  };

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  onCheckboxChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: !state[field] }));
  };

  setDeleteFence(val) {
    this.setState({ ...this.state, delete_fences: [val] });
    this.toggleModal('delete_modal');
  }

  setDeleteFences() {
    this.setState({ ...this.state, delete_fences: this.state.selected_fences });
    this.toggleModal('delete_modal');
  }

  async deleteFences() {
    const response = await axios.delete('/geofences/bulk', {
      data: { ids: this.state.delete_fences }
    });

    if (response.status === 200) {
      this.getFences();
      this.toggleModal('delete_modal');
    }
  }

  onTagChange(value, field) {
    let nt = this.state.edit_tag;
    nt[field] = value;
    this.setState(state => ({ ...state, edit_tag: nt }));
  }

  onFenceSelect = fence => {
    const selected_fences = this.state.selected_fences;

    if (selected_fences.includes(fence)) {
      const index = selected_fences.indexOf(fence);

      if (index > -1) selected_fences.splice(index, 1);
    } else {
      selected_fences.push(fence);
    }

    this.setState({ ...this.state, selected_fences: selected_fences });
  };

  async getFences() {
    const response = await axios.get('geofences', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map(x => x.value)
      }
    });

    if (response.status === 200) {
      let geofences_mapped = [];
      let master_fences = {};

      for (let fence of response.data) {
        if (fence.is_master) {
          master_fences[fence.id] = [];
          master_fences[fence.id].push(fence);
        } else {
          if (!master_fences[fence.geofence_id]) {
            master_fences[fence.geofence_id] = [];
          }
          master_fences[fence.geofence_id].push(fence);
        }
      }

      for (let arr of Object.keys(master_fences)) {
        geofences_mapped.push(master_fences[arr]);
      }

      this.setState(state => ({
        ...state,
        geofences: response.data,
        geofences_mapped: geofences_mapped,
        selectAll: false,
        tableData: response.data.slice(0, 25),
        selected_fences: []
      }));
    }
  }

  filtersUpdated(filters) {
    this.setState({ filters: filters }, () => this.getFences());
  }

  filtersClose() {
    this.setState({ filters_open: false });
  }

  render() {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const columns = [
      {
        Header: () => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={isOpen =>
                this.setState({
                  isOpenSelectionPopover: isOpen !== undefined ? isOpen : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();

                    if (this.state.selectAll) {
                      this.setState({ selectAll: !this.state.selectAll });
                      this.setState(state => ({ ...state, selected_fences: [] }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        filterable: false,
        accessor: d => ({ id: d.id }),
        Cell: props => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_fences.includes(props.value.id)}
              value={props.value.id}
              onChange={e => this.onFenceSelect(parseInt(e.target.value))}
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        maxWidth: 85
      },
      {
        Header: '#',
        id: 'index',
        accessor: d => ({
          index_main: d.index_main,
          index_sub: d.index_sub,
          identifier: parseInt(d.index_main) + 1 + (d.index_sub == 0 ? '' : '.' + d.index_sub)
        }), // String-based value accessors!,
        headerClassName: 'wordwrap',
        Cell: props => (
          <b>
            {parseInt(props.value.index_main) + 1}
            {props.value.index_sub == 0 ? '' : '.' + props.value.index_sub}
          </b>
        ),
        sortable: true,
        sortMethod: (a, b) => {
          let mainIdA = a.index_main + 1;
          let subIdA = a.index_sub;
          let mainIdB = b.index_main + 1;
          return mainIdA - mainIdB || subIdA - mainIdB;
        },
        maxWidth: 85
      },
      {
        Header: 'Geofence ID',
        id: 'id',
        accessor: d => ({ is_master: d.is_master, identifier: d.identifier, id: d.id }),
        headerClassName: 'wordwrap',
        Cell: props => {
          let parse = [];
          // if(props.value.is_master) {
          // 	parse.push(<i className="mdi mdi-star-circle"></i>)
          // }
          parse.push(props.value.identifier);
          return (
            <a
              href="#"
              onClick={() => {
                this.props.history.push('geofence/' + props.value.id);
              }}>
              {parse}
            </a>
          );
        },
        sortMethod: (a, b) => {
          let idA = a.identifier;
          let idB = b.identifier;

          idA.replace(/MG-/g, '');
          idA.replace(/G-/g, '');
          idB.replace(/MG-/g, '');
          idB.replace(/G-/g, '');

          return idA.localeCompare(idB);
        }
      },
      {
        Header: 'Geofence Name',
        accessor: 'name',
        id: 'geofenceName',
        headerClassName: 'wordwrap',
        minWidth: 150
      },
      {
        Header: 'Geofence Size (ha)',
        accessor: 'size',
        id: 'geofenceSize',
        headerClassName: 'wordwrap',
        Cell: props => numberWithCommas(Number(props.value).toFixed(2))
      },
      {
        Header: 'Geofence Perimeter (km)',
        accessor: 'circumference',
        id: 'geofencePerimeter',
        headerClassName: 'wordwrap',
        Cell: props => numberWithCommas(Number(props.value).toFixed(2))
      },
      {
        Header: 'Assigned Livestock',
        accessor: 'active_livestock_count',
        id: 'assignedLivestock',
        Cell: props => {
          return numberWithCommas(Number(props.original.active_livestock_count).toFixed(2));
        },
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Expected Tracked Livestock',
        accessor: 'livestock_with_tag',
        id: 'expectedTaggedLivestock',
        Cell: props => numberWithCommas(Number(props.value).toFixed(2)),
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Actual Tracked Livestock',
        accessor: 'actual_livestock',
        id: 'actualTaggedLivestock',
        Cell: props => numberWithCommas(Number(props.value).toFixed(2)),
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Assigned Groups',
        id: 'assignedGroups',
        accessor: 'labels',
        Cell: props => {
          return props.original?.labels?.map((l, i) => {
            return (
              <span key={l.id}>
                {i !== 0 ? <span>{', '}</span> : null}
                <a href={`/group/${l.id}`}>{l.name}</a>
              </span>
            );
          });
        },
        headerClassName: 'wordwrap',
        filterMethod: (filter, row) => {
          let groupsStr = '';
          row?.assignedGroups?.forEach(g => (groupsStr += g.name));
          const payload = { assignedGroups: groupsStr };

          return filterCaseInsensitive(filter, payload);
        }
      }
    ];

    const data = [];

    if (this.state.geofences.length > 0) {
      for (let geofence_main_index in this.state.geofences_mapped) {
        for (let geofence_sub_index in this.state.geofences_mapped[geofence_main_index]) {
          data.push({
            ...this.state.geofences_mapped[geofence_main_index][geofence_sub_index],
            index_main: geofence_main_index,
            index_sub: geofence_sub_index
          });
        }
      }
    }

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;

      if (row[id]) {
        if (row[id] && typeof row[id] == 'object') {
          if (typeof row[id]['identifier'] == 'number') {
            return row[id]['identifier'] !== undefined ? String(row[id]['identifier']).includes(filter.value) : true;
          } else {
            return row[id] !== undefined
              ? String(row[id]['identifier'].toLowerCase()).includes(filter.value.toLowerCase())
              : true;
          }
        } else if (typeof row[id] == 'number') {
          return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
        } else {
          return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
        }
      }
    }

    return (
      <>
        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm']}
        />

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Geofences</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Geofences</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
                  <Search
                    className="mr-2"
                    id="search-geofences"
                    placeholder="Search geofences"
                    defaultValue={this.state.query}
                    handleChange={e => this.onChange(e.target.value, e.target.name)}
                    handleKeyPress={() => this.handleKeyPress(13)}
                  />

                  <AppDropdown
                    label="Actions"
                    items={this.state.actions.filter(item => item.isVisible())}
                    handleClick={action => action.handler()}
                  />

                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() => this.setState({ ...this.state, filters_open: !this.state.filters_open })}>
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={data.length > 0}
                    minRows={0}
                    data={data}
                    columns={getTableColumns(
                      columns,
                      this.props.subscription.myPlan?.type,
                      FEATURE_LIST.GEOFENCES_LIST
                    )}
                    resizable={false}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    getTrProps={(state, rowInfo) => {
                      return {
                        style: {
                          background: rowInfo.original.is_master ? '#ececec' : ''
                        }
                      };
                    }}
                    onFetchData={props => {
                      const data = props.data.length ? props.sortedData.slice(0, props.pageSize) : this.state.geofences;
                      this.setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive geofences</b>
            </h5>
            <br />
            Are you sure you want to archive these geofences? This action cannot be undone.
            <br />
            <br />
            <SelectedFencesNames
              geofences={this.state.geofences}
              selected_fences={this.state.delete_fences}></SelectedFencesNames>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteFences()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(state => state)(Geofences);
