import { REDUCERS } from '../../constants/reducers';

const initialState = {
  gatewaysAll: [],
  gateways: [],
  gateway: {},
  gatewaysError: null,
  gatewaysLoading: false
};

const gateway = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.GATEWAY.SET_ALL_GATEWAYS: {
      return Object.assign({}, state, {
        gatewaysAll: [...action.value]
      });
    }
    case REDUCERS.GATEWAY.SET_GATEWAY: {
      return Object.assign({}, state, {
        gateway: { ...action.value }
      });
    }
    case REDUCERS.GATEWAY.FETCH_GATEWAYS_REQUEST:
      return { ...state, gatewaysLoading: true, gatewaysError: null };
    case REDUCERS.GATEWAY.FETCH_GATEWAYS_SUCCESS:
      return { ...state, gatewaysLoading: false, gateways: action.payload };
    case REDUCERS.GATEWAY.FETCH_GATEWAYS_FAILURE:
      return { ...state, gatewaysLoading: false, gatewaysError: action.payload };
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { gateway };
