import axios from '../../axios';

const service = {
  fetchBreeds() {
    return axios({ method: 'get', url: '/breeds' });
  },
  fetchBreedingStatuses() {
    return axios({ method: 'get', url: '/breeding_event_status' });
  }
};

export default service;
