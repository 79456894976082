import React, { memo } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

import './styles.scss';

const UploadModal = ({ isOpen, handleCSVChange, onCancel, onUpload, description }) => {
  return (
    <Modal size="lg" isOpen={isOpen} className="upload-modal">
      <ModalBody>
        <br />
        <h5 className="text-center">
          <b>XLSX Upload</b>
        </h5>
        <br />
        <br />
        <br />
        <br />
        <div className="download-block">{description}</div>
        <br />
        <br />
        <FormGroup>
          <Label>XLSX file</Label>
          <Input type={'file'} onChange={handleCSVChange}></Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onUpload}>
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(UploadModal);
