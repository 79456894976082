import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';

import { Select } from 'antd';
import debounce from 'lodash.debounce';
import moment from 'moment/moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { sortDates, sortNumbers, sortStrings } from '../../helpers/filter';

import { BATTERY_STATUSES, CONNECTION_STATUSES, DATE_FORMAT, EDIT_COMPONENT_TYPE } from '../../constants/common';
import messages from '../../constants/messages';

import { fetchGateways } from '../../redux/actions/gateway';
import { fetchLocations } from '../../redux/actions/location';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import { errorToastHandler, successToastHandler } from '../../components/action_notifier';
import Livestock from '../../components/animals/Livestock';
import StatusIndicator from '../../components/statusIndicator';

import { SocketContext } from '../../context/socket';
import { TagService } from '../../services';

export default function Devices(props) {
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const { gateways, gatewaysLoading } = useSelector(state => state.gateway);
  const { filterOptions } = useSelector(state => state.location);
  const [state, setState] = useState({
    filters: { farms: [] },
    filters_open: false,
    gatewaysQuery: '',
    ws: null,

    // tags
    actions: [],
    add_modal: false,
    animals: [],
    csv_modal: false,
    csvfile: '',
    delete_modal: false,
    farm_opts: [],
    farms: [],
    // filters: {
    //   farms: [],
    //   geofences: [],
    //   labels: []
    // },
    // filters_open: false,
    isOpenSelectionPopover: false,
    link_modal: false,
    new_tag: {},
    tagsQuery: '',
    search_livestock: '',
    selectAll: false,
    selected_animals: [],
    selected_farm: {},
    selected_tags: [],
    selected_tags_unlinked: [],
    tableData: [],
    tags: [],
    uploading: false
    // ws: null
  });

  useEffect(() => {
    initActions();
    getFarms();
  }, []);

  useEffect(() => {
    initActions();
  }, [state.selected_tags]);

  useEffect(() => {
    dispatch(fetchGateways());
    dispatch(fetchLocations());
  }, [dispatch]);

  useEffect(() => {
    if (socket && !state.ws) {
      setState(prevState => ({ ...prevState, ws: socket }));

      socket.on('gatewayStatus', data => {
        setState(prevState => ({
          ...prevState,
          gateways: gateways.map(item => {
            let status = item.status;

            if (data.ids.includes(item.id)) {
              status = data.status;
            }

            return { ...item, status };
          })
        }));
      });
    }

    return () => socket.off('gatewayStatus');
  }, [socket]);

  const addTag = async () => {
    await axios.post('tags', { ...state.new_tag });

    toggleModal('add_modal');

    await getTags();
  };

  const handleCSVFarmChange = selectedOption => {
    setState(prevState => ({ ...prevState, csv_farm: selectedOption }));
  };

  const handleCSVChange = event => {
    setState(prevState => ({ ...prevState, uploading: true }));

    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);

    reader.onload = function () {
      setState(prevState => ({ ...prevState, csvfile: reader.result, uploading: false }));
    }.bind(this);

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  const uploadCSV = async () => {
    const csv = state.csvfile;
    const response = await axios.post('tags/csv', {
      csv: csv,
      farm_id: state.csv_farm.value
    });

    if (response.status === 200) {
      await getTags();
      toggleModal('csv_modal');
    }
  };

  const initActions = () => {
    const { selected_tags } = state;

    setState(prevState => ({
      ...prevState,
      actions: [
        {
          label: 'Add Kraal Tag',
          isVisible: true,
          handler: () => toggleModal('add_modal')
        },
        {
          label: 'Archive Kraal Tag',
          isVisible: selected_tags?.length,
          handler: () => setDeleteTags()
        },
        {
          label: 'Upload Kraal Tag list',
          isVisible: true,
          handler: () => toggleModal('csv_modal')
        }
      ]
    }));
  };

  const linkTag = async () => {
    const animalId = state.selected_animals[0];
    const animal = state.animals.filter(a => a.id === animalId)[0];
    let response;

    if (state.re_linking) {
      response = await axios.put('links', {
        tag_id: state.selected_tags_unlinked?.at(0),
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: state.selected_tags_unlinked?.at(0),
        animal_id: animal.id
      });
    }

    if (response.status === 200) {
      await getTags();
      toggleModal('link_modal');
      setState(prevState => ({ ...prevState, re_linking: false, linking: false }));

      window.location.reload();
    }
  };

  const getLabel = async id => {
    const tags = state.tags?.filter(t => t.id === id);

    return tags?.length > 0 ? tags?.at(0).diagri_id : '';
  };

  const selectRecords = selected => {
    setState(prevState => ({
      ...prevState,
      selected_tags: selected
    }));
  };

  const handleFarmChange = selectedOption => {
    setState(prevState => ({
      ...prevState,
      selected_farm: selectedOption,
      new_tag: { ...prevState.new_tag, farm_id: selectedOption.value }
    }));
  };

  const onNewTagChange = (value, field) => {
    const nt = state.new_tag;
    nt[field] = value;

    setState(prevState => ({ ...prevState, newTag: nt }));
  };

  const setDeleteTags = () => {
    setState(prevState => ({ ...prevState, delete_tags: state.selected_tags }));
    toggleModal('delete_modal');
  };

  const toggleModal = modal => {
    setState(state => ({ ...state, [modal]: !state[modal] }));
  };

  const deleteTag = async () => {
    const response = await axios.delete('tags/bulk', {
      data: {
        ids: state.delete_tags
      }
    });

    if (response.status === 200) {
      await getTags();
      toggleModal('delete_modal');
    }
  };

  const getFarms = async () => {
    const response = await axios.get('farms/', {
      query: state.query || null
    });

    if (response.status === 200) {
      const mapped_farms = [];

      for (let farm of response.data) {
        mapped_farms.push({ value: farm.id, label: farm.name });
      }

      setState(prevState => ({ ...prevState, farms: response.data, farm_opts: mapped_farms }));
    }
  };

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, 1000),
    []
  );

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleKeyPressOnGatewaysSearch = charCode => {
    if (charCode === 13) debounceSearch(getGateways);
  };

  const handleKeyPressOnTagsSearch = charCode => {
    if (charCode === 13) debounceSearch(getTags);
  };

  const getGateways = async () => {
    try {
      dispatch(fetchGateways({ query: state.gatewaysQuery }));
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  };

  const getTags = async () => {
    try {
      dispatch(fetchGateways({ tagsQuery: state.tagsQuery }));
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  };

  const onClickSaveEdit = async data => {
    const payload = { tags: [{ farm_id: data?.at(0)?.farm?.value, id: data?.at(0)?.id }] };

    try {
      await TagService.updateMany(payload);
      await getTags();

      successToastHandler('Updated successfully!');
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  };

  const columns = [
    {
      title: 'Gateway ID',
      dataIndex: 'identifier',
      fixed: 'left',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      render: (value, record) => <Link to={`gateway/${record.id}`}>{record.identifier}</Link>
    },
    {
      title: 'Gateway Name',
      dataIndex: 'name',
      ellipsis: true,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.name, b.name),
      render: (value, record) => <Link to={`gateway/${record.id}`}>{value}</Link>
    },
    {
      title: 'Connection Status',
      dataIndex: 'status',
      ellipsis: true,
      width: COLUMN_SIZE.XL,
      sortDirections: ['ascend', 'descend'],
      filters: CONNECTION_STATUSES,
      onFilter: (value, record) => record?.status.startsWith(value),
      sorter: (a, b) => sortStrings(a?.status, b?.status),
      render: (value, record) => {
        const status = record?.status;
        const id = record?.id;

        return (
          record && (
            <div className="d-flex align-items-center">
              <StatusIndicator key={id} status={status} />
              {capitalize(status)}
            </div>
          )
        );
      }
    },
    {
      title: 'Connected Kraal Tags',
      dataIndex: 'connected_tags_count',
      ellipsis: true,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortNumbers(a.connected_tags_count, b.connected_tags_count)
    }
  ];

  const expandColumns = [
    {
      title: 'Kraal Tag ID',
      dataIndex: 'diagri_id',
      ellipsis: true,
      fixed: 'left',
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => <Link to={`/tag/${record.id}`}>{record.diagri_id || '/'}</Link>,
      sorter: (a, b) => sortStrings(a.diagri_id, b.diagri_id)
    },
    {
      title: 'Livestock ID',
      dataIndex: 'livestockId',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => {
        const searchField = record?.animal?.identifier || 'Unlinked';

        return searchField.toString().toLowerCase().includes(value.toLowerCase());
      },
      render: (value, record) =>
        record?.animal ? <Link to={`/animal/${record?.animal?.id}`}>{record?.animal?.identifier}</Link> : 'Unlinked',
      sorter: (a, b) => sortStrings(a.animal?.identifier, b.animal?.identifier)
    },
    {
      title: 'Farm',
      dataIndex: 'farm',
      ellipsis: true,
      width: COLUMN_SIZE.XL,
      sortDirections: ['ascend', 'descend'],
      filters: state.farm_opts.map(option => ({ text: option.label, value: option.value })),
      onFilter: (value, record) => record?.farm_id === value,
      sorter: (a, b) => sortStrings(a.farm?.name, b.farm?.name),
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: state.farm_opts,
        valueKey: 'id'
      }
    },
    {
      title: 'Location',
      dataIndex: 'animal',
      ellipsis: true,
      width: COLUMN_SIZE.XL,
      filters: filterOptions,
      onFilter: (value, record) => record?.farm?.master_geofence?.at(0)?.id === value,
      render: (value, record) => {
        const location = record?.farm?.master_geofence?.at(0);

        return <Link to={`geofence/${record?.farm?.master_geofence?.at(0)?.id}`}>{location.name}</Link>;
      },
      sorter: (a, b) => sortStrings(a.farm?.master_geofence?.at(0)['name'], b.farm?.master_geofence?.at(0)['name'])
    },
    {
      title: 'Battery Status',
      dataIndex: 'battery_status',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      sortDirections: ['ascend', 'descend'],
      filters: BATTERY_STATUSES,
      onFilter: (value, record) => record?.battery_status_label.toLowerCase() === value,
      sorter: (a, b) => sortNumbers(a?.battery_percentage, b?.battery_percentage),
      render: (value, record) => (
        <div className="d-flex align-items-center">
          <StatusIndicator key={record?.id} status={record?.battery_status} />
          {capitalize(record.battery_status_label)}
        </div>
      )
    },
    {
      title: 'Connection Status',
      dataIndex: 'status',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      sortDirections: ['ascend', 'descend'],
      filters: CONNECTION_STATUSES,
      onFilter: (value, record) => record?.status === value,
      sorter: (a, b) => sortStrings(a?.status, b?.status),
      render: (value, record) => {
        const gateway = gateways.find(gateway => gateway.id === record.gateway_id);

        return (
          record && (
            <div className="d-flex align-items-center">
              <StatusIndicator key={gateway?.id} status={gateway?.status} />
              {capitalize(gateway?.status)}
            </div>
          )
        );
      }
    },
    {
      title: 'Last Reading',
      dataIndex: 'timestamp_at',
      ellipsis: true,
      width: COLUMN_SIZE.LG,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      onFilter: (value, record) => record?.timestamp_at?.includes(value.toLowerCase()),
      sorter: (a, b) => sortDates(a?.timestamp_at, b?.timestamp_at),
      render: (value, record) => {
        const lastReadingTime = record?.timestamp_at;

        return lastReadingTime ? moment(lastReadingTime).format(DATE_FORMAT.DATETIME) : null;
      }
    },
    {
      title: 'Linked Status',
      dataIndex: 'animal',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      sortDirections: ['ascend', 'descend'],
      filters: [
        {
          text: 'Linked',
          value: 'linked'
        },
        {
          text: 'Not Linked',
          value: 'not_linked'
        }
      ],
      onFilter: (value, record) => (value === 'linked' ? record?.animal : !record?.animal),
      sorter: (a, b) => {
        const strA = a.animal ? 'Linked' : 'Not Linked';
        const strB = b.animal ? 'Linked' : 'Not Linked';

        return sortStrings(strA, strB);
      },
      render: (value, record) => (record?.animal ? 'Linked' : 'Not Linked')
    }
  ];

  const expandedRowRender = record => (
    <AppTable
      actions={state.actions}
      baseColumns={expandColumns}
      dataSource={record.tags}
      filterable={false}
      searchable={false}
      settings={true}
      loading={gatewaysLoading}
      handleOnSelectRecords={selectRecords}
      updateTableData={onClickSaveEdit}
    />
  );

  return (
    <>
      <div className="card p-2">
        <AppTable
          title="Kraal Gateways"
          breadcrumb="List of Kraal Gateways"
          searchPlaceholder="Search Kraal Gateways"
          searchable={true}
          filterable={false}
          loading={gatewaysLoading}
          baseColumns={columns}
          dataSource={gateways}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ['0']
          }}
          searchDefaultValue={state.gatewaysQuery}
          handleSearchChange={e => onChange(e.target.value, 'gatewaysQuery')}
          handleSearchKeyPress={() => handleKeyPressOnGatewaysSearch(13)}
        />
      </div>

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonText="Archive"
        title="Archive tags"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={deleteTag}>
        <div className="py-4">Are you sure you want to archive selected tags? This action cannot be undone.</div>
      </AppModal>

      <AppModal
        isOpen={state.add_modal}
        confirmButtonText="Add tag"
        title="Add Kraal Tag"
        handleCancel={() => toggleModal('add_modal')}
        handleConfirm={addTag}>
        <Form className="py-4">
          <FormGroup row>
            <Label sm="3">Tag DEVEUI</Label>
            <Col sm="9">
              <Input type="text" placeholder="DEVEUI" onChange={e => onNewTagChange(e.target.value, 'deveui')} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Kraal Tag ID</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Kraal Tag ID"
                onChange={e => onNewTagChange(e.target.value, 'diagri_id')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag Version</Label>
            <Col sm="9">
              <Input type="text" placeholder="Tag Version" onChange={e => onNewTagChange(e.target.value, 'version')} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag Firmware Version</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Firmware Version"
                onChange={e => onNewTagChange(e.target.value, 'firmware_version')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag farm</Label>
            <Col sm="9">
              <Select
                placeholder="Select a Farm"
                options={state.farm_opts}
                style={{ width: '100%' }}
                value={state.selected_farm}
                onChange={handleFarmChange}
              />
            </Col>
          </FormGroup>
        </Form>
      </AppModal>

      <AppModal
        isOpen={state.link_modal}
        confirmButtonText="Link"
        title="Linking confirmation"
        handleCancel={() => toggleModal('link_modal')}
        handleConfirm={linkTag}>
        <p className="text-center">
          Tag <b>{getLabel(state.selected_tags_unlinked?.at(0))}</b> will be linked to
        </p>
        <Livestock animals={state.animals} animal={state.selected_animals}></Livestock>
      </AppModal>

      <AppModal
        isOpen={state.csv_modal}
        confirmButtonText="Upload"
        title="CSV file upload"
        handleCancel={() => toggleModal('csv_modal')}
        handleConfirm={uploadCSV}>
        <div className="py-4">
          <div className="mb-4">
            Download{' '}
            <a href="/files/tags_import_example.csv" style={{ fontWeight: 'bold' }}>
              example
            </a>{' '}
            CSV file.
          </div>

          <FormGroup>
            <Label>Select farm</Label>
            <Select
              options={state.farm_opts}
              style={{ width: '100%' }}
              value={state.csv_farm}
              onChange={handleCSVFarmChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>CSV file</Label>
            <Input type="file" onChange={handleCSVChange}></Input>
          </FormGroup>
        </div>
      </AppModal>
    </>
  );
}
