import { Component } from 'react';
import ReactTable from 'react-table';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';

import axios from '../../axios';

import SelectionPopover from '../../components/Popovers/SelectionPopover';
import RuleSnoozeIcon from '../../components/RuleSnoozeIcon.jsx';
import Search from '../../components/Search/Search';
import Filters from '../../components/filters/index.jsx';

import AppDropdown from '../ui-components/dropdown';

function Priority(props) {
  let priority = props.priority;
  const colors = {
    priority: 'red',
    high: 'red',
    normal: '#FFBF00',
    medium: '#FFBF00',
    low: '#CCCCCC',
    record: '#CCCCCC'
  };

  if (priority) {
    priority = priority.toLowerCase();

    return (
      <div className="priority-tag" style={{ background: colors[priority] }}>
        {props.priority.charAt(0).toUpperCase() + props.priority.slice(1)}
      </div>
    );
  } else {
    return null;
  }
}

export default class Rules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      alert_modal: false,
      delete_modal: false,
      filters: {
        farms: props?.location?.filter?.farms || [],
        geofences: [],
        labels: [],
        sites: [],
        animals: []
      },
      isOpenSelectionPopover: false,
      modal_message: '',
      modal_title: '',
      modal_type: '',
      query: '',
      rules: [],
      selectAll: false,
      selected_rules: [],
      tableData: []
    };

    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.getRules = this.getRules.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onClickAllRecords = this.onClickAllRecords.bind(this);
    this.onClickOnThisPage = this.onClickOnThisPage.bind(this);
    this.onRuleSelect = this.onRuleSelect.bind(this);
    this.sigleAction = this.sigleAction.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.getRules();
    this.initActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_rules !== this.state.selected_rules) {
      this.initActions();
    }
  }

  initActions() {
    const { selected_rules } = this.state;

    this.setState({
      actions: [
        {
          label: 'Add rule',
          isVisible: () => true,
          handler: () => this.props.history.push('/rules/builder/new')
        },
        {
          label: 'Snooze rule',
          isVisible: () => selected_rules.length,
          handler: () => this.toggleModal('delete_modal', 'Snooze')
        },
        {
          label: 'Unsnooze rule',
          isVisible: () => selected_rules.length,
          handler: () => this.toggleModal('delete_modal', 'Unsnooze')
        },
        {
          label: 'Archive rule',
          isVisible: () => selected_rules.length,
          handler: () => this.archiveRule()
        }
      ]
    });
  }

  onClickOnThisPage() {
    const newCheckboxValue = !this.state.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) {
      this.state.tableData.map(val => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }

    this.setState(state => ({ ...state, selected_rules: checkedCopy }));
    this.setState({ selectAll: newCheckboxValue });
    this.onToggleSelectionPopover();
  }

  onClickAllRecords() {
    const newCheckboxValue = !this.state.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) this.state.rules.map(val => checkedCopy.push(val.id));

    this.setState(state => ({ ...state, selected_rules: checkedCopy }));
    this.setState({ selectAll: newCheckboxValue });
    this.onToggleSelectionPopover();
  }

  onToggleSelectionPopover(isOpen) {
    const open = isOpen !== undefined ? isOpen : !this.state.isOpenSelectionPopover && !this.state.selectAll;

    this.setState({ isOpenSelectionPopover: open });
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: value }));
  };

  handleKeyPress = charCode => {
    if (charCode === 13) this.getRules();
  };

  async getRules() {
    const response = await axios.get('rules', {
      params: {
        query: this.state.query,
        farm_ids: this.state.filters.farms.map(x => x.value),
        label_ids: this.state.filters.labels.map(x => x.value),
        geofence_ids: this.state.filters.geofences.map(x => x.value),
        site_ids: this.state.filters.sites.map(x => x.value),
        animal_ids: this.state.filters.animals.map(x => x.value)
      }
    });

    if (response.status === 200) {
      // add flag for default rules //
      response.data.map(x => {
        x.isDefault = x.default_rule === 1;
        return x;
      });

      this.setState({ rules: response.data, tableData: response.data.slice(0, 25) });
    }
  }

  async sigleAction(id, type) {
    const modal_type = type === 'snoozable' ? 'Snooze' : 'Unsnooze';
    const selected_rules = [];

    selected_rules.push(id);
    this.setState({ selected_rules: selected_rules, modal_type: modal_type, selectAll: false });
    this.toggleSingleModal('delete_modal', modal_type);
  }

  toggleSingleModal(modal, type) {
    const title = type === 'Snooze' ? 'Snooze rule' : 'Unsnooze rule';
    const message =
      type === 'Snooze' ? 'Are you sure you want to snooze this rule?' : 'Are you sure you want to unsnooze this rule?';

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  async deleteRules() {
    let response = {};

    if (this.state.modal_type === 'Archive') {
      response = await axios.delete('rules/bulk', {
        data: { ids: this.state.selected_rules, force: true }
      });
    } else if (this.state.modal_type === 'Snooze') {
      response = await axios.put('rules/snooze/bulk', {
        ids: this.state.selected_rules,
        force: true,
        seconds: 86400
      });
    } else {
      response = await axios.put('rules/snooze/bulk', {
        ids: this.state.selected_rules,
        force: true,
        seconds: -1
      });
    }

    if (response.status && response.status === 200) {
      await this.getRules();
      this.setState({ delete_modal: false, selectAll: false, selected_rules: [] });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  archiveRule() {
    let isDefaultRuleExists = false;

    this.state.selected_rules.map(x => {
      const rule = this.state.rules.filter(data => data.id === x);

      if (rule[0] && rule[0].isDefault) {
        isDefaultRuleExists = true;
      }
    });

    if (isDefaultRuleExists) {
      this.toggleModal('alert_modal', 'Alert');
    } else {
      this.toggleModal('delete_modal', 'Archive');
    }
  }

  toggleModal(modal, type) {
    let title = '';
    let message = '';

    if (type === 'Archive') {
      title = 'Archive rules';
      message = 'Are you sure you want to archive selected rules? This action cannot be undone.';
    } else if (type === 'Snooze') {
      title = 'Snooze rules';
      message = 'Are you sure you want to snooze selected rules?';
    } else if (type === 'Alert') {
      title = 'Alert';
      message = 'This is a default system Rule which can not be edited or archived.';
    } else {
      title = 'Unsnooze rules';
      message = 'Are you sure you want to unsnooze selected rules?';
    }

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  onRuleSelect(rule) {
    const rules = this.state.selected_rules;

    if (rules.includes(rule)) {
      const index = rules.indexOf(rule);

      if (index > -1) rules.splice(index, 1);
    } else {
      rules.push(rule);
    }

    this.setState({ selectAll: this.state.rules.length === this.state.selected_rules.length });
    this.setState({ selected_rules: rules });
  }

  filtersUpdated(filters) {
    this.setState({ filters }, () => this.getRules());
  }

  filtersClose() {
    this.setState({ filters_open: false });
  }

  render() {
    const rulePriority = {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
      priority: 'High',
      normal: 'Medium',
      record: 'Low'
    };
    const rulePriorityIndex = {
      high: 2,
      medium: 1,
      low: 0,
      priority: 2,
      normal: 1,
      record: 0
    };
    const columns = [
      {
        Header: () => (
          <div className="position-relative form-check">
            <SelectionPopover
              isOpen={this.state.isOpenSelectionPopover}
              onClickOnThisPage={this.onClickOnThisPage}
              onClickAllRecords={this.onClickAllRecords}
              setOpen={isOpen =>
                this.setState({
                  isOpenSelectionPopover: isOpen !== undefined ? isOpen : !this.state.isOpenSelectionPopover
                })
              }
              content={
                <input
                  type="checkbox"
                  defaultChecked={this.state.selectAll}
                  onChange={() => {
                    this.onToggleSelectionPopover();

                    if (this.state.selectAll) {
                      this.setState({ selectAll: !this.state.selectAll });
                      this.setState(state => ({ ...state, selected_rules: [] }));
                    }
                  }}
                />
              }
            />
          </div>
        ),
        id: 'select',
        filterable: false,
        accessor: d => ({ id: d.id, tag: d.tag }),
        Cell: props => (
          <FormGroup check>
            <Input
              type="checkbox"
              defaultChecked={this.state.selected_rules.includes(props.value.id)}
              value={props.value.id}
              onChange={e => this.onRuleSelect(parseInt(e.target.value), props.value.tag ? 'linked' : 'unlinked')}
              key={props.value.id}
            />{' '}
          </FormGroup>
        ),
        headerClassName: 'wordwrap',
        sortable: false,
        maxWidth: 85
      },
      {
        Header: 'Rule ID',
        id: 'id',
        accessor: d => ({
          id: d.id,
          identifier: d.identifier,
          is_snoozed: d.is_snoozed
        }),
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
        Cell: props => (
          <a href="#" onClick={() => this.props.history.push('rule/' + props.value.id)}>
            {props.value.is_snoozed && <RuleSnoozeIcon></RuleSnoozeIcon>} {props.value.identifier}{' '}
          </a>
        )
      },
      {
        Header: 'Rule Name',
        accessor: 'name',
        headerClassName: 'wordwrap',
        minWidth: 250
      },
      {
        Header: 'Rule Type',
        accessor: 'type_display_name',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Rule Priority',
        id: 'rulepid',
        accessor: d => ({
          priority: d.data.action ? rulePriority[d.data.action.value] : '',
          index: d.data.action ? rulePriorityIndex[d.data.action.value] : -1
        }),
        Cell: props => <Priority priority={props.value.priority}></Priority>,
        filterMethod: (filter, row) => {
          const payload = { rulepid: row?.rulepid?.priority };

          return filterCaseInsensitive(filter, payload);
        },
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a.index - b.index
      },
      {
        Header: 'Snooze',
        id: 'rulesnoozzeid',
        accessor: d => ({ snooze: d.is_snoozed ? 'snoozed' : 'snoozable', id: d.id }),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 85,
        Cell: props => (
          <div>
            <a href="#">
              <i
                onClick={() => this.sigleAction(props.value.id, props.value.snooze)}
                className={'rule_not-action mdi mdi-alarm ' + props.value.snooze}></i>
            </a>
          </div>
        )
      },

      {
        Header: 'Rule Status',
        id: 'rulestatusid',
        accessor: d => (d.is_snoozed ? 'Snoozed' : 'Active'),
        headerClassName: 'wordwrap'
      },

      {
        Header: 'Rule Triggers',
        accessor: 'triggers_count',
        headerClassName: 'wordwrap'
      },
      {
        Header: 'Rule Objects',
        accessor: 'objects_count',
        headerClassName: 'wordwrap'
      }
    ];

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      if (row[id] && typeof row[id] == 'object') {
        return row[id] !== undefined
          ? String(row[id]['identifier']?.toLowerCase())?.includes(filter?.value?.toLowerCase())
          : true;
      } else if (typeof row[id] == 'number') {
        return row[id] !== undefined ? String(row[id])?.includes(filter?.value) : true;
      } else {
        return row[id] !== undefined ? String(row[id]?.toLowerCase())?.includes(filter?.value?.toLowerCase()) : true;
      }
    }

    return (
      <div>
        <Filters
          farms={this.state.filters.farms}
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'geofence', 'label', 'site', 'animal']}
        />

        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Rules</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Rules</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
                  <Search
                    className="mr-2"
                    id="search-rules"
                    placeholder="Search rules"
                    defaultValue={this.state.query}
                    handleChange={e => this.onChange(e.target.value, 'query')}
                    handleKeyPress={() => this.handleKeyPress(13)}
                  />

                  <AppDropdown
                    label="Actions"
                    items={this.state.actions.filter(item => item.isVisible())}
                    handleClick={action => action.handler()}
                  />

                  <Button
                    color="primary"
                    className="float-right mr-2"
                    onClick={() => this.setState({ ...this.state, filters_open: !this.state.filters_open })}>
                    <i className="fa fa-filter"></i>Filters
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={this.state.rules.length > 0}
                    minRows={0}
                    data={this.state.rules}
                    columns={columns}
                    resizable={true}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    defaultPageSize={25}
                    onFetchData={props => {
                      const data = props.data.length ? props.sortedData.slice(0, props.pageSize) : this.state.rules;
                      this.setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRules()}>
              {this.state.modal_type}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.alert_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('alert_modal')}>
              Close
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
