import { REDUCERS } from '../../constants/reducers';

import { TABLE_SIZE } from '../../components/AppTable/constants';

const initialState = {
  loading: true,
  isAdmin: false,
  user: {},
  baseURL: '',
  users: [],
  tableSettings: {
    size: TABLE_SIZE.SM
  }
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.USER.SET_USER_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.USER.SET_USER: {
      return Object.assign({}, state, {
        user: { ...action.value }
      });
    }
    case REDUCERS.USER.SET_BASE_URL: {
      return Object.assign({}, state, {
        baseURL: action.value
      });
    }
    case REDUCERS.USER.SET_USERS: {
      return Object.assign({}, state, {
        users: [...action.value]
      });
    }
    case REDUCERS.USER.SET_TABLE_SETTINGS: {
      return Object.assign({}, state, {
        tableSettings: { size: action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { user };
