import React, { createRef } from 'react';
import { FeatureGroup, GeoJSON, LayersControl, Map, Polygon, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'react-leaflet-fullscreen/dist/styles.css';
import { GoogleLayer } from 'react-leaflet-google';
import { Link } from 'react-router-dom';

import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import SiteFeature from './SiteFeature';

const { BaseLayer } = LayersControl;
const provider = new OpenStreetMapProvider();
const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const satellite = 'SATELLITE';

function Feature(props) {
  const type = props.type;
  let LINE;
  if (type === 'Polygon') {
    LINE = (
      <Polygon
        onClick={() => props.setAnimal(type)}
        color={props.color}
        opacity="0.5"
        fillOpacity="0.5"
        positions={props.geometry}>
        <Popup>
          <Popup>
            <b>{props.feature.properties.name}</b>
            <br />
            <br />
            {props.feature.properties.description}
            <br />
            <br />
            <Link to={`/geofence/${props.feature.properties.id}`}>
              <span>More details</span>
            </Link>
          </Popup>
        </Popup>
      </Polygon>
    );
  }
  return LINE;
}
class MapSimple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      farm: props.farm,
      position: [-29.132312, 26.057016],
      editing: props.editing,
      color: this.props.color,
      draw: {},
      features: [],
      sites: {
        features: []
      },
      geofences: [],
      enableDraw: true,
      set: false
    };
    this.drawApi;
    this.created = this.created.bind(this);
    this.edited = this.edited.bind(this);
    this.tick = this.tick.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.drawControlRef = createRef();
    this.featuregroupRef = createRef();
    this.mapRef = createRef();
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  async componentDidMount() {
    let features = {
      type: 'FeatureGroup',
      features: []
    };
    let geo = {
      type: 'FeatureCollection',
      features: []
    };
    let sts = {
      type: 'FeatureGroup',
      features: []
    };
    let fences = JSON.parse(JSON.stringify(this.props.farm.geofences));
    let sites = JSON.parse(JSON.stringify(this.props.farm.sites || []));
    for (let st of sites) {
      if (!st.geo_json.type) {
        continue;
      }
      sts.features.push({ ...st.geo_json, color: st.color });
    }
    for (let gf of fences) {
      if (gf.is_master) {
        let coords = JSON.parse(JSON.stringify(gf.geo_json.geometry.coordinates[0]));
        for (let c in coords) {
          let co = coords[c];
          coords[c] = [co[1], co[0]];
        }
        gf.geo_json.geometry.coordinates[0] = coords;
        features.features.push(gf.geo_json);
      } else {
        geo.features.push({ ...gf.geo_json, color: gf.color });
      }
    }
    this.setState(
      {
        features,
        geofences: geo,
        sites: sts,
        set: true
      },
      () => this.tick(this.props)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.tick(nextProps);
    if (nextProps.isFullScreen !== this.props.isFullScreen) {
      this.mapRef.current.leafletElement._onResize();
    }

    if (nextProps.color !== this.state.color) {
      this.mapRef.current.leafletElement.eachLayer(layer => {
        if (layer._leaflet_id === this.state.leaflet_id) {
          layer.setStyle({
            color: nextProps.color
          });
        }
      });

      this.setState({ color: nextProps.color });
    }
  }

  async tick(props) {
    let set_positon = props.farm.full_address;
    if (props.defaultPosition && props.farm.full_address.length < 1) {
      set_positon = props.defaultPosition;
    }
    let address = await provider.search({ query: set_positon });
    let position = this.state.position;
    if (address.length > 0) {
      position = [address[0].y, address[0].x];
    }
    let bounds;
    if (this.state.features.features.length > 0) {
      const group = new L.geoJson(this.state.features);
      bounds = group.getBounds();

      const corner2 = L.latLng(parseFloat(bounds._southWest?.lng), parseFloat(bounds._southWest?.lat));
      const corner1 = L.latLng(parseFloat(bounds._northEast?.lng), parseFloat(bounds._northEast?.lat));
      bounds = L.latLngBounds(corner1, corner2);
    } else {
      bounds = [
        [-31.0, 13.0],
        [-31.0, 33.0]
      ];
    }

    this.setState({
      farm: props.farm,
      position,
      bounds: bounds,
      editing: props.editing
    });
    //this.forceUpdate();
  }
  created(e) {
    this.setState({
      enableDraw: false
    });
    let layers = L.layerGroup().addLayer(e.layer);
    this.setState({
      leaflet_id: e.layer._leaflet_id
    });

    layers.eachLayer(a => {
      if (this.props.type === 'farm') {
        if (this.props.newGeofence) {
          this.props.newGeofence(a.toGeoJSON());
        }
      }
    });
  }
  edited(e) {
    this.setState({
      enableDraw: false
    });

    e.layers.eachLayer(a => {
      if (this.props.type === 'farm') {
        if (this.props.editFence) {
          this.props.editFence(a.toGeoJSON());
        }
      }
    });
  }

  onDelete() {
    this.setState({ enableDraw: true });
  }

  render() {
    return (
      <Map
        ref={this.mapRef}
        scrollWheelZoom={false}
        className={`farms mapobject ${this.props.editing ? 'editing' : ''}`}
        center={this.state.position}
        bounds={this.state.bounds || null}
        zoom={10}>
        <p></p>
        <FeatureGroup ref={this.featuregroupRef}>
          <EditControl
            ref={this.drawControlRef}
            position="topright"
            onEdited={this.edited}
            onCreated={this.created}
            onDeleted={this.onDelete}
            draw={{
              polygon: this.props.editing &&
                this.state.enableDraw && {
                  shapeOptions: {
                    color: this.state.color,
                    fillOpacity: '0.5',
                    opacity: 0.5
                  }
                },
              polyline: false,
              point: false,
              marker: false,
              circle: false,
              circlemarker: false,
              rectangle: false
            }}
            edit={{
              edit: true,
              delete: true
            }}
          />

          {this.state.set &&
            this.state.features.features &&
            this.state.features.features.map(
              function (i, index) {
                return (
                  <Feature
                    feature={i}
                    color={this.state.color}
                    setAnimal={this.props.setAnimal}
                    type={i.geometry.type}
                    geometry={i.geometry.coordinates}
                    key={index}
                  />
                );
              }.bind(this)
            )}

          {/* */}
        </FeatureGroup>
        <FeatureGroup
          edit={{
            edit: false,
            delete: false
          }}>
          {this.state.sites.features.length &&
            !this.state.new &&
            this.state.sites.features.map(i => {
              return (
                <SiteFeature
                  key={JSON.stringify(i)}
                  type={i.geometry.type}
                  color={i.color}
                  geometry={i.geometry.coordinates}
                  properties={i.properties}></SiteFeature>
              );
            })}
        </FeatureGroup>

        <LayersControl collapsed={!L.Browser.mobile} position="bottomleft">
          <BaseLayer checked name="Google Maps Satellite">
            <GoogleLayer googlekey={key} maptype={satellite} />
          </BaseLayer>
          <BaseLayer name="Google Maps Hybrid">
            <GoogleLayer googlekey={key} maptype={'HYBRID'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Terrain">
            <GoogleLayer googlekey={key} maptype={'TERRAIN'} />
          </BaseLayer>
          <BaseLayer name="Google Maps Roadmap">
            <GoogleLayer googlekey={key} maptype={'ROADMAP'} />
          </BaseLayer>
        </LayersControl>

        {this.state.geofences?.features?.map(item => {
          return (
            <GeoJSON
              key={item?.properties?.id}
              data={item}
              style={{
                color: item.color,
                weight: 2,
                fillOpacity: 0.2,
                opacity: 0.8,
                className: 'outline'
              }}>
              <Popup>
                <b>{item.properties.name}</b>
                <br />
                <br />
                {item.properties.description}
                <br />
                <br />
                <Link to={`/geofence/${item.properties.id}`}>
                  <span>More details</span>
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
      </Map>
    );
  }
}

export default MapSimple;
