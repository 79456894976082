import React from 'react';
import { Button, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';

import axios from '../../axios';

import img2 from '../../assets/images/diagri-background.png';
import img101 from '../../assets/images/kraal_darklogo.png';
import validators from './validators';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

class Login2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      hide_password: true,
      show: true,
      message: 'You will receive a reset link shortly.'
    };
    this.handleClick = this.handleClick.bind(this);
    this.validators = validators;
    this.onInputChange = this.onInputChange.bind(this);
    this.doReset = this.doReset.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.formValidators = this.formValidators.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }
  togglePassword(type = 'normal') {
    if (type === 'normal') {
      this.setState({
        hide_password: !this.state.hide_password
      });
    } else if (type === 'confirm') {
      this.setState({
        hide_password_confirm: !this.state.hide_password_confirm
      });
    }
    this.forceUpdate();
  }
  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.formValidators([event.target.name], event.target.value);
  }
  formValidators(fieldName, value) {
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach(rule => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }
  validForm() {
    let status = true;
    Object.keys(this.validators).forEach(field => {
      if (field === 'email' || field === 'password') {
        if (!this.validators[field].valid) {
          status = false;
        }
      }
    });
    return status;
  }
  showErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = '';
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return <div className="error mb-2">{errors}</div>;
    }
    return result;
  }
  async doReset(event) {
    const { email } = this.state;
    event.preventDefault();
    try {
      let response = await axios.post('/password/reset/email', {
        email: email,
        link_type: 'dashboard'
      });
      if (response.status === 200) {
        this.setState({
          ...this.state,
          show: false
        });
      }
    } catch (error) {}
  }
  handleClick() {
    var elem = document.getElementById('loginform');
    elem.style.transition = 'all 2s ease-in-out';
    elem.style.display = 'none';
    document.getElementById('recoverform').style.display = 'block';
  }
  render() {
    return (
      <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
        {/*--------------------------------------------------------------------------------*/}
        {/*Login2 Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center reset-height">
              <Col md="6" lg="5" className="bg-dark text-white">
                <div className="p-5">
                  <h4 className="font-login">Forgot password?</h4>
                  <h5 className="font-login">No worries, we'll help you.</h5>
                  <div className="logo-place login-form-logo">
                    <img src={img101}></img>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="5" className="bg-white">
                {this.state.show && (
                  <div className="p-5">
                    <h3 className="font-medium mb-3">Forgot Password?</h3>
                    <p className="text-mute">Please enter your email and we will send you a new password.</p>
                    <Form className="mt-3 reset-form" id="loginform" action="/dashbaord">
                      <Label for="email" className="">
                        Email
                      </Label>
                      <InputGroup className="mb-2" size="lg">
                        <InputGroupText>
                          <i className="fas fa-at"></i>
                        </InputGroupText>
                        <Input
                          type="email"
                          id="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.onInputChange}
                          placeholder="Email"
                        />
                      </InputGroup>

                      <Row className="mb-3">
                        <Col xs="12">
                          <Button
                            className="reset-btn"
                            color="primary"
                            onClick={this.doReset}
                            size="lg"
                            type="submit"
                            block>
                            Send reset link
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <div className="text-center reset-text">
                      Back to{' '}
                      <a href="/auth/login" className="text-info ml-1">
                        <b>Sign in</b>
                      </a>
                    </div>
                  </div>
                )}
                {!this.state.show && <p className="pad-30">{this.state.message}</p>}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Login2;
