import { createContext, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import { DndContext, DragOverlay, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { Button, ConfigProvider, DatePicker, Form, Input, Select, Table } from 'antd';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import debounce from 'lodash.debounce';
import moment from 'moment/moment';

import { DATE_FORMAT, EDIT_COMPONENT_TYPE } from '../../constants/common';

import { TABLE_SIZE } from '../../components/AppTable/constants';

import AppTableControls from './AppTableControls';
import './styles.scss';

dayjs.extend(customParseFormat);

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `
  };
});

const DragIndexContext = createContext({ active: -1, over: -1 });
const dragActiveStyle = (dragState, id) => {
  const { active, over, direction } = dragState;
  let style = {};

  if (active && active === id) {
    style = { backgroundColor: 'gray', opacity: 0.5 };
  } else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? {
            borderRight: '1px dashed gray'
          }
        : {
            borderLeft: '1px dashed gray'
          };
  }

  return style;
};

const TableBodyCell = ({ editable, children, dataIndex, record, rules, width, handleSave, ...props }) => {
  const dragState = useContext(DragIndexContext);
  const form = useContext(EditableContext);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing) inputRef.current?.focus();
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      if (record[dataIndex] === values[dataIndex]) return;

      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable_cell"
        style={{ paddingInlineEnd: 24, textOverflow: 'ellipsis', overflow: 'hidden', width }}
        onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return (
    <td {...props} style={{ ...props.style, ...dragActiveStyle(dragState, props.id) }}>
      {childNode}
    </td>
  );
};

const TableHeaderCell = props => {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id
  });
  const style = {
    ...props.style,
    cursor: 'move',
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9,
          userSelect: 'none'
        }
      : {}),
    ...dragActiveStyle(dragState, props.id)
  };

  return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const EditableContext = createContext(null);

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const AppTable = ({
  actions,
  baseColumns,
  expandable,
  children,
  dataSource,
  filterable,
  searchable,
  searchPlaceholder,
  settings,
  title,
  breadcrumb,
  loading,
  handleOnSelectRecords,
  updateTableData,
  handleFilters,
  handleSearchChange,
  handleSearchKeyPress,
  handleOnChange
}) => {
  const { tableSettings } = useSelector(state => state.user);
  const [dragIndex, setDragIndex] = useState({ active: -1, over: -1 });
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const getColumnSearchProps = ({ dataIndex, title, onFilter }) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          placeholder={`Search ${title}`}
          prefix={<SearchOutlined />}
          ref={searchInput}
          style={{ marginBottom: 8, color: 'rgba(0,0,0,.25)' }}
          value={selectedKeys[0]}
          onChange={e => {
            const value = e.target.value;
            setSelectedKeys(value ? [value] : []);
            handleSearch([value], confirm, dataIndex);
          }}
        />
        <div className="d-flex justify-content-between">
          <Button
            size="small"
            type="link"
            onClick={() => {
              setSelectedKeys([]);
              handleSearch([], confirm, dataIndex);
            }}>
            Reset
          </Button>
          <Button type="primary" size="small" onClick={() => close()}>
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#000000' : undefined }} />,
    onFilter: onFilter
      ? onFilter
      : (value, record) => {
          if (dataIndex && record[dataIndex]) {
            return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
          }
        }
    // render: text =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   )
  });

  function handleChange(updatedRecord) {
    updateTableData([updatedRecord]);
  }

  function renderColumns() {
    let updatedColumns = [];

    baseColumns?.forEach((column, i) => {
      const col = {
        ...column,
        key: i,
        ...(column.searchable ? getColumnSearchProps(column) : {}),
        onHeaderCell: () => ({
          id: i,
          style: {
            width: `${column.width}px`
          }
        }),
        onCell: () => ({
          id: i,
          style: {
            width: `${column.width}px`
          }
        })
      };

      if (col.editConfig?.type === EDIT_COMPONENT_TYPE.INPUT) {
        col.onCell = record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: record[col.dataIndex],
          rules: col.rules,
          style: {
            width: `${column.width}px`
          },
          handleSave
        });
      }

      if (col.editConfig?.type === EDIT_COMPONENT_TYPE.SELECT) {
        col.render = (value, record) => {
          if (value && typeof value == 'object') {
            value = value.name;
          }
          const defaultObj = col.editConfig.options.find(option => option.value === value || option.label === value);
          const defaultVal = defaultObj ? defaultObj.value : '';
          const handleOnChange = (_, newValue) => {
            handleChange({
              ...record,
              [col.dataIndex]: newValue
            });
          };

          return (
            <Select
              variant="borderless"
              options={col.editConfig.options}
              style={{ width: '100%' }}
              value={defaultVal}
              onChange={handleOnChange}
            />
          );
        };
      }

      if (col.editConfig?.type === EDIT_COMPONENT_TYPE.DATE_PICKER) {
        col.render = (value, record) => {
          const dateFormat = DATE_FORMAT.DATE;
          const selected = moment(value).format(dateFormat);
          const dateValue = value ? dayjs(selected, dateFormat) : null;
          const handleOnChange = newValue => {
            handleChange({ ...record, [col.dataIndex]: newValue });
          };

          return (
            <DatePicker
              allowClear={false}
              format={dateFormat}
              value={dateValue}
              variant="borderless"
              onChange={handleOnChange}
            />
          );
        };
      }

      updatedColumns.push(col);
    });

    return updatedColumns;
  }

  const [columns, setColumns] = useState(renderColumns());

  useEffect(() => {
    if (dataSource?.length) setColumns(renderColumns());
  }, [dataSource]);

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
    handleOnSelectRecords(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      {
        key: 'all-page',
        text: 'Select all data on this page',
        onSelect: changeableRowKeys => setSelectedRowKeys(changeableRowKeys)
      },
      {
        key: 'all',
        text: 'Select all data',
        onSelect: () => {
          const data = [...dataSource];
          const newSelectedRowKeys = data.map(item => item.id);

          setSelectedRowKeys(newSelectedRowKeys);
        }
      },
      Table.SELECTION_NONE
    ]
  };

  const [size, setSize] = useState(tableSettings.size);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1
      }
    })
  );
  const { styles } = useStyle();

  const handleSearch = useCallback(
    debounce((value, confirm, dataIndex) => {
      confirm(false);
      setSearchText(value);
      setSearchedColumn(dataIndex);
    }, 300),
    []
  );

  const handleSave = updatedRecord => {
    updateTableData([updatedRecord]);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setColumns(prevState => {
        const activeIndex = prevState.findIndex(i => i.key === active?.id);
        const overIndex = prevState.findIndex(i => i.key === over?.id);

        return arrayMove(prevState, activeIndex, overIndex);
      });
    }

    setDragIndex({ active: -1, over: -1 });
  };

  const onDragOver = ({ active, over }) => {
    const activeIndex = columns.findIndex(i => i.key === active.id);
    const overIndex = columns.findIndex(i => i.key === over?.id);

    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? 'right' : 'left'
    });
  };

  function handleCheckedList(checkedList) {
    const newColumns = columns.map(item => ({
      ...item,
      hidden: !checkedList.includes(item.key)
    }));

    setColumns(newColumns);
  }

  return (
    <>
      <AppTableControls
        actions={actions}
        columns={columns}
        filterable={filterable}
        searchable={searchable}
        settings={settings}
        title={title}
        searchPlaceholder={searchPlaceholder}
        breadcrumb={breadcrumb}
        handleTableSize={size => setSize(size)}
        handleCheckedList={handleCheckedList}
        handleFilters={handleFilters}
        handleSearchChange={handleSearchChange}
        handleSearchKeyPress={handleSearchKeyPress}
      />

      <div>{children}</div>

      <DndContext
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        collisionDetection={closestCenter}>
        <DragIndexContext.Provider value={dragIndex}>
          <SortableContext items={columns.map(i => i.key)} strategy={horizontalListSortingStrategy}>
            <ConfigProvider
              theme={{
                token: {
                  fontSize: size === TABLE_SIZE.SM ? 13 : size === TABLE_SIZE.LG ? 17 : 15
                },
                components: {
                  Select: {
                    optionFontSize: size === TABLE_SIZE.SM ? 13 : size === TABLE_SIZE.LG ? 17 : 15
                  }
                }
              }}>
              <Table
                expandable={expandable}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                components={{
                  header: { cell: TableHeaderCell },
                  body: { cell: TableBodyCell, row: EditableRow }
                }}
                rowKey="id"
                rowClassName={() => 'editable-row'}
                bordered
                loading={loading}
                className={styles.customTable}
                size={size}
                scroll={{ y: '65vh', scrollToFirstRowOnChange: true }}
                showSorterTooltip={{ target: 'sorter-icon' }}
                pagination={{ defaultPageSize: 100, hideOnSinglePage: true }}
                onChange={handleOnChange}
              />
            </ConfigProvider>
          </SortableContext>
        </DragIndexContext.Provider>

        <DragOverlay>
          <th style={{ backgroundColor: 'gray', padding: 16 }}>
            {columns[columns.findIndex(i => i.key === dragIndex.active)]?.title}
          </th>
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default memo(AppTable);
