import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Card, CardBody, Col, Input, Row, Table } from 'reactstrap';

import moment from 'moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { isLivestockFieldAvailable } from '../../helpers/subscription';

import { DATE_FORMAT } from '../../constants/common';
import { FEATURE_LIST } from '../../constants/subscription';

import default_animal from '../../assets/images/default-animal.jpg';
import AnimalPopup from '../animal_popup';

class AnimalCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      farm_opts: [],
      label_opts: [],
      breed_opts: [],
      animals: [],
      type_opts: [],
      animal: this.props.animal || {},
      sex_opts: [
        { value: 'female', label: 'Female' },
        { value: 'male', label: 'Male' }
      ],
      polled_horned: [
        { value: 'Polled', label: 'Polled' },
        { value: 'Horned', label: 'Horned' }
      ],
      specie_opts: [
        { value: 'Bovine', label: 'Bovine' },
        { value: 'Ovine', label: 'Ovine' },
        { value: 'Caprine', label: 'Caprine' }
      ],
      col_1: {
        eartag_management_id: {
          id: 'eartag_management_id',
          is_editable: true,
          label: 'Mgmt Tag ID *'
        },
        kraal_tag_id: {
          id: 'kraalTagID',
          is_editable: true,
          label: 'Kraal Tag ID'
        },
        eartag_official_id: {
          id: 'officialTagId',
          is_editable: true,
          label: 'Official Tag ID'
        },
        brand: {
          id: 'brand',
          is_editable: true,
          label: 'Brand'
        },
        ear_mark: {
          id: 'earMark',
          is_editable: true,
          label: 'Ear Mark'
        },
        specie: {
          id: 'specie',
          is_editable: true,
          label: 'Specie *'
        },
        breed: {
          id: 'breed',
          is_editable: true,
          label: 'Breed'
        },
        stock_type: {
          id: 'livestockType',
          is_editable: true,
          label: 'Livestock Type'
        },
        sex: {
          id: 'sex',
          is_editable: true,
          label: 'Sex *'
        },
        colour: {
          id: 'colour',
          is_editable: true,
          label: 'Colour'
        },
        dob_at: {
          id: 'dob',
          is_editable: true,
          label: 'DOB'
        },
        polled_horned: {
          id: 'polledHorned',
          is_editable: true,
          label: 'Polled/Horned'
        },
        name: {
          id: 'description',
          is_editable: true,
          label: 'Description'
        },
        farm: { id: 'farm', is_editable: true, label: 'Farm *' },

        geofences: {
          id: 'assignedGeofence',
          is_editable: true,
          label: 'Assigned Geofence'
        },
        sire_id: {
          id: 'sireId',
          is_editable: true,
          label: 'Sire ID'
        },
        dam_id: {
          id: 'damId',
          is_editable: true,
          label: 'Dam ID'
        }
      },
      col_2: {
        breeding_status: {
          id: 'breedingStatus',
          is_editable: false,
          label: 'Breeding Status'
        },
        calf_livestock_identifier: {
          id: 'calfLivestockId',
          is_editable: false,
          label: 'Calf ID'
        },
        calf_age: {
          id: 'calfAge',
          is_editable: false,
          label: 'Calf Age'
        },
        purchase_date: {
          id: 'purchaseDate',
          is_editable: true,
          label: 'Purchase Date'
        },
        purchased_from: {
          id: 'purchasedFrom',
          is_editable: true,
          label: 'Purchased From'
        },
        purchased_where: {
          id: 'purchasedWhere',
          is_editable: true,
          label: 'Purchased Location'
        },
        purchase_notes: {
          id: 'purchaseNotes',
          is_editable: true,
          label: 'Purchase Notes'
        },
        date_sold: {
          id: 'dateSold',
          is_editable: true,
          label: 'Date Sold'
        },
        sold_to: {
          id: 'soldTo',
          is_editable: true,
          label: 'Sold To'
        },
        loss_date: {
          id: 'lossDate',
          is_editable: true,
          label: 'Loss Date'
        },
        loss_reason: {
          id: 'lossReason',
          is_editable: true,
          label: 'Loss Reason'
        },
        comments: {
          id: 'comments',
          is_editable: true,
          label: 'Comments'
        },
        weight: {
          id: 'weight',
          is_editable: false,
          label: 'Weight'
        },
        age: {
          id: 'age',
          is_editable: false,
          label: 'Age'
        },
        lastReading: {
          id: 'lastReading',
          is_editable: false,
          label: 'Last Reading'
        },
        connectionStatus: {
          id: 'connectionStatus',
          is_editable: false,
          label: 'Connection Status'
        }
      },
      col_3: {
        physical_group: {
          is_editable: true,
          label: 'Physical Group'
        },
        virtual_group: {
          is_editable: true,
          label: 'Virtual Groups'
        }
      },
      showPopup: false,
      animal_popup_field: null,
      filtered_popup_animals: []
    };
    this.onLivestockChangeDate = this.onLivestockChangeDate.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleGeofenceChange = this.handleGeofenceChange.bind(this);

    this.onAnimalSelect = this.onAnimalSelect.bind(this);
    this.showAninalPopup = this.showAninalPopup.bind(this);
    this.closeAnimalPopup = this.closeAnimalPopup.bind(this);
  }
  async componentDidMount() {
    this.getFarms();
  }
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.animal.id !== this.state.animal.id) {
      this.setState({ animal: nextProps.animal });
    }
  }
  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  }
  async getFarms() {
    let response = await axios.get('farms?with_details=true', {
      query: this.state.query || null
    });
    let mapped_farms = [];
    for (let farm of response.data) {
      mapped_farms.push({
        value: farm.id,
        label: farm.name,
        meta: {
          features: farm.geofences
        }
      });
    }
    this.setState(
      {
        farm_opts: mapped_farms
      },
      () => this.getLabels()
    );
  }
  async getLabels() {
    let response = await axios.get('labels');
    this.setState(
      {
        label_opts: response.data
          .filter(label => label.type === 'custom')
          .filter(label => label.farm_id)
          .map(x => {
            return {
              farm_id: x.farm_id,
              value: x.id,
              label: x.name,
              color: x.colour,
              main_type: x.main_type,
              type: 'custom'
            };
          })
      },
      () => this.getBreeds()
    );
  }
  async getBreeds() {
    let response = await axios.get('breeds');
    this.setState(
      {
        breed_opts: response.data.map(x => {
          return { value: x.display_name, label: x.display_name };
        })
      },
      () => this.getTypes()
    );
  }
  async getTypes() {
    let response = await axios.get('/animals/stock-types');
    this.setState(
      {
        type_opts: response.data
      },
      () => this.getLivestocks()
    );
  }
  async getLivestocks() {
    let response = await axios.get('animals', { params: {} });
    this.setState({
      animals: response.data
    });
  }
  onLivestockChangeDate(val, field) {
    this.setState(state => ({
      animal: {
        ...state.animal,
        [field]: moment(val, 'MMMM d, yyyy h:mm aa').format()
      }
    }));
  }
  onEditAnimalChange = (value, field) => {
    this.setState(state => ({
      animal: {
        ...state.animal,
        [field]: value
      }
    }));
  };
  handleGeofenceChange = (value, field) => {
    this.setState(state => ({
      animal: {
        ...state.animal,
        [field]: [
          {
            id: value.value,
            name: value.label
          }
        ]
      }
    }));
  };
  handleSelectChange = (selectedOption, field) => {
    this.setState(state => ({
      animal: {
        ...state.animal,
        [field]: selectedOption.value
      }
    }));
  };
  handleFarmChange = selectedOption => {
    this.setState(state => ({
      animal: {
        ...state.animal,
        farm_id: selectedOption.value,
        geofences: [],
        labels: []
      }
    }));
  };
  handleBreedChange = (selectedOption, key) => {
    this.setState(state => ({
      animal: {
        ...state.animal,
        [key]: { id: selectedOption.value, display_name: selectedOption.label }
      }
    }));
  };
  handleGroupChange = (value, field, type) => {
    const groups = this?.state?.animal?.labels || [];
    const defaultGroups = groups.filter(l => l.type !== 'custom') || [];
    const virtualGroups = groups.filter(item => {
      return item.main_type === 'virtual';
    });
    const physicalGroups = groups.filter(item => {
      return item.main_type === 'physical';
    });
    const newValues = value.map(x => {
      return {
        main_type: x.main_type,
        name: x.label,
        id: x.value,
        colour: x.color,
        type: x.type
      };
    });
    let newGroups = [...defaultGroups, ...newValues];
    if (type === 'virtual') {
      newGroups = [...newGroups, ...physicalGroups];
    }
    if (type === 'physical') {
      newGroups = [...newGroups, ...virtualGroups];
    }

    this.setState(state => ({
      animal: {
        ...state.animal,
        [field]: newGroups
      }
    }));
  };
  getLivestockData() {
    //// ADD DEAFULT 'MASTER GEO-FENCE' AS DMB-209 ////
    let selected_farm = this.state.farm_opts.filter(s => s.value === this.state.animal.farm_id)[0];
    if (selected_farm) {
      let feature = selected_farm.meta.features.filter(s => s.is_master)[0];
      if (feature) {
        let geofences = this.state.animal.geofences;
        geofences.push({ id: feature.id, name: feature.name });

        this.setState(state => ({
          animal: {
            ...state.animal,
            geofences: geofences
          }
        }));
      }
    }
    //////////////////////////////////////////////

    return this.state.animal;
  }
  _arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  getBase64(file) {
    return new Promise(resolve => {
      let document = '';
      let reader = new FileReader();
      document = reader.readAsArrayBuffer(file);
      reader.onload = function () {
        document = reader.result;
        let photo = `data:${file.type}; base64, ${this._arrayBufferToBase64(reader.result)}`;
        resolve(photo);
      }.bind(this);
      reader.onerror = function () {};
      return document;
    });
  }
  async onEditAnimalFileChange(e) {
    let photo = await this.getBase64(e.target.files[0]);
    this.setState(state => ({
      animal: {
        ...state.animal,
        new_photo: photo
      }
    }));
  }
  showAninalPopup(key, liveStock) {
    this.setState(state => ({
      ...state,
      showPopup: true,
      animal_popup_field: key || null,
      filtered_popup_animals: liveStock || []
    }));
  }
  closeAnimalPopup() {
    this.setState(state => ({
      ...state,
      showPopup: false,
      animal_popup_field: null,
      filtered_popup_animals: []
    }));
  }
  onAnimalSelect(field, value) {
    this.onEditAnimalChange(value, field);
    this.closeAnimalPopup();
  }
  render() {
    let COL1_ROWS = [],
      COL2_ROWS = [],
      COL3_ROWS = [];
    let maleLivestocks = this.state.animals.filter(s => s.sex === 'male' && s.id !== this.props.animal.id);
    let femaleLivestocks = this.state.animals.filter(s => s.sex === 'female' && s.id !== this.props.animal.id);

    // COLOUMN 1 - DATA
    for (let key of Object.keys(this.state.col_1)) {
      let label = this.state.col_1[key]['label'] || '';
      let id = this.state.col_1[key]['id'] || '';

      if (!isLivestockFieldAvailable(id, this.props.subscription?.myPlan?.type, FEATURE_LIST.LIVESTOCK_LIST)) {
        continue;
      }
      let isEdit = this.state.col_1[key]['is_editable'] || false;

      let value = this.props.animal[key];

      if (isEdit && this.props.editMode) {
        if (key === 'kraal_tag_id') {
          if (this.state.animal[key]) {
            value = this.state.animal[key] ? this.state.animal[key] : '';
          } else {
            value = this.state.animal['tag'];
            value = value && value.diagri_id ? value.diagri_id : '';
          }
        }
        if (key === 'eartag_management_id' && value && value.indexOf('mgmt~tmp~') > -1) {
          value = '';
        }
        if (key === 'sex') {
          value = (
            <Select
              value={this.state.sex_opts.filter(s => s.value === this.state.animal.sex)[0]}
              onChange={e => this.handleSelectChange(e, key)}
              options={this.state.sex_opts}
            />
          );
        } else if (key === 'farm') {
          let selected_farm = this.state.farm_opts.filter(s => s.value === this.state.animal.farm_id)[0];
          value = <Select value={selected_farm} onChange={this.handleFarmChange} options={this.state.farm_opts} />;
        } else if (key === 'specie') {
          value = (
            <Select
              value={this.state.specie_opts.filter(s => s.value === this.capitalize(this.state.animal.specie))[0]}
              onChange={e => this.handleSelectChange(e, key)}
              options={this.state.specie_opts}
            />
          );
        } else if (key === 'breed') {
          value = this.state.animal[key];
          value = (
            <Select
              value={this.state.breed_opts.filter(s => s.value == value)[0]}
              onChange={e => this.handleBreedChange(e, key)}
              options={this.state.breed_opts}
            />
          );
        } else if (key == 'stock_type') {
          value = this.state.animal[key];
          let selected_type = { value: value, label: this.capitalize(value) };
          value = (
            <Select
              value={selected_type}
              onChange={e => this.handleSelectChange(e, key)}
              options={this.state.type_opts}
            />
          );
        } else if (key == 'polled_horned') {
          value = (
            <Select
              value={this.state.polled_horned.filter(s => s.value == this.state.animal.polled_horned)[0]}
              onChange={e => this.handleSelectChange(e, key)}
              options={this.state.polled_horned}
            />
          );
        } else if (key == 'sire_id') {
          value = Number(this.state.animal[key]);
          let tmp_val = maleLivestocks.filter(s => s.id == value);
          value = tmp_val.length ? tmp_val[0].identifier : 'Select';

          value = (
            <div>
              <a href="#;" onClick={e => this.showAninalPopup(key, maleLivestocks)}>
                {value}
              </a>
              {value !== 'Select' && (
                <span className="float-right rg-pointer" onClick={e => this.onEditAnimalChange('', key)}>
                  clear
                </span>
              )}
            </div>
          );
        } else if (key == 'dam_id') {
          value = Number(this.state.animal[key]);
          let tmp_val = femaleLivestocks.filter(s => s.id == value);
          value = tmp_val.length ? tmp_val[0].identifier : 'Select';

          value = (
            <div>
              <a href="#;" onClick={e => this.showAninalPopup(key, femaleLivestocks)}>
                {value}
              </a>
              {value !== 'Select' && (
                <span className="float-right rg-pointer" onClick={e => this.onEditAnimalChange('', key)}>
                  clear
                </span>
              )}
            </div>
          );
        } else if (key == 'purchase_date' || key == 'date_sold' || key == 'loss_date' || key == 'dob_at') {
          value = this.state.animal[key];
          if (value && value != '' && value != '0000-00-00 00:00:00') {
            value = (
              <DatePicker
                className="form-control"
                selected={new Date(value)}
                onChange={e => this.onLivestockChangeDate(e, key)}
                dateFormat="dd/MM/yyyy"
              />
            );
          } else {
            value = (
              <DatePicker
                className="form-control"
                onChange={e => this.onLivestockChangeDate(e, key)}
                dateFormat="dd/MM/yyyy"
              />
            );
          }
        } else if (key == 'geofences') {
          if (this.state.animal[key]) {
            value = this.state.animal[key];
            value = value.filter(s => s.name.indexOf('Master Geofence') == -1);
            let selected_farm = this.state.farm_opts.filter(s => s.value == this.state.animal.farm_id)[0];
            let features = [];
            if (selected_farm) {
              features = selected_farm.meta.features.filter(s => s.is_master == false);
            }
            value = (
              <Select
                value={value.map(x => {
                  return { label: x.name, value: x.id };
                })}
                onChange={e => this.handleGeofenceChange(e, key)}
                options={features.map(x => {
                  return { value: x.id, label: x.name };
                })}
              />
            );
          } else {
            //for add livestock option
            let features = [];
            if (this.state.farm_opts) {
              features = this.state.farm_opts.filter(s => s.is_master == false);
            }
            value = (
              <Select
                onChange={e => this.handleGeofenceChange(e, key)}
                options={features.map(x => {
                  return { value: x.id, label: x.name };
                })}
              />
            );
          }
        } else {
          value = (
            <Input type="text" defaultValue={value} onChange={e => this.onEditAnimalChange(e.target.value, key)} />
          );
        }
      } else {
        if (key == 'eartag_management_id' && value && value.indexOf('mgmt~tmp~') > -1) {
          value = '';
        } else if (key == 'sex') {
          if (value === 'male') value = 'Male';
          else if (value === 'female') value = 'Female';
        } else if (key == 'kraal_tag_id') {
          if (this.state.animal[key]) {
            value = this.state.animal[key];
          } else {
            value = this.props.animal['tag'];
            value = value && value.diagri_id ? <a href={'/tag/' + value.id}>{value.diagri_id}</a> : '';
          }
        } else if (key == 'farm') {
          value =
            value && value.name ? (
              <a href="#" onClick={e => this.props.history.push('/farm/' + this.state.animal[key]['id'])}>
                {value.name}
              </a>
            ) : (
              ''
            );
        } else if (value && key == 'geofences') {
          value = value.filter(s => s.name.indexOf('Master Geofence') == -1);
          //value = value.map(x=>x.name).join(", ") || "";
          value =
            value && value.length > 0
              ? value
                  .map(x => (
                    <a href="#" onClick={e => this.props.history.push('/geofence/' + x.id)}>
                      {x.name}
                    </a>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr]) || ''
              : '';
        } else if (key == 'stock_type') {
          value = this.capitalize(value);
        } else if (key == 'sire_id') {
          let tmp_value = maleLivestocks.filter(s => s.id == this.state.animal[key])[0];
          value = tmp_value ? <a href={'/animal/' + tmp_value.id}>{tmp_value.identifier}</a> : '';
        } else if (key == 'dam_id') {
          let tmp_value = femaleLivestocks.filter(s => s.id == this.state.animal[key])[0];
          value = tmp_value ? <a href={'/animal/' + tmp_value.id}>{tmp_value.identifier}</a> : '';
        } else if (key == 'specie') {
          value = this.capitalize(value);
        } else if (value == null) {
          value = '';
        } else if (key == 'dob_at') {
          value = moment(value).format(DATE_FORMAT.DATE);
        }
      }
      COL1_ROWS.push(
        <tr key={key}>
          <td className="v-align-middle">{label}</td>
          <td>{value}</td>
        </tr>
      );
    }
    // COLOUMN 2 - DATA
    for (let key of Object.keys(this.state.col_2)) {
      let label = this.state.col_2[key]['label'] || '';
      let id = this.state.col_2[key]['id'] || '';
      if (!isLivestockFieldAvailable(id, this.props.subscription?.myPlan?.type, FEATURE_LIST.LIVESTOCK_LIST)) {
        continue;
      }
      let isEdit = this.state.col_2[key]['is_editable'] || false;
      let value = this.props.animal[key];

      if (isEdit && this.props.editMode) {
        if (key == 'purchase_date' || key == 'date_sold' || key == 'loss_date' || key == 'dob_at') {
          value = this.state.animal[key];
          if (value && value != '' && value != '0000-00-00 00:00:00') {
            value = (
              <DatePicker
                className="form-control"
                selected={new Date(value)}
                onChange={e => this.onLivestockChangeDate(e, key)}
                dateFormat="dd/MM/yyyy"
              />
            );
          } else {
            value = (
              <DatePicker
                className="form-control"
                onChange={e => this.onLivestockChangeDate(e, key)}
                dateFormat="dd/MM/yyyy"
              />
            );
          }
        } else if (key == 'geofences') {
          value = this.state.animal[key];
          value = value.filter(s => s.name.indexOf('Master Geofence') == -1);
          let selected_farm = this.state.farm_opts.filter(s => s.value == this.state.animal.farm_id)[0];
          let features = [];
          if (selected_farm) {
            features = selected_farm.meta.features.filter(s => s.is_master == false);
          }
          value = (
            <Select
              value={value.map(x => {
                return { label: x.name, value: x.id };
              })}
              onChange={e => this.handleGeofenceChange(e, key)}
              options={features.map(x => {
                return { value: x.id, label: x.name };
              })}
            />
          );
        } else {
          value = (
            <Input type="text" defaultValue={value} onChange={e => this.onEditAnimalChange(e.target.value, key)} />
          );
        }
      } else {
        if (
          (key == 'purchase_date' ||
            key == 'date_sold' ||
            key == 'loss_date' ||
            key == 'dob_at' ||
            key == 'last_dip_date' ||
            key == 'last_dose_date') &&
          value &&
          value != '' &&
          value != '0000-00-00 00:00:00'
        ) {
          value = new Date(value);
          value = moment(value).format(DATE_FORMAT.DATE);
        }
        if (key === 'geofences') {
          value = value.filter(s => s.name.indexOf('Master Geofence') === -1);
          //value = value.map(x=>x.name).join(", ") || "";
          value =
            value && value.length > 0
              ? value
                  .map(x => (
                    <a href="#" onClick={() => this.props.history.push('/geofence/' + x.id)}>
                      {x.name}
                    </a>
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr]) || ''
              : '';
        } else if (key === 'calf_livestock_identifier') {
          value = value ? <a href={'/animal/' + this.state.animal['calf_livestock_id']}>{value}</a> : '';
        } else if (key == 'calf_age') {
          if (value && value != '' && value != '0000-00-00 00:00:00') {
            let b = moment(value);
            let a = moment();
            let years = a.diff(b, 'year');
            b.add(years, 'years');
            let months = a.diff(b, 'months');
            b.add(months, 'months');
            let days = a.diff(b, 'days');
            value = years + 'y ' + months + 'm ' + days + 'd';
          } else {
            value = '';
          }
        } else if (key === 'age') {
          value = this.props.animal['dob_at'];
          if (value && value != '' && value !== '0000-00-00 00:00:00') {
            let b = moment(value);
            let a = moment();
            let years = a.diff(b, 'year');
            b.add(years, 'years');
            let months = a.diff(b, 'months');
            b.add(months, 'months');
            let days = a.diff(b, 'days');
            value = years + 'y ' + months + 'm ' + days + 'd';
          } else {
            value = '';
          }
        } else if (id === 'lastReading') {
          value = this.props.animal?.tag?.timestamp_at
            ? moment(this.props.animal?.tag?.timestamp_at).format(DATE_FORMAT.DATETIME)
            : '';
        } else if (id === 'connectionStatus') {
          const connectionStatus = this.props.animal?.tag?.status;
          value = <div>{capitalize(connectionStatus)}</div>;
        } else if (value == null) {
          value = '';
        }
      }

      COL2_ROWS.push(
        <tr key={key}>
          <td>{label}</td>
          <td>{value}</td>
        </tr>
      );
    }

    // COLUMN 3 - DATA
    for (let key of Object.keys(this.state.col_3)) {
      const farmId = this.state.animal?.farm_id;
      let label = this.state.col_3[key]['label'] || '';
      let isEdit = this.state.col_3[key]['is_editable'] || false;
      let value = '';
      let groups = this.props.animal.labels || [];
      let virtualGroups = groups.filter(item => {
        return item.main_type === 'virtual';
      });
      let physicalGroups = groups.filter(item => {
        return item.main_type === 'physical';
      });

      if (key === 'virtual_group') {
        if (isEdit && this.props.editMode) {
          groups = this.state.animal.labels || [];
          virtualGroups = groups.filter(item => {
            return item.main_type === 'virtual';
          });
          if (virtualGroups.length) {
            const selectedGroups = virtualGroups
              .filter(l => l.type === 'custom')
              .map(item => {
                return {
                  main_type: item.main_type,
                  label: item.name,
                  value: item.id,
                  color: item.colour,
                  type: item.type
                };
              });
            value = (
              <Select
                value={selectedGroups}
                onChange={e => this.handleGroupChange(e, 'labels', 'virtual')}
                options={this.state.label_opts.filter(item => item.main_type === 'virtual' && item.farm_id === farmId)}
                isMulti={true}
                isSearchable={true}
              />
            );
          } else {
            value = (
              <Select
                value={false}
                onChange={e => this.handleGroupChange(e, 'labels', 'virtual')}
                options={this.state.label_opts.filter(item => item.main_type === 'virtual' && item.farm_id === farmId)}
                isMulti={true}
                isSearchable={true}
              />
            );
          }
        } else {
          value = virtualGroups.map(x => x.name).join(', ') || '';
        }
      }

      if (key === 'physical_group') {
        if (isEdit && this.props.editMode) {
          groups = this.state.animal.labels || [];
          physicalGroups = groups.filter(item => {
            return item.main_type === 'physical';
          });
          if (physicalGroups.length) {
            const [selectedGroup] = physicalGroups
              .filter(l => l.type === 'custom')
              .map(item => {
                return {
                  main_type: item.main_type,
                  label: item.name,
                  value: item.id,
                  color: item.colour,
                  type: item.type
                };
              });

            value = (
              <Select
                value={selectedGroup}
                onChange={e => this.handleGroupChange([e], 'labels', 'physical')}
                options={this.state.label_opts.filter(item => item.main_type === 'physical' && item.farm_id === farmId)}
                isSearchable={true}
              />
            );
          } else {
            value = (
              <Select
                value={false}
                onChange={e => this.handleGroupChange([e], 'labels', 'physical')}
                options={this.state.label_opts.filter(item => item.main_type === 'physical' && item.farm_id === farmId)}
                isSearchable={true}
              />
            );
          }
        } else {
          value = physicalGroups.map(x => x.name).join(', ') || '';
        }
      }

      COL3_ROWS.push(
        <tr key={key}>
          <td>{label}</td>
          <td>{value}</td>
        </tr>
      );
    }
    return (
      <div>
        <Card className="animal-single-detail-page">
          <CardBody>
            <Row>
              <Col sm="4">
                <Table className="no-border less-pad dual-text animal-card-table">
                  <tbody>{COL1_ROWS}</tbody>
                </Table>
              </Col>
              <Col sm="4">
                <Table className="no-border less-pad dual-text animal-card-table">
                  <tbody>{COL2_ROWS}</tbody>
                </Table>
              </Col>
              <Col sm="4" className="animal-photo">
                <Card>
                  <img
                    src={
                      (this.props.editMode && this.state.animal?.new_photo) || this.props.animal.photo || default_animal
                    }
                    className="card-img-top ieFix"
                  />
                </Card>

                {this.props.editMode && <Input type="file" onChange={e => this.onEditAnimalFileChange(e)} />}

                <Table className="no-border less-pad dual-text animal-card-table">
                  <tbody>{COL3_ROWS}</tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {this.state.showPopup && (
          <AnimalPopup
            key="animal-popup"
            fieldKey={this.state.animal_popup_field}
            animals={this.state.filtered_popup_animals}
            onSelect={this.onAnimalSelect}
            onClose={this.closeAnimalPopup}
          />
        )}
      </div>
    );
  }
}
export default AnimalCard;
