import { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import moment from 'moment';

import axios from '../../axios';

import { DATE_FORMAT } from '../../constants/common';
import { FEATURE_LIST } from '../../constants/subscription';

import { setNotificationList } from '../../redux/actions/notification';

import Calendar from '../../components/Calendar';
import FeatureProvider from '../../components/FeatureProvider';
import Tasks from '../../components/Tasks';
import { OrderStatus, TopSection } from '../../components/dashboard-components';
import Filters from '../../components/filters/index.jsx';
import WeatherForecast from '../../components/weather-forecast';

import AppDropdown from '../ui-components/dropdown';

import { TABLE_COLUMNS } from '../../configs/subscriptions';
import './styles.scss';

function Priority(props) {
  let priority = props.priority;
  let colors = {
    priority: !props.isClear ? 'red' : '',
    high: !props.isClear ? 'red' : '',
    normal: !props.isClear ? '#FFBF00' : '',
    medium: !props.isClear ? '#FFBF00' : '',
    low: !props.isClear ? '#CCCCCC' : '',
    record: !props.isClear ? '#CCCCCC' : ''
  };
  return (
    <div
      className="priority-tag"
      style={{
        background: colors[priority],
        color: !props.isClear ? 'white' : '#565663'
      }}>
      {props.priority.charAt(0).toUpperCase() + props.priority.slice(1)}
    </div>
  );
}

function RulePriority(props) {
  let priority = props.priority;
  const colors = {
    priority: 'red',
    high: 'red',
    normal: '#FFBF00',
    medium: '#FFBF00',
    low: '#CCCCCC',
    record: '#CCCCCC'
  };

  if (priority) {
    priority = priority.toLowerCase();

    switch (priority) {
      case 'record':
        priority = 'low';
        break;
      case 'normal':
        priority = 'medium';
        break;
      case 'priority':
        priority = 'high';
        break;
    }

    priority = priority.toLowerCase();
  }

  return (
    <div className="priority-tag" style={{ background: colors[priority] }}>
      {priority.charAt(0).toUpperCase() + priority.slice(1)}
    </div>
  );
}

class General extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      delete_modal: false,
      exception_modal: false,
      farmes: [],
      filterType: 'sex',
      filters: { farms: [] },
      filters_open: false,
      livestockCount: [],
      livestockData: [],
      livestockTableData: [],
      livestock_exceptions: '',
      modal_message: '',
      modal_title: '',
      modal_type: '',
      notifications: [],
      rules: [],
      selected_geofence: '',
      selected_rules: [],
      totalNotificationCount: 0,
      totalRuleCount: 0,
      weatherInfo: null
    };

    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.getLivestockCountColumns = this.getLivestockCountColumns.bind(this);
    this.initActions = this.initActions.bind(this);
  }

  componentDidMount() {
    this.getFarms([], true);
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Refresh farm data',
          isVisible: () => true,
          handler: () => this.refreshFarmData()
        }
      ]
    });
  }

  filtersClose() {
    this.setState(state => ({ ...state, filters_open: false }));
  }

  sigleAction(id, type) {
    const modal_type = type === 'snoozable' ? 'Snooze' : 'Unsnooze';
    const selected_rules = [];

    selected_rules.push(id);

    this.setState({ selected_rules: selected_rules, modal_type: modal_type, selectAll: false });
    this.toggleSingleModal('delete_modal', modal_type);
  }

  toggleSingleModal(modal, type) {
    const title = type === 'Snooze' ? 'Snooze rule' : 'Unsnooze rule';
    const message =
      type === 'Snooze' ? 'Are you sure you want to snooze this rule?' : 'Are you sure you want to unsnooze this rule?';

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  async getFarms(farmIds = [], isLoadAll) {
    const response = await axios.get('farms');
    const farms = response.data.filter(item => isLoadAll || farmIds.includes(item.id));

    this.setFarmsInfo(farms);
  }

  setFarmsInfo(farms) {
    const [weatherFarm] = farms;
    const [coordinates] = weatherFarm?.master_geofence?.geo_json?.geometry?.coordinates[0] || [];
    const weatherInfo = weatherFarm && {
      headingTxt: `${weatherFarm.address.city}, ${weatherFarm.address.country}`,
      location: `${weatherFarm.address.city}, ${weatherFarm.address.country}`,
      latLng: {
        lat: coordinates[0],
        lng: coordinates[1]
      }
    };

    this.setState(
      {
        farmIds: farms.map(item => item.id),
        weatherInfo,
        filters: {
          farms: farms.map(item => ({
            value: item.id,
            label: item.name
          }))
        }
      },
      () => this.getDashboardData()
    );
  }

  getDashboardData() {
    this.getGeofences();
    this.getNotifications();
    this.getRules();
    this.getLivestockData();
  }

  async snoozeNotification(id) {
    await axios.put('notifications/' + id + '/snooze');
    this.getNotifications();
  }

  async clearNotification(id) {
    await axios.put('notifications/' + id + '/clear');
    const updatedNotificationList = this.props.notification.list.filter(item => item.id !== id);
    this.props.setNotificationList(updatedNotificationList);
    this.getNotifications();
  }

  async getNotifications() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ notifications: [], totalNotificationCount: 0 });

      return;
    }

    const response = await axios.get('notifications', {
      params: {
        query: 'dashboard',
        farm_ids: farmIds
      }
    });

    this.setState({
      notifications: response.data.notifications,
      totalNotificationCount: response.data.length_72_hours
    });
  }

  async getRules() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ rules: [], totalRuleCount: 0 });

      return;
    }

    const response = await axios.get('rules', {
      params: {
        query: 'dashboard',
        farm_ids: farmIds
      }
    });

    this.setState({ rules: response.data.rules, totalRuleCount: response.data.active_rules_count });
  }

  async getGeofences() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ livestockCount: [], activeLivestockCount: 0 });

      return;
    }

    const response = await axios.get('geofences', {
      params: {
        farm_ids: farmIds
      }
    });
    const activeLivestockCount = response.data.reduce((previousValue, currentValue) => {
      if (currentValue.is_master) return currentValue.active_livestock_count + (previousValue || 0);

      return previousValue || 0;
    }, 0);
    const unassignedLivestockCount = response.data.map(i => i.unassignedAnimals).filter(i => i !== undefined);
    const generalUnassignedAnimalsCount = unassignedLivestockCount.reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    this.setState({
      livestockCount: response.data,
      hasUnassignedAnimals: !!generalUnassignedAnimalsCount,
      activeLivestockCount
    });
  }

  showException(livestock_exceptions, selected_geofence) {
    const exception_modal = !this.state.exception_modal;

    this.setState(state => ({
      ...state,
      exception_modal: exception_modal,
      livestock_exceptions: livestock_exceptions,
      selected_geofence: selected_geofence
    }));
  }

  async getLivestockData() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ livestockData: [], livestockTableData: [] });

      return;
    }

    const response = await axios.get('animals', {
      params: {
        dashboard_query: 'dashboard',
        farm_ids: farmIds
      }
    });

    const staticObj = { sex: [], stock_type: [], age_group: [] };

    if (response.data.sex && response.data.sex.length > 0) {
      response.data.sex.forEach(element => {
        let newArray = [];
        newArray.push(element.type);
        newArray.push(element.count);
        staticObj['sex'].push(newArray);
      });
    }

    if (response.data.age_group && response.data.age_group.length > 0) {
      response.data.age_group.forEach(element => {
        let newArray = [];
        newArray.push(element.type);
        newArray.push(element.count);
        staticObj['age_group'].push(newArray);
      });
    }

    if (response.data.stock_type && response.data.stock_type.length > 0) {
      response.data.stock_type.forEach(element => {
        let newArray = [];
        newArray.push(element.type);
        newArray.push(element.count);
        staticObj['stock_type'].push(newArray);
      });
    }

    this.setState({ livestockData: staticObj, livestockTableData: response.data });
  }

  async deleteRules() {
    if (this.state.modal_type === 'Archive') {
      await axios.delete('rules/bulk', {
        data: { ids: this.state.selected_rules, force: true }
      });
    } else if (this.state.modal_type === 'Snooze') {
      await axios.put('rules/snooze/bulk', {
        ids: this.state.selected_rules,
        force: true,
        seconds: 86400
      });
    } else {
      await axios.put('rules/snooze/bulk', {
        ids: this.state.selected_rules,
        force: true,
        seconds: -1
      });
    }

    this.getRules();
    this.setState({ delete_modal: false, selectAll: false, selected_rules: [] });
  }

  async refreshFarmData() {
    this.getDashboardData();
  }

  filtersUpdated = filters => {
    const farmIds = filters.farms.map(item => item.value);

    this.getFarms(farmIds);
  };

  getTrProps = (state, rowInfo) => {
    return rowInfo
      ? {
          style: {
            background: rowInfo.row.actions.clear ? '#f0f0f0' : '#ffffff'
          }
        }
      : {};
  };

  getLivestockCountColumns = columns => {
    const planType = this.props.subscription?.myPlan?.type;
    let animalsColumns = columns;

    if (!this.state.hasUnassignedAnimals) {
      animalsColumns = animalsColumns.filter(column => column.id !== 'unassignedAnimals');
    }

    if (TABLE_COLUMNS[FEATURE_LIST.LIVESTOCK_COUNT_WIDGET]?.[planType]?.length) {
      return animalsColumns.filter(column =>
        TABLE_COLUMNS[FEATURE_LIST.LIVESTOCK_COUNT_WIDGET]?.[planType]?.includes(column.id)
      );
    }

    return animalsColumns;
  };

  render() {
    const Livestockcolumns = [
      {
        Header: 'Geofence Name',
        id: 'geofenceName',
        accessor: d => ({ id: d.id, name: d.name }),
        Cell: props => (
          <a href="#" onClick={() => this.props.history.push('geofence/' + props.value.id)}>
            {props.value.name}
          </a>
        ),
        headerClassName: 'wordwrap',
        minWidth: 200,
        sortMethod: (a, b) => a.name.localeCompare(b.name)
      },
      {
        Header: 'Assigned Livestock',
        id: 'assignedLivestock',
        accessor: 'active_livestock_count',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Expected Tracked Livestock',
        id: 'taggedLivestock',
        accessor: 'livestock_with_tag',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Actual Tracked Livestock',
        id: 'actualLivestock',
        accessor: 'actual_livestock',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Difference',
        id: 'difference',
        accessor: 'livestock_difference',
        headerClassName: 'wordwrap',
        minWidth: 110
      },
      {
        Header: 'Unassigned Livestock',
        id: 'unassignedAnimals',
        accessor: 'unassignedAnimals',
        headerClassName: 'wordwrap',
        minWidth: 120
      },
      {
        Header: 'Exceptions',
        id: 'exceptionsId',
        accessor: d => {
          {
            const livestockExceptions = d.livestock_exceptions;
            const hasLivestockExceptions = livestockExceptions ? livestockExceptions.length : null;

            return {
              geofencename: d.name,
              exceptions: hasLivestockExceptions
                ? livestockExceptions
                    .map(x => {
                      return (
                        <a key={x.id} href="#" onClick={() => this.props.history.push('/animal/' + x.id)}>
                          {x.identifier}
                        </a>
                      );
                    })
                    .reduce((prev, curr) => [prev, ', ', curr])
                : ''
            };
          }
        },
        headerClassName: 'wordwrap',
        Cell: props => {
          return props.value.exceptions != '' ? (
            props.value.exceptions.toString().split(',').length > 1 ? (
              <a href="#" onClick={() => this.showException(props.value.exceptions, props.value.geofencename)}>
                View all
              </a>
            ) : (
              props.value.exceptions
            )
          ) : (
            ''
          );
        },
        minWidth: 200
      }
    ];

    const notificationsColumns = [
      {
        Header: 'Notification ID',
        id: 'id',
        accessor: d => ({ id: d.id, identifier: d.identifier }),
        headerClassName: 'wordwrap',
        Cell: props => (
          <a href="#" onClick={() => this.props.history.push('notification/' + props.value.id)}>
            {props.value.identifier}
          </a>
        ),
        sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
        maxWidth: 110
      },
      {
        Header: 'Notification Priority',
        id: 'priority',
        accessor: d => ({ priority: d.priority, index: d.priority_index }),
        headerClassName: 'wordwrap',
        Cell: props => <Priority priority={props.value.priority} isClear={props.row.actions.clear}></Priority>,
        sortMethod: (a, b) => a.index - b.index,
        maxWidth: 120
      },
      {
        Header: 'Type',
        accessor: 'type',
        headerClassName: 'wordwrap capitalize',
        className: 'capitalize',
        maxWidth: 110
      },
      {
        Header: 'Rule Name',
        accessor: 'rule.name',
        headerClassName: 'wordwrap',
        width: 250
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        headerClassName: 'wordwrap',
        Cell: props => moment(props.value).format(DATE_FORMAT.DATETIME)
      },
      {
        Header: '',
        id: 'actions',
        accessor: d => ({ snooze: d.status, clear: d.cleared_at != null, id: d.id }),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        Cell: props => (
          <div>
            <i
              onClick={() => this.snoozeNotification(props.value.id)}
              className={'not-action mdi mdi-alarm ' + props.value.snooze}></i>
            <i
              onClick={() => this.clearNotification(props.value.id)}
              className={'not-action clear mdi mdi-check-circle-outline ' + props.value.clear}></i>
          </div>
        ),
        minWidth: 50
      }
    ];

    const rulePriorityIndex = {
      high: 2,
      medium: 1,
      low: 0,
      priority: 2,
      normal: 1,
      record: 0
    };
    const rulesColumns = [
      {
        Header: 'Rule ID',
        id: 'rulenamId',
        accessor: d => ({ id: d.id, identifier: d.identifier }),
        Cell: props => (
          <a href="#" onClick={() => this.props.history.push('rule/' + props.value.id)}>
            {props.value.identifier}
          </a>
        ),
        headerClassName: 'wordwrap',
        sortMethod: (a, b) => a.identifier.localeCompare(b.identifier),
        maxWidth: 200
      },
      {
        Header: 'Notification Count',
        accessor: 'triggers_count',
        headerClassName: 'wordwrap',
        maxWidth: 200
      },
      {
        Header: 'Rule Priority',
        id: 'rpriority',
        accessor: d => ({
          priority: d.data && d.data.action ? d.data.action.value : '',
          index: d.data && d.data.action ? rulePriorityIndex[d.data.action.value] : -1
        }),
        headerClassName: 'wordwrap',
        Cell: props => <RulePriority priority={props.value.priority}></RulePriority>,
        maxWidth: 110,
        sortMethod: (a, b) => a.index - b.index
      },
      {
        Header: 'Snooze',
        id: 'rulesnoozzeid',
        accessor: d => ({ snooze: d.is_snoozed ? 'snoozed' : 'snoozable', id: d.id }),
        headerClassName: 'wordwrap',
        sortable: false,
        filterable: false,
        maxWidth: 80,
        Cell: props => (
          <div>
            <a href="#">
              <i
                onClick={() => this.sigleAction(props.value.id, props.value.snooze)}
                className={'rule_not-action mdi mdi-alarm ' + props.value.snooze}></i>
            </a>
          </div>
        )
      },
      {
        Header: 'Rule Status',
        id: 'rulestatusid',
        accessor: d => (d.is_snoozed ? 'Snoozed' : 'Active'),
        headerClassName: 'wordwrap',
        maxWidth: 150
      }
    ];

    return (
      <div>
        <Row className="dashboard-title-row">
          <Col xs="12" md="12" lg="6">
            <h4 className="">Dashboard</h4>
          </Col>
          <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
            <AppDropdown
              label="Actions"
              items={this.state.actions.filter(item => item.isVisible())}
              handleClick={action => action.handler()}
            />

            <FeatureProvider name={FEATURE_LIST.FARM_FILTER}>
              <Button
                color="primary"
                className="float-right mr-2"
                onClick={() => this.setState({ ...this.state, filters_open: !this.state.filters_open })}>
                <i className="fa fa-filter"></i>Filters
              </Button>
            </FeatureProvider>

            <Filters
              farms={this.state.filters.farms}
              open={this.state.filters_open}
              onClose={this.filtersClose}
              updateFilters={this.filtersUpdated}
              filter_types={['farm']}
            />
          </Col>
        </Row>

        <TopSection
          farm={this.state.filters.farm}
          ruleCount={this.state.totalRuleCount}
          totalNotication={this.state.totalNotificationCount}
          activeLivestock={this.state.activeLivestockCount}
          history={this.props.history}
        />

        <Row>
          <Col sm={12} lg={6} className="comman-col-box LivestockCount-widget-col">
            <div className="cols-wrap">
              <div className="heading-with-icons">
                <h4>Livestock Count</h4>
                <div className="close-full-btns">
                  <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
              <ReactTable
                showPagination={this.state.livestockCount > 0}
                minRows={0}
                data={this.state.livestockCount}
                columns={this.getLivestockCountColumns(Livestockcolumns)}
                resizable={false}
                defaultPageSize={10}
              />
            </div>
          </Col>
          <FeatureProvider name={FEATURE_LIST.WEATHER_WIDGET}>
            <Col sm={12} lg={6} className="comman-col-box Weather-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Weather</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                {this.state.weatherInfo ? (
                  <WeatherForecast
                    apikey="a7d27f9bd8fad47e927c8936a8b7809b"
                    headingTxt={this.state.weatherInfo.headingTxt || null}
                    location={this.state.weatherInfo.location || null}
                    latLng={this.state.weatherInfo.latLng || null}
                  />
                ) : (
                  <div
                    style={{
                      padding: '40px',
                      textAlign: 'center',
                      color: 'rgba(0,0,0,0.5)'
                    }}>
                    No weather data
                  </div>
                )}
              </div>
            </Col>
          </FeatureProvider>

          <FeatureProvider name={FEATURE_LIST.LAST_NOTIFICATIONS_WIDGET}>
            <Col sm={12} lg={6} className="comman-col-box Notifications-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Last 10 Notifications</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>

                <ReactTable
                  showPagination={false}
                  minRows={0}
                  data={this.state.notifications}
                  columns={notificationsColumns}
                  resizable={true}
                  defaultPageSize={10}
                  getTrProps={this.getTrProps}
                />
              </div>
            </Col>
          </FeatureProvider>

          <FeatureProvider name={FEATURE_LIST.LIVESTOCK_ANALYSIS_WIDGET}>
            <Col lg={6} className="comman-col-box LivestockBreakdown-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Livestock Analysis</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>

                {this.state.livestockData['sex'] && this.state.livestockData['sex'].length > 0 ? (
                  <OrderStatus
                    livestocKData={this.state.livestockData}
                    livestockTableData={this.state.livestockTableData}
                  />
                ) : (
                  <div
                    style={{
                      padding: '40px',
                      textAlign: 'center',
                      color: 'rgba(0,0,0,0.5)'
                    }}>
                    No livestock data
                  </div>
                )}
              </div>
            </Col>
          </FeatureProvider>

          <FeatureProvider name={FEATURE_LIST.CALENDAR_WIDGET}>
            <Col lg={6} className="comman-col-box calendar-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Calendar</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                <Calendar step={90} />
              </div>
            </Col>
          </FeatureProvider>

          <Col sm={12} lg={6} className="comman-col-box TopRules-widget-col">
            <div className="cols-wrap">
              <FeatureProvider name={FEATURE_LIST.RULES_WIDGET}>
                <div className="heading-with-icons">
                  <h4>Top 5 Rules</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                <ReactTable
                  showPagination={false}
                  minRows={0}
                  data={this.state.rules}
                  columns={rulesColumns}
                  resizable={true}
                  defaultPageSize={10}
                />
              </FeatureProvider>

              <FeatureProvider name={FEATURE_LIST.TASKS_WIDGET}>
                <div className="heading-with-icons">
                  <h4>Top 10 Tasks</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                <Tasks showPagination={false} queryParameters={{ limit: 10 }} />
              </FeatureProvider>
            </div>
          </Col>
        </Row>
        <Modal isOpen={this.state.exception_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Livestock Exceptions for {this.state.selected_geofence} </b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.livestock_exceptions}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.showException()}>
              Cancel
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>{this.state.modal_title}</b>
            </h5>
            <br />
            <br />
            <br />
            {this.state.modal_message}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleSingleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteRules()}>
              {this.state.modal_type}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => state,
  dispatch => ({
    setNotificationList: data => {
      dispatch(setNotificationList(data));
    }
  })
)(General);
