import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';

import axios from '../../axios';

import img2 from '../../assets/images/diagri-background.png';
import img101 from '../../assets/images/kraal_darklogo.png';
import validators from './validators';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

class Login2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      hide_password: true,
      axios: axios,
      logging: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.validators = validators;
    this.onInputChange = this.onInputChange.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.formValidators = this.formValidators.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }
  togglePassword(type = 'normal') {
    if (type === 'normal') {
      this.setState({
        hide_password: !this.state.hide_password
      });
    } else if (type === 'confirm') {
      this.setState({
        hide_password_confirm: !this.state.hide_password_confirm
      });
    }
    this.forceUpdate();
  }
  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    //this.formValidators([event.target.name], event.target.value);
  }
  formValidators(fieldName, value) {
    if (fieldName !== 'email') {
      this.validators[fieldName].valid = true;
      this.validators[fieldName].state = value;
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach(rule => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }
  validForm() {
    let status = true;
    Object.keys(this.validators).forEach(field => {
      if (field === 'email') {
        if (!this.validators[field].valid) {
          status = false;
        }
      }
    });
    return status;
  }
  showErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = '';
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return <div className="error mb-2">{errors}</div>;
    }
    return result;
  }
  async doLogin(event) {
    if (this.state.logging) {
      return;
    }
    event.preventDefault();
    const { email, password } = this.state;
    try {
      this.setState({
        logging: true
      });
      await axios.post('auth/login', {
        email: email,
        password: password
      });
      this.setState({
        logging: false
      });
      const redirectURL = this.props.baseURL ?? '/dashboard';
      this.props.history.push(redirectURL);
    } catch (error) {
      let response = error.response;
      if (response?.data?.field == null) {
        response.data['field'] = 'password';
      }
      this.validators[response.data.field].valid = false;
      this.validators[response.data.field].errors = [];
      this.validators[response.data.field].errors.push(response.data.message);
      this.forceUpdate();
      this.setState({
        logging: false
      });
    }
  }
  handleClick() {
    var elem = document.getElementById('loginform');
    elem.style.transition = 'all 2s ease-in-out';
    elem.style.display = 'none';
    document.getElementById('recoverform').style.display = 'block';
  }
  render() {
    return (
      <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
        {/*--------------------------------------------------------------------------------*/}
        {/*Login2 Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="5" className="bg-dark text-white login-logo-col">
                <div className="p-5">
                  <h4 className="font-login">Welcome to Kraal,</h4>
                  <h5 className="font-login-small">
                    your complete livestock tracking, monitoring and management system.
                  </h5>

                  <div className="logo-place login-form-logo">
                    <img src={img101}></img>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="5" className="bg-white">
                <div className="p-5">
                  <h3 className="font-medium mb-3">Sign In</h3>
                  <Form className="mt-3" id="loginform" action="/dashbaord">
                    <Label for="email" className="">
                      Email
                    </Label>
                    <InputGroup className="mb-2" size="lg">
                      <InputGroupText>
                        <i className="fas fa-at"></i>
                      </InputGroupText>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.onInputChange}
                        placeholder="Email"
                      />
                    </InputGroup>
                    {this.showErrors('email')}
                    <Label for="password" className="mt-3">
                      Password
                    </Label>
                    <InputGroup className="mb-3" size="lg">
                      <InputGroupText>
                        <i className="fas fa-lock"></i>
                      </InputGroupText>
                      <Input
                        type={this.state.hide_password ? 'password' : 'text'}
                        id="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onInputChange}
                        placeholder="Password"
                      />

                      <InputGroupText onClick={() => this.togglePassword('normal')}>
                        <i className={`fas ${this.state.hide_password ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                      </InputGroupText>
                    </InputGroup>
                    {this.showErrors('password')}
                    <div className="d-flex no-block align-items-center mb-4 mt-4">
                      <label htmlFor="exampleCustomCheckbox">
                        <Input addon className="mr-2" type="checkbox" id="exampleCustomCheckbox" />
                        Remember Me
                      </label>
                      <a href="/auth/recover-pwd" className="text-info ml-1 forgot-pw">
                        Forgot password?
                      </a>
                    </div>
                    <Row className="mb-3">
                      <Col xs="12">
                        <Button
                          color="primary"
                          onClick={this.doLogin}
                          className={`${this.validForm() ? '' : ''}`}
                          size="lg"
                          type="submit"
                          block>
                          Log In
                        </Button>
                      </Col>
                    </Row>
                    <div className="text-center">
                      Don&apos;t have an account? <br />
                      <a href="https://kraal.farm/" className="text-info ml-1">
                        <b>Become a customer</b>
                      </a>{' '}
                      or{' '}
                      <a href="mailto:contact@kraal.farm?subject=Kraal user account enquiry">
                        <b>contact us</b>
                      </a>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => state.user)(Login2);
