import React, { memo, useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import moment from 'moment';

import { capitalize } from '../../../helpers/common';
import { formatDate } from '../../../helpers/date';
import { getEventNullState, getEventStatus, validateEvent } from '../../../helpers/event';

import { DATE_FORMAT } from '../../../constants/common';
import { EVENT_MODAL_MODE, RECURRING_TYPES, RELATION_TYPES } from '../../../constants/event';

import {
  acceptEvent,
  createNewEvent,
  declineEvent,
  deleteEvent,
  getAllEvents,
  getEventById,
  sendRelationQuery,
  updateEvent
} from '../../../redux/actions/event';

import { errorToastHandler } from '../../action_notifier';
import ConfirmationModal from '../ConfirmationModal';
import './styles.scss';

const EventModal = ({ isOpen, onCancel, onSubmit, mode = EVENT_MODAL_MODE.CREATE, config }) => {
  const dispatch = useDispatch();
  const eventState = useSelector(state => state.event);
  const userState = useSelector(state => state.user);

  useEffect(() => {
    if (mode !== EVENT_MODAL_MODE.CREATE && eventState.currentEvent.id && isOpen) {
      dispatch(getEventById(eventState.currentEvent.id));
    }
  }, [eventState.currentEvent.id, isOpen]);

  useEffect(() => {
    if (config) {
      setNewEvent(prev => {
        return { ...prev, ...config };
      });
    }
  }, [config]);

  const EVENT_NULL_STATE = getEventNullState();
  const [modalMode, setModalMode] = useState(mode);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [newEvent, setNewEvent] = useState(EVENT_NULL_STATE);

  const [relationOptions, setRelationOptions] = useState([]);

  const getRelationOptions = relationType => {
    dispatch(sendRelationQuery(relationType)).then(data => {
      setRelationOptions(data);
    });
  };

  const onCancelClick = () => {
    setOpenConfirmationModal(false);
    setNewEvent(getEventNullState());
    onCancel();
    setModalMode(mode);
  };

  useEffect(() => {
    if (eventState.currentEvent.is_owner === false) {
      setModalMode(EVENT_MODAL_MODE.GUEST);
    }
  }, [eventState.currentEvent.is_owner]);

  useEffect(() => {
    if (newEvent.relationType) {
      getRelationOptions(newEvent.relationType);
    }
  }, [newEvent.relationType]);

  const onChange = (value, field) => {
    let formattedValue = value ? value : EVENT_NULL_STATE[field];
    if (field === 'startDate') {
      const endDate = moment(formattedValue).add(1, 'h');
      if (moment(endDate).isValid()) {
        setNewEvent(prevState => {
          return {
            ...prevState,
            endDate: endDate
          };
        });
      }
    }
    setNewEvent(prevState => {
      return {
        ...prevState,
        [field]: formattedValue
      };
    });
  };

  const onChangeRelation = (values, field) => {
    if (values) {
      const { value, label } = values;
      setNewEvent(prevState => {
        return {
          ...prevState,
          [field]: value,
          relationName: label
        };
      });
    } else {
      setNewEvent(prevState => {
        return {
          ...prevState,
          [field]: null,
          relationName: null
        };
      });
    }
  };

  const onCreateClick = () => {
    const payload = {
      title: newEvent.title,
      description: newEvent.description,
      endDate: moment(newEvent.endDate, DATE_FORMAT.DATETIME).format(),
      startDate: moment(newEvent.startDate, DATE_FORMAT.DATETIME).format(),
      remindDate: newEvent.remindDate ? moment(newEvent.remindDate, DATE_FORMAT.DATE).format() : null,
      userIds: [...newEvent?.userIds],
      relationType: newEvent.relationType || newEvent.relation_type,
      relationId: newEvent.relationId || newEvent.relation_id
    };
    const error = validateEvent(payload);
    if (error) {
      errorToastHandler(error);
    } else {
      dispatch(createNewEvent(payload)).then(res => {
        if (res) {
          dispatch(getAllEvents());
          onSubmit();
        }
      });
    }
  };

  const onDeleteSubmit = () => {
    dispatch(deleteEvent(eventState.currentEvent.id)).then(() => {
      dispatch(getAllEvents(eventState.queryParams));
      setOpenConfirmationModal(false);
      setNewEvent(EVENT_NULL_STATE);
      onCancel();
    });
  };

  const onDeleteClick = () => {
    setOpenConfirmationModal(true);
  };

  const onEditClick = () => {
    const event = {
      ...eventState.currentEvent,
      startDate: eventState.currentEvent.start_at
        ? moment(eventState.currentEvent.start_at).format(DATE_FORMAT.DATETIME)
        : null,
      endDate: eventState.currentEvent.end_at
        ? moment(eventState.currentEvent.end_at).format(DATE_FORMAT.DATETIME)
        : null,
      remindDate: eventState.currentEvent.remind_at
        ? moment(eventState.currentEvent.remind_at).format(DATE_FORMAT.DATE)
        : null
    };
    setNewEvent(event);
    setModalMode(EVENT_MODAL_MODE.UPDATE);
  };

  const onUpdateClick = () => {
    const formattedEvent = {
      title: newEvent.title,
      description: newEvent.description,
      endDate: moment(newEvent.endDate, DATE_FORMAT.DATETIME).format(),
      startDate: moment(newEvent.startDate, DATE_FORMAT.DATETIME).format(),
      remindDate: newEvent.remindDate ? moment(newEvent.remindDate, DATE_FORMAT.DATE).format() : null,
      userIds: [...newEvent?.userIds],
      relationType: newEvent.relationType || newEvent.relation_type,
      relationId: newEvent.relationId || newEvent.relation_id
    };

    const error = validateEvent(formattedEvent);
    if (error) {
      errorToastHandler(error);
    } else {
      dispatch(updateEvent(newEvent.id, formattedEvent)).then(res => {
        if (res) {
          dispatch(getAllEvents(eventState.queryParams));
          setNewEvent(EVENT_NULL_STATE);
          setModalMode(EVENT_MODAL_MODE.VIEW);
          onCancel();
        }
      });
    }
  };

  const onDeclineClick = () => {
    dispatch(declineEvent(eventState.currentEvent.id)).then(res => {
      if (res) {
        dispatch(getAllEvents(eventState.queryParams));
        setNewEvent(EVENT_NULL_STATE);
        setModalMode(EVENT_MODAL_MODE.GUEST);
        onCancel();
      }
    });
  };

  const onAcceptClick = () => {
    dispatch(acceptEvent(eventState.currentEvent.id)).then(res => {
      if (res) {
        dispatch(getAllEvents(eventState.queryParams));
        setNewEvent(EVENT_NULL_STATE);
        setModalMode(EVENT_MODAL_MODE.GUEST);
        onCancel();
      }
    });
  };
  return (
    <div className="event-modal-component">
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        title={'Delete event'}
        description={'Are you sure you want to delete this event? This action cannot be undone.'}
        onSubmit={onDeleteSubmit}
      />
      <Modal size="lg" isOpen={isOpen} className="event-modal">
        <ModalBody>
          <br />
          <h5 className="text-center">
            <strong>
              {capitalize(modalMode)} {modalMode === 'create' && 'new'} event
            </strong>
          </h5>
          <br />
          {modalMode === EVENT_MODAL_MODE.VIEW || modalMode === EVENT_MODAL_MODE.GUEST ? (
            <div className="modal-view-mode">
              <Row>
                <Col sm="4">Title*</Col>
                <Col sm="8">{eventState.currentEvent.title}</Col>
              </Row>
              <Row>
                <Col sm="4">Description</Col>
                <Col sm="8">{eventState.currentEvent.description || '/'}</Col>
              </Row>
              <Row>
                <Col sm="4">Start date*</Col>
                <Col sm="8">{formatDate(eventState.currentEvent.start_at)}</Col>
              </Row>
              <Row>
                <Col sm="4">End date*</Col>
                <Col sm="8">{formatDate(eventState.currentEvent.end_at)}</Col>
              </Row>
              <Row>
                <Col sm="4">Remind date</Col>
                <Col sm="8">
                  {eventState.currentEvent.remind_at
                    ? moment(eventState.currentEvent.remind_at).format(DATE_FORMAT.DATE)
                    : '/'}
                </Col>
              </Row>
              <Row>
                <Col sm="4">Relation</Col>
                <Col sm="8">{eventState.currentEvent.relationType || '/'}</Col>
              </Row>
              {eventState.currentEvent.relationType ? (
                <Row>
                  <Col sm="4">{capitalize(eventState.currentEvent.relationType)}</Col>
                  <Col sm="8">{eventState.currentEvent.relationName || '/'}</Col>
                </Row>
              ) : null}
              <Row>
                <Col sm="4">Recurring type</Col>
                <Col sm="8">{capitalize(eventState.currentEvent.recurringType) || '/'}</Col>
              </Row>
              {eventState.currentEvent.recurringType ? (
                <Row>
                  <Col sm="4">Recurring end date</Col>
                  <Col sm="8">{eventState.currentEvent.recurringEndDate || '/'}</Col>
                </Row>
              ) : null}
              {!eventState.currentEvent?.users?.length ? (
                <Row>
                  <Col sm="4">Users</Col>
                  <Col sm="8">/</Col>
                </Row>
              ) : (
                eventState.currentEvent?.users?.map((u, i) => {
                  if (i === 0) {
                    return (
                      <Row key={u.id}>
                        <Col sm="4">Users</Col>
                        <Col sm="8">
                          {u.email} -{' '}
                          <span className={getEventStatus(u.status).toLowerCase()}>{getEventStatus(u.status)}</span>
                        </Col>
                      </Row>
                    );
                  }
                  return (
                    <Row key={u.id}>
                      <Col sm="4"></Col>
                      <Col sm="8">
                        {u.email} -{' '}
                        <span className={getEventStatus(u.status).toLowerCase()}>{getEventStatus(u.status)}</span>
                      </Col>
                    </Row>
                  );
                })
              )}
            </div>
          ) : null}

          {modalMode === EVENT_MODAL_MODE.CREATE || modalMode === EVENT_MODAL_MODE.UPDATE ? (
            <Form>
              <FormGroup row>
                <Label sm="4">Title*</Label>
                <Col sm="8">
                  <Input type="text" value={newEvent.title} onChange={e => onChange(e.target.value, 'title')} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Description</Label>
                <Col sm="8">
                  <Input
                    type="text"
                    value={newEvent.description}
                    onChange={e => onChange(e.target.value, 'description')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Start date*</Label>
                <Col sm="8">
                  <Datetime
                    value={newEvent.startDate ? newEvent.startDate : moment().add(1, 'h').format('D/M/YYYY h:00')}
                    onChange={e => onChange(e, 'startDate')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">End date*</Label>
                <Col sm="8">
                  <Datetime
                    value={newEvent.endDate ? newEvent.endDate : moment().add(2, 'h').format('D/M/YYYY h:00')}
                    onChange={e => onChange(e, 'endDate')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Remind date</Label>
                <Col sm="8">
                  <Datetime
                    timeFormat={false}
                    value={newEvent.remindDate ? newEvent.remindDate : null}
                    onChange={e => onChange(e, 'remindDate')}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="4">Relation</Label>
                <Col sm="8">
                  <Select
                    value={{
                      value: newEvent.relationType,
                      label: capitalize(newEvent.relationType)
                    }}
                    onChange={e => onChange(e?.value, 'relationType')}
                    options={RELATION_TYPES.map(type => {
                      return { value: type, label: capitalize(type) };
                    })}
                    isClearable={true}
                  />
                </Col>
              </FormGroup>

              {newEvent.relationType ? (
                <FormGroup row>
                  <Label sm="4">{capitalize(newEvent.relationType)}</Label>
                  <Col sm="8">
                    <Select
                      value={{
                        value: newEvent.relationId,
                        label: newEvent.relationName
                      }}
                      onChange={e => onChangeRelation(e, 'relationId')}
                      options={relationOptions?.map(option => {
                        return {
                          value: option.id,
                          label: option.identifier + ' ' + option.name
                        };
                      })}
                      isClearable={true}
                    />
                  </Col>
                </FormGroup>
              ) : null}
              <FormGroup row>
                <Label sm="4">Recurring type</Label>
                <Col sm="8">
                  <Select
                    value={{ label: newEvent.recurringType }}
                    onChange={e => onChange(e?.value, 'recurringType')}
                    options={RECURRING_TYPES.map(type => {
                      return { value: type, label: type };
                    })}
                    isClearable={true}
                  />
                </Col>
              </FormGroup>

              {newEvent.recurringType ? (
                <FormGroup row>
                  <Label sm="4">Recurring end date</Label>
                  <Col sm="8">
                    <Datetime value={newEvent.recurringEndDate} onChange={e => onChange(e, 'recurringEndDate')} />
                  </Col>
                </FormGroup>
              ) : null}

              <FormGroup row>
                <Label sm="4">Users</Label>
                <Col sm="8">
                  <Select
                    value={newEvent.userEmails.map(email => {
                      return { label: email };
                    })}
                    onChange={(e, value) => {
                      const removedValueLabel = value?.removedValue?.label;
                      const addedValue = value?.option?.value;
                      let userIds = [];
                      let userEmails = [];
                      if (removedValueLabel) {
                        const user = userState.users.find(u => u.email === removedValueLabel);
                        userIds = newEvent?.userIds.filter(id => id !== user.id);
                        userEmails = newEvent?.userEmails.filter(email => email !== user.email);
                      } else if (addedValue) {
                        userIds = [addedValue, ...newEvent.userIds];
                        userEmails = [value?.option?.label, ...newEvent.userEmails];
                      }
                      setNewEvent(prevState => {
                        return {
                          ...prevState,
                          userIds: userIds,
                          userEmails: userEmails
                        };
                      });
                    }}
                    options={userState.users?.map(user => {
                      return { value: user.id, label: user.email };
                    })}
                    isMulti={true}
                    isSearchable={true}
                  />
                </Col>
              </FormGroup>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          {modalMode === EVENT_MODAL_MODE.VIEW ? (
            <Row>
              <Col>
                <Button color="danger" onClick={onDeleteClick}>
                  Delete
                </Button>
              </Col>
              <Col style={{ textAlign: 'end' }}>
                <Button color="secondary" onClick={onCancelClick}>
                  Cancel
                </Button>{' '}
                <Button color="primary" onClick={onEditClick}>
                  Edit
                </Button>
              </Col>
            </Row>
          ) : null}
          {modalMode === EVENT_MODAL_MODE.UPDATE ? (
            <Col style={{ textAlign: 'end' }}>
              <Button color="secondary" onClick={onCancelClick}>
                Cancel
              </Button>{' '}
              <Button color="primary" onClick={onUpdateClick}>
                Update
              </Button>
            </Col>
          ) : null}
          {modalMode === EVENT_MODAL_MODE.CREATE ? (
            <Col style={{ textAlign: 'end' }}>
              <Button color="secondary" onClick={onCancelClick}>
                Cancel
              </Button>{' '}
              <Button color="primary" onClick={onCreateClick}>
                Create
              </Button>
            </Col>
          ) : null}
          {modalMode === EVENT_MODAL_MODE.GUEST ? (
            <Row>
              <Col>
                <Button color="secondary" onClick={onCancelClick}>
                  Cancel
                </Button>
              </Col>
              <Col style={{ textAlign: 'end' }}>
                <Button color="danger" onClick={onDeclineClick}>
                  Decline
                </Button>{' '}
                <Button color="primary" onClick={onAcceptClick}>
                  Accept
                </Button>
              </Col>
            </Row>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default memo(EventModal);
