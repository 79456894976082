import React, { memo, useEffect, useMemo } from 'react';
import CsvDownload from 'react-json-to-csv';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';

import axios from '../../../axios';

import { GATEWAY_CSV_TEMPLATE } from '../../../constants/templates';

import { useSetState } from '../../../hooks/useSetState';

import { createNewGateway, searchGateways } from '../../../redux/actions/gateway';

import Search from '../../../components/Search/Search';
import Filters from '../../../components/filters/index.jsx';

import AppDropdown from '../../../views/ui-components/dropdown';

import { getColumns } from './settings';

const GatewayTable = ({ history }) => {
  const dispatch = useDispatch();
  const gateways = useSelector(state => state.gateway.gatewaysAll);
  const [selection, setSelection] = useSetState({ selectAll: false, selectedGateways: [] });
  const [state, setState] = useSetState({
    action: [],
    activeTab: '1',
    filters: {
      farms: []
    },
    filters_open: false,
    gateways: [],
    gatewaysCopy: [],
    isOpenSelectionPopover: false,
    newGateway: {},
    query: '',
    tableData: [],
    validate: {
      addNewGateway: {
        carrier_type: null,
        appeui: null,
        gweui: null,
        identifier: null,
        version: null,
        name: null,
        isValid: false
      }
    }
  });

  useEffect(() => {
    setState({ tableData: gateways.slice(0, 25) });
  }, [gateways]);

  useEffect(() => {
    initActions();
  }, [selection.selectAll, selection.selectedGateways]);

  function initActions() {
    setState({
      actions: [
        {
          label: 'Add Kraal gateway',
          isVisible: () => !selection.selectAll && !selection.selectedGateways?.length,
          handler: () => toggleModal('add_modal')
        },
        {
          label: 'Archive Kraal gateway',
          isVisible: () => selection.selectAll || selection.selectedGateways?.length,
          handler: () => setDeleteGateways()
        },
        {
          label: 'Upload Kraal gateway list',
          isVisible: () => true,
          handler: () => toggleModal('csv_modal')
        },
        {
          label: 'Download Kraal gateway list',
          isVisible: () => false
        }
      ]
    });
  }

  function onClickOnThisPage() {
    const newCheckboxValue = !selection.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) {
      state.tableData.map(val => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }

    setSelection({ selectedGateways: checkedCopy, selectAll: newCheckboxValue });
    onToggleSelectionPopover();
  }

  function onClickAllRecords() {
    const newCheckboxValue = !selection.selectAll;
    const checkedCopy = [];

    if (newCheckboxValue) gateways.map(val => checkedCopy.push(val.id));

    setSelection(state => ({ ...state, selectedGateways: checkedCopy, selectAll: newCheckboxValue }));
    onToggleSelectionPopover();
  }

  function onToggleSelectionPopover(isOpen) {
    const open = isOpen !== undefined ? isOpen : !state.isOpenSelectionPopover && !selection.selectAll;

    setState({ isOpenSelectionPopover: open });
  }

  const onSelect = gateway => {
    const selectedGateways = selection.selectedGateways;

    if (selectedGateways.includes(gateway)) {
      const index = selectedGateways.indexOf(gateway);

      if (index > -1) selectedGateways.splice(index, 1);
    } else {
      selectedGateways.push(gateway);
    }

    if (gateways.length === selectedGateways.length) {
      setSelection(state => ({ ...state, selectAll: true }));
    } else {
      setSelection(state => ({ ...state, selectAll: false }));
    }

    setSelection(state => ({ ...state, selectedGateways: selectedGateways }));
  };

  const onChangeSelectAllCheckbox = () => {
    onToggleSelectionPopover();

    if (selection.selectAll) {
      setSelection({ selectAll: !selection.selectAll, selectedGateways: [] });
    }
  };

  const columns = useMemo(() => {
    return getColumns({
      selection,
      setSelection,
      onSelect,
      history,
      onToggleSelectionPopover,
      onClickAllRecords,
      onClickOnThisPage,
      onChangeSelectAllCheckbox,
      state
    });
  }, [selection, state]);

  const onChange = (value, field) => {
    setState(state => ({ ...state, [field]: value }));
  };

  const getGateways = async () => {
    try {
      const payload = {
        query: state.query,
        farm_ids: state.filters.farms.map(x => x.value)
      };
      dispatch(searchGateways(payload)).then(response => {
        setState(state => ({
          ...state,
          gateways: response.data,
          gatewaysCopy: response.data
        }));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = charCode => {
    if (charCode === 13) {
      getGateways();
    }
  };

  const addGateway = async () => {
    try {
      const payload = { ...state.newGateway };

      dispatch(createNewGateway(payload)).then(() => {
        toggleModal('add_modal');
        setState(state => ({
          ...state,
          validate: {
            addNewGateway: {
              carrier_type: null,
              appeui: null,
              gweui: null,
              identifier: null,
              version: null,
              name: null,
              isValid: false
            }
          }
        }));
      });
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  const filtersUpdated = filters => {
    setState({ filters: filters }, () => getGateways());
  };

  const toggleModal = modal => {
    setState(state => ({ ...state, [modal]: !state[modal] }));
  };

  const filtersClose = () => {
    setState(state => ({ ...state, filters_open: false }));
  };

  function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;

    if (row[id]) {
      if (row[id] && typeof row[id] == 'object') {
        if (typeof row[id]['identifier'] == 'number') {
          return row[id]['identifier'] !== undefined ? String(row[id]['identifier']).includes(filter.value) : true;
        } else {
          return row[id] !== undefined
            ? String(row[id]['identifier'].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }
      } else if (typeof row[id] == 'number') {
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
      } else {
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
      }
    }
  }

  const onNewTagChange = (value, field) => {
    const nt = state.newGateway;
    const validationValues = { ...state.validate.addNewGateway, [field]: value };
    let isValid = true;

    Object.keys(validationValues).forEach(i => {
      if (i !== 'isValid' && !validationValues[i]?.length) isValid = false;
    });

    nt[field] = value;
    setState(state => ({
      ...state,
      newGateway: nt,
      validate: {
        addNewGateway: { ...validationValues, isValid: isValid }
      }
    }));
  };

  const handleCSVChange = event => {
    const reader = new FileReader();

    reader.readAsText(event.target.files[0]);
    reader.onload = function () {
      setState({ csvfile: reader.result, uploading: false });
    };

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  const uploadCSV = async () => {
    const csv = state.csvfile;
    const response = await axios.post('gateways/store/csv', {
      csv: csv
    });

    if (response.status === 200) {
      getGateways();
      toggleModal('csv_modal');
    }
  };

  const setDeleteGateways = () => {
    const selected = selection.selectedGateways;

    setState({ delete_gateways: selected });
    toggleModal('delete_modal');
  };

  const deleteGateways = async () => {
    const response = await axios.delete('gateways/archive', {
      data: {
        ids: state.delete_gateways
      }
    });

    setSelection({ selectAll: false, selectedGateways: [] });

    if (response.status === 200) {
      getGateways();
      toggleModal('delete_modal');
    }
  };

  return (
    <div className="gateway-table">
      <div>
        <Filters
          open={state.filters_open}
          updateFilters={filtersUpdated}
          onClose={filtersClose}
          filter_types={['farm']}
        />

        <Row>
          <Col xs="12" md="12" lg="12"></Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row className="pad-10">
                <Col xs="12" md="12" lg="6">
                  <Row>
                    <Col xs="12" md="12" lg="6" className="listing-heading">
                      <h4 className="">Kraal Gateways</h4>
                      <Breadcrumb>
                        <BreadcrumbItem>List of Kraal Gateways</BreadcrumbItem>
                      </Breadcrumb>
                    </Col>

                    <Col xs="12" md="12" lg="6"></Col>
                  </Row>
                </Col>
                <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
                  <Search
                    className="mr-2"
                    id="search-kraal-gateways"
                    placeholder="Search Kraal Gateways"
                    defaultValue={state.query}
                    handleChange={e => onChange(e.target.value, e.target.name)}
                    handleKeyPress={() => handleKeyPress(13)}
                  />

                  {state.actions && (
                    <AppDropdown
                      label="Actions"
                      items={state.actions.filter(item => item.isVisible())}
                      handleClick={action => action.handler()}
                    />
                  )}
                  {/*<Button*/}
                  {/*  color="primary"*/}
                  {/*  className="float-right mr-2"*/}
                  {/*  onClick={() => setState({ ...state, filters_open: !state.filters_open })}>*/}
                  {/*  <i className="fa fa-filter"></i>Filters*/}
                  {/*</Button>*/}
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <ReactTable
                    showPagination={gateways.length > 0}
                    minRows={0}
                    data={gateways}
                    columns={columns}
                    resizable={true}
                    defaultPageSize={25}
                    filterable={true}
                    defaultFilterMethod={filterCaseInsensitive}
                    onFetchData={props => {
                      const data = props.data.length ? props.sortedData.slice(0, props.pageSize) : gateways;
                      setState({ tableData: data });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={state.delete_modal} className={'delete-modal'}>
        <ModalBody>
          <br />
          <h5 className="text-center">
            <b>Archive gateways</b>
          </h5>
          <br />
          <br />
          <br />
          {selection.selectedGateways.some(id => gateways.find(g => g.id === id)?.connected_tags_count)
            ? 'This gateway has active tag connections. Archiving this gateway will disconnect all tags connected to this gateway. Are you sure you want to archive this gateway?'
            : 'Are you sure you want to archive selected gateways? This action cannot be undone.'}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal('delete_modal')}>
            Cancel
          </Button>{' '}
          <Button color="danger" onClick={() => deleteGateways()}>
            Archive
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="lg" isOpen={state.csv_modal}>
        <ModalBody>
          <br />
          <h5 className="text-center">
            <b>XLSX Upload</b>
          </h5>
          <br />
          <br />
          <br />
          <br />
          <div className="download-block">
            Download
            <CsvDownload data={GATEWAY_CSV_TEMPLATE} filename={'gateways-template.csv'}>
              example
            </CsvDownload>
            XLSX file.
          </div>

          <br />
          <br />
          <FormGroup>
            <Label>XLSX file</Label>
            <Input type="file" onChange={handleCSVChange}></Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal('csv_modal')}>
            Cancel
          </Button>{' '}
          <Button color="primary" onClick={() => uploadCSV()}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={state.add_modal} className={'add-modal'}>
        <ModalHeader>
          <h6>
            <b>Add Kraal gateway</b>
          </h6>
        </ModalHeader>
        <ModalBody>
          <br />
          <br />
          <Form>
            <FormGroup row>
              <Label sm="3">Appeui</Label>
              <Col sm="9">
                <Input
                  type="text"
                  placeholder="Appeui"
                  onChange={e => onNewTagChange(e.target.value, 'appeui')}
                  valid={state.validate.addNewGateway.appeui?.length}
                  invalid={!state.validate.addNewGateway.appeui?.length && state.validate.addNewGateway.appeui !== null}
                />
                <FormFeedback>Required</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm="3">Gweui</Label>
              <Col sm="9">
                <Input
                  type="text"
                  placeholder="Gweui"
                  onChange={e => onNewTagChange(e.target.value, 'gweui')}
                  valid={state.validate.addNewGateway.gweui?.length}
                  invalid={!state.validate.addNewGateway.gweui?.length && state.validate.addNewGateway.gweui !== null}
                />
                <FormFeedback>Required</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm="3">Identifier</Label>
              <Col sm="9">
                <Input
                  type="text"
                  placeholder="Identifier"
                  onChange={e => onNewTagChange(e.target.value, 'identifier')}
                  valid={state.validate.addNewGateway.identifier?.length}
                  invalid={
                    !state.validate.addNewGateway.identifier?.length && state.validate.addNewGateway.identifier !== null
                  }
                />
                <FormFeedback>Required</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm="3">Name</Label>
              <Col sm="9">
                <Input
                  type="text"
                  placeholder="Name"
                  onChange={e => onNewTagChange(e.target.value, 'name')}
                  valid={state.validate.addNewGateway.name?.length}
                  invalid={!state.validate.addNewGateway.name?.length && state.validate.addNewGateway.name !== null}
                />
                <FormFeedback>Required</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm="3">Version</Label>
              <Col sm="9">
                <Input
                  type="text"
                  placeholder="Version"
                  onChange={e => onNewTagChange(e.target.value, 'version')}
                  valid={state.validate.addNewGateway.version?.length}
                  invalid={
                    !state.validate.addNewGateway.version?.length && state.validate.addNewGateway.version !== null
                  }
                />
                <FormFeedback>Required</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm="3">Carrier Type</Label>
              <Col sm="9">
                <Input
                  type="text"
                  placeholder="Carrier Type"
                  onChange={e => onNewTagChange(e.target.value, 'carrier_type')}
                  valid={state.validate.addNewGateway.carrier_type?.length}
                  invalid={
                    !state.validate.addNewGateway.carrier_type?.length &&
                    state.validate.addNewGateway.carrier_type !== null
                  }
                />
                <FormFeedback>Required</FormFeedback>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal('add_modal')}>
            Cancel
          </Button>{' '}
          <Button color="primary" disabled={!state.validate.addNewGateway.isValid} onClick={() => addGateway()}>
            Add gateway
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default memo(GatewayTable);
