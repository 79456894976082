import axios from '../../axios';

const service = {
  getLocations() {
    console.log(123, 'getLocations');
    return axios({ method: 'get', url: '/geofences' });
  }
};

export default service;
