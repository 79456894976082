import React from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';

import axios from '../../axios';

import img2 from '../../assets/images/diagri-background.png';
import validators from './validators';

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

class Register2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      password_confirm: '',
      error: null,
      hide_password: true,
      hide_password_confirm: true,
      registered: false,
      agreement: false
    };
    this.validators = validators;
    this.onInputChange = this.onInputChange.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.formValidators = this.formValidators.bind(this);

    this.togglePassword = this.togglePassword.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }
  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });

    this.formValidators([event.target.name], event.target.value);
  }
  togglePassword(type = 'normal') {
    if (type === 'normal') {
      this.setState({
        hide_password: !this.state.hide_password
      });
    } else if (type === 'confirm') {
      this.setState({
        hide_password_confirm: !this.state.hide_password_confirm
      });
    }
    this.forceUpdate();
  }
  formValidators(fieldName, value) {
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;

    this.validators[fieldName].rules.forEach(rule => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }
  //Passwords do not match. Please try again.
  isFormValid() {
    let status = true;
    Object.keys(this.validators).forEach(field => {
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }
  showErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = '';
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });

      return <div className="error mb-2">{errors}</div>;
    }
    return result;
  }
  async doRegister(event) {
    event.preventDefault();
    event.stopPropagation();
    const { email, password } = this.state;
    try {
      let response = await axios.post('users', {
        email: email,
        password: password,
        app_type: 'dashboard'
      });

      if (response.status == 200) {
        this.setState({
          registered: true
        });
      }
    } catch (error) {
      let response = error.response;
      console.error(this.validators[response.data.field]);
      this.validators[response.data.field].valid = false;
      this.validators[response.data.field].errors = [];
      this.validators[response.data.field].errors.push(response.data.message);
      console.error(this.validators[response.data.field]);
      this.forceUpdate();
    }
  }
  render() {
    return (
      <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
        {/*--------------------------------------------------------------------------------*/}
        {/*Login2 Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div className="container">
          <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="5" className="bg-dark text-white">
                <div className="p-5">
                  {this.state.registered === false && (
                    <div>
                      <h4 className="font-login">Welcome to Kraal,</h4>
                      <h5 className="font-login-small">
                        your complete livestock tracking, monitoring and management system. <br />
                        <br />
                        Sign up to join Kraal
                      </h5>
                      <div className="logo-place"></div>
                    </div>
                  )}

                  {this.state.registered === true && (
                    <div>
                      <h4 className="font-login"> Thank you!</h4>
                      <div className="logo-place"></div>
                    </div>
                  )}
                </div>
              </Col>
              <Col md="6" lg="5" className="bg-white">
                {this.state.registered === false && (
                  <div className="p-5">
                    <h3 className="font-medium mb-3">Sign Up</h3>
                    <Form className="mt-3" id="loginform" action="/dashbaord">
                      <FormGroup className="mb-3">
                        <Label for="email" className="font-medium">
                          Email
                        </Label>
                        <InputGroup className="mb-2" size="lg">
                          <InputGroupText>
                            <i className="fas fa-at"></i>
                          </InputGroupText>
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.onInputChange}
                            placeholder="Email"
                          />
                        </InputGroup>
                      </FormGroup>
                      {this.showErrors('email')}
                      <FormGroup className="mb-3">
                        <Label for="password" className="">
                          Password
                        </Label>
                        <InputGroup className="mb-3" size="lg">
                          <InputGroupText>
                            <i className="fas fa-lock"></i>
                          </InputGroupText>

                          <Input
                            type={this.state.hide_password ? 'password' : 'text'}
                            value={this.state.password}
                            onChange={this.onInputChange}
                            name="password"
                            id="password"
                            placeholder="Password"
                            bsSize="lg"
                          />
                          <InputGroupText
                            onClick={() => {
                              this.togglePassword('normal');
                            }}>
                            <i className={`fas ${this.state.hide_password ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                      {this.showErrors('password')}
                      <FormGroup className="mb-3">
                        <Label for="password_confirm" className="">
                          Password
                        </Label>
                        <InputGroup className="mb-3" size="lg">
                          <InputGroupText>
                            <i className="fas fa-lock"></i>
                          </InputGroupText>

                          <Input
                            type={this.state.hide_password_confirm ? 'password' : 'text'}
                            value={this.state.password_confirm}
                            onChange={this.onInputChange}
                            name="password_confirm"
                            id="password_confirm"
                            placeholder="Password"
                            bsSize="lg"
                          />
                          <InputGroupText
                            onClick={() => {
                              this.togglePassword('confirm');
                            }}>
                            <i className={`fas ${this.state.hide_password_confirm ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>
                      {this.showErrors('password_confirm')}
                      <Input type="checkbox" id="exampleCustomCheckbox" label="I agree to all Terms" />
                      {this.showErrors('checkbox')}
                      <Row className="mb-3 mt-3">
                        <Col xs="12">
                          <Button
                            onClick={this.doRegister}
                            className={`text-uppercase`}
                            disabled={!this.isFormValid()}
                            color="primary"
                            size="lg"
                            type="submit"
                            block>
                            Sign Up
                          </Button>
                        </Col>
                      </Row>
                      <div className="text-center">
                        Already have an account?{' '}
                        <a href="/authentication/login2" className="text-info ml-1">
                          <b>Sign In</b>
                        </a>
                      </div>
                    </Form>
                  </div>
                )}
                {this.state.registered && (
                  <p className="register-text">
                    Thank you for registering with Kraal. You will receive a validation email shortly - please click on
                    the link within the email to start your experience on the Kraal livestock platform.
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Register2;
