import { REDUCERS } from '../../constants/reducers';

const initialState = {
  breeds: null,
  breedsError: null,
  breedsFilterOptions: [],
  breedsLoading: false,
  breedsSelectOptions: [],
  statuses: null,
  statusesError: null,
  statusesFilterOptions: [],
  statusesLoading: false
};

const breed = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.BREEDING_STATUSES.FETCH_DATA_REQUEST:
      return { ...state, statusesLoading: true, statusesError: null };
    case REDUCERS.BREEDING_STATUSES.FETCH_DATA_SUCCESS:
      return { ...state, statusesLoading: false, ...action.payload };
    case REDUCERS.BREEDING_STATUSES.FETCH_DATA_FAILURE:
      return { ...state, statusesLoading: false, statusesError: action.payload };
    case REDUCERS.BREEDS.FETCH_BREEDS_REQUEST:
      return { ...state, breedsLoading: true, breedsError: null };
    case REDUCERS.BREEDS.FETCH_BREEDS_SUCCESS:
      return { ...state, breedsLoading: false, ...action.payload };
    case REDUCERS.BREEDS.FETCH_BREEDS_FAILURE:
      return { ...state, breedsLoading: false, breedsError: action.payload };
    default:
      return state;
  }
};

export { breed };
