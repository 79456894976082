import { formatArrayToOptions } from '../../helpers/common';

import { REDUCERS } from '../../constants/reducers';

import { FarmService } from '../../services';

export const setFarmState = value => ({
  type: REDUCERS.FARM.SET_FARM_STATE,
  value
});

export const getFarms = () => {
  return async dispatch => {
    try {
      const { data } = await FarmService.getFarms();
      const payload = {
        list: data,
        options: formatArrayToOptions(data, 'name', 'id')
      };

      dispatch(setFarmState(payload));

      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
