import { memo } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import './styles.scss';

function AppTableSearchPopup({ selectedKeys, title, handleOnChange, handleReset, handleConfirm }) {
  return (
    <div className="" onKeyDown={e => e.stopPropagation()}>
      <div className="p-2">
        <Input
          placeholder={`Search ${title}`}
          prefix={<SearchOutlined />}
          value={selectedKeys[0]}
          onChange={handleOnChange}
        />
      </div>
      <div className="d-flex justify-content-between p-2" style={{ borderTop: '1px solid #e9ecef ' }}>
        <Button size="small" type="link" onClick={handleReset} disabled={selectedKeys.length === 0}>
          Reset
        </Button>
        <Button size="small" type="primary" onClick={handleConfirm}>
          Ok
        </Button>
      </div>
    </div>
  );
}

export default memo(AppTableSearchPopup);
