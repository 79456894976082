import { Component } from 'react';
import { CompactPicker } from 'react-color';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import classnames from 'classnames';

import axios from '../../axios';

import { isFieldAvailable } from '../../helpers/subscription';

import { FEATURE_LIST, PLANS_TYPE } from '../../constants/subscription';

import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';
import Map from '../../components/map';

import AppDropdown from '../ui-components/dropdown';

import zoom from '../../assets/images/zoom.png';
import countries from '../../countries.json';

const KEY_MAP = {
  identifier: 'Farm ID',
  name: 'Farm Name',
  full_address: 'Farm Address',
  size: 'Farm Size (ha)',
  herd_numbers: 'Farm Herd #',
  geofence_id: 'Farm Master Geofence ID',
  description: 'Farm Description',
  circumference: 'Farm Perimeter (km)',
  address: 'Farm Address',
  street: 'Farm Address',
  house_number: 'Farm House Number',
  city: 'Farm Town',
  postal_code: 'Farm Postal Code',
  country: 'Farm Country',
  animals_count: 'Assigned Livestock',
  expected_livestock: 'Expected Tracked Livestock',
  actual_livestock: 'Actual Tracked Livestock'
};
const ORDER = [
  'identifier',
  'name',
  'address',
  'house_number',
  'city',
  'postal_code',
  'country',
  'size',
  'circumference',
  'herd_numbers',
  'animals_count',
  'expected_livestock',
  'actual_livestock',
  'description'
];
const ADDRESS = ['address', 'street', 'house_number', 'city', 'postal_code', 'country'];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function FarmRows(props) {
  if (props.farm === null) return null;

  const editing = props.editing;
  const farm = editing ? props.farm_edit : props.farm;
  const blacklist_edit = [
    'size',
    'herd_nr',
    'geofence_id',
    'circumference',
    'rules',
    'identifier',
    'total_expected_livestock'
  ];
  const blacklist_full = ['full_address', 'user_id', 'address_id', 'created_at', 'updated_at', 'id'];
  const geoList = [];

  for (let geofence of farm.geofences) {
    if (!geofence.is_master) {
      geoList.push({ value: geofence.id, label: geofence.name, geofence: geofence });
    }
  }

  const FARM_INFO = [];
  FARM_INFO.push(
    <tr key={Math.random()}>
      <td colSpan="2">
        <h4>Farm {farm.name} Details</h4>
      </td>
    </tr>
  );

  let index = 0;
  const formattedOrder = ORDER.filter(fieldId =>
    isFieldAvailable(fieldId, props.subscription.myPlan?.type, FEATURE_LIST.FARM_DETAILS_FIELDS)
  );

  for (let key of formattedOrder) {
    let value = farm[key];

    if (blacklist_full.includes(key)) {
      continue;
    }

    if (key === 'address') {
      value = value.full_address;
    }

    if (key === 'house_number') {
      value = farm['address'];
      value = value.house_number;
    }

    if (key === 'city') {
      value = farm['address'];
      value = value.city;
    }

    if (key === 'postal_code') {
      value = farm['address'];
      value = value.postal_code;
    }

    if (key === 'country') {
      value = farm['address'];
      value = value.country;
    }

    if (key === 'herd_numbers') {
      value = value ? numberWithCommas(value) : '/';
    }

    if (key === 'size') {
      value = value ? numberWithCommas(value) : '/';
    }

    if (key === 'circumference') {
      value = value ? numberWithCommas(value) : '/';
    }

    if (Array.isArray(value)) {
      if (editing) {
        if (key !== 'geofences') {
          value = value.map(e => e.name);
          value = value.join(', ');
        }
      } else {
        value = value
          .filter(e => {
            if (!e.is_master) {
              return e;
            }
          })
          .map(e => e.name);
        value = value.join(', ');
      }
    }

    if (editing) {
      if (key === 'address') {
        key = 'street';
        value = farm['address'].street;
      }

      let INPUT = (
        <Input
          name={key}
          defaultValue={value}
          onChange={e => {
            props.onChange(e.target.value, e.target.name);
          }}></Input>
      );

      if (ADDRESS.includes(key)) {
        INPUT = (
          <Input
            name={key}
            defaultValue={value}
            onChange={e => {
              props.onAddressChange(e.target.value, e.target.name);
            }}></Input>
        );
      }

      if (key === 'geofences') {
        value = value
          .filter(e => {
            if (!e.is_master) {
              return e;
            }
          })
          .map(e => e.name);
        value = value.join(', ');
        INPUT = value;
      }

      if (key === 'description') {
        INPUT = (
          <Input
            type="textarea"
            name={key}
            defaultValue={value}
            onChange={e => {
              props.onChange(e.target.value, e.target.name);
            }}></Input>
        );
      }

      if (key === 'country') {
        INPUT = (
          <Select
            value={countries
              .map(x => {
                return { value: x, label: x };
              })
              .filter(x => x.value === value)}
            onChange={e => {
              props.handleSelectChange(e, key);
            }}
            options={countries.map(x => {
              return { value: x, label: x };
            })}
          />
        );
      }

      if (blacklist_edit.includes(key)) {
        INPUT = value;
      }

      FARM_INFO.push(
        <tr key={index}>
          <td className="v-align-middle">
            <b>{KEY_MAP[key]}</b>
          </td>
          <td>{INPUT}</td>
        </tr>
      );
    } else {
      if (value === 0) {
        FARM_INFO.push(
          <tr data-name={key} key={index}>
            <td className="v-align-middle">
              <b>{KEY_MAP[key]}</b>
            </td>
            <td>{value}</td>
          </tr>
        );
      } else {
        FARM_INFO.push(
          <tr data-name={key} key={index}>
            <td className="v-align-middle">
              <b>{KEY_MAP[key]}</b>
            </td>
            <td>{value || '/'}</td>
          </tr>
        );
      }
    }
    index++;
  }

  const colorPicker = (
    <tr>
      <td className="v-align-middle">
        <b>Farm Color</b>
      </td>
      <td>
        <CompactPicker name="colour" color={props.color} onChange={e => props.setColor(e.hex)}></CompactPicker>
      </td>
    </tr>
  );

  if (props.editing) {
    FARM_INFO.splice(3, 0, colorPicker);
  }

  return FARM_INFO;
}

class Farm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      color: '#3388ff',
      delete_modal: false,
      editing: false,
      farmId: null,
      farm: {
        // days_grazed: "",
        address: '',
        animals_count: '',
        circumference: '',
        description: '',
        full_address: '',
        geofence_id: '',
        geofences: [],
        id: null,
        name: '',
        rules: [],
        size: ''
      },
      farm_edit: {
        // days_grazed: "",
        address: '',
        animals_count: '',
        circumference: '',
        description: '',
        full_address: '',
        geofence_id: '',
        geofences: [],
        id: null,
        name: '',
        rules: [],
        size: ''
      },
      isFullScreen: false,
      selected_geofences: []
    };

    this.getFarm = this.getFarm.bind(this);
    this.multiselectChange = this.multiselectChange.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onFarmChangeAddress = this.onFarmChangeAddress.bind(this);
    this.onFarmEditChange = this.onFarmEditChange.bind(this);
    this.onMasterFenceUpdate = this.onMasterFenceUpdate.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.initActions = this.initActions.bind(this);
  }

  async componentDidMount() {
    this.getFarm();
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit farm',
          isVisible: () => true,
          handler: () => this.onChange(true, 'editing')
        },
        {
          label: 'Archive farm',
          isVisible: () => true,
          handler: () => this.toggleModal('delete_modal')
        }
      ]
    });
  }

  toggleFullscreen() {
    this.setState(state => ({ ...state, isFullScreen: !this.state.isFullScreen }));
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  onFarmChangeAddress = (value, field) => {
    this.setState(() => ({
      farm_edit: {
        ...this.state.farm_edit,
        address: {
          ...this.state.farm_edit.address,
          [field]: value
        }
      }
    }));
  };

  onChange = (value, field) => {
    this.setState(state => ({ ...state, [field]: value }));
    this.forceUpdate();
  };

  onCancelEdit = (value, field) => {
    this.setState(state => ({ ...state, farm_edit: this.state.farm, [field]: value }));
  };

  onFarmEditChange = (value, field) => {
    this.setState({ farm_edit: { ...this.state.farm_edit, [field]: value } });
  };

  onCountryChange = e => {
    const temp = { ...this.state.farm_edit };
    temp.address.country = e.value;
    this.setState({ temp });
  };

  onMasterFenceUpdate = value => {
    const fences = this.state.farm_edit.geofences;
    const index = fences.findIndex(e => e.is_master);
    fences[index]['geo_json'] = value;
    this.setState({
      farm_edit: {
        ...this.state.farm_edit,
        geofences: fences
      },
      farm: {
        ...this.state.farm,
        geofences: fences
      }
    });
  };

  multiselectChange = sle => {
    this.setState({ selected_geofences: sle });
  };

  async deleteFarm() {
    const farmId = this.props.match.params.id;
    await axios.delete('farms/' + farmId);
    this.props.history.push('/farms');
  }

  async getFarm() {
    const farmId = this.props.match.params.id;

    if (farmId) {
      const response = await axios.get('farms/' + farmId);
      const farm = response.data;
      farm.full_address = farm.address.full_address;
      const masterGeofence = farm.geofences.find(item => item.is_master);

      this.setState(state => ({
        ...state,
        farmId: farmId,
        color: masterGeofence.color,
        farm: JSON.parse(JSON.stringify(farm)),
        farm_edit: JSON.parse(JSON.stringify(farm)),
        selected_geofences: farm.geofences
          .filter(gf => !gf.is_master)
          .map(gf => {
            return {
              value: gf.id,
              label: gf.name,
              geofence: gf
            };
          })
      }));
    }
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  async saveFarm() {
    const farmId = this.props.match.params.id;
    const fences = this.state.farm_edit.geofences;
    const index = fences.findIndex(e => e.is_master);
    const farm = this.state.farm_edit;

    // EVENT TRIGGER FOR MAP 'SAVE' ACTION //
    if (document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top')) {
      document.querySelector('.leaflet-draw-actions.leaflet-draw-actions-top li a').click();
    }

    // WAIT FOR 200 MILISECONDS TO GET DATA //
    await setTimeout(() => {}, 300);

    await axios.put('farms/' + farmId, {
      id: farm.id,
      address: farm.address,
      name: farm.name,
      description: farm.description,
      color: this.state.color,
      herd_numbers: farm.herd_numbers !== null && farm.herd_numbers !== '' ? [farm.herd_numbers] : [],
      master_geofence: farm.geofences[index].geo_json,
      geofence_ids: this.state.selected_geofences.map(e => {
        if (e.value) {
          return e.value;
        }
      })
    });

    await this.getFarm();
    this.setState({ ...this.state, editing: false });
  }

  render() {
    const zoomImg = { backgroundImage: 'url(' + zoom + ')' };

    if (!this.state.farm.id) return null;

    return (
      <>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4 className="">
              {this.state.editing && 'Edit '}Farm {this.state.farm.name}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/farms">List of Farms</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Farm {this.state.farm.name}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              history={this.props.history}
              nextId={this.state.farm.next_id}
              prevId={this.state.farm.prev_id}
              path="farm"
            />
            {this.state.editing && (
              <>
                <Button color="primary" className="float-right" onClick={() => this.saveFarm()}>
                  Save
                </Button>
                <Button className="outline float-right" onClick={() => this.onCancelEdit(false, 'editing')}>
                  Cancel
                </Button>
              </>
            )}
            {!this.state.editing && (
              <AppDropdown
                className="d-flex justify-content-end"
                label="Actions"
                items={this.state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className={`
                    ${this.state.isFullScreen ? 'rg-full-screen icon-position' : ''} 
                    ${this.state.editing ? '' : 'rg-no-space'}
                    `}>
                  <Map
                    sites={this.state.farm.sites}
                    setAnimal={this.setAnimal}
                    className={this.state.editing ? 'editing' : ''}
                    farm={this.state.farm}
                    color={this.state.color}
                    editing={this.state.editing}
                    type="farm"
                    editFence={this.onMasterFenceUpdate}
                    newGeofence={this.onMasterFenceUpdate}
                    isFullScreen={this.state.isFullScreen}></Map>
                  <div className="full-screen-icon text-center" style={zoomImg} onClick={this.toggleFullscreen}></div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <FarmRows
                        key={1}
                        color={this.state.color}
                        setColor={color => {
                          this.setState({
                            color
                          });
                        }}
                        farm={this.state.farm}
                        farm_edit={this.state.farm_edit}
                        onAddressChange={this.onFarmChangeAddress}
                        editing={this.state.editing}
                        onChange={this.onFarmEditChange}
                        multiselectChange={this.multiselectChange}
                        selected_geofences={this.state.selected_geofences}
                        handleSelectChange={this.onCountryChange}
                        subscription={this.props.subscription}></FarmRows>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {this.state.editing && (
          <Row className="mb-4">
            <Col lg="12" className={this.state.isFullScreen ? 'rg-full-screen-btn' : ''}>
              <Button color="primary" className="float-right" onClick={() => this.saveFarm()}>
                Save
              </Button>
              <Button className="outline float-right" onClick={() => this.onCancelEdit(false, 'editing')}>
                Cancel
              </Button>
            </Col>
          </Row>
        )}
        {[PLANS_TYPE.ADVANCED, PLANS_TYPE.BASIC, PLANS_TYPE.TRIAL].includes(
          this.props.subscription.myPlan?.type
        ) ? null : (
          <Card>
            <Row className="pad-10 pb-0">
              <Col sm="12" className="mb-0">
                <h5>History</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => this.toggle('1')}>
                      Notifications
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <TabContent className="no-bg" activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Alerts type="full" query={{ farm_ids: [this.state.farmId] }}></Alerts>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        )}

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive Farm</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this farm? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteFarm()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(state => state)(Farm);
