import { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';

import classnames from 'classnames';
import moment from 'moment';

import axios from '../../axios';

import { getSearchParams } from '../../helpers/uri';

import messages from '../../constants/messages';

import { getFeatureOptions, getPlans } from '../../redux/actions/subscription';
import { getUserReq } from '../../redux/actions/user';

import CloseAccountModal from '../../components/Modals/CloseAccountModal';
import SubscriptionTable from '../../components/SubscriptionTable';
import { errorToastHandler, successToastHandler } from '../../components/action_notifier';

import AppDropdown from '../ui-components/dropdown';

import countries from '../../countries.json';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '3',
      countries: [],
      defaultCountryCode: '',
      editMode: false,
      user: {
        profile_picture_url: null,
        first_name: null,
        last_name: null,
        id: null,
        email: null,
        address: {
          id: null,
          full_address: null,
          addressees_name: null,
          street: null,
          house_number: null,
          house_name: null,
          city: null,
          postal_code: null,
          state: null,
          country: null,
          lat: null,
          lng: null
        },
        billingAddress: {
          id: null,
          full_address: null,
          addressees_name: null,
          street: null,
          house_number: null,
          house_name: null,
          city: null,
          postal_code: null,
          state: null,
          country: null,
          lat: null,
          lng: null
        },
        full_address: null,
        work_phone: null,
        private_phone: null,
        is_push_notify_enabled: null,
        is_email_notify_enabled: null,
        notify_email: null,
        notify_phone: null,
        farms: null,
        geofences: null
      },
      user_edit: {
        profile_picture_url: null,
        first_name: null,
        last_name: null,
        id: null,
        email: null,
        address: {
          id: null,
          full_address: null,
          addressees_name: null,
          street: null,
          house_number: null,
          house_name: null,
          city: null,
          postal_code: null,
          state: null,
          country: null,
          lat: null,
          lng: null
        },
        billingAddress: {
          id: null,
          full_address: null,
          addressees_name: null,
          street: null,
          house_number: null,
          house_name: null,
          city: null,
          postal_code: null,
          state: null,
          country: null,
          lat: null,
          lng: null
        },
        full_address: null,
        work_phone: null,
        private_phone: null,
        notify_email: null,
        notify_phone: null,
        is_push_notify_enabled: null,
        is_email_notify_enabled: null,
        farms: null,
        geofences: null,
        old_password: null,
        password: null,
        password_confirm: null
      }
    };

    this.getFeatureOptionsData = props.getFeatureOptionsData.bind(this);
    this.getPlansData = props.getPlansData.bind(this);
    this.getUserData = props.getUserData.bind(this);
    this._arrayBufferToBase64 = this._arrayBufferToBase64.bind(this);
    this.areFieldsValid = this.areFieldsValid.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.getUser = this.getUser.bind(this);
    this.initActions = this.initActions.bind(this);
    this.multiselectChange = this.multiselectChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickEditAction = this.onClickEditAction.bind(this);
    this.onCopyHomeAddress = this.onCopyHomeAddress.bind(this);
    this.onEditAnimalFileChange = this.onEditAnimalFileChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    try {
      const params = getSearchParams();

      if (params?.tab) this.setState({ activeTab: params.tab });

      this.getUser();
      this.getPlansData();
      this.getFeatureOptionsData();
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }

    const cntrs = [];

    for (let cnt of countries) {
      cntrs.push({ value: cnt, label: cnt });
    }

    this.setState({ countries: cntrs });
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit profile',
          isVisible: () => true,
          handler: () => this.onClickEditAction()
        },
        {
          label: 'Close my account',
          isVisible: () => !this.props.user.close,
          handler: () => this.onClickCloseAccountAction()
        },
        {
          label: 'Keep my account',
          isVisible: () => this.props.user.close,
          handler: () => this.onClickKeepAccountAction()
        }
      ]
    });
  }

  onChange = (value, field) => {
    this.setState({ user: { ...this.state.user, [field]: value } });
  };

  async getUser() {
    const response = await axios.get('users/me/account');
    const user = {
      profile_picture_url: response.data.user.profile_picture_url,
      first_name: response.data.user.first_name ? response.data.user.first_name : 'Your',
      last_name: response.data.user.last_name ? response.data.user.last_name : 'Name',
      id: response.data.user.id,
      email: response.data.user.email ? response.data.user.email : '/',
      backup_email: response.data.user.backup_email ? response.data.user.backup_email : '/',
      address: response.data.user.address ?? {},
      billingAddress: response.data.user.billingAddress ?? {},
      full_address: response.data.user?.address?.full_address ? response.data.user?.address?.full_address : '/',
      work_phone: response.data.user.work_phone ? response.data.user.work_phone : '/',
      private_phone: response.data.user.private_phone ? response.data.user.private_phone : '/',
      is_sms_notify_enabled: response.data.user.is_sms_notify_enabled,
      is_email_notify_enabled: response.data.user.is_email_notify_enabled,
      notify_email: response.data.user.notify_email,
      notify_phone: response.data.user.notify_phone,
      farms: response.data.farms,
      geofences: response.data.geofences
    };

    this.setState({ user: user, user_edit: { ...this.state.user_edit, ...user, address: { ...user.address } } });
  }

  onUserEditAddressChange(value, field, type = 'address') {
    const user_edit = { ...this.state.user_edit };
    user_edit[type][field] = value;

    this.setState(state => ({ ...state, user_edit: user_edit }));
  }

  multiselectChange(sle, type = 'address') {
    const user_edit = { ...this.state.user_edit };
    user_edit[type].country = sle.value;

    this.setState(state => ({ ...state, user_edit: user_edit }));
  }

  onUserEditChange(value, field) {
    if (field === 'is_email_notify_enabled' || field === 'is_sms_notify_enabled') {
      value = !this.state.user_edit[field];
    }

    this.setState({ user_edit: { ...this.state.user_edit, [field]: value } });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  areFieldsValid(fields) {
    for (const item of fields) {
      if (!item.value) {
        errorToastHandler(messages.FIELD_REQUIRED(item.title));

        return false;
      }
    }

    return true;
  }

  async saveProfile() {
    try {
      const user_edit = this.state.user_edit;
      const fields = [
        { value: user_edit.first_name, title: 'First name' },
        { value: user_edit.last_name, title: 'Last name' },
        { value: user_edit.email, title: 'Email' },
        { value: user_edit.backup_email, title: 'Backup email' },
        { value: user_edit.work_phone, title: 'Work phone' },
        { value: user_edit.private_phone, title: 'Mobile phone' },
        { value: user_edit.address.city, title: 'City' },
        { value: user_edit.address.country, title: 'Country' },
        { value: user_edit.address.postal_code, title: 'Postal code' },
        { value: user_edit.address.street, title: 'Street' },
        { value: user_edit.address.house_number, title: 'House number' },
        {
          value: user_edit.notify_phone,
          title: 'Mobile number for SMS notifications'
        }
      ];
      const isValid = this.areFieldsValid(fields);

      if (isValid) {
        user_edit.address.full_address =
          user_edit?.address?.full_address && user_edit?.address?.full_address.length > 0
            ? user_edit?.address?.full_address
            : null;
        user_edit.billingAddress.full_address =
          user_edit?.billingAddress?.full_address && user_edit?.billingAddress?.full_address.length > 0
            ? user_edit?.billingAddress?.full_address
            : null;

        await axios.put('users/me', this.state.user_edit);

        if (this.state.file) {
          await axios.put('users/me/profile_picture', {
            profile_picture: this.state.file
          });
        }

        this.toggle('3');
        this.getUserData();
        this.setState(state => ({ ...state, file: '', editMode: false }));
      }
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  onClickEditAction() {
    this.setState(state => ({ ...state, editMode: true }));
  }

  onClickCloseAccountAction() {
    this.setState(state => ({ ...state, isConfirmationOpen: true }));
  }

  async onClickKeepAccountAction() {
    const resp = await axios.put('users/keep');
    successToastHandler(resp.data.message);
    this.getUserData();
  }

  async closeAccount() {
    try {
      const resp = await axios.delete('users/close');
      successToastHandler(resp.data.message);
      this.getUserData();
      this.setState(state => ({ ...state, isConfirmationOpen: false }));
    } catch (e) {
      this.setState(state => ({ ...state, isConfirmationOpen: false }));
    }
  }

  cancelEditing() {
    this.setState(state => ({
      // ...state,
      file: null,
      editMode: false,
      user_edit: { ...state.user }
    }));
  }

  _arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  }

  getBase64(file) {
    return new Promise(resolve => {
      let document = '';
      const reader = new FileReader();
      document = reader.readAsArrayBuffer(file);
      reader.onload = function () {
        document = reader.result;
        let photo = `data:${file.type}; base64, ${this._arrayBufferToBase64(reader.result)}`;
        resolve(photo);
      }.bind(this);
      reader.onerror = function () {};

      return document;
    });
  }

  async onEditAnimalFileChange(e) {
    const photo = await this.getBase64(e.target.files[0]);

    this.setState(state => ({ ...state, file: photo }));
  }

  onCopyHomeAddress() {
    this.setState(state => ({
      ...state,
      user_edit: {
        ...state.user_edit,
        billingAddress: { ...state.user_edit.address }
      }
    }));
  }

  render() {
    if (!this.state.user.id) return null;

    let FARM_LIST = [];
    if (this.state.user.farms) {
      for (let farm of this.state.user.farms) {
        FARM_LIST.push(
          <li className="simpleListItem" key={farm.id}>
            <NavLink to={`/farm/` + farm.id}>
              {farm.name}
              <br />
              <small>{farm.address.full_address}</small>
            </NavLink>
          </li>
        );
      }
    }

    let GEOFENCE_LIST = [];
    if (this.state.user.geofences) {
      for (let fence of this.state.user.geofences) {
        GEOFENCE_LIST.push(
          <li className="simpleListItem" key={fence.id}>
            <NavLink to={`/geofence/` + fence.id}>{fence.name}</NavLink>
          </li>
        );
      }
    }

    let closeAccDescription = `If you close your account, you will lose all access, and your 
    account and data will be lost. To continue, select the button below.`;
    if (this.props.subscription?.myPlan?.id && this.props.subscription?.myPlan?.status === 'active') {
      const endDate = moment(this.props.subscription?.myPlan?.end_date).format('dddd, DD MMMM YYYY');
      closeAccDescription = `If you close your account, you will lose all access, and your account and data will be lost. 
        Your current subscription and access will continue until ${endDate}. 
        Your account will be closed after this date. To continue, select the button below.`;
    }

    return (
      <>
        <CloseAccountModal
          description={closeAccDescription}
          isOpen={this.state.isConfirmationOpen}
          onCancel={() => this.setState(state => ({ ...state, isConfirmationOpen: false }))}
          onSubmit={() => this.closeAccount()}
        />

        <Row style={{ marginBottom: '10px' }}>
          <Col xs="12" md="12" lg="6">
            <h4>My Profile</h4>
          </Col>
          <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
            {this.state.editMode ? (
              <>
                <Button color="secondary" className="outline float-right mr-3" onClick={() => this.cancelEditing()}>
                  Cancel
                </Button>{' '}
                <Button color="primary" className="float-right" onClick={() => this.saveProfile()}>
                  Save
                </Button>
              </>
            ) : (
              <AppDropdown
                label="Actions"
                items={this.state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="3">
            <Card>
              <CardBody>
                <div className="text-center mt-4">
                  <img
                    src={this.state.file || this.props.user.profile_picture_url}
                    className="rounded-circle"
                    width="150"
                    alt=""
                  />
                  <CardTitle className="mt-2">{this.state.user.first_name + ' ' + this.state.user.last_name}</CardTitle>

                  {/* <CardSubtitle> */}
                  {this.state.editMode && (
                    <Form>
                      <FormGroup>
                        <Label className="float-left">Avatar</Label>
                        <Input type="file" onChange={e => this.onEditAnimalFileChange(e)} />
                      </FormGroup>
                    </Form>
                  )}
                  {/* </CardSubtitle> */}
                </div>
              </CardBody>
              <CardBody className="border-top">
                <div>
                  <small className="text-muted">Email address </small>
                  <h6>{this.state.user.email}</h6>
                  <small className="text-muted">Backup Email address </small>
                  <h6>{this.state.user.backup_email}</h6>
                  <small className="text-muted pt-4 db">Work Phone</small>
                  <h6>{this.state.user.work_phone}</h6>
                  <small className="text-muted pt-4 db">Mobile Phone</small>
                  <h6>{this.state.user.private_phone}</h6>
                  <small className="text-muted pt-4 db">Address</small>
                  <h6>{this.state.user.full_address}</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" lg="9">
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => this.toggle('3')}>
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '5' })}
                    onClick={() => this.toggle('5')}>
                    Subscription
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <h5 className="seperator">
                            Farms
                            <a href="/farms">
                              <Button color="primary" className="float-right">
                                Go to Farms
                              </Button>
                            </a>
                          </h5>
                          <ul className="simpleList">{FARM_LIST}</ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <h5 className="seperator">
                            Geofences
                            <a href="/geofences">
                              <Button color="primary" className="float-right">
                                Go to Geofences
                              </Button>
                            </a>
                          </h5>
                          <ul className="simpleList">{GEOFENCE_LIST}</ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <Form>
                            <h5 className="seperator dispaly-5">
                              About You{' '}
                              {this.props.user.close ? (
                                <span style={{ color: '#e73a1e' }}>(Close requested)</span>
                              ) : null}
                            </h5>
                            <FormGroup>
                              <Label>Name</Label>
                              <Input
                                type="text"
                                name="first_name"
                                value={this.state.user_edit.first_name || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="Name"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Surname</Label>
                              <Input
                                type="text"
                                name="last_name"
                                value={this.state.user_edit.last_name || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="Last Name"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <h5 className="seperator dispaly-5">Home Address</h5>
                            <FormGroup>
                              <Label>House Number / Name / Farm Name</Label>
                              <Input
                                type="text"
                                name="house_number"
                                value={this.state.user_edit?.address?.house_number || ''}
                                onChange={e => this.onUserEditAddressChange(e.target.value, e.target.name)}
                                placeholder="“House Number / Name / Farm Name”"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Street Name</Label>
                              <Input
                                type="text"
                                name="street"
                                value={this.state.user_edit?.address?.street || ''}
                                onChange={e => this.onUserEditAddressChange(e.target.value, e.target.name)}
                                placeholder="Street Name"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>City / Town</Label>
                              <Input
                                type="text"
                                name="city"
                                value={this.state.user_edit?.address?.city || ''}
                                onChange={e => this.onUserEditAddressChange(e.target.value, e.target.name)}
                                placeholder="“City / Town”"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Postal Code</Label>
                              <Input
                                type="text"
                                name="postal_code"
                                value={this.state.user_edit?.address?.postal_code || ''}
                                onChange={e => this.onUserEditAddressChange(e.target.value, e.target.name)}
                                placeholder="“Postal Code”"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Country</Label>
                              <Select
                                name="country"
                                value={
                                  this.state.countries.filter(
                                    s => s.value === this.state.user_edit?.address?.country || ''
                                  )[0]
                                }
                                onChange={this.multiselectChange}
                                options={this.state.countries}
                                isMulti={false}
                                placeholder="“Country”"
                                isDisabled={!this.state.editMode}></Select>
                            </FormGroup>
                            <div
                              className="seperator"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                              <h5 style={{ marginBottom: 0 }}>Billing Address</h5>
                              {!!this.state.editMode && (
                                <Button color="primary" className="float-right" onClick={this.onCopyHomeAddress}>
                                  Copy Home Address
                                </Button>
                              )}
                            </div>

                            <FormGroup>
                              <Label>House Number / Name / Farm Name</Label>
                              <Input
                                type="text"
                                name="house_number"
                                value={this.state.user_edit?.billingAddress?.house_number || ''}
                                onChange={e =>
                                  this.onUserEditAddressChange(e.target.value, e.target.name, 'billingAddress')
                                }
                                placeholder="“House Number / Name / Farm Name”"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Street Name</Label>
                              <Input
                                type="text"
                                name="street"
                                value={this.state.user_edit?.billingAddress?.street || ''}
                                onChange={e =>
                                  this.onUserEditAddressChange(e.target.value, e.target.name, 'billingAddress')
                                }
                                placeholder="Street Name"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>City / Town</Label>
                              <Input
                                type="text"
                                name="city"
                                value={this.state.user_edit?.billingAddress?.city || ''}
                                onChange={e =>
                                  this.onUserEditAddressChange(e.target.value, e.target.name, 'billingAddress')
                                }
                                placeholder="“City / Town”"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Postal Code</Label>
                              <Input
                                type="text"
                                name="postal_code"
                                value={this.state.user_edit?.billingAddress?.postal_code || ''}
                                onChange={e =>
                                  this.onUserEditAddressChange(e.target.value, e.target.name, 'billingAddress')
                                }
                                placeholder="“Postal Code”"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Country</Label>
                              <Select
                                name="country"
                                value={
                                  this.state.countries.filter(
                                    s => s.value === this.state.user_edit?.billingAddress?.country || ''
                                  )[0]
                                }
                                onChange={e => this.multiselectChange(e, 'billingAddress')}
                                options={this.state.countries}
                                isMulti={false}
                                placeholder="“Country”"
                                isDisabled={!this.state.editMode}></Select>
                            </FormGroup>
                            <h5 className="seperator dispaly-5">Contact</h5>
                            <FormGroup>
                              <Label>Primary Email</Label>
                              <Input
                                type="email"
                                name="email"
                                value={this.state.user_edit.email || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="example@domain.com"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Backup Email</Label>
                              <Input
                                type="email"
                                name="backup_email"
                                value={this.state.user_edit.backup_email || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="example@domain.com"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Work Phone</Label>
                              <PhoneInput
                                value={this.state.user_edit.work_phone || ''}
                                onChange={phone => {
                                  this.onUserEditChange(phone, 'work_phone');
                                }}
                                className="phone-input"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Mobile Phone</Label>
                              <PhoneInput
                                value={this.state.user_edit.private_phone || ''}
                                onChange={phone => {
                                  this.onUserEditChange(phone, 'private_phone');
                                }}
                                className="phone-input"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>

                            <h5 className="seperator dispaly-5">Manage Password</h5>
                            <FormGroup>
                              <Label>Old Password</Label>
                              <Input
                                type="password"
                                name="old_password"
                                value={this.state.user_edit.old_password || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="********"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>New Password</Label>
                              <Input
                                type="password"
                                name="password"
                                value={this.state.user_edit.new_password || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="********"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Confirm New Password</Label>
                              <Input
                                type="password"
                                name="password_confirm"
                                value={this.state.user_edit.new_password_confirm || ''}
                                onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                placeholder="********"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            <h5 className="seperator dispaly-5">Notification Preferences</h5>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="is_sms_notify_enabled"
                                  checked={this.state.user_edit.is_sms_notify_enabled}
                                  onChange={e => this.onUserEditChange(e.target.value, e.target.name)}
                                  disabled={!this.state.editMode}
                                />{' '}
                                <span>SMS Notifications</span>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label>Mobile number for SMS notifications</Label>
                              <PhoneInput
                                value={this.state.user_edit.notify_phone || ''}
                                onChange={phone => {
                                  this.onUserEditChange(phone, 'notify_phone');
                                }}
                                className="phone-input"
                                disabled={!this.state.editMode}
                              />
                            </FormGroup>
                            {this.state.editMode ? (
                              <>
                                <Button color="primary" className="float-right" onClick={() => this.saveProfile()}>
                                  Save
                                </Button>
                                <Button
                                  color="secondary"
                                  className="outline float-right"
                                  onClick={() => this.cancelEditing()}>
                                  Cancel
                                </Button>
                              </>
                            ) : null}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody></CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <SubscriptionTable />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default connect(
  state => ({
    user: state.user.user,
    subscription: state.subscription
  }),
  dispatch => ({
    getUserData: () => {
      return dispatch(getUserReq());
    },
    getPlansData: () => {
      return dispatch(getPlans());
    },
    getFeatureOptionsData: () => {
      return dispatch(getFeatureOptions());
    }
  })
)(Profile);
