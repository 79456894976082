import axios from '../../axios';

const service = {
  getAllGateways() {
    return axios({
      method: 'get',
      url: '/gateways/all'
    });
  },

  getGateways(params) {
    return axios({
      method: 'get',
      url: '/gateways',
      params
    });
  },

  createNewGateway(payload) {
    return axios({
      method: 'post',
      url: '/gateways',
      data: payload
    });
  },

  searchGateways(params) {
    return axios({
      method: 'get',
      url: '/gateways/search',
      params
    });
  }
};

export default service;
